import React, { Component } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { fieldHasError } from "../../helpers/formValidators";
import { showToast } from "../../helpers/toast";
import DatePicker from "react-datepicker";
import "./LocationsDevices.scss";
import moment from "moment";
import timezoneMoment from "moment-timezone";

import "react-datepicker/dist/react-datepicker.css";

class ChooseDate extends Component {
  state = {
    startDate: "",
    endDate: "",
  };

  // componentDidMount() {
  //   console.log(this.props, "Hii1")
  //   this.props && this.props.startDate &&
  //   this.setState({ startDate: this.props.startDate });
  //   this.props && this.props.endDate &&
  //   this.setState({ endDate: this.props.endDate });
  // }

  handleCancel = () => {
    const { startDate, endDate, setNoDateSelected } = this.props;
    if (startDate && endDate) {
      setNoDateSelected({ nodate: false });
    } else {
      setNoDateSelected({ nodate: true });
    }
    this.props.toggleModal();
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.startDate === "" || this.state.endDate === "") {
      showToast("Please select date", "error");
    } else {
      // const startDate = moment(this.state.startDate).add(1, "days");

      // const endDate = moment(this.state.endDate);
      this.props.setStartDate({
        date: moment(this.state.startDate).toISOString(),
      });
      this.props.setEndDate({
        date: moment(this.state.endDate).toISOString(),
      });

      // if (endDate.diff(startDate, "days") > 90) {
      //   this.props.setExceeded({ exceeded: true });
      // } else {
      this.props.setExceeded({ exceeded: false });
      // }
      // let params = {
      //   startDate: moment(this.state.startDate.toUTCString()).format(
      //     "YYYY-MM-DD"
      //   ),
      //   endDate: moment(this.state.endDate.toUTCString()).format("YYYY-MM-DD"),
      //   page: 1,
      //   timeValue: 6,
      //   deviceId: this.props.modalData.deviceId,
      //   timezone: timezoneMoment.tz.guess(),
      // };
      // const timeDiff = 1;
      // this.props.getRawData({ ...params, timeDiff });
      // this.props.getGraphData(params);
      // this.props.setNoDateSelected({ nodate: false });

      this.props.toggleModal();
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    // const { mode } = this.state;
    let m = moment();
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    m.toISOString();
    m.format();

    return (
      <React.Fragment>
        <form className="mb-3" name="formCustom" onSubmit={this.onSubmit}>
          <ModalHeader>Choose Date</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="text-muted">Start Date</label>
              <div className="input-group with-focus">
                <DatePicker
                  type="date"
                  autoComplete="off"
                  placeholderText="Start Date"
                  className="form-control"
                  name="startDate"
                  invalid={this.hasError("formCustom", "StartDate", "required")}
                  data-validate='["required"]'
                  selected={this.state.startDate}
                  onFocus={(e) => (e.target.readOnly = true)}
                  onChange={(date) => {
                    this.setState({ startDate: date });
                  }}
                />
                {this.hasError("formCustom", "StartDate", "required") && (
                  <span className="invalid-feedback">
                    Start date is required
                  </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="text-muted">EndDate</label>
              <div className="input-group with-focus">
                <DatePicker
                  type="date"
                  name="endDate"
                  autoComplete="off"
                  placeholderText="End Date"
                  className="form-control"
                  placeholder="End Date"
                  invalid={this.hasError("formCustom", "endDate", "required")}
                  data-validate='["required"]'
                  selected={this.state.endDate}
                  onFocus={(e) => (e.target.readOnly = true)}
                  onChange={(date) => {
                    this.setState({ endDate: date });
                  }}
                />
                {this.hasError("formDevice", "deviceId", "required") && (
                  <span className="invalid-feedback">End date is required</span>
                )}
              </div>
            </div>
            {/* {this.state.startDate &&
              this.state.endDate &&
              moment(this.state.endDate).diff(
                moment(this.state.startDate),
                "days"
              ) > 90 && (
                <>
                  <p>
                    Note: Selected graph view is more than 90 days which exceeds
                    the graph limit. The Raw data is still available to download
                    via PDF or CSV.
                  </p> */}
            {/* <Row className="mb-3">
                    <Col xl={6} lg={12} md={6}>
                      <div className="mb-2">
                        <button
                          onClick={() =>
                            this.props.modalData.downloadRawDataPdfDownload(moment(this.state.startDate.toUTCString()).format("YYYY-MM-DD"), moment(this.state.endDate.toUTCString()).format("YYYY-MM-DD"))
                          }
                          type="button"
                          className="btn btn-info pull-right"
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa fa-download"
                            aria-hidden="true"
                          ></i>{" "}
                          PDF
                        </button>
                        <button
                          onClick={() =>
                            this.props.modalData.downloadRawDataCsvDownload(moment(this.state.startDate.toUTCString()).format("YYYY-MM-DD"), moment(this.state.endDate.toUTCString()).format("YYYY-MM-DD"))
                          }
                          type="button"
                          className="btn btn-info pull-right"
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa fa-download"
                            aria-hidden="true"
                          ></i>{" "}
                          CSV
                        </button>
                      </div>
                    </Col>
                  </Row> */}
            {/* </>

              )} */}
            {this.state.startDate &&
              this.state.endDate &&
              moment(this.state.endDate).diff(
                moment(this.state.startDate),
                "days"
              ) < 0 && (
                <p>
                  Please ensure that the End Date is greater than or equal to
                  the Start Date.
                </p>
              )}
            {this.state.startDate &&
              this.state.endDate &&
              m.diff(moment(this.state.startDate), "days") < 0 && (
                <p>No Data Available</p>
              )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              type="submit"
              disabled={
                (this.state.startDate &&
                  this.state.endDate &&
                  (moment(this.state.endDate).diff(
                    moment(this.state.startDate),
                    "days"
                  ) < 0 ||
                    // || this.state.startDate &&
                    // this.state.endDate &&
                    // moment(this.state.endDate).diff(
                    //   moment(this.state.startDate),
                    //   "days"
                    // ) > 90

                    m.diff(moment(this.state.startDate), "days") < 0)) ||
                moment().diff(moment(this.state.startDate)) < 0 ||
                moment().diff(moment(this.state.endDate)) < 0
              }
            >
              Save
            </Button>
            <Button color="secondary" type="button" onClick={() => this.props.toggleModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    startDate: state.locationsDevices.startDate,
    endDate: state.locationsDevices.endDate,
    gatewayList: state.gateways.gatewayList,
    locations: state.locationsDevices.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNoDateSelected: (nodate) => dispatch(actions.setNoDateSelected(nodate)),
    setExceeded: (exceeded) => dispatch(actions.setExceeded(exceeded)),
    setStartDate: (date) => dispatch(actions.setStartDate(date)),
    setEndDate: (date) => dispatch(actions.setEndDate(date)),
    getRawData: (data) => dispatch(actions.getRawData(data)),
    getGraphData: (data) => dispatch(actions.getGraphData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseDate);
