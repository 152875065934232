import { toast } from 'react-toastify';

export const showToast = (message, type, pos = 'bottom-center', autoClose = true) => {
    toast(message, {
        type: type,
        position: pos,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        hideProgressBar: true,
        autoClose: autoClose
    });
}