import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import { showToast } from "../../helpers/toast.js";
import * as actions from "../../stores/actions/index";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";

class AddUserMessageTemplate extends Component {
  countyCodeList = ["+44", "+91", "+1"];
  state = {
    form: {
      userId: "",
      templateId: "",
      type: "",
      method: "",
    },
    id: "",
    templates: [],
  };

  toggleDropDown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  getTempFn = (payload) => {
    const { getUserMessageTemplatesRel } = this.props;
    getUserMessageTemplatesRel(payload);
  };

  setTempFn = (payload) => {
    const { setUserMessageTemplateRel, toggleModal } = this.props;
    setUserMessageTemplateRel(payload);
    toggleModal();
  };

  componentDidMount() {
    this.submiButtonRef = React.createRef();
    const { modalData, getUserMessageTemplates } = this.props;
    getUserMessageTemplates();
    if (modalData && modalData.user) {
      this.setState({
        form: {
          userId: modalData.user.id,
          templateId: "",
          type: "",
          method: "",
        },
        id: "",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error && this.props.error) {
      // showToast(this.props.error, "error");
    }
    if (
      this.props.existingRel &&
      this.props.existingRel.length > 0 &&
      prevProps.existingRel !== this.props.existingRel
    ) {
      this.setState({
        form: {
          ...this.state.form,
          templateId: this.props.existingRel[0].templateId,
        },
        id: this.props.existingRel[0].id,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const state = onSubmit(e, this.state);
    this.setState(state);
    if (!state.hasError) {
      if (!this.state.form.userId) {
        showToast("User is not selected", "error");
        return;
      }
      if (!this.state.form.templateId) {
        showToast("Template is not selected", "error");
        return;
      }
      if (!this.state.form.type) {
        showToast("Type is not selected", "error");
        return;
      }
      if (!this.state.form.method) {
        showToast("Method is not selected", "error");
        return;
      }

      const params = {
        userId: this.state.form.userId,
        templateId: this.state.form.templateId,
        type: this.state.form.type,
        method: this.state.form.method,
      };
      this.setTempFn(params);
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    const { toggleModal, templates, loading, existingRelLoading } = this.props;

    return (
      <React.Fragment>
        <form className="mb-3" name="formUser" onSubmit={this.onSubmit}>
          <ModalHeader toggle={toggleModal}>Set Message Template</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="text-muted">Method</label>
              <div className="input-group with-focus">
                <select
                  style={{
                    backgroundColor: "#272934",
                    color: "#fff",
                    borderBottom: "1px solid black",
                    width: "100%",
                    padding: "9px",
                    opacity: "0.7",
                  }}
                  onChange={(data) => {
                    const value =
                      data.target.value == -1 ? "" : data.target.value;
                    this.setState({
                      form: {
                        ...this.state.form,
                        method: data.target.value,
                        templateId: "",
                      },
                    });
                    if (["SMS", "EMAIL"].includes(value)) {
                      if (
                        this.state.form.type !== "" &&
                        this.state.form.userId !== ""
                      ) {
                        this.getTempFn({
                          type: this.state.form.type,
                          userId: this.state.form.userId,
                          method: value,
                        });
                      }
                    }
                  }}
                  defaultValue={"-1"}
                >
                  <option value="-1">Select...</option>
                  <option value="SMS">SMS</option>
                  <option value="EMAIL">Email</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="text-muted">Type</label>
              <div className="input-group with-focus">
                <select
                  style={{
                    backgroundColor: "#272934",
                    color: "#fff",
                    borderBottom: "1px solid black",
                    width: "100%",
                    padding: "9px",
                    opacity: "0.7",
                  }}
                  onChange={(data) => {
                    const value =
                      data.target.value == -1 ? "" : data.target.value;
                    this.setState({
                      form: {
                        ...this.state.form,
                        type: data.target.value,
                        templateId: "",
                      },
                    });
                    if (
                      [
                        "HUMIDITY",
                        "TEMPERATURE",
                        "CO2",
                        "PRESSURE",
                        "WATER",
                        "BATTERY",
                        "DEVICE_OFFLINE",
                      ].includes(value)
                    ) {
                      if (
                        this.state.form.method !== "" &&
                        this.state.form.userId !== ""
                      ) {
                        this.getTempFn({
                          method: this.state.form.method,
                          userId: this.state.form.userId,
                          type: value,
                        });
                      }
                    }
                  }}
                  defaultValue={"-1"}
                >
                  <option value="-1">Select...</option>
                  <option value="HUMIDITY">Humidity</option>
                  <option value="TEMPERATURE">Temperature</option>
                  <option value="CO2">CO2</option>
                  <option value="PRESSURE">Pressure</option>
                  <option value="WATER">Water</option>
                  <option value="BATTERY">Battery</option>
                  <option value="DEVICE_OFFLINE">Device offline</option>
                </select>
              </div>
            </div>
            <>&nbsp;</>

            <div className="form-group">
              <label className="text-muted">Template</label>
              <div className="input-group with-focus">
                {existingRelLoading ? (
                  <div>Loading ...</div>
                ) : (
                  <select
                    style={{
                      backgroundColor: "#272934",
                      color: "#fff",
                      borderBottom: "1px solid black",
                      width: "100%",
                      padding: "9px",
                      opacity: "0.7",
                    }}
                    onChange={(data) =>
                      (this.state.form.templateId = data.target.value)
                    }
                    defaultValue={"-1"}
                  >
                    <option value="-1">
                      {this.state.form.templateId && this.state.id
                        ? "Remove template !!!"
                        : "Select..."}
                    </option>
                    {templates.map((el) => (
                      <option
                        value={el.id}
                        selected={
                          el.id === this.state.form.templateId ? "selected" : ""
                        }
                      >
                        {el.title}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.form.templateId && (
              <Button
                color="danger"
                onClick={(event) => {
                  this.state.form.templateId = -1;
                  this.submiButtonRef.current.click();
                  //event.target.form.submit();
                }}
              >
                {"Remove template"}
              </Button>
            )}
            <Button color="info" type="submit" innerRef={this.submiButtonRef}>
              {"Save"}
            </Button>
            <Button color="secondary" type="button" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.userMessageTemplates.loading,
    templates: state.userMessageTemplates.data,
    meta: state.userMessageTemplates.meta,
    success: state.userMessageTemplates.success,

    existingRel: state.userMessageTemplatesRel.data,
    existingRelLoading: state.userMessageTemplatesRel.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserMessageTemplatesRel: (data) =>
      dispatch(actions.getUserMessageTemplatesRel(data)),
    setUserMessageTemplateRel: (data) =>
      dispatch(actions.setUserMessageTemplateRel(data)),
    getUserMessageTemplates: (data) =>
      dispatch(actions.getUserMessageTemplates(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserMessageTemplate);
