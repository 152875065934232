import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utilities';

const initialState = {
    showModal: false,
    modalName: '',
    modalData: {},
    onCancel : null,
};

const toggleModal = (state, action) => {
    return updateObject(state, { 
        onCancel : action.action.onCancel,
        showModal: action.action.showModal, 
        modalName: action.action.modalName || '',
        modalData: action.action.modalData || {}});
};

const modal = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MODAL: return toggleModal(state, action);
        default:
            return state;
    }
};

export default modal;