import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";

export const getSubUserListStart = () => {
  return {
    type: actionTypes.GET_SUB_USER_LIST_START,
  };
};

export const getSubUserListSuccess = (data) => {
  return {
    type: actionTypes.GET_SUB_USER_LIST_SUCCESS,
    data: data,
  };
};

export const getSubUserListFail = (error) => {
  return {
    type: actionTypes.GET_SUB_USER_LIST_FAIL,
    error: error,
  };
};

export const getSubUsersList = (paramId) => {
  return (dispatch) => {
    dispatch(getSubUserListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.user.subUsersList}`;
    if (paramId) {
      url += `?parentId=${paramId}`;
    }
    // url = url.replace('{id}', userId);
    // if (page) {
    //   url += `?page=${page}&pageSize=10`;
    // } else {
    //   url += '?page=1&pageSize=1000';
    // }

    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getSubUserListSuccess(response.data));
      })
      .catch((error) => {
        // this part is wrong ken

        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 404) {
          // getSubUserIdsFail({error:[{message:'404 not found'}]})
        } else {
          dispatch(getSubUserListFail(error.response.data.errors));
        }
      });
  };
};
