import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import { showToast } from "../../helpers/toast.js";
import * as actions from "../../stores/actions/index";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle.js";

const DBFields = [
  {
    title: 'alarm.name',
    value: '{{!ALARM.name}}'
  },
  {
    title: 'alarm.minHumidity',
    value: '{{!ALARM.minHumidity}}'
  },
  {
    title: 'alarm.maxHumidity',
    value: '{{!ALARM.maxHumidity}}'
  },
  {
    title: 'alarm.minTemp',
    value: '{{!ALARM.minTemp}}'
  },
  {
    title: 'alarm.maxTemp',
    value: '{{!ALARM.maxTemp}}'
  },
  {
    title: 'alarm.minCarbon',
    value: '{{!ALARM.minCarbon}}'
  },
  {
    title: 'alarm.maxCarbon',
    value: '{{!ALARM.maxCarbon}}'
  },
  {
    title: 'alarm.maxPressure',
    value: '{{!ALARM.maxPressure}}'
  },
  {
    title: 'alarm.minPressure',
    value: '{{!ALARM.minPressure}}'
  },
  {
    title: 'graph.text',
    value: '{{!GRAPH.text}}'
  },
  {
    title: 'graph.url',
    value: '{{!GRAPH.url}}'
  },
  {
    title: 'device.name',
    value: '{{!DEVICE.name}}'
  },
  {
    title: 'device.id',
    value: '{{!DEVICE.id}}'
  },
  {
    title: 'device.uniqueNum',
    value: '{{!DEVICE.uniqueNum}}'
  },
  {
    title: 'location.name',
    value: '{{!LOCATION.name}}'
  },
  {
    title: 'incomming.value',
    value: '{{!VALUE}}'
  }
]
class AddMessageTemplate extends Component {
  countyCodeList = ["+44", "+91", "+1"];
  state = {
    mode: 'Add',
    form: {
      title: "",
      template: "",
    },
    id: ""
  };

  addMessageTemplateFn = (payload) => {
    const { addMessageTemplate } = this.props;
    addMessageTemplate(payload);
  };

  editMessageTemplateFn = (payload, id) => {
    const { editMessageTemplate } = this.props;
    editMessageTemplate({ payload, id });
  };

  componentDidMount() {
    const { modalData } = this.props;
    if (modalData && modalData.template) {
      const { template } = modalData;
      this.setState({
        mode: "Edit",
        form: {
          title: template.title,
          template: template.template,
        },
        id: template.id
      });
    } 
    else{
      this.setState({
        form: {
          title: "",
          template: ""
        }
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const state = onSubmit(e, this.state);
    this.setState(state);
    if (!state.hasError) {
      let title = this.state.form.title;
      if (!title || title.trim() === "") {
        showToast("Please Choose Title", "error");
        return;
      }
      let template = this.state.form.template;
      if (!template || template.trim() === "") {
        showToast("Please Choose Template", "error");
        return;
      }
      const params = {
        title: this.state.form.title,
        template: this.state.form.template,
      };
      if (state.mode === "Add") {
        this.addMessageTemplateFn(params);
      } else {
        this.editMessageTemplateFn(params, state.id);
      }
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    const { toggleModal } = this.props;

    return (
      <React.Fragment>
        <form className="mb-3" name="formAccount" onSubmit={this.onSubmit}>
          <ModalHeader toggle={toggleModal}>Title</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="text-muted">Title</label>
              <div className="input-group with-focus">
                <Input
                  type="text"
                  name="title"
                  placeholder="Title"
                  defaultValue={this.state.form.title}
                  onChange={(data)=>{
                    this.setState(
                      {
                        form: {
                          ...this.state.form,
                          title: data.target.value
                        }
                      })
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="text-muted">Template</label>
                <div className="input-group with-focus">
                  <select style={{
                    backgroundColor: "#272934", 
                    color: "#fff",
                    borderBottom: "1px solid black",
                    width: "100%",
                    padding: "9px",
                    opacity: "0.7"}}
                    onChange={(data) => {
                      const d = this.state.form.template + ` ${data.target.value}`;
                      this.setState(
                        {
                          form: {
                            ...this.state.form,
                            template: d
                          }
                        })
                    }}
                    defaultValue={''}
                    >
                    <option value="">Select...</option>
                    {DBFields.map((el, index) => (
                      <option key={index} value={el.value} >{el.title}</option>
                    )
                    )}
                  </select>
                </div>
            </div>
            <div className="form-group">
              <label className="text-muted">Template</label>
              <div className="input-group with-focus">
                <textarea
                  style={{
                      height: '150px',
                      width: '100%',
                      backgroundColor: 'rgb(39, 41, 52)',
                      color: 'rgb(255, 255, 255)',
                      borderBottom: '1px solid black',
                      padding: '9px',
                      opacity: '0.7'
                    }}
                  type="number"
                  name="template"
                  placeholder="Template"
                  value={this.state.form.template}
                  onChange={(data)=>{
                    this.setState(
                      {
                        form: {
                          ...this.state.form,
                          template: data.target.value
                        }
                      })
                  }}
                ></textarea>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="submit">
              Add
            </Button>
            <Button color="secondary" type="button" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.users.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMessageTemplate: (data) => dispatch(actions.addMessageTemplate(data)),
    editMessageTemplate: (data) => dispatch(actions.editMessageTemplate(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMessageTemplate);
