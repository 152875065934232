import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  deviceList: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const getDeviceListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    // deviceList: [],
    meta: {},
    loading: true,
  });
};

const getDeviceListSuccess = (state, action) => {
  return updateObject(state, {
    deviceList: action.deviceList,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getDeviceListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    deviceList: [],
    meta: {},
    loading: false,
  });
};

const deleteDeviceStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const deleteDeviceSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    // deviceList: []
  });
};

const deleteDeviceFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const addDeviceStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const addDeviceSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    // deviceList: []
  });
};

const addDeviceFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message || action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const editDeviceStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const editDeviceSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    success: "Device updated successfully",
    // deviceList: []
  });
};

const editDeviceFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message || action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const downloadDevicesStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const downloadDevicesSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    // deviceList: []
  });
};

const downloadDevicesFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message || action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const devices = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DEVICE_LIST_START:
      return getDeviceListStart(state, action);
    case actionTypes.GET_DEVICE_LIST_SUCCESS:
      return getDeviceListSuccess(state, action.data);
    case actionTypes.GET_DEVICE_LIST_FAIL:
      return getDeviceListFail(state, action);
    case actionTypes.DELETE_DEVICE_START:
      return deleteDeviceStart(state, action);
    case actionTypes.DELETE_DEVICE_SUCCESS:
      return deleteDeviceSuccess(state, action.data);
    case actionTypes.DELETE_DEVICE_FAIL:
      return deleteDeviceFail(state, action);
    case actionTypes.ADD_DEVICE_START:
      return addDeviceStart(state, action);
    case actionTypes.ADD_DEVICE_SUCCESS:
      return addDeviceSuccess(state, action.data);
    case actionTypes.ADD_DEVICE_FAIL:
      return addDeviceFail(state, action);
    case actionTypes.EDIT_DEVICE_START:
      return editDeviceStart(state, action);
    case actionTypes.EDIT_DEVICE_SUCCESS:
      return editDeviceSuccess(state, action.data);
    case actionTypes.EDIT_DEVICE_FAIL:
      return editDeviceFail(state, action);
    case actionTypes.DOWNLOAD_DEVICES_START:
      return downloadDevicesStart(state, action);
    case actionTypes.DOWNLOAD_DEVICES_SUCCESS:
      return downloadDevicesSuccess(state, action.data);
    case actionTypes.DOWNLOAD_DEVICES_FAIL:
      return downloadDevicesFail(state, action);
    default:
      return state;
  }
};

export default devices;
