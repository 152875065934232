import React from "react";
import { ButtonGroup, Button } from "reactstrap";

const Pagination = (props) => {
  const { pagination, changePage } = props;
  const { currentPage, totalPages } = pagination;
  return (
    <ButtonGroup className="align-items-center mr-3">
      <Button
        disabled={currentPage <= 1}
        color="info"
        className="btn-oval mr-1"
        onClick={() => changePage(1)}
      >
        <i className="fas fa-angle-double-left"></i>
      </Button>
      <Button
        disabled={currentPage <= 1}
        color="info"
        className="btn-oval"
        onClick={() => changePage(Number(currentPage - 1))}
      >
        <i className="fas fa-angle-left"></i>
      </Button>
      <label className="mb-0 ml-2 mr-2">
        {`${currentPage}/${totalPages}`}{" "}
      </label>
      <Button
        disabled={currentPage >= totalPages}
        color="info"
        className="btn-oval mr-1"
        onClick={() => changePage(Number(currentPage) + 1)}
      >
        <i className="fas fa-angle-right"></i>
      </Button>
      <Button
        disabled={currentPage >= totalPages}
        color="info"
        className="btn-oval mr-2"
        onClick={() => changePage(totalPages)}
      >
        <i className="fas fa-angle-double-right"></i>
      </Button>
    </ButtonGroup>
  );
};

export default Pagination;
