import React, { Component } from "react";
import { convertDateFormat } from "../../utils/helperFunctions";

import { MDBBtn, MDBDataTable } from "mdbreact";
import { Row, Col, Button } from "reactstrap";
import Pagination from "../Common/Pagination";
const columns = [
  {
    label: "Device ID",
    field: "deviceId",
    sort: "asc",
    width: 150,
  },
  {
    label: "Device Name",
    field: "name",
    sort: "asc",
    width: 150,
  },
  {
    label: "Gateway ID",
    field: "gatewayId",
    sort: "asc",
    width: 150,
  },
  {
    label: "Equipment type",
    field: "equipmentType",
    sort: "asc",
    width: 150,
  },
  {
    label: "Equipment Status",
    field: "equipmentStatus",
    sort: "asc",
    width: 150,
  },
  {
    label: "Device Offline Alerts",
    field: "offlineAlarmEnable",
    sort: "asc",
    width: 150,
  },
  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 150,
  },
  {
    label: "Location",
    field: "locationDisplay",
    sort: "asc",
    width: 150,
  },
  {
    label: "Action",
    field: "action",
    width: 80,
  },
];

class DeviceTable extends Component {
  state = {
    loadTable: false,
    gatewayList: [],
    pagination: {},
  };

  componentDidMount() {
    const { devices } = this.props;
    if (devices && devices.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.devices !== this.props.devices &&
      this.props.devices &&
      this.props.devices.length
    ) {
      this.showTable(true);
    }
  }

  showTable = (loadTable) => {
    if (loadTable) {
      const { devices, editDevice, deleteDevice, pagination } = this.props;

      const rows = devices.map((device, index) => {
        let deviceRow = { ...device };
        delete deviceRow["userId"];
        deviceRow.gatewayId =
          (deviceRow.gateway && deviceRow.gateway.name) || "-";
        deviceRow.locationDisplay =
          (deviceRow.location && deviceRow.location.name) || "-";
        deviceRow.equipmentType = deviceRow.equipmentType || "N/A";
        deviceRow.equipmentStatus = deviceRow.equipmentStatus
          ? "Active"
          : "Inactive";
        deviceRow.offlineAlarmEnable = deviceRow.offlineAlarmEnable
          ? "Enabled"
          : "Disabled";
        deviceRow.createdAt = convertDateFormat(
          deviceRow.createdAt,
          "shortDateTime"
        );
        deviceRow.action = (
          <>
            <MDBBtn
              onClick={deleteDevice.bind(this, deviceRow.id)}
              tag="a"
              rounded
              size="sm"
              className="px-2 transparentBtn"
            >
              <i className="fas fa-trash mt-0"></i>
            </MDBBtn>
            <MDBBtn
              onClick={editDevice.bind(this, deviceRow)}
              tag="a"
              rounded
              size="sm"
              className="px-2 transparentBtn"
            >
              <i className=" fas fa-pencil-alt mt-0"></i>
            </MDBBtn>
          </>
        );
        return deviceRow;
      });
      this.setState({
        loadTable,
        deviceList: {
          columns: columns,
          rows: rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { changePage } = this.props;
    const { loadTable, deviceList, pagination } = this.state;

    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort"
              bordered
              data={deviceList}
              paging={false}
              scrollX
            />
            <Row className="mt-3 mb-3 text-center">
              <Col lg={12}>
                {/* {pagination.totalPages !== pagination.currentPage &&
                  <Button
                    color="info"
                    onClick={changePage}
                  >Load More
                  </Button>
                } */}
                {/* <label>
                  {/* Showing {pagination.currentPage * 10 - 9} to{' '}
                  {pagination.currentPage * 10} of all results 
                </label> */}
              </Col>
              {/* <Col lg={6} >
      
                {/* <Pagination
                  pagination={pagination}
                  changePage={changePage}></Pagination> 
            </Col> */}
            </Row>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default DeviceTable;
