import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";
import { getTimeZones } from "@vvo/tzdb";
import "../Dashboard/LocationDevices/index.styles.scss";
import "../Dashboard/DeviceStatus/index.styles.scss";
import { showToast } from "../../helpers/toast";

class AddMap extends Component {
  state = {
    browseImage: { preview: '', data: '' },
    imageTitle: '',
    uploadStatus: ''
  };
  imageUploadRef = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    
  }
  
  componentDidMount() {
    console.log("===== this.props ", this.props)
  }


  onSubmit = async (e) => {
    const {modalData} = this.props;
    e.preventDefault()
    if(!this.state.browseImage.data){
      showToast('Please select image !!!', "error");
      return false;
    }
    if(!this.state.imageTitle || !this.state.imageTitle.trim()){
      showToast('Please select title !!!', "error");
      return false;
    }
    let formData = new FormData()
    formData.append('file', this.state.browseImage.data);
    formData.append('title', this.state.imageTitle);
    const headers = getCommonHeaders();
    const response = await fetch(`${apiPath.baseUrl}${apiPath.map.uploadMap}`, {
      method: 'POST',
      headers: headers,
      body: formData,
    })

    if (response && response.ok) {
      modalData.setUploadStatus("Saved");
      showToast('Map upoaded succesfully', "success");
    }
    else{
      showToast('Something went wrong', "error");
    }
  };

  handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
    this.setState({browseImage: img})
  }
  render() {
    const { toggleModal} = this.props;
    return (
      <React.Fragment>
        <form className="mb-3" name="formDevice" onSubmit={this.onSubmit}>
          <ModalHeader toggle={toggleModal}>Add map</ModalHeader>
          <ModalBody>
            
            <div className="form-group" style={{textAlign: 'center'}}>
              <div>
                {this.state.browseImage.preview && <img src={this.state.browseImage.preview} height='200' width="400" />}
              </div>
              <div>
                <div onClick={() => {
                  this.imageUploadRef.current.click()
                }} style={{cursor: 'pointer'}}>
                  <span className="icon-cloud-upload"></span> Upload image
                </div>
                
                <input type='file' name='file' onChange={this.handleFileChange} ref={this.imageUploadRef} style={{'display': 'none'}} accept="image/*"></input>
              </div>
            </div>
            <div className="form-group">
              <label className="text-muted">Title</label>
              <Input type='text' name='text' onChange={(e) => {
                this.setState({imageTitle: e.target.value})
              }} value={this.state.imageTitle} />
            </div>
            
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <div>
              <Button color="info" type="submit" className="mr-2">
                 Add
              </Button>
              <Button color="secondary" type="button" onClick={toggleModal}>
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

export default AddMap;
