import React, { Component } from "react";
import {
  Nav,
  Collapse,
  NavItem,
  NavLink,
  NavbarToggler,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { NavLink as Link, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import HeaderRun from "./Header.run";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import { LoopCircleLoading } from "react-loadingg";
import "./index.scss";
import ThemeSwitch from "./ThemeSwitch.js";
// import ProfileDropdown from "./Profile.js";
class HeaderHorizontal extends Component {
  state = {
    isOpen: false,
    userRole: "",
    userEmail: "",
    loading: true,
    shouldOpenMenu: false,
  };

  componentDidMount() {
    const { userRole, userEmail } = this.props;
    this.props.getTempStatus();
    HeaderRun();
    let loading = false;
    if (!userRole.length) {
      loading = true;
    }
    if (!userEmail.length) {
      loading = true;
    }
    this.setState({
      userRole: userRole,
      userEmail: userEmail,
      loading: loading,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token && !this.props.token) {
      showToast("Logged out successfully", "success");
      const history = useHistory();
      history.push("login");
    }
    if (
      prevProps.successMessage !== this.props.successMessage &&
      this.props.successMessage
    ) {
      showToast(this.props.successMessage, "success");
      this.props.getTempStatus();
      this.props.getRecentAlarmList();
    }
    if (prevProps.userRole !== this.props.userRole) {
      let loading = false;
      if (!this.props.userRole.length) {
        loading = true;
      }
      this.setState({
        userRole: this.props.userRole,
        loading: loading,
      });
    }
    if (prevProps.userEmail !== this.props.userEmail) {
      let loading = false;
      if (!this.props.userEmail.length) {
        loading = true;
      }
      this.setState({
        userEmail: this.props.userEmail,
        loading: loading,
      });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName: modalName,
      modalData: modalData,
    });
  };

  logout = () => {
    this.showModal("logoutModal");
  };

  toggleTempModal = () => {
    this.showModal("toggleTempModal", {
      toggleTemp: this.props.toggleTemp,
    });
  };

  render() {
    const { userRole, loading, userEmail } = this.state;

    const email = "Tanja.Weis@med.uni-heidelberg.de";
    const labEmail = "labrepairproseurope@gmail.com";

    return (
      <header className="topnavbar-wrapper">
        {loading ? (
          <div className="loaderHeader d-flex justify-content-center">
            <LoopCircleLoading />
          </div>
        ) : null}
        {/* START Top Navbar */}
        <nav className="navbar topnavbar navbar-expand-lg navbar-light">
          {/* START navbar header */}
          <div className="navbar-header ml-3">
            <div className="navbar-brand">
              <div className="brand-logo">
                <Link
                  to={{ pathname: "https://www.lariantech.com" }}
                  target="_blank"
                >
                  <img
                    className="img-fluid img-dark"
                    src="img/LARIAN-TECH.png"
                    alt="App Logo"
                  />
                  <img
                    className="img-fluid img-light"
                    src="img/LARIAN-TECH-2.png"
                    alt="App Logo"
                  />
                </Link>
              </div>
              <div className="brand-logo-collapsed">
                <img
                  className="img-fluid"
                  src="img/logo-single.png"
                  alt="App Logo"
                />
              </div>
            </div>
            <NavbarToggler onClick={this.toggle} />
          </div>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar className="mr-auto flex-column flex-lg-row">
              {!userRole ? null : userRole === "super_admin" ? (
                <>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="users"
                    >
                      Users
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="gateways"
                    >
                      Gateways
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="otaDevices"
                    >
                      Node Devices
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="gatewayLogs"
                    >
                      Gateway Logs
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="alarms"
                    >
                      Alarms
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="accounts"
                    >
                      Accounts
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="userNotifications"
                    >
                      User Notifications
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="UserNotificationStatistics"
                    >
                      User Notifications Statistics
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="UserMessageTemplates"
                    >
                      Message Templates
                    </Link>
                  </NavItem>
                  <Switch>{/* <Redirect from="/" to="/users" /> */}</Switch>
                </>
              ) : userRole === "super_user" || userRole === "user" ? (
                <>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="dashboard"
                    >
                      Dashboard
                    </Link>
                  </NavItem>
                  <NavItem className="order-1">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="reports"
                    >
                      Reports
                    </Link>
                  </NavItem>
                  {userRole !== "user" && (
                    <NavItem className="order-1">
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to="alertProfile"
                      >
                        Alert Profiles
                      </Link>
                    </NavItem>
                  )}
                  <NavItem className="order-1">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="Map"
                    >
                      Map
                    </Link>
                  </NavItem>
                  {(userEmail.toLowerCase() === email.toLowerCase() ||
                    userEmail.toLowerCase() === labEmail.toLowerCase()) && (
                    <NavItem className="order-1">
                      <Link
                        to={{
                          pathname:
                            userEmail === "Tanja.Weis@med.uni-heidelberg.de"
                              ? "http://dzhk.u213242.abus-server.com/"
                              : "http://lariantech.u203786.abus-server.com/doc/page/preview.asp",
                        }}
                        className="nav-link"
                        target="_blank"
                      >
                        Video
                      </Link>
                    </NavItem>
                  )}
                  <Switch>{/* <Redirect from="/" to="/dashboard" /> */}</Switch>
                </>
              ) : null}

              {userRole === "super_user" && (
                <>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="devices"
                    >
                      Devices
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="gateways"
                    >
                      Gateways
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="users"
                    >
                      Users
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="audittrail"
                    >
                      Audit
                    </Link>
                  </NavItem>

                  {/* <NavItem>
                    <Link className='nav-link' activeClassName='active' to='mqtt'>
                      MQTT
                    </Link>
                  </NavItem> */}
                </>
              )}
            </Nav>
            <Nav navbar className="flex-row">
              <ThemeSwitch />
            </Nav>
            <Nav className="flex-row" navbar>
              {/* Search icon */}
              {/*<NavItem>
                <NavLink data-search-open=''>
                  <em className='icon-magnifier'></em>
                </NavLink>
              </NavItem>*/}
              {/* <NavItem style={{ minWidth: "60px", fontSize: "30px" }}> */}
              {!this.state.isOpen && (
                <div className="d-flex">
                  <Dropdown
                    isOpen={this.state.shouldOpenMenu}
                    onClick={() =>
                      this.setState({
                        shouldOpenMenu: !this.state.shouldOpenMenu,
                      })
                    }
                  >
                    <DropdownToggle className="dropdown-toggle">
                      <i
                        class="fa fa-user-circle"
                        style={{
                          color: "var(--navbar-header-color)",
                          fontSize: "1.5rem",
                        }}
                      ></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-toggle-menu">
                      <DropdownItem header className="dropdown-color">
                        {userEmail}
                      </DropdownItem>
                      <DropdownItem
                        className=" logout-container dropdown-color"
                        onClick={() => {
                          this.toggleTempModal();
                        }}
                      >
                        <i className="fa fa-wrench" aria-hidden="true"></i>
                        <span>Settings</span>
                      </DropdownItem>
                      <DropdownItem
                        text
                        className="logout-container dropdown-color"
                        onClick={this.logout}
                      >
                        <em className="icon-logout"></em>
                        <span>Log out</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
              {this.state.isOpen && (
                <>
                  <NavItem style={{ minWidth: "60px", fontSize: "30px" }}>
                    <NavLink
                      onClick={this.toggleTempModal}
                      className="logout-container"
                    >
                      <i className="fa fa-wrench" aria-hidden="true"></i>
                      <span>Settings</span>
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ minWidth: "60px", fontSize: "30px" }}>
                    <NavLink onClick={this.logout} className="logout-container">
                      <em className="icon-logout"></em>
                      <span>Log out</span>
                    </NavLink>
                  </NavItem>{" "}
                </>
              )}
            </Nav>
          </Collapse>
          <form className="navbar-form" role="search" action="search.html">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Type and hit enter ..."
              />
              <div
                className="fa fa-times navbar-form-close"
                data-search-dismiss=""
              ></div>
            </div>
            <button className="d-none" type="submit">
              Submit
            </button>
          </form>
          {/* END Search form */}
        </nav>
        {/* END Top Navbar */}
      </header>
    );
  }
}

// export default HeaderHorizontal;
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    success: state.auth.token,
    dashboard: state.dashboard.dashboard,
    successMessage: state.auth.successMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    getUserMeta: () => dispatch(actions.getUserMeta()),
    getUserMetaStart: () => dispatch(actions.getUserMetaStart()),
    setModal: (data) => dispatch(actions.setModal(data)),
    toggleTemp: (data) => dispatch(actions.toggleTemp(data)),
    getTempStatus: () => dispatch(actions.getTempStatus()),
    getRecentAlarmList: () => dispatch(actions.getRecentAlarmList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderHorizontal));
