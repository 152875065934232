import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import "./index.styles.scss";
import { convertDateFormat } from "../../../utils/helperFunctions";
import { Row, Col } from "reactstrap";

const RecentDoorOpening = ({
  recentDoors,
  onMinimizeToggle,
  isExpanded,
  getRecentDoorsList,
}) => {
  const [isMinimized, setIsMinimized] = useState(isExpanded);
  const [page, setPage] = useState(null);

  const ra_columns = [
    {
      label: "Device",
      field: "deviceName",
      sort: "asc",
      width: 150,
    },
    {
      label: "Location",
      field: "location",
      sort: "asc",
      width: 126,
    },
    {
      label: "User",
      field: "user",
      sort: "asc",
      width: 150,
    },
    {
      label: "Time opened",
      field: "timeOpened",
      sort: "asc",
      width: 100,
    },
    {
      label: "Time closed",
      field: "timeClosed",
      sort: "asc",
      width: 98,
    },

    {
      label: "Date",
      field: "createdAt",
      sort: "asc",
      width: 150,
    },
  ];

  const ra_rows = recentDoors
    ? recentDoors.data &&
      recentDoors.data.map((alarm) => {
        let alarmRow = { ...alarm };
        alarmRow.createdAt = convertDateFormat(alarmRow.createdAt, "shortDate");

        alarmRow.deviceName = alarm.deviceName;
        alarmRow.user = alarmRow.user;
        alarmRow.timeOpened =
          moment(alarmRow.timeOpened).format("hh:mm:ss") === "Invalid date"
            ? "N/A"
            : moment(alarmRow.timeOpened).format("hh:mm:ss");
        alarmRow.timeClosed =
          moment(alarmRow.timeClosed).format("hh:mm:ss") === "Invalid date"
            ? "N/A"
            : moment(alarmRow.timeClosed).format("hh:mm:ss");
        // return alarmRow;
        alarmRow.location = alarmRow.location ? alarmRow.location : "N/A";
        // console.log(alarmRow);
        return alarmRow;
      })
    : [];

  const recentDoorList = {
    columns: ra_columns,
    rows: ra_rows,
  };

  const toggleMinimize = () => {
    const newMinimizedState = !isMinimized;
    setIsMinimized(newMinimizedState);
    onMinimizeToggle(newMinimizedState);
  };

  const { data, ...paging } = recentDoors;

  useEffect(() => {
    if (page >= 1) {
      getRecentDoorsList(page);
    }
  }, [page]);

  return (
    <Card
      className={`recent-alarms ${isMinimized ? "minimized" : ""}`}
      style={{
        width: "100%",
        marginBottom: "0.35rem !important",
        border: "1px solid var(--dropdown-border-bg)",
        borderRadius: "0.25rem",
      }}
    >
      <CardHeader className="recent-alarms__header">
        <CardTitle>Recent door openings</CardTitle>
        <button onClick={toggleMinimize}>
          {isMinimized ? (
            <>
              <i className="fa fa-chevron-down" aria-hidden="true"></i> Maximize
            </>
          ) : (
            <>
              <i className="fa fa-chevron-up" aria-hidden="true"></i> Minimize
            </>
          )}
        </button>
      </CardHeader>
      <div
        style={{
          height: "1px",
          width: "100%",
          background: "#ccc",
        }}
      />
      {!isMinimized && (
        <CardBody
          className={`recent-alarms__body ${isMinimized ? "minimized" : ""}`}
          style={{ padding: "0px", position: "relative" }}
        >
          <>
            <MDBDataTable
              className="recentAlarmTable border-0"
              data={recentDoorList}
              paging={false}
              searching={false}
              scrollY
              scrollX
              maxHeight="193px"
            />
            <Row
              className="mt-3 mb-3"
              style={{
                position: "absolute",
                maxHeight: "20%",
                bottom: "-35px",
                right: "3%",
                backgroundColor: "#0000",
                borderRadius: "5px",
                width: "98%",
              }}
            >
              <Col
                lg={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label style={{ marginBottom: 0 }}>
                  Showing {(paging.page || 1) * 10 - 9} to{" "}
                  {(paging.page || 1) * 10} of all results
                </label>
              </Col>
              <Col
                lg={6}
                className="text-right d-flex"
                style={{
                  width: "100%",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "flex-end",
                }}
              >
                <i
                  className="fas fa-angle-double-left mr-4 fa-1x"
                  onClick={() => {
                    if (page > 1) {
                      setPage((page || 1) - 1);
                    }
                  }}
                ></i>
                <i
                  className="fas fa-angle-double-right fa-1x"
                  onClick={() => {
                    if (page !== paging.total) {
                      setPage((page || 1) + 1);
                    }
                  }}
                ></i>
              </Col>
            </Row>
          </>
        </CardBody>
      )}
    </Card>
  );
};

export default RecentDoorOpening;
