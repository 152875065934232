import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { withNamespaces, Trans } from "react-i18next";
import ContentWrapper from "../Layout/ContentWrapper";
import { Row, Col } from "reactstrap";
import { convertDateFormat } from "../../utils/helperFunctions";
import socketIOClient from "socket.io-client";
import { apiPath } from "../../config/config";
import moment from "moment";
import timezoneMoment from "moment-timezone";
import "./Dashboard.scss";
import LocationDevices from "./LocationDevices";
import CustomLocation from "./LocationDevices/custom-location";
import Status from "./Status";
import CustomStatus from "./Status/custom-connected-devices";
import RecentAlarms from "./RecentAlarms";
import DeviceData from "./DeviceData";
import DeviceStatus from "./DeviceStatus";
import CustomDeviceStatus from "./DeviceStatus/custom-device-status";
import RecentDoorOpening from "./RecentDoors";

let socket;
let chart;
let temperature = [];
let humidity = [];
let carbon = [];
let categories = [];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }
  state = {
    activeTab: "1",
    rawDataInterval: 0.5,
    curretPage: 1,
    deviceId: "",
    deviceName: "",
    clickFromRecentAlarms: false,
    device_id: localStorage.getItem("device_id") || "",
    currentDevice: {},
    timeValue: 0,
    basicData: [
      {
        temperature: "",
        humidity: "",
        carbon: "",
      },
    ],
    datetime: {
      lastTime: "",
      currentDeviceId: "",
    },
    rows: "",
    devicesStatusData: "",
    testData: [],
    activeStatus: [],
    alarmsData: [],
    removeAlarm: [],
    list: [
      "status",
      "location",
      "deviceStatus",
      "locationDevices",
      "deviceData",
    ],
    isRecentDoorMinimized: this.loadStateFromLocalStorage(),
    userEmail: "",
  };

  componentDidMount() {
    const { dashboard } = this.props;
    const { userInfo } = dashboard;
    this.props.getUserList(0);
    this.props.getTempStatus();
    let userRole = "";
    if (userInfo) {
      const { role } = userInfo;
      userRole = role;
    }
    // if (userRole === "super_user") {
    this.props.getRecentAlarmList();
    this.props.getRecentDoorsList();
    // this.props.getRecentDoorsList();
    // }
    this.props.getDeviceConectedStatus();
    this.props.getDeviceStatus();
    const endpoint = `${apiPath.baseUrl}`;

    this.alarmSocket = socketIOClient(endpoint, { transports: ["websocket"] });

    this.alarmSocket.on("alarmData", (msg) => {
      this.props.getRecentAlarmList();
      this.props.getRecentDoorsList();
    });
    // this.alarmSocket.on("recentDoorData", (msg) => {
    //   this.props.getRecentDoorsList();
    // });
    this.interval = setInterval(
      () => this.props.getDeviceConectedStatus(),
      60000
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isRecentDoorMinimized !== this.state.isRecentDoorMinimized) {
      this.saveStateToLocalStorage(this.state.isRecentDoorMinimized);
    }

    if (
      prevProps.devices !== this.props.devices &&
      !this.state.clickFromRecentAlarms
    ) {
      if (this.props.devices[0]) {
        this.setState({ deviceId: this.props.devices[0].deviceId });
        this.setState({ deviceName: this.props.devices[0].name });
      } else {
        this.setState({ deviceId: "" });
        this.setState({ deviceName: "" });
      }
    }
    if (prevState.deviceId !== this.state.deviceId) {
      this.setState({
        datetime: {
          lastTime: "",
          currentDeviceId: "",
        },
      });
    }
    if (
      prevProps.deviceDataList !== this.props.deviceDataList &&
      this.props.deviceDataList &&
      this.props.deviceDataList.length
    ) {
      if (chart && chart.series) {
        this.getDataFromGraph();
      }
    }

    if (
      prevProps.reloadAlarms !== this.props.reloadAlarms &&
      this.props.reloadAlarms
    ) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      let selectedDevice = JSON.parse(localStorage.getItem("selectedDevice"));
      let selectedDeviceId = selectedDevice.id;
      this.props.getDeviceAlarmList({ id: selectedDeviceId });
    }

    if (
      prevState.activeTab !== this.state.activeTab &&
      this.state.activeTab === "1"
    ) {
      if (this.state.timeValue === 6) {
        this.props.getGraphData({
          deviceId: this.state.deviceId,
          timeValue: this.state.timeValue,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
          timezone: timezoneMoment.tz.guess(),
        });
      } else {
        this.props.getGraphData({
          deviceId: this.state.deviceId,
          timeValue: this.state.timeValue,
        });
      }
    }
    if (
      prevState.activeTab !== this.state.activeTab &&
      this.state.activeTab === "2"
    ) {
      if (this.state.timeValue === 6) {
        this.props.getRawData({
          page: this.state.curretPage,
          timeDiff: this.state.rawDataInterval,
          deviceId: this.state.deviceId,
          timeValue: this.state.timeValue,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        });
      } else {
        this.props.getRawData({
          page: this.state.curretPage,
          timeDiff: this.state.rawDataInterval,
          deviceId: this.state.deviceId,
          timeValue: this.state.timeValue,
        });
      }
    }
    if (prevState.rawDataInterval !== this.state.rawDataInterval) {
      if (this.state.timeValue === 6) {
        this.props.getRawData({
          page: this.state.curretPage,
          timeDiff: this.state.rawDataInterval,
          deviceId: this.state.deviceId,
          timeValue: this.state.timeValue,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        });
      } else {
        this.props.getRawData({
          page: this.state.curretPage,
          timeDiff: this.state.rawDataInterval,
          deviceId: this.state.deviceId,
          timeValue: this.state.timeValue,
        });
      }
    }
    if (prevProps.pagedDeviceDataList !== this.props.pagedDeviceDataList) {
      const rows = this.props.pagedDeviceDataList.map((deviceData, index) => {
        let deviceDataRow = { ...deviceData };
        deviceDataRow.createdAt = convertDateFormat(
          deviceDataRow.createdAt,
          "shortDateTime"
        );
        deviceDataRow.temperature =
          deviceDataRow.temperature !== -255 ? deviceDataRow.temperature : null;
        deviceDataRow.humidity =
          deviceDataRow.humidity !== -255 ? deviceDataRow.humidity : null;
        deviceDataRow.carbon =
          deviceDataRow.carbon !== -255 ? deviceDataRow.carbon : null;
        return deviceDataRow;
      });
      this.setState({
        rows,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    if (socket) {
      socket.off("liveData");
      socket.off("newAlarm");
      socket.off("removeAlarm");
      socket.off("graphData");
      socket.off("recentDoorData");
      socket.disconnect();
    }

    if (this.alarmSocket) {
      this.alarmSocket.off("alarmData");
      this.alarmSocket.off("recentDoorData");
      this.alarmSocket.disconnect();
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };
  toggleRawDataInterval = (dataInterval) => {
    if (this.state.rawDataInterval !== dataInterval) {
      this.setState({ rawDataInterval: dataInterval });
    }
  };
  disconnectStream() {
    if (socket) {
      socket.disconnect();
    }
  }

  convertToFeherenheit(value) {
    return value * (9 / 5) + 32;
  }

  updateTimeValue = (timeValue) => {
    this.setState({
      timeValue,
      rawDataInterval: 0.5,
    });
  };

  updateCustomTimeValue = () => {
    this.showModal("chooseDate", {
      deviceId: this.deviceID,
      // downloadRawDataCsvDownload: (startDate, endDate) =>
      //   this.downloadCsv(startDate, endDate),
      // downloadRawDataPdfDownload: (startDate, endDate) =>
      //   this.downloadPdf(startDate, endDate),
    });
    this.setState({ timeValue: 6, rawDataInterval: 0.5 });
  };

  createUpdateStream = (device, timeValue) => {
    if (socket) {
      socket.disconnect();
      socket.off("liveData");
      socket.off("newAlarm");
      socket.off("removeAlarm");
      socket.off("graphData");
      socket.off("recentDoorData");
    }
    if (device.deviceId) {
      this.deviceID = device.deviceId;

      const endpoint = `${
        apiPath.baseUrl
      }?deviceId=${device.deviceId.toLowerCase()}&timeValue=${timeValue}`;
      socket = socketIOClient(endpoint, { transports: ["websocket"] });
      socket.on("deviceStatus", (msg) => {
        const basic = JSON.parse(msg);
        if (basic) {
          const formattedBasicData =
            this.state.activeStatus &&
            this.state.activeStatus.filter(
              (eachDevice) => eachDevice.device_id !== basic.device_id
            );
          this.setState({
            activeStatus: [
              ...formattedBasicData,
              {
                device_id: basic.device_id,
                status: basic.status,
              },
            ],
          });
        }
      });
      socket.on("newAlarm", (msg) => {
        const basic = JSON.parse(msg);
        if (basic) {
          const formattedBasicData =
            this.state.alarmsData &&
            this.state.alarmsData.filter(
              (eachDevice) => eachDevice !== basic.deviceId
            );
          this.setState({
            alarmsData: [...formattedBasicData, basic.deviceId],
          });
        }
      });
      socket.on("removeAlarm", (msg) => {
        const basic = JSON.parse(msg);
        if (basic) {
          const formattedBasicData =
            this.state.removeAlarm &&
            this.state.removeAlarm.filter(
              (eachDevice) => eachDevice !== basic.deviceId
            );
          this.setState({
            removeAlarm: [...formattedBasicData, basic.deviceId],
          });
          const formattedData =
            this.state.alarmsData &&
            this.state.alarmsData.filter(
              (eachDevice) => eachDevice !== basic.deviceId
            );
          this.setState({
            alarmsData: formattedData,
          });
        }
      });
      socket.on("liveData", (msg) => {
        const basic = JSON.parse(msg);
        const formattedBasicData =
          this.state.basicData &&
          this.state.basicData.filter(
            (eachDevice) => eachDevice.id !== basic.id
          );

        if (basic) {
          localStorage.setItem("device_id", device.id);
          localStorage.setItem(`${device.deviceId}`, JSON.stringify(basic));

          this.setState({
            deviceId: device.deviceId,
            device_id: device.id,
            currentDevice: device,
            timeValue,
            basicData: [
              ...formattedBasicData,
              {
                temperature: basic.t !== -255 ? basic.t : null,
                humidity: basic.h !== -255 ? basic.h : null,
                carbon: basic.c !== -255 ? basic.c : null,
                b: basic.b,
                d: basic.d,
                m: basic.m,
                w: basic.w,
                id: basic.id,
                ...basic,
              },
            ],
            datetime: {
              lastTime: basic.createdAt,
              currentDeviceId: basic.id,
            },
          });
        }
      });
      if (timeValue < 6) {
        this.setState({
          verifyTimeValue: this.state.timeValue,
        });
        socket.on("graphData", (msg) => {
          const graph = JSON.parse(msg);
          if (chart && chart.series) {
            this.chartUpdate(graph);
          }
        });
      }
      socket.on("alarmData", (msg) => {
        this.props.getRecentAlarmList();
        this.props.getRecentDoorsList();
      });
      // socket.on("recentDoorData", (msg) => {
      //
      // });
      const { dashboard } = this.props;
      const { userInfo } = dashboard;
      let userRole = "";
      if (userInfo) {
        const { role } = userInfo;
        userRole = role;
      }
      if (userRole === "super_user") {
        socket.on("devicesStatusData", (msg) => {
          this.setState({ devicesStatusData: msg });
        });
      }
    }
  };

  getDataFromGraph = () => {
    const { deviceDataList } = this.props;
    const graph = deviceDataList;
    categories = [];
    temperature = [];
    humidity = [];
    carbon = [];
    // const { testData } = this.state;
    // const _testData = [...testData];
    graph.forEach((item) => {
      if (this.state.timeValue === 6) {
        categories.unshift(
          timezoneMoment
            .tz(item.createdAt, timezoneMoment.tz.guess())
            .format("L, h:mm:ss a")
        );
      } else {
        categories.unshift(
          timezoneMoment
            .tz(item.createdAt, timezoneMoment.tz.guess())
            .format("L, h:mm:ss a")
        );
      }
      temperature.unshift(item.temperature !== -255 ? item.temperature : null);
      humidity.unshift(item.humidity !== -255 ? item.humidity : null);
      carbon.unshift(item.carbon !== -255 ? item.carbon : null);
      // _testData.unshift({
      //   time: moment(item.createdAt).format('L, h:mm:ss a'),
      //   temp: item.temperature !== -255 ? item.temperature : null,
      // });
    });
    // console.table(_testData);
    // this.setState({ testData: _testData });
    if (chart && chart.series) {
      chart.series[1].setData(temperature);
      chart.series[0].setData(humidity);
      chart.series[2].setData(carbon);
      chart.xAxis[0].update({
        categories: [...categories],
      });
      chart.redraw();
    }
  };

  chartUpdate = (graph) => {
    if (this.state.timeValue === 0) {
      if (categories.length > 700) {
        categories.shift();
        temperature.shift();
        humidity.shift();
        carbon.shift();
      }
      // const { testData } = this.state;
      // const _testData = [...testData];
      let cat = moment(graph.createdAt).format("L, h:mm:ss a");

      const t =
        graph.Node_Type === 1
          ? graph.thermo !== -255
            ? Number(graph.thermo)
            : null
          : graph.t !== -255
          ? Number(graph.t)
          : null;
      const h = graph.h !== -255 ? Number(graph.h) : null;
      const c = graph.c !== -255 ? Number(graph.c) : null;
      // _testData.push({
      //   time: cat,
      //   temp: t,
      // });
      // console.table(_testData);
      // this.setState({ testData: _testData });
      categories.push(cat);
      temperature.push(t);
      humidity.push(h);
      carbon.push(c);

      chart.series[1].setData(temperature);
      chart.series[0].setData(humidity);
      chart.series[2].setData(carbon);
      chart.xAxis[0].update({
        categories: [...categories],
      });
      chart.redraw();
    }
  };

  afterChartCreated = (highcharts) => {
    this.internalChart = highcharts;
    chart = this.internalChart;
  };

  changePage = (page) => {
    this.setState({ curretPage: page });
    if (this.state.timeValue === 6) {
      this.props.getRawData({
        page: page,
        timeDiff: this.state.rawDataInterval,
        deviceId: this.state.deviceId,
        timeValue: this.state.timeValue,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    } else {
      this.props.getRawData({
        timeDiff: this.state.rawDataInterval,
        page: page,
        deviceId: this.state.deviceId,
        timeValue: this.state.timeValue,
      });
    }
  };

  downloadCsv = (startDate, endDate) => {
    if (this.state.timeValue === 6) {
      let data = {
        timeDiff: this.state.rawDataInterval,
        deviceId: this.state.deviceId,
        timeValue: this.state.timeValue,
        startDate: this.props.startDate || startDate,
        endDate: this.props.endDate || endDate,
        timezone: timezoneMoment.tz.guess(),
      };
      this.props.getRawDataCsvDownload(data);
    } else {
      this.props.getRawDataCsvDownload({
        timeDiff: this.state.rawDataInterval,
        deviceId: this.state.deviceId,
        timeValue: this.state.timeValue,
      });
    }
  };
  downloadPdf = (startDate, endDate) => {
    if (this.state.timeValue === 6) {
      let data = {
        timeDiff: this.state.rawDataInterval,
        deviceId: this.state.deviceId,
        timeValue: this.state.timeValue,
        startDate: this.props.startDate || startDate,
        endDate: this.props.endDate || endDate,
        timezone: timezoneMoment.tz.guess(),
      };
      this.props.getRawDataPdfDownload(data);
    } else {
      this.props.getRawDataPdfDownload({
        timeDiff: this.state.rawDataInterval,
        deviceId: this.state.deviceId,
        timeValue: this.state.timeValue,
      });
    }
  };
  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName: modalName,
      modalData: modalData,
    });
  };

  editDeviceAlarm = (deviceAlarm) => {
    this.showModal("addEditDeviceAlarm", { deviceAlarm: deviceAlarm });
  };

  showRecentAlarmComment = (comments) => {
    this.showModal("recentAlarmComments", {
      comments,
      getRecentAlarmList: this.props.getRecentAlarmList,
    });
  };

  addDeviceAlarm = () => {
    let selectedDevice = JSON.parse(localStorage.getItem("selectedDevice"));
    let selectedDeviceId = selectedDevice.id;
    this.showModal("addEditDeviceAlarm", {
      deviceId: selectedDeviceId,
      currentDevice: selectedDevice,
      openMode: "add",
    });
  };

  deleteDeviceAlarm = (id) => {
    this.showModal("deleteDeviceAlarm", { id: id });
  };

  toggleTooltip = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }
  };

  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  updateDeviceName = (deviceName) => {
    this.setState({ deviceName: deviceName });
  };

  updateDeviceId = async (deviceId) => {
    await this.props.getDeviceData({ deviceId });
    this.setState({ deviceId: deviceId });
  };

  updateRecentAlarmsEvent = (clickableEvent) => {
    this.setState({ clickFromRecentAlarms: clickableEvent });
    this.scrollRef.current && this.scrollRef.current.scrollIntoView();
  };

  loadStateFromLocalStorage() {
    const savedState = localStorage.getItem("isRecentDoorMinimized");
    return savedState !== null ? JSON.parse(savedState) : false;
  }

  saveStateToLocalStorage(state) {
    localStorage.setItem("isRecentDoorMinimized", JSON.stringify(state));
  }

  handleRecentDoorMinimize = (isMinimized) => {
    this.setState({ isRecentDoorMinimized: isMinimized }, () => {
      this.saveStateToLocalStorage(this.state.isRecentDoorMinimized);
    });
  };

  render() {
    const { dashboard, loading, deviceAlarmList } = this.props;
    const { timeValue, list } = this.state;

    const { userInfo } = dashboard;
    let userRole = "";
    let email = "";
    if (userInfo) {
      const { role } = userInfo;
      userRole = role;
      email = userInfo.email;
    }

    const getCustomUIComponent = (key) => {
      switch (key) {
        case "status":
          return (
            <Col xs={12} md={12} lg={12} xl={5}>
              <CustomStatus
                {...this.props}
                devicesStatusData={this.state.devicesStatusData}
                deviceId={this.state.deviceId}
                deviceStatusResult={this.props.deviceStatusResult}
                isExpanded={this.state.isRecentDoorMinimized}
              />
              <CustomDeviceStatus
                {...this.state}
                latestData={this.state.rows[0]}
                convertToFeherenheit={this.convertToFeherenheit}
              />
            </Col>
          );
        case "location":
          return (
            <Col
              xs={12}
              md={12}
              lg={12}
              xl={7}
              className="d-flex justify-content-end  flex-column pr-xl-0"
            >
              <RecentAlarms
                {...this.props}
                deviceId={this.state.deviceId}
                showRecentAlarmComment={this.showRecentAlarmComment}
                getRecentAlarmList={this.props.getRecentAlarmList}
                updateDeviceId={this.updateDeviceId}
                updateDeviceName={this.updateDeviceName}
                updateRecentAlarmsEvent={this.updateRecentAlarmsEvent}
                isExpanded={this.state.isRecentDoorMinimized}
              />
              <RecentDoorOpening
                {...this.props}
                deviceId={this.state.deviceId}
                onMinimizeToggle={this.handleRecentDoorMinimize}
                isExpanded={this.state.isRecentDoorMinimized}
                getRecentDoorsList={this.props.getRecentDoorsList}
              />
            </Col>
          );

        case "deviceStatus":
          return (
            <Col xs={12} md={12} lg={5} className="custom_device__margin__top">
              <CustomLocation
                updateDeviceName={this.updateDeviceName}
                updateDeviceId={this.updateDeviceId}
                createUpdateStream={this.createUpdateStream}
                disconnectStream={this.disconnectStream}
                timeValue={timeValue}
                rawDataInterval={this.state.rawDataInterval}
                updateRawDataInterval={this.toggleRawDataInterval}
                convertToFeherenheit={this.convertToFeherenheit}
                updateRecentAlarmsEvent={this.updateRecentAlarmsEvent}
                role={userRole}
                {...this.state}
                {...this.props}
              />
            </Col>
          );

        default:
          break;
      }
    };

    const getComponent = (key) => {
      switch (key) {
        case "status":
          return (
            <Col xs={12} md={12} lg={12} xl={5}>
              <Status
                {...this.props}
                devicesStatusData={this.state.devicesStatusData}
                deviceId={this.state.deviceId}
                deviceStatusResult={this.props.deviceStatusResult}
                isExpanded={this.state.isRecentDoorMinimized}
              />
            </Col>
          );
        case "location":
          return (
            <Col
              xs={12}
              md={12}
              lg={12}
              xl={7}
              className="d-flex justify-content-end  flex-column pr-xl-0"
            >
              <RecentAlarms
                {...this.props}
                deviceId={this.state.deviceId}
                showRecentAlarmComment={this.showRecentAlarmComment}
                getRecentAlarmList={this.props.getRecentAlarmList}
                updateDeviceId={this.updateDeviceId}
                updateDeviceName={this.updateDeviceName}
                updateRecentAlarmsEvent={this.updateRecentAlarmsEvent}
                isExpanded={this.state.isRecentDoorMinimized}
              />
              <RecentDoorOpening
                {...this.props}
                deviceId={this.state.deviceId}
                onMinimizeToggle={this.handleRecentDoorMinimize}
                isExpanded={this.state.isRecentDoorMinimized}
                getRecentDoorsList={this.props.getRecentDoorsList}
              />
            </Col>
          );

        case "deviceStatus":
          return (
            <Col xs={12} md={12} lg={5} className="device__margin__top">
              <DeviceStatus
                {...this.state}
                latestData={this.state.rows[0]}
                convertToFeherenheit={this.convertToFeherenheit}
              />
              <LocationDevices
                updateDeviceName={this.updateDeviceName}
                updateDeviceId={this.updateDeviceId}
                createUpdateStream={this.createUpdateStream}
                disconnectStream={this.disconnectStream}
                timeValue={timeValue}
                rawDataInterval={this.state.rawDataInterval}
                updateRawDataInterval={this.toggleRawDataInterval}
                convertToFeherenheit={this.convertToFeherenheit}
                updateRecentAlarmsEvent={this.updateRecentAlarmsEvent}
                role={userRole}
                {...this.state}
                {...this.props}
              />
            </Col>
          );

        case "deviceData":
          return (
            <Col xs={12} md={12} lg={7} className="d-flex flex-column pr-xl-0">
              <DeviceData
                {...this.state}
                {...this.props}
                rows={this.state.rows}
                toggleRawDataInterval={this.toggleRawDataInterval}
                userRole={userRole}
                deviceAlarmList={deviceAlarmList}
                updateTimeValue={this.updateTimeValue}
                updateCustomTimeValue={this.updateCustomTimeValue}
                toggle={this.toggle}
                showGraph={this.showGraph}
                afterChartCreated={this.afterChartCreated}
                changePage={this.changePage}
                addDeviceAlarm={this.addDeviceAlarm}
                isToolTipOpen={this.isToolTipOpen}
                toggleTooltip={this.toggleTooltip}
                editDeviceAlarm={this.editDeviceAlarm}
                deleteDeviceAlarm={this.deleteDeviceAlarm}
                downloadRawDataCsvDownload={this.downloadCsv}
                downloadRawDataPdfDownload={this.downloadPdf}
                scrollRef={this.scrollRef}
              />
            </Col>
          );
        default:
          break;
      }
    };

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Dashboard
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>
        {loading ? (
          <h3 className="text-center">Loading...</h3>
        ) : (
          <div className="container-fluid">
            <Row className="justify-content-between">
              {email.toLowerCase() === "tanja.weis@med.uni-heidelberg.de" ? (
                <>{list.map((key) => getCustomUIComponent(key))}</>
              ) : (
                <>{list.map((key) => getComponent(key))}</>
              )}
            </Row>

            {email.toLowerCase() === "tanja.weis@med.uni-heidelberg.de" ? (
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <DeviceData
                  {...this.state}
                  {...this.props}
                  rows={this.state.rows}
                  toggleRawDataInterval={this.toggleRawDataInterval}
                  userRole={userRole}
                  deviceAlarmList={deviceAlarmList}
                  updateTimeValue={this.updateTimeValue}
                  updateCustomTimeValue={this.updateCustomTimeValue}
                  toggle={this.toggle}
                  showGraph={this.showGraph}
                  afterChartCreated={this.afterChartCreated}
                  changePage={this.changePage}
                  addDeviceAlarm={this.addDeviceAlarm}
                  isToolTipOpen={this.isToolTipOpen}
                  toggleTooltip={this.toggleTooltip}
                  editDeviceAlarm={this.editDeviceAlarm}
                  deleteDeviceAlarm={this.deleteDeviceAlarm}
                  downloadRawDataCsvDownload={this.downloadCsv}
                  downloadRawDataPdfDownload={this.downloadPdf}
                  scrollRef={this.scrollRef}
                />
              </div>
            ) : null}
          </div>
        )}
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.locationsDevices.devices,
    loading: state.dashboard.loading,
    dashboard: state.dashboard.dashboard,
    error: state.dashboard.error,
    users: state.users.userList,
    deviceDataList: state.locationsDevices.deviceDataList,
    pagedDeviceMeta: state.locationsDevices.pagedDeviceMeta,
    pagedDeviceDataList: state.locationsDevices.pagedDeviceDataList,
    deviceAlarmList: state.deviceAlarms.deviceAlarmList,
    reloadAlarms: state.deviceAlarms.reload,
    recentAlarms: state.deviceAlarms.recentAlarms,
    recentDoors: state.deviceAlarms.recentDoors,
    recentDoorLoading: state.deviceAlarms.recentDoorLoading,
    recentAlarmLoading: state.deviceAlarms.recentAlarmLoading,
    showGraph: state.locationsDevices.showGraph,
    startDate: state.locationsDevices.startDate,
    endDate: state.locationsDevices.endDate,
    deviceConectedStatus: state.deviceConectedStatus.deviceConectedStatus,
    deviceStatusResult: state.deviceConectedStatus.deviceStatusResult,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGraphData: (data) => dispatch(actions.getGraphData(data)),
    getRawDataCsvDownload: (data) =>
      dispatch(actions.getRawDataCsvDownload(data)),
    getRawDataPdfDownload: (data) =>
      dispatch(actions.getRawDataPdfDownload(data)),
    getRawData: (data) => dispatch(actions.getRawData(data)),
    getUserList: (data) => dispatch(actions.getUserList(data)),
    getDeviceAlarmList: (data) => dispatch(actions.getDeviceAlarmList(data)),
    getRecentAlarmList: (page) => dispatch(actions.getRecentAlarmList(page)),
    getRecentDoorsList: (page) => dispatch(actions.getRecentDoorsList(page)),
    setModal: (data) => dispatch(actions.setModal(data)),
    getDeviceConectedStatus: () => dispatch(actions.getDeviceConectedStatus()),
    getDeviceData: (payload) => dispatch(actions.getDeviceStatusData(payload)),
    getDeviceStatus: () => dispatch(actions.getDeviceStatus()),
    getTempStatus: () => dispatch(actions.getTempStatus()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(Dashboard));
