import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utilities';

const initialState = {
    token: null,
    userId: null,
    error: null,
    success: null,
    loading: false
};

const forgotPasswordStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const forgotPasswordSuccess = (state, action) => {
    return updateObject(state, {
        success: action.success,
        error: null,
        loading: false
    });
};

const forgotPasswordFail = (state, action) => {
    return updateObject(state, {
        error: action.error[0].message,
        token: null,
        loading: false
    });
};

const validateTokenStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const validateTokenSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        userId: action.userId,
        error: null,
        loading: false
    });
};

const validateTokenFail = (state, action) => {
    return updateObject(state, {
        error: (action.error[0] && action.error[0].message) || action.error.message,
        token: null,
        loading: false
    });
};

const resetPasswordStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const resetPasswordSuccess = (state, action) => {
    return updateObject(state, {
        success : action.success,
        error: null,
        loading: false
    });
};

const resetPasswordFail = (state, action) => {
    return updateObject(state, {
        error: (action.error[0] && action.error[0].msg) || action.error.message ,
        token: null,
        loading: false
    });
};

const forgotPassword = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FORGOT_PASSWORD_START: return forgotPasswordStart(state, action);
        case actionTypes.FORGOT_PASSWORD_SUCCESS: return forgotPasswordSuccess(state, action);
        case actionTypes.FORGOT_PASSWORD_FAIL: return forgotPasswordFail(state, action);
        case actionTypes.VALIDATE_RESET_TOKEN_START: return validateTokenStart(state, action);
        case actionTypes.VALIDATE_RESET_TOKEN_SUCCESS: return validateTokenSuccess(state, action);
        case actionTypes.VALIDATE_RESET_TOKEN_FAIL: return validateTokenFail(state, action);
        case actionTypes.RESET_PASSWORD_START: return resetPasswordStart(state, action);
        case actionTypes.RESET_PASSWORD_SUCCESS: return resetPasswordSuccess(state, action);
        case actionTypes.RESET_PASSWORD_FAIL: return resetPasswordFail(state, action);

        default:
            return state;
    }
};

export default forgotPassword;