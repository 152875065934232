import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
import { showToast } from "../../helpers/toast";
// import { dispatch } from "d3-dispatch";

export const getUserMessageTemplatesStart = () => {
  return {
    type: actionTypes.GET_USER_MESSAGE_TEMPLATE_START,
  };
};

export const getUserMessageTemplatesSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_MESSAGE_TEMPLATE_SUCCESS,
    data: data,
  };
};

export const getUserMessageTemplatesFail = (error) => {
  return {
    type: actionTypes.GET_USER_MESSAGE_TEMPLATE_FAIL,
    error: error,
  };
};


export const addMessageTemplateStart = () => {
  return {
    type: actionTypes.ADD_MESSAGE_TEMPLATE_START,
  };
};

export const addMessageTemplateSuccess = (data) => {
  return {
    type: actionTypes.ADD_MESSAGE_TEMPLATE_SUCCESS,
    data: data,
  };
};

export const addMessageTemplateFail = (error) => {
  return {
    type: actionTypes.ADD_MESSAGE_TEMPLATE_FAIL,
    error: error,
  };
};

export const editMessageTemplateStart = () => {
  return {
    type: actionTypes.EDIT_MESSAGE_TEMPLATE_START,
  };
};

export const editMessageTemplateSuccess = (data) => {
  return {
    type: actionTypes.EDIT_MESSAGE_TEMPLATE_SUCCESS,
    data: data,
  };
};

export const editMessageTemplateFail = (error) => {
  return {
    type: actionTypes.EDIT_MESSAGE_TEMPLATE_FAIL,
    error: error,
  };
};

export const deleteMessageTemplateStart = () => {
  return {
    type: actionTypes.DELETE_MESSAGE_TEMPLATE_START,
  };
};

export const deleteMessageTemplateSuccess = (data) => {
  return {
    type: actionTypes.DELETE_MESSAGE_TEMPLATE_SUCCESS,
    data: data,
  };
};

export const deleteMessageTemplateFail = (error) => {
  return {
    type: actionTypes.DELETE_MESSAGE_TEMPLATE_FAIL,
    error: error,
  };
};

export const getUserMessageTemplates = (params = {page: 1, pageSize: 10}) => {
  return (dispatch) => {
    dispatch(getUserMessageTemplatesStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.messageTemplates.list}`;
    if (params.page) {
      url += `?page=${params.page}&pageSize=${params.pageSize}`;
    } else {
      url += "?page=1&pageSize=1000";
    }
    
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getUserMessageTemplatesSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getUserMessageTemplatesFail(error.response.data.errors));
        }
      });
  };
};

export const addMessageTemplate = (payload) => {
  return (dispatch) => {
    dispatch(addMessageTemplateStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.messageTemplates.add}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        dispatch(addMessageTemplateSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(addMessageTemplateFail(error.response.data.errors));
        }
      });
  };
};

export const editMessageTemplate = ({payload, id}) => {
  return (dispatch) => {
    dispatch(editMessageTemplateStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.messageTemplates.edit}`;
    url = url.replace('{id}', id);
    axios
      .put(url, payload, { headers })
      .then((response) => {
        dispatch(editMessageTemplateSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(editMessageTemplateFail(error.response.data.errors));
        }
      });
  };
};

export const deleteMessageTemplate = ( id ) => {
  return (dispatch) => {
    dispatch(deleteMessageTemplateStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.messageTemplates.delete}`;
    url = url.replace('{id}', id);
    axios
      .delete(url, { headers })
      .then((response) => {
        dispatch(deleteMessageTemplateSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        }else if(error.response.status === 500){
          dispatch(deleteMessageTemplateFail('Cant delete template!'))
        } else {
          dispatch(deleteMessageTemplateFail(error.response.data.errors));
        }
      });
  };
};

export const getUserMessageTemplatesRelStart = () => {
  return {
    type: actionTypes.GET_USER_MESSAGE_TEMPLATE_REL_START,
  };
};

export const getUserMessageTemplatesRelSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_MESSAGE_TEMPLATE_REL_SUCCESS,
    data: data,
  };
};

export const getUserMessageTemplatesRelFail = (error) => {
  return {
    type: actionTypes.GET_USER_MESSAGE_TEMPLATE_REL_FAIL,
    error: error,
  };
};

export const setUserMessageTemplateRelStart = () => {
  return {
    type: actionTypes.SET_USER_MESSAGE_TEMPLATE_REL_START,
  };
};

export const setUserMessageTemplateRelSuccess = (data) => {
  return {
    type: actionTypes.SET_USER_MESSAGE_TEMPLATE_REL_SUCCESS,
    data: data,
  };
};

export const setUserMessageTemplateRelFail = (error) => {
  return {
    type: actionTypes.SET_USER_MESSAGE_TEMPLATE_REL_FAIL,
    error: error,
  };
};


export const getUserMessageTemplatesRel = (params) => {
  return (dispatch) => {
    dispatch(getUserMessageTemplatesRelStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.messageTemplatesRel.get}`;
    
    var whereParams = [];
    if (params.userId) {
      whereParams.push(`userId=${params.userId}`);
    } 
    if (params.templateId) {
      whereParams.push(`templateId=${params.templateId}`);
    }
    if (params.type) {
      whereParams.push(`type=${params.type}`);
    }
    if (params.method) {
      whereParams.push(`method=${params.method}`);
    }
    
    if(whereParams.length > 0){
      url += `?${whereParams.join("&")}`
    }
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getUserMessageTemplatesRelSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getUserMessageTemplatesRelFail(error.response.data.errors));
        }
      });
  };
};

export const setUserMessageTemplateRel = (payload) => {
  return (dispatch) => {
    dispatch(setUserMessageTemplateRelStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.messageTemplatesRel.set}`;
    
    axios
      .post(url, payload, { headers })
      .then((response) => {
        dispatch(setUserMessageTemplateRelSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(setUserMessageTemplateRelFail(error.response.data.errors));
        }
      });
  };
};