import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  data: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
  apiKey: {},
  voice_call_success: undefined,
  voice_call_fail: undefined,
  voice_call_loading: false,
};

const getAccountListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    data: [],
    meta: {},
    loading: true,
  });
};

const getAccountListSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getAccountListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    data: [],
    meta: {},
    loading: false,
  });
};

const editVoiceCallStart = (state, action) => {
  console.log("START");
  return updateObject(state, {
    voice_call_success: false,
    voice_call_fail: false,
    voice_call_loading: true,
  });
};

const editVoiceCallSuccess = (state, action) => {
  console.log("SUCCESS");
  return updateObject(state, {
    voice_call_success: true,
    voice_call_fail: false,
    voice_call_loading: false,
  });
};

const editVoiceCallFail = (state, action) => {
  console.log("FAIL");
  return updateObject(state, {
    voice_call_success: false,
    voice_call_fail: true,
    voice_call_loading: false,
  });
};

const deleteAccountStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    data: [],
    meta: {},
    loading: true,
    success: undefined,
  });
};

const deleteAccountSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    meta: action.meta,
    error: undefined,
    loading: false,
    success: "Account deleted successfully.",
  });
};

const deleteAccountFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    data: [],
    meta: {},
    success: undefined,
  });
};

const generateAccountAPIStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    apiKey: {},
    meta: {},
    loading: true,
  });
};

const generateAccountAPISuccess = (state, action) => {
  return updateObject(state, {
    apiKey: action.data,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const generateAccountAPIFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    apiKey: {},
    meta: {},
    loading: false,
  });
};

const Account = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_LIST_START:
      return getAccountListStart(state, action);
    case actionTypes.GET_ACCOUNT_LIST_SUCCESS:
      return getAccountListSuccess(state, action.data);
    case actionTypes.GET_ACCOUNT_LIST_FAIL:
      return getAccountListFail(state, action);

    case actionTypes.EDIT_VOICE_CALL_START:
      return editVoiceCallStart(state, action);
    case actionTypes.EDIT_VOICE_CALL_SUCCESS:
      return editVoiceCallSuccess(state, action);
    case actionTypes.EDIT_VOICE_CALL_FAIL:
      return editVoiceCallFail(state, action);
    case actionTypes.DELETE_ACCOUNT_LIST_START:
      return deleteAccountStart(state, action);
    case actionTypes.DELETE_ACCOUNT_LIST_SUCCESS:
      return deleteAccountSuccess(state, action.data);
    case actionTypes.DELETE_ACCOUNT_LIST_FAIL:
      return deleteAccountFail(state, action);

    case actionTypes.GENERATE_ACCOUNT_API_START:
      return generateAccountAPIStart(state, action);
    case actionTypes.GENERATE_ACCOUNT_API_SUCCESS:
      return generateAccountAPISuccess(state, action.data);
    case actionTypes.GENERATE_ACCOUNT_API_FAIL:
      return generateAccountAPIFail(state, action);

    case actionTypes.CREATE_ACCOUNT_API_START:
      return generateAccountAPIStart(state, action);
    case actionTypes.CREATE_ACCOUNT_API_SUCCESS:
      return generateAccountAPISuccess(state, action.data);
    case actionTypes.CREATE_ACCOUNT_API_FAIL:
      return generateAccountAPIFail(state, action);

    default:
      return state;
  }
};

export default Account;
