import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  offlineAlarmsStatus: false,
};

const updateOfflineAlarmsStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: true,
  });
};

const updateOfflineAlarmsSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    offlineAlarmsStatus: true,
    loading: false,
  });
};

const updateOfflineAlarmsFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    offlineAlarmsStatus: false,
  });
};

const getOfflineAlarmsStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: true,
  });
};

const getOfflineAlarmsSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    offlineAlarmsDetails: action.data,
    loading: false,
  });
};

const getOfflineAlarmsFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    offlineAlarmsDetails: "",
  });
};

const resetAllInfo = (state, action) => {
  return updateObject(state, {
    offlineAlarmsDetails: "",
    offlineAlarmsStatus: false,
  });
};

const updateOfflineAlarms = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_OFFLINE_ALARMS_START:
      return updateOfflineAlarmsStart(state, action);
    case actionTypes.UPDATE_OFFLINE_ALARMS_SUCCESS:
      return updateOfflineAlarmsSuccess(state, action.data);
    case actionTypes.UPDATE_OFFLINE_ALARMS_FAIL:
      return updateOfflineAlarmsFail(state, action);
    case actionTypes.GET_OFFLINE_ALARMS_START:
      return getOfflineAlarmsStart(state, action);
    case actionTypes.GET_OFFLINE_ALARMS_SUCCESS:
      return getOfflineAlarmsSuccess(state, action.data);
    case actionTypes.GET_OFFLINE_ALARMS_FAIL:
      return getOfflineAlarmsFail(state, action);
    case actionTypes.RESET_ALL_ALARMS_INFO:
      return resetAllInfo(state, action);
    default:
      return state;
  }
};
export default updateOfflineAlarms;
