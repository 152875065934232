import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";

export const getDeviceConectedStatusStart = () => {
  return {
    type: actionTypes.GET_DEVICE_STATUS_CONECTED_START,
  };
};

export const getDeviceConectedStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_DEVICE_STATUS_CONECTED_SUCCESS,
    data: data,
  };
};

export const getDeviceConectedStatusFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_STATUS_CONECTED_FAIL,
    error: error,
  };
};

export const getDeviceStatusStart = () => {
  return {
    type: actionTypes.GET_DEVICES_STATUS_START,
  };
};

export const getDeviceStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_DEVICES_STATUS_SUCCESS,
    data: data,
  };
};

export const getDeviceStatusFail = (error) => {
  return {
    type: actionTypes.GET_DEVICES_STATUS_FAIL,
    error: error,
  };
};

export const getDeviceConectedStatus = () => {
  return (dispatch) => {
    dispatch(getDeviceConectedStatusStart());
    const headers = getCommonHeaders();
    const url = `${apiPath.baseUrl}${apiPath.dashboard.userMeta}`;
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getDeviceConectedStatusSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 500) {
          dispatch(getDeviceConectedStatusFail([error.response.data.message]));
        } else {
          dispatch(getDeviceConectedStatusFail(error.response.data.errors));
        }
      });
  };
};

export const getDeviceStatus = () => {
  return (dispatch) => {
    const headers = getCommonHeaders();

    dispatch(getDeviceStatusStart());
    const url = `${apiPath.baseUrl}${apiPath.dashboard.accountMetaData}`;
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getDeviceStatusSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 500) {
          dispatch(getDeviceStatusFail([error.response.data.message]));
        } else {
          dispatch(getDeviceStatusFail(error.response.data.errors));
        }
      });
  };
};
