import React, { Component } from 'react';
import { ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../stores/actions/index';

class DeleteDeviceAlarm extends Component {

    submitModal = () => {
        const { deleteDeviceAlarm, modalData } = this.props;
        deleteDeviceAlarm(modalData);
    }

    render () {
        const { toggleModal } = this.props;
        return (
            <React.Fragment>
                <ModalHeader toggle={toggleModal}>Delete alarm</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to delete this alarm ?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.submitModal}>Confirm</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter> 
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteDeviceAlarm: (data) => dispatch(actions.deleteDeviceAlarm(data))
    };
};

export default connect(null, mapDispatchToProps)(DeleteDeviceAlarm);
