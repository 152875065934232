import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators";

class Login extends Component {
  state = {
    formLogin: {
      email: "",
      password: "",
    },
  };

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token && this.props.token) {
      showToast("Login Successfully", "success");
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
  }

  validateOnChange = (event) => {
    const state = validateOnChange(event, this.state);
    this.setState(state);
  };

  onSubmit = (e) => {
    const state = onSubmit(e, this.state);
    this.setState(state);

    if (!state.hasError) {
      const params = {
        email: this.state.formLogin.email,
        password: this.state.formLogin.password,
      };
      this.props.login(params);
    }
    e.preventDefault();
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    return (
      <React.Fragment>
        <p className="text-center py-2">SIGN IN TO CONTINUE.</p>
        <form className="mb-3" name="formLogin" onSubmit={this.onSubmit}>
          <div className="form-group">
            <div className="input-group with-focus">
              <Input
                type="email"
                name="email"
                className="border-light border-right-0"
                placeholder="Enter email"
                invalid={
                  this.hasError("formLogin", "email", "required") ||
                  this.hasError("formLogin", "email", "email")
                }
                onChange={this.validateOnChange}
                data-validate='["required", "email"]'
                value={this.state.formLogin.email}
              />
              <div className="input-group-append">
                <span className="input-group-text text-muted bg-transparent border-left-0">
                  <em className="fa fa-envelope"></em>
                </span>
              </div>
              {this.hasError("formLogin", "email", "required") && (
                <span className="invalid-feedback">Email is required</span>
              )}
              {this.hasError("formLogin", "email", "email") && (
                <span className="invalid-feedback">Email must be valid</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="input-group with-focus">
              <Input
                type="password"
                id="id-password"
                name="password"
                className="border-light border-right-0"
                placeholder="Password"
                invalid={this.hasError("formLogin", "password", "required")}
                onChange={this.validateOnChange}
                data-validate='["required"]'
                value={this.state.formLogin.password}
              />
              <div className="input-group-append">
                <span className="input-group-text text-muted bg-transparent border-left-0">
                  <em className="fa fa-lock"></em>
                </span>
              </div>
              <span className="invalid-feedback">Password is required</span>
            </div>
          </div>
          <div className="clearfix">
            <div className="float-right">
              <Link to="forgot" className="text-muted">
                Forgot your password?
              </Link>
            </div>
          </div>
          <button className="btn btn-block btn-primary mt-3" type="submit">
            Login
          </button>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    success: state.auth.token,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => dispatch(actions.login(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
