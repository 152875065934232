import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/gateways";
import { selectStyle } from "../../config/selectStyle";
import Select from "react-select";

class UpdateGatewayFirmware extends Component {
  state = {
    chosenFW: undefined,
    nextClick: false,
  };
  submitModal = () => {
    const { updateGateway, modalData } = this.props;
    
    updateGateway({ id: modalData.id, command: this.state.chosenFW, Node_Devices: modalData.Node_Devices });
  };
  handleNextClick = () => {
    this.setState({ nextClick: true });
  };
  handleChangeDevice = (chosenFw) => {
    console.info(chosenFw);
    this.setState({ chosenFW: chosenFw.id });
  };
  render() {
    const { toggleModal, modalData } = this.props;
    const { chosenFW, nextClick } = this.state;
    const options = [
      {
        id: "main_LoRa",
        name: "LoRa",
      },
      {
        id: "main_ota",
        name: "ESP",
      },
      {
        id: "nextion_main",
        name: "Nextion",
      },
    ];

    const devicesOptions = [
      {
        id: "main_LoRa",
        name: "LoRa ESP",
      },
      {
        id: "nextion_main",
        name: "Nextion",
      },
    ]

    return (
      <React.Fragment>
        <ModalHeader toggle={toggleModal}>Update Firmware</ModalHeader>
        {!nextClick && (
          <>
            <ModalBody>
              <div className="form-group">
                <label className="text-muted">Please Choose FirmWare</label>
                <Select
                  styles={selectStyle}
                  name="chooseFW"
                  value={!!chosenFW && chosenFW.id}
                  onChange={this.handleChangeDevice}
                  options={!!modalData.Node_Devices ? devicesOptions : options}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option}`}
                  isOptionSelected={(option) => option.id === chosenFW}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="info" onClick={this.handleNextClick}>
                Next
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
        {nextClick && (
          <>
            <ModalBody>
              <p>{`Are you sure you want to overrite the existing firmware for the device with MAC address ${modalData.id} ?`}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="info"
                onClick={() => this.setState({ nextClick: false })}
              >
                Back
              </Button>
              <Button color="info" onClick={this.submitModal}>
                Confirm
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteGateway: (data) => dispatch(actions.deleteGateway(data)),
    updateGateway: (data) => dispatch(actions.updateGateway(data)),
  };
};

export default connect(null, mapDispatchToProps)(UpdateGatewayFirmware);
