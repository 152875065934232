import React, { useEffect, useState } from "react";

import { Switch } from "antd";

const ThemeSwitch = () => {
  const savedTheme = localStorage.getItem("data-theme");
  const [checked, setChecked] = useState(savedTheme === "light");

  useEffect(() => {
    const theme = checked ? "light" : "dark";
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("data-theme", theme);
    // Notify other components or tabs about the theme change
    window.dispatchEvent(new Event("storage"));
  }, [checked]);

  return (
    <div>
      <span style={{ color: checked ? "#333" : "#ccc" }} className="ml-3 ">
        {checked ? "Light" : "Dark"} Mode
      </span>
      <Switch
        className="ml-2 mr-2"
        checked={checked}
        onClick={() => setChecked(!checked)}
      />
    </div>
  );
};

export default ThemeSwitch;
