import React, { Component } from 'react';
import { ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../stores/actions/index';

class DeleteLocation extends Component {

    submitModal = () => {
        const { deleteLocation, modalData } = this.props;
        deleteLocation(modalData);
    }

    render() {
        const { toggleModal } = this.props;
        return (
            <React.Fragment>
                <ModalHeader toggle={toggleModal}>Delete Location</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to delete this location ?</p>
                    <p>All the devices will be removed from this location.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.submitModal}>Confirm</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteLocation: (data) => dispatch(actions.deleteLocation(data))
    };
};

export default connect(null, mapDispatchToProps)(DeleteLocation);
