import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators.js";
import "react-phone-input-2/lib/style.css";


class EditVoiceCall extends Component {
  countyCodeList = ["+44", "+91", "+1"];
  state = {
    mode: 'Edit',
    form: {
      voiceCall: false,
    },
    id: ""
  };


  editVoiceCallFn = (payload, id) => {
    const { editVoiceCall } = this.props;
    editVoiceCall({ payload, id });
  };

  componentDidMount() {
    const { modalData } = this.props;
    if (modalData && modalData.account) {
      const { account } = modalData;
      this.setState({
        mode: "Edit",
        form: {
          voiceCall: account.voiceCall,
        },
        id: account.id
      });
    } 
  }

  onSubmit = (e) => {
    e.preventDefault();
    const state = onSubmit(e, this.state);
    this.setState(state);
    if (!state.hasError) {
     
      const params = {
        voiceCall: this.state.form.voiceCall,
      };
      this.editVoiceCallFn(params, state.id);
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  validateOnChange = (event) => {
    const state = validateOnChange(event, this.state);
    this.setState(state);
  };

  render() {
    const { toggleModal } = this.props;

    return (
      <React.Fragment>
        <form className="mb-3" name="formAccount" onSubmit={this.onSubmit}>
          <ModalHeader toggle={toggleModal}>Voice Call</ModalHeader>
          <ModalBody>
            <div className="enableCheckbox d-flex justify-content-start algin-items-center flex-column form-group pt-2">
                <h6 className="text-muted text-center">Voice Call</h6>
                <div className="d-flex justify-content-around">
                <p className="pt-2">
                  <label className="text-muted">Disable</label>
                  <Input
                    type="checkbox"
                    id="voiceCall"
                    name="voiceCall"
                    checked={this.state.form.voiceCall}
                    onChange={(e) => {
                      this.setState({
                        ...Button,
                        form: {
                          voiceCall: !this.state.form.voiceCall,
                        },
                      });
                    }}
                  />
                  <label htmlFor="voiceCall" className="checkbox-label">
                    <span className="ui" />
                  </label>
                  <label className="text-muted">Enable</label>
                </p>
                </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="submit">
              Submit
            </Button>
            <Button color="secondary" type="button" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.users.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editVoiceCall: (data) => dispatch(actions.editVoiceCall(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVoiceCall);
