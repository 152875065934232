import React, { Component } from 'react';
import { convertDateFormat } from '../../utils/helperFunctions';
import { MDBBtn, MDBDataTable } from 'mdbreact';
import { Row, Col } from 'reactstrap';
import Pagination from '../Common/Pagination';
const columns = [
  {
    label: 'Device ID',
    field: 'deviceId',
    sort: 'asc',
    width: 250,
  },
  {
    label: 'Alarm Name',
    field: 'name',
    sort: 'asc',
    width: 250,
  },
  {
    label: 'Device Name',
    field: 'deviceName',
    sort: 'asc',
    width: 250,
  },
  {
    label: 'Type',
    field: 'type',
    sort: 'asc',
    width: 250,
  },
  {
    label: 'Created At',
    field: 'createdAt',
    sort: 'asc',
    width: 250,
  },
  
//   {
//     label: 'Action',
//     field: 'action',
//     width: 80,
//   },
];
class AlarmsTable extends Component {
  state = {
    loadTable: false,
    deviceList: [],
    pagination: {},
    role: localStorage.getItem('role'),
  };

  componentDidMount() {
    const { devices } = this.props;
    if (devices && devices.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.devices !== this.props.devices &&
      this.props.devices &&
      this.props.devices.length
    ) {
      this.showTable(true);
    }
  }

  showTable = (loadTable) => {
    if (loadTable) {
      const {
        devices,
        pagination,
        updateFirmware,
      } = this.props;

      const rows = devices.map((devices, index) => {
        let gatewayRow = { ...devices };
        delete gatewayRow['userId'];
        gatewayRow.createdAt = convertDateFormat(
          gatewayRow.createdAt,
          'shortDateTime'
        );
        gatewayRow.action =
            <MDBBtn
              onClick={() => updateFirmware(devices.deviceId)}
              tag="a"
              rounded
              size="sm"
              className="px-2 transparentBtn"
            >
              <i className="fas fa-cog mt-0"></i>
            </MDBBtn>
          
        return gatewayRow;
      });
      this.setState({
        loadTable,
        deviceList: {
          columns:
          columns,
          rows: rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { changePage } = this.props;
    const { loadTable, deviceList, pagination } = this.state;
    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort"
              bordered
              data={deviceList}
              paging={false}
              scrollX
            />
            <Row className="mt-3 mb-3">
              <Col lg={6}>
                <label>
                  Showing {pagination.currentPage * 10 - 9} to{' '}
                  {pagination.currentPage * 10} of all results
                </label>
              </Col>
              <Col lg={6} className="text-right">
                <Pagination
                  pagination={pagination}
                  changePage={(page) => changePage(page)}
                ></Pagination>
              </Col>
            </Row>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default AlarmsTable;
