import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  dashboard: {
    gatewayCount: 0,
    deviceCount: 0,
  },
  deviceStatus: {},
  error: undefined,
  loading: false,
};

const getUserMetaStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    dashboard: {
      gatewayCount: 0,
      deviceCount: 0,
      totalOfflineDevices: 0,
      totalOfflineGateway: 0,
    },
    loading: false,
  });
};

const getUserMetaSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    dashboard: action.data,
    loading: false,
  });
};

const getUserMetaFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    dashboard: {
      gatewayCount: 0,
      deviceCount: 0,
      totalOfflineDevices: 0,
      totalOfflineGateway: 0,
    },
    loading: false,
  });
};

const getDeviceStatusStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
  });
};

const getDeviceStatusSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    deviceStatus: action.data,
    loading: false,
  });
};

const getDeviceStatusFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    deviceStatus: {},
    loading: false,
  });
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_META_START:
      return getUserMetaStart(state, action);
    case actionTypes.GET_USER_META_SUCCESS:
      return getUserMetaSuccess(state, action.data);
    case actionTypes.GET_USER_META_FAIL:
      return getUserMetaFail(state, action);
    case actionTypes.GET_DEVICE_STATUS_START:
      return getDeviceStatusStart(state, action);
    case actionTypes.GET_DEVICE_STATUS_SUCCESS:
      return getDeviceStatusSuccess(state, action.data);
    case actionTypes.GET_DEVICE_STATUS_FAIL:
      return getDeviceStatusFail(state, action);

    default:
      return state;
  }
};

export default dashboard;
