import React, { Component } from "react";
import { ModalHeader, ModalFooter, Button, ModalBody } from "reactstrap";
import { MDBDataTable } from "mdbreact";

const columns = [
  {
    label: "Account ID",
    field: "accountId",
    sort: "asc",
    width: 150,
  },
  {
    label: "Account Name",
    field: "accountName",
    sort: "asc",
    width: 150,
  },
  {
    label: "Account Email",
    field: "accountEmail",
    sort: "asc",
    width: 250,
  },
  {
    label: "Gateway ID",
    field: "gatewayId",
    sort: "asc",
    width: 150,
  },
  {
    label: "Gateway Name",
    field: "gatewayName",
    sort: "asc",
    width: 150,
  },
];

class AssociatedGatewayAndDevices extends Component {
  state = {
    list: {},
  };

  componentDidMount() {
    const { modalData } = this.props;
    if (
      modalData.deviceDetail &&
      (modalData.deviceDetail.account || modalData.deviceDetail.gateway)
    ) {
      const { deviceDetail } = modalData;
      const rows = {
        accountId: (deviceDetail.account && deviceDetail.account.id) || "N/A",
        accountName:
          (deviceDetail.account && deviceDetail.account.companyName) || "N/A",
        accountEmail:
          (deviceDetail.account && deviceDetail.account.email) || "N/A",
        gatewayId:
          (deviceDetail.gateway && deviceDetail.gateway.gatewayId) || "N/A",
        gatewayName:
          (deviceDetail.gateway && deviceDetail.gateway.name) || "N/A",
      };
      this.setState({
        list: {
          columns: columns,
          rows: [rows],
        },
      });
    }
  }

  render() {
    const { toggleModal } = this.props;
    const { list } = this.state;

    return (
      <React.Fragment>
        <ModalHeader toggle={toggleModal}>
          List Of Devices/Gateways Associated with Account
        </ModalHeader>
        <ModalBody>
          <MDBDataTable
            className="hideLastColSort"
            bordered
            data={list}
            paging={false}
            scrollX
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" type="button" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </React.Fragment>
    );
  }
}

export default AssociatedGatewayAndDevices;
