import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
import { showToast } from "../../helpers/toast";
import { saveAs } from "file-saver";

export const getDeviceListStart = () => {
  return {
    type: actionTypes.GET_DEVICE_LIST_START,
  };
};

export const getDeviceListSuccess = (data) => {
  return {
    type: actionTypes.GET_DEVICE_LIST_SUCCESS,
    data: data,
  };
};

export const getDeviceListFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_LIST_FAIL,
    error: error,
  };
};

export const deleteDeviceStart = () => {
  return {
    type: actionTypes.DELETE_DEVICE_START,
  };
};

export const deleteDeviceSuccess = (data) => {
  return {
    type: actionTypes.DELETE_DEVICE_SUCCESS,
    data: data,
  };
};

export const deleteDeviceFail = (error) => {
  return {
    type: actionTypes.DELETE_DEVICE_FAIL,
    error: error,
  };
};

export const addDeviceStart = () => {
  return {
    type: actionTypes.ADD_DEVICE_START,
  };
};

export const addDeviceSuccess = (data) => {
  return {
    type: actionTypes.ADD_DEVICE_SUCCESS,
    data: data,
  };
};

export const addDeviceFail = (error) => {
  return {
    type: actionTypes.ADD_DEVICE_FAIL,
    error: error,
  };
};

export const editDeviceStart = () => {
  return {
    type: actionTypes.EDIT_DEVICE_START,
  };
};

export const editDeviceSuccess = (data) => {
  return {
    type: actionTypes.EDIT_DEVICE_SUCCESS,
    data: data,
  };
};

export const editDeviceFail = (error) => {
  return {
    type: actionTypes.EDIT_DEVICE_FAIL,
    error: error,
  };
};

export const downloadDevicesStart = () => {
  return {
    type: actionTypes.DOWNLOAD_DEVICES_START,
  };
};

export const downloadDevicesSuccess = (data) => {
  return {
    type: actionTypes.DOWNLOAD_DEVICES_SUCCESS,
    data: data,
  };
};

export const downloadDevicesFail = (error) => {
  return {
    type: actionTypes.DOWNLOAD_DEVICES_FAIL,
    error: error,
  };
};

export const getDeviceList = ({ page, locationId, deviceId, isFilter }) => {
  return (dispatch) => {
    dispatch(getDeviceListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.list}`;
    url = url.replace("{id}", deviceId);
    if (page) {
      url += `?page=${page}&pageSize=10`;
    } else {
      url += "?page=1&pageSize=1000";
    }
    if (locationId) {
      url += `&locationId=${locationId}`;
    }

    if (isFilter) {
      url += `&location=false`;
    }

    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getDeviceListSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getDeviceListFail(error.response.data.errors));
        }
      });
  };
};

export const deleteDevice = ({ id }) => {
  return (dispatch) => {
    dispatch(deleteDeviceStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.delete}`;
    url = url.replace("{id}", id);
    axios
      .delete(url, { headers })
      .then((response) => {
        showToast("Device deleted successfully", "success");
        dispatch(deleteDeviceSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(deleteDeviceFail(error.response.data.errors));
        }
      });
  };
};

export const addDevice = (payload) => {
  return (dispatch) => {
    dispatch(addDeviceStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.add}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        showToast("Device addded successfully", "success");
        dispatch(addDeviceSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(addDeviceFail(error.response.data.errors));
          // if(error.response.data.errors[0].msg){
          //   showToast(error.response.data.errors[0].msg, 'error');
          // }
        }
      });
  };
};

export const editDevice = ({ payload, id }) => {
  return (dispatch) => {
    dispatch(editDeviceStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.edit}`;
    url = url.replace("{id}", id);
    axios
      .put(url, payload, { headers })
      .then((response) => {
        dispatch(editDeviceSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(editDeviceFail(error.response.data.errors));
        }
      });
  };
};

export const downloadDevices = () => {
  return (dispatch) => {
    dispatch(downloadDevicesStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.deviceListCSV}`;

    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = response;
        const blob = new Blob([data], { type: "application/csv" });

        saveAs(blob, "deviceList.csv");
        dispatch(downloadDevicesSuccess());
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(downloadDevicesFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};
