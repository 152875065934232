export const apiPath = {
  // baseUrl: 'http://18.140.174.118:3009',
  // baseUrl: "https://test.lariantech.cloud",
  webSocket: "ws://18.140.174.118:3009",
  baseUrl: process.env.REACT_APP_BE_URL
    ? process.env.REACT_APP_BE_URL
    : "https://test.lariantech.cloud",
  baseLambdaUrl: "`https://api.lariantech.cloud",
  // baseGraphanaUrl: "https://lariantech.cloud/",
  baseGraphanaUrl: process.env.REACT_APP_GRAFANA_URL
    ? process.env.REACT_APP_GRAFANA_URL
    : "https://lariantech.cloud/grafana-iframe-data/d-solo/ca5494ea-69d0-4c6c-b4cc-8a7b58274f04/livedashboard?",
  waterSensorGraphanaUrl: process.env.REACT_APP_GRAFANA_WATER_SENSOR_URL
    ? process.env.REACT_APP_GRAFANA_WATER_SENSOR_URL
    : "https://lariantech.cloud/grafana-iframe-data/d-solo/ca5494ea-69d0-4c6c-b4cc-8a7b58274f04/livedashboard?",
  account: {
    list: "/account/list",
    addSmsLimit: "/account/addLimit",
    toggleTemp: "/account/updateAccountSettings",
    tempStatus: "/account/getAccountSettings",
    generateAPIKey: "/account/getApiKey",
    createAPIKey: "/account/createApiKey",
    voiceCall: "/account/voiceCall/{id}",
    delete: "/account/deleteAccount/{id}",
  },
  map: {
    list: "/map/list",
    add: "/map",
    uploadMap: "/map/upload",
    delete: "/map/{id}",
  },
  auditTrail: {
    list: "/audit/logs",
    pdf: "/tasks",
    task: "/tasks/{id}",
  },
  messageTemplates: {
    list: "/message-templates/",
    recent: "/message-templates/{id}",
    add: "/message-templates/",
    edit: "/message-templates/{id}",
    delete: "/message-templates/{id}",
  },
  messageTemplatesRel: {
    get: "/message-templates-rel/",
    set: "/message-templates-rel/",
  },
  deviceAlarm: {
    list: "/alarms/{id}",
    add: "/alarms/setAlarm",
    edit: "/alarms/{id}",
    delete: "/alarms/{id}",
    recent: "/alarms/recentAlarms/v2",
    recentDoors: "/alarms/recentDoorOpenings",
    addComment: "/alarms/addComment",
    pdfDownload: "/alarms/downloadAlarmPDF",
    csvDownload: "/alarms/downloadAlarmCSV",
    favourites: "/alarms/favourites",
  },

  auth: {
    login: "/auth",
    logout: "/auth",
    signUp: "/auth/signup",
    forgotInit: "/auth/forgot",
    validateToken: "/auth/forgot/{resetToken}",
    resetPassword: "/auth/forgot/{resetToken}",
    emailConfirmation: "/auth/confirm/{emailToken}",
    createCompany: "/account/public/create",
  },
  device: {
    add: "/device",
    edit: "/device/{id}",
    delete: "/device/{id}",
    list: "/device/{id}/",
    assignUser: "/device/subuser/assign",
    unassignUser: "/device/subuser/unassign",
    csvdata: "/device/csvdata",
    unassignLocation: "/device/unassignLocation/{id}",
    downloadCsv: "/device/downloadCsv",
    downloadPdf: "/device/downloadPdf",
    downloadCsvData: process.env.REACT_APP_DOWNLOAD_CSV_URL
      ? process.env.REACT_APP_DOWNLOAD_CSV_URL
      : "https://test.lariantech.cloud/device/downloadDeviceDataCsv/",
    downloadPdfData: process.env.REACT_APP_DOWNLOAD_PDF_URL
      ? process.env.REACT_APP_DOWNLOAD_PDF_URL
      : "https://test.lariantech.cloud/device/downloadDeviceDataPdf/",
    deviceListCSV: "/device/downloadDevicesCSV",
  },
  gateway: {
    add: "/gateway",
    edit: "/gateway/{id}",
    delete: "/gateway/{id}",
    list: "/gateway/{id}/",
    upload: "/gateway/public/uploadFile",
    global: "/gateway/global",
    update: "/gateway/public/gateway",
    gatewayListCSV: "/gateway/downloadGatewaysCSV",
  },
  user: {
    add: "/user/add",
    edit: "/user/{id}",
    delete: "/user/{id}",
    list: "/user/{id}/",
    userIds: "/user/userIds",
    subUsersList: "/user/subUsers",
  },
  report: {
    add: "/reports",
    edit: "/reports/{id}",
    delete: "/reports/{id}",
    list: "/reports",
  },
  dashboard: {
    userMeta: "/user/metadata",
    deviceStatus: "/misc/latestData",
    accountMeta: "/prod/accountMetadata",
    accountMetaData: "/user/accountMetadata",
  },
  locations: {
    list: "/location/{id}",
    add: "/location",
    edit: "/location/{id}",
    delete: "/location/{id}",
  },
  otaDevices: {
    list: "/device/global",
  },
  userNotifications: {
    list: "/user/notifications",
    statistics: "/user/notifications/statistics",
  },
  gatewayLogs: {
    list: "/gateway/gatewayLogs",
  },
  alarms: {
    list: "/alarms/alarmsList",
  },
  alertProfile: {
    save: "/alarms/setAlarmProfile",
    get: "/alarms/getAlarmProfiles",
    delete: "/alarms/deleteAlarmProfile",
    edit: "/alarms/updateAlarmProfile",
  },
  offlineAlarms: {
    offlineAlarmsUrl: "/misc/account/offlineAlarmSetting",
  },
};
export const config = {
  userCancelledErrorMessage: "Cancelled - User Action",
};
export const appInfo = {
  year: new Date().getFullYear(),
  name: "Larian Tech",
  desc: "www.lariantech.com",
};
