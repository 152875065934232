import React, { Component } from 'react';
import { convertDateFormat, capitalizeFirstLetter } from '../../utils/helperFunctions';
import { MDBBtn, MDBDataTable } from 'mdbreact';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Pagination from '../Common/Pagination';
const columns = [
    {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width : 250,
    },
    {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width : 300,
    }, 
    {
        label: 'Phone Number',
        field: 'phone',
        sort: 'asc',
        width : 250,
    }, 
    {
        label: 'Created At',
        field: 'createdAt',
        sort: 'asc',
        width : 250,
    },
];
// ,
// {
//     label: 'Action',
//         field: 'action',
//     }
class SubUsersTable extends Component {
    
    state = {
        loadTable: false,
        userList: [],
        pagination: {}
    }

    componentDidMount() {
        const { users } = this.props;
        if (users && users.length) {
            this.showTable(true);
        } else {
            this.showTable(false);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.users !== this.props.users && this.props.users && this.props.users.length) {
            this.showTable(true);
        }
    }

    showTable = loadTable => {
        if(loadTable) {
            const { users, pagination } = this.props;
            const rows = users.map((user, index) => {
                let userRow = {...user};
                
                delete userRow['userId'];
                if (userRow.countryCode && userRow.phoneNumber) {
                    userRow.phone = userRow.countryCode + userRow.phoneNumber;
                } else {
                    userRow.phone = 'N/A';
                }
                
                userRow.usr = userRow.usr !== null ? userRow.usr : 'N/A';
                userRow.createdAt = convertDateFormat(userRow.createdAt, 'shortDateTime');
                
                
                return userRow;
            })
            this.setState({
                loadTable,
                userList: {
                    columns: columns,
                    rows: rows
                }, 
                pagination
            });
        }
        else {
            this.setState({ loadTable })
        }
    }
    
    render() {
        const { changePage } = this.props;
        const { loadTable, userList, pagination } = this.state;
        return (
            <React.Fragment>
                {loadTable ? 
                    <>
                    <MDBDataTable
                        className="hideLastColSort"
                        bordered
                        data={userList}
                        paging={false}
                        scrollX
                    />
                    <Row className="mt-3 mb-3">
                        <Col lg={6}>
                                <label>Showing {pagination.currentPage*10 - 9} to {pagination.currentPage * 10} of all results</label>
                        </Col>
                        <Col lg={6} className="text-right">
                            <Pagination pagination={pagination} changePage={page => changePage(page)}></Pagination>
                        </Col>
                    </Row>
                    </>
                 : null}
                
            </React.Fragment>
        );

    }
}

export default withRouter(SubUsersTable);
