import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  data: [],
  meta: {},
  task: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const getAuditTrailStart = (state, action) => {
  return updateObject(state, {
    task: {},
    error: undefined,
    data: [],
    meta: {},
    loading: true,
  });
};

const getAuditTrailSuccess = (state, action) => {
  return updateObject(state, {
    task: {},
    data: action.data,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getAuditTrailFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    task: {},
    data: [],
    meta: {},
    loading: false,
  });
};

const pdfExportAuditTrailStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    task: {},
    meta: {},
    loading: true,
  });
};

const pdfExportAuditTrailSuccess = (state, action) => {
  return updateObject(state, {
    task: action,
    error: undefined,
    loading: false,
  });
};

const pdfExportAuditTrailFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    task: [],
    meta: {},
    loading: false,
  });
};

const auditTrail = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AUDIT_TRAIL_START:
      return getAuditTrailStart(state, action);
    case actionTypes.GET_AUDIT_TRAIL_SUCCESS:
      return getAuditTrailSuccess(state, action.data);
    case actionTypes.GET_AUDIT_TRAIL_FAIL:
      return getAuditTrailFail(state, action);
    case actionTypes.PDF_EXPORT_TRAIL_START:
      return pdfExportAuditTrailStart(state, action);
    case actionTypes.PDF_EXPORT_TRAIL_SUCCESS:
      return pdfExportAuditTrailSuccess(state, action.task);
    case actionTypes.PDF_EXPORT_TRAIL_FAIL:
      return pdfExportAuditTrailFail(state, action);
    default:
      return state;
  }
};

export default auditTrail;
