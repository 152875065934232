import React, { Component } from "react";
import { convertDateFormat } from "../../utils/helperFunctions";
import { MDBBtn, MDBDataTable } from "mdbreact";
import { Row, Col } from "reactstrap";
import Pagination from "../Common/Pagination";
const columns = [
  {
    label: "Device ID",
    field: "deviceId",
    sort: "asc",
    width: 250,
  },
  {
    label: "Device Name",
    field: "name",
    sort: "asc",
    width: 250,
  },
  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 250,
  },
  //   {
  //     label: 'Action',
  //     field: 'action',
  //     width: 80,
  //   },
];
const superAdminColumn = [
  {
    label: "Gateway ID",
    field: "gatewayId",
    sort: "asc",
    width: 250,
  },
  {
    label: "Message",
    field: "messages",
    sort: "asc",
    width: 250,
  },
  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 250,
  },
  //   {
  //     label: 'FV Last',
  //     field: 'fvLastVersion',
  //     sort: 'asc',
  //     width: 100,
  //   },
  //   {
  //     label: 'FV New',
  //     field: 'fvNewVersion',
  //     sort: 'asc',
  //     width: 100,
  //   },
  //   {
  //     label: 'Action',
  //     field: 'action',
  //     width: 80,
  //   },
];
class DeviceTable extends Component {
  state = {
    loadTable: false,
    logsList: [],
    pagination: {},
    role: localStorage.getItem("role"),
  };

  componentDidMount() {
    const { gatewayLogs } = this.props;
    if (gatewayLogs && gatewayLogs.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.gatewayLogs !== this.props.gatewayLogs &&
      this.props.gatewayLogs &&
      this.props.gatewayLogs.length
    ) {
      this.showTable(true);
    }
  }

  showTable = (loadTable) => {
    if (loadTable) {
      const {
        gatewayLogs,
        // editGateway,
        // deleteGateway,
        pagination,
        updateFirmware,
      } = this.props;
      const rows = gatewayLogs.map((gatewayLogs, index) => {
        let gatewayRow = { ...gatewayLogs };
        delete gatewayRow["userId"];
        gatewayRow.createdAt = convertDateFormat(
          gatewayRow.createdAt,
          "shortDateTime"
        );
        gatewayRow.action = (
          // this.state.role === 'super_admin' ? (
          <MDBBtn
            //   onClick={() => updateFirmware(gatewayLogs.deviceId)}
            tag="a"
            rounded
            size="sm"
            className="px-2 transparentBtn"
          >
            <i className="fas fa-cog mt-0"></i>
          </MDBBtn>
        );
        // ) : (
        // <>
        //   <MDBBtn
        //     // onClick={deleteGateway.bind(this, gatewayRow.id)}
        //     tag="a"
        //     rounded
        //     size="sm"
        //     className="px-2 transparentBtn"
        //   >
        //     <i className="fas fa-trash mt-0"></i>
        //   </MDBBtn>
        //   <MDBBtn
        //     // onClick={editGateway.bind(this, gatewayRow)}
        //     tag="a"
        //     rounded
        //     size="sm"
        //     className="px-2 transparentBtn"
        //   >
        //     <i className="fas fa-pencil-alt mt-0"></i>
        //   </MDBBtn>
        // </>
        // );
        return gatewayRow;
      });
      this.setState({
        loadTable,
        logsList: {
          columns: superAdminColumn,
          rows: rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { changePage } = this.props;
    const { loadTable, logsList, pagination } = this.state;
    const formattedRows =
      logsList.rows &&
      logsList.rows.map((data) => {
        return {
          gatewayId: data.gatewayId,
          createdAt: data.createdAt,
          messages: data.message,
        };
      });
    const formattedData = { ...logsList, rows: formattedRows };

    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort"
              bordered
              data={formattedData}
              paging={false}
              scrollX
            />
            <Row className="mt-3 mb-3">
              <Col lg={6}>
                <label>
                  Showing {pagination.currentPage * 10 - 9} to{" "}
                  {pagination.currentPage * 10} of all results
                </label>
              </Col>
              <Col lg={6} className="text-right">
                <Pagination
                  pagination={pagination}
                  changePage={(page) => changePage(page)}
                ></Pagination>
              </Col>
            </Row>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default DeviceTable;
