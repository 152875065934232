import React, { useEffect, useState } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import AuthBase from "./containers/AuthBase/AuthBase";
import BaseHorizontal from "./containers/Base/BaseHorizontal";
import Login from "./components/Pages/Login";
import Register from "./components/Pages/Register";
import ForgotPassword from "./components/Pages/ForgotPassword";
import ResetPassword from "./components/Pages/ResetPassword";
import Dashboard from "./components/Dashboard/Dashboard";
import Devices from "./components/Devices/Devices";
import Gateways from "./components/Gateways/Gateways";
import Users from "./components/Users/Users";
import SubUsers from "./components/SubUsers/SubUsers";
import Alarms from "./components/Alarms/Alarms";
import Reports from "./components/Reports/Reports";
import OtaDevices from "./components/OtaDevices/otaDevices";
import UserNotifications from "./components/UserNotifications/userNotifications";
import UserNotificationStatistics from "./components/UserNotificationsStatistics/userNotificationStatistics";
import UserMessageTemplates from "./components/UserMessageTemplates/UserMessageTemplates";
import AuditTrail from "./components/AuditTrail/AuditTrail";
import Map from "./components/Map/Map";
import GatewayLogs from "./components/GatewayLogs/gatewayLogs";
import { toast } from "react-toastify";
import Privacypolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/Terms&Conditions";
import AlertProfile from "./components/AlertProfile";
import LightProfile from "./components/AlertProfile/lightProfile";
import Accounts from "./components/Accounts";

const Routes = (props) => {
  const { location } = props;
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };
  const animationName = "rag-fadeIn";
  const role = localStorage.getItem("role");

  useEffect(() => {
    window.addEventListener("offline", () => {
      toast("No internet connection", {
        type: "error",
        position: "bottom-center",
        autoClose: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        hideProgressBar: true,
      });
    });
    window.addEventListener("online", () => {
      window.location.reload();
    });
  }, []);

  if (props.isAuthenticated) {
    return (
      <BaseHorizontal>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/devices" component={Devices} />
                <Route exact path="/gateways" component={Gateways} />
                <Route exact path="/users" component={Users} />
                <Route exact path="/accounts" component={Accounts} />
                <Route exact path="/reports" component={Reports} />
                <Route exact path="/otaDevices" component={OtaDevices} />
                <Route
                  exact
                  path="/UserNotifications"
                  component={UserNotifications}
                />
                <Route
                  exact
                  path="/UserNotificationStatistics"
                  component={UserNotificationStatistics}
                />
                <Route
                  exact
                  path="/UserMessageTemplates"
                  component={UserMessageTemplates}
                />
                <Route exact path="/map" component={Map} />
                <Route exact path="/AuditTrail" component={AuditTrail} />
                <Route exact path="/gatewaylogs" component={GatewayLogs} />
                <Route exact path="/subUsers" component={SubUsers} />
                <Route exact path="/alarms" component={Alarms} />
                <Route exact path="/privacyPolicy" component={Privacypolicy} />
                <Route
                  exact
                  path="/termsAndConditions"
                  component={TermsAndConditions}
                />
                <Route exact path="/alertProfile" component={AlertProfile} />
                <Route exact path="/lightProfile" component={LightProfile} />
                {role && role === "super_admin" ? (
                  <Route path="/" component={Users} />
                ) : (
                  <Route path="/" component={Dashboard} />
                )}
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </BaseHorizontal>
    );
  } else {
    return (
      <AuthBase>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/lariantech/admin/signup" component={Register} />
          <Route exact path="/forgot" component={ForgotPassword} />
          <Route exact path="/privacyPolicy" component={Privacypolicy} />
          <Route
            exact
            path="/termsAndConditions"
            component={TermsAndConditions}
          />
          <Route
            exact
            path="/auth/forgot/:resetToken"
            render={({ match }) => (
              <ResetPassword resetToken={match.params.resetToken} />
            )}
          />
          <Route exact path="/" component={Login} />
          <Route exact component={Login} />
        </Switch>
      </AuthBase>
    );
  }
};

export default withRouter(Routes);
