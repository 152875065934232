import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
import { showToast } from "../../helpers/toast";
import { saveAs } from "file-saver";
// import { dispatch } from "d3-dispatch";

export const getGatewayListStart = () => {
  return {
    type: actionTypes.GET_GATEWAY_LIST_START,
  };
};

export const getGatewayListSuccess = (data) => {
  return {
    type: actionTypes.GET_GATEWAY_LIST_SUCCESS,
    data: data,
  };
};

export const getGatewayListFail = (error) => {
  return {
    type: actionTypes.GET_GATEWAY_LIST_FAIL,
    error: error,
  };
};

export const deleteGatewayStart = () => {
  return {
    type: actionTypes.DELETE_GATEWAY_START,
  };
};

export const deleteGatewaySuccess = (data) => {
  return {
    type: actionTypes.DELETE_GATEWAY_SUCCESS,
    data: data,
  };
};

export const deleteGatewayFail = (error) => {
  return {
    type: actionTypes.DELETE_GATEWAY_FAIL,
    error: error,
  };
};

export const addGatewayStart = () => {
  return {
    type: actionTypes.ADD_GATEWAY_START,
  };
};

export const addGatewaySuccess = (data) => {
  return {
    type: actionTypes.ADD_GATEWAY_SUCCESS,
    data: data,
  };
};

export const addGatewayFail = (error) => {
  return {
    type: actionTypes.ADD_GATEWAY_FAIL,
    error: error,
  };
};

export const editGatewayStart = () => {
  return {
    type: actionTypes.EDIT_GATEWAY_START,
  };
};

export const editGatewaySuccess = (data) => {
  return {
    type: actionTypes.EDIT_GATEWAY_SUCCESS,
    data: data,
  };
};

export const editGatewayFail = (error) => {
  return {
    type: actionTypes.EDIT_GATEWAY_FAIL,
    error: error,
  };
};

export const uploadGatewayStart = () => {
  return {
    type: actionTypes.UPLOAD_GATEWAY_START,
  };
};

export const uploadGatewaySuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_GATEWAY_SUCCESS,
    data: data,
  };
};

export const uploadGatewayFail = (error) => {
  return {
    type: actionTypes.UPLOAD_GATEWAY_FAIL,
    error: error,
  };
};

export const updateGatewayStart = () => {
  return {
    type: actionTypes.UPDATE_GATEWAY_START,
  };
};

export const updateGatewaySuccess = (data) => {
  return {
    type: actionTypes.UPDATE_GATEWAY_SUCCESS,
    data: data,
  };
};

export const updateGatewayFail = (error) => {
  return {
    type: actionTypes.UPDATE_GATEWAY_FAIL,
    error: error,
  };
};

export const downloadGatwaysStart = () => {
  return {
    type: actionTypes.DOWNLOAD_GATEWAY_START,
  };
};

export const downloadGatwaysSuccess = (data) => {
  return {
    type: actionTypes.DOWNLOAD_GATEWAY_SUCCESS,
    data: data,
  };
};

export const downloadGatwaysFail = (error) => {
  return {
    type: actionTypes.DOWNLOAD_GATEWAY_FAIL,
    error: error,
  };
};

export const getGatewayList = (page = 1, gatewayId = undefined) => {
  return (dispatch) => {
    dispatch(getGatewayListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.gateway.list}`;
    const role = localStorage.getItem("role");
    url = url.replace("{id}", gatewayId);
    if (role === "super_admin") {
      url = `${apiPath.baseUrl}${apiPath.gateway.global}`;
    }
    // if (page) {
    //   url += `?page=${page}&pageSize=10`;
    // }
    // else {
    url += "?page=1&pageSize=1000";
    // }
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getGatewayListSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getGatewayListFail(error.response.data.errors));
        }
      });
  };
};

export const deleteGateway = ({ id }) => {
  return (dispatch) => {
    dispatch(deleteGatewayStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.gateway.delete}`;

    url = url.replace("{id}", id);

    axios
      .delete(url, { headers })
      .then((response) => {
        // showToast("Gateway deleted successfully", "success");
        dispatch(deleteGatewaySuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(deleteGatewayFail(error.response.data.errors));
        }
      });
  };
};

export const addGateway = (payload) => {
  return (dispatch) => {
    dispatch(addGatewayStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.gateway.add}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        // showToast("Gateway added successfully", "success");
        dispatch(addGatewaySuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(addGatewayFail(error.response.data.errors));
        }
      });
  };
};

export const editGateway = ({ payload, id }) => {
  return (dispatch) => {
    dispatch(editGatewayStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.gateway.edit}`;
    url = url.replace("{id}", id);
    axios
      .put(url, payload, { headers })
      .then((response) => {
        // showToast("Gateway updated successfully", "success");
        dispatch(editGatewaySuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(editGatewayFail(error.response.data.errors));
        }
      });
  };
};

export const uploadGatewayFirmware = (payload) => {
  return (dispatch) => {
    dispatch(uploadGatewayStart());
    const headers = getCommonHeaders();
    const formData = new FormData();
    payload && payload.map((eachData) => formData.append("file", eachData));
    // formData.append("file", payload);
    const formattedData = {
      files: formData,
    };
    let url = `${apiPath.baseUrl}${apiPath.gateway.upload}`;
    axios
      .post(url, formData, { headers, "content-type": "multipart/form-data" })
      .then((response) => {
        showToast("Gateway uploaded successfully", "success");
        dispatch(uploadGatewaySuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(uploadGatewayFail(error.response.data.errors));
        }
      });
  };
};

export const updateGateway = (payload) => {
  return (dispatch) => {
    dispatch(updateGatewayStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.gateway.update}?id=${payload.id}&command=${payload.command}`;
    axios
      .get(url, { headers })
      .then((response) => {
        showToast(
          `${
            payload.Node_Devices ? "Node Devices" : "Gateway"
          } updated successfully`,
          "success"
        );
        dispatch(updateGatewaySuccess(payload));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(updateGatewayFail(error.response.data.errors));
        }
      });
  };
};

export const downloadGatways = () => {
  return (dispatch) => {
    dispatch(downloadGatwaysStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.gateway.gatewayListCSV}`;

    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = response;
        const blob = new Blob([data], { type: "application/csv" });

        saveAs(blob, "gatewayList.csv");
        dispatch(downloadGatwaysSuccess());
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(downloadGatwaysFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};
