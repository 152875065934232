import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  gatewayList: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const getGatewayListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    gatewayList: [],
    meta: {},
    loading: true,
  });
};

const getGatewayListSuccess = (state, action) => {
  return updateObject(state, {
    gatewayList: action.gatewayList,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getGatewayListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    gatewayList: [],
    meta: {},
    loading: false,
  });
};

const deleteGatewayStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const deleteGatewaySuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: undefined,
    reload: true,
    gatewayList: [],
    success: "Gateway deleted successfully.",
  });
};

const deleteGatewayFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error[0].message,
    reload: false,
    success: undefined,
  });
};

const addGatewayStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const addGatewaySuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    gatewayList: [],
    success: "Gateway added successfully.",
  });
};

const addGatewayFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message || action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const editGatewayStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const editGatewaySuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    gatewayList: [],
    success: "Gateway updated successfully",
  });
};

const editGatewayFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message || action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const uploadGatewayStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const uploadGatewaySuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    success: "Gateway updated successfully",
  });
};

const uploadGatewayFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message || action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const updateGatewayStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const updateGatewaySuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    success: action.Node_Devices
      ? "Node devices updated successfully"
      : "Gateway updated successfully",
  });
};

const updateGatewayFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message || action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const downloadGatewaysStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const downloadGatewaysSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    success: undefined,
  });
};

const downloadGatewaysFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message || action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const gateways = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GATEWAY_LIST_START:
      return getGatewayListStart(state, action);
    case actionTypes.GET_GATEWAY_LIST_SUCCESS:
      return getGatewayListSuccess(state, action.data);
    case actionTypes.GET_GATEWAY_LIST_FAIL:
      return getGatewayListFail(state, action);
    case actionTypes.DELETE_GATEWAY_START:
      return deleteGatewayStart(state, action);
    case actionTypes.DELETE_GATEWAY_SUCCESS:
      return deleteGatewaySuccess(state, action.data);
    case actionTypes.DELETE_GATEWAY_FAIL:
      return deleteGatewayFail(state, action);
    case actionTypes.ADD_GATEWAY_START:
      return addGatewayStart(state, action);
    case actionTypes.ADD_GATEWAY_SUCCESS:
      return addGatewaySuccess(state, action.data);
    case actionTypes.ADD_GATEWAY_FAIL:
      return addGatewayFail(state, action);
    case actionTypes.EDIT_GATEWAY_START:
      return editGatewayStart(state, action);
    case actionTypes.EDIT_GATEWAY_SUCCESS:
      return editGatewaySuccess(state, action.data);
    case actionTypes.EDIT_GATEWAY_FAIL:
      return editGatewayFail(state, action);
    case actionTypes.UPLOAD_GATEWAY_START:
      return uploadGatewayStart(state.action);
    case actionTypes.UPLOAD_GATEWAY_SUCCESS:
      return uploadGatewaySuccess(state, action.data);
    case actionTypes.UPLOAD_GATEWAY_FAIL:
      return uploadGatewayFail(state, action);
    case actionTypes.UPDATE_GATEWAY_START:
      return updateGatewayStart(state.action);
    case actionTypes.UPDATE_GATEWAY_SUCCESS:
      return updateGatewaySuccess(state, action.data);
    case actionTypes.UPDATE_GATEWAY_FAIL:
      return updateGatewayFail(state, action);
    case actionTypes.DOWNLOAD_GATEWAY_START:
      return downloadGatewaysStart(state.action);
    case actionTypes.DOWNLOAD_GATEWAY_SUCCESS:
      return downloadGatewaysSuccess(state, action.data);
    case actionTypes.DOWNLOAD_GATEWAY_FAIL:
      return downloadGatewaysFail(state, action);
    default:
      return state;
  }
};

export default gateways;
