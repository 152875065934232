import React, { Component } from 'react';
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../stores/actions/index';
import { fieldHasError, validateOnChange, onSubmit } from '../../helpers/formValidators';
class AddEditGateway extends Component {

    state = {
        mode: 'Add',
        formGateway: {
            gatewayName: '',
            gatewayId: ''
        },
        id: '',
        formError: ''
    }

    addGatewayFn = (payload) => {
        const { addGateway } = this.props;
        addGateway(payload);
    }

    editGatewayFn = (payload, id) => {
        const { editGateway } = this.props;
        editGateway({ payload, id });
    }

    componentDidMount() {
        const { modalData } = this.props;
        if (modalData && modalData.gateway) {
            const { gateway } = modalData;
            this.setState({
                mode: 'Edit',
                formGateway: {
                    gatewayName: gateway.name,
                    gatewayId: gateway.gatewayId
                },
                id: gateway.id
            });
        }
    }

    validateOnChange = event => {
        const state = validateOnChange(event, this.state);
        const updatedState = !event.target.value ? {...state, formError: ""} : state
        this.setState(updatedState);
    }

    onSubmit = e => {
        const state = onSubmit(e, this.state);

        this.setState(state);
        if (!this.state.formError) {
            if (!state.hasError) {
                const params = {
                    gatewayName: this.state.formGateway.gatewayName,
                    gatewayId: (this.state.formGateway.gatewayId).toLowerCase(),
                }
                if (state.mode === 'Add') {
                    this.addGatewayFn(params)
                } else {
                    this.editGatewayFn(params, state.id)
                }
            }
        }
        e.preventDefault();

    }

    /* Simplify error check */
    hasError = (formName, inputName, method) => {
        return fieldHasError(this.state, formName, inputName, method);
    }

    render() {
        const { toggleModal } = this.props;
        const { mode } = this.state;
        return (
            <React.Fragment>
                <form className="mb-3" name="formGateway" onSubmit={this.onSubmit}>
                    <ModalHeader toggle={toggleModal}>{mode} Gateway</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label className="text-muted">Gateway Name</label>
                            <div className="input-group with-focus">
                                <Input type="text"
                                    name="gatewayName"
                                    placeholder="Enter Gateway Name"
                                    invalid={this.hasError('formGateway', 'gatewayName', 'required')}
                                    onChange={this.validateOnChange}
                                    data-validate='["required"]'
                                    value={!!this.state.formGateway.gatewayName.trim() ? this.state.formGateway.gatewayName : ''}
                                    minLength="3" />
                                {this.hasError('formGateway', 'gatewayName', 'required') && <span className="invalid-feedback">Gateway name is required</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="text-muted">Gateway ID</label>
                            <div className="input-group with-focus">
                                <Input type="text"
                                    name="gatewayId"
                                    placeholder="Enter Gateway ID"
                                    invalid={this.hasError('formGateway', 'gatewayId', 'required')}
                                    onChange={this.validateOnChange}
                                    data-validate='["required"]'
                                    value={!!this.state.formGateway.gatewayId.trim() ? this.state.formGateway.gatewayId : ''}
                                    minLength="2" />
                                {this.hasError('formGateway', 'gatewayId', 'required') && <span className="invalid-feedback">Gateway Id is required</span>}
                            </div>
                        </div>
                        {/* {!!this.state.formError && <div style={{color: '#f05050', marginTop: '0.25rem', fontSize: '80%', width: '100%'}}>{`${this.state.formError === "gatewayName" ? "Gateway Name" : "Gateway ID"} is Required`}</div>} */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" type="submit">{mode === 'Add' ? 'Add' : 'Save'}</Button>
                        <Button color="secondary" type="button" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </form>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addGateway: (data) => dispatch(actions.addGateway(data)),
        editGateway: (data) => dispatch(actions.editGateway(data))
    };
};

export default connect(null, mapDispatchToProps)(AddEditGateway);
