import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
import { showToast } from "../../helpers/toast";
// import { dispatch } from "d3-dispatch";

export const getUserNotificationStatisticsStart = () => {
  return {
    type: actionTypes.GET_USER_NOTIFICATIONS_STATISTICS_START,
  };
};

export const getUserNotificationStatisticsSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_NOTIFICATIONS_STATISTICS_SUCCESS,
    data: data,
  };
};

export const getUserNotificationStatisticsFail = (error) => {
  return {
    type: actionTypes.GET_USER_NOTIFICATIONS_STATISTICS_FAIL,
    error: error,
  };
};


export const addSmsLimitStart = () => {
  return {
    type: actionTypes.ADD_SMS_LIMIT_START,
  };
};

export const addSmsLimitSuccess = (data) => {
  return {
    type: actionTypes.ADD_SMS_LIMIT_SUCCESS,
    data: data,
  };
};

export const addSmsLimitFail = (error) => {
  return {
    type: actionTypes.ADD_SMS_LIMIT_FAIL,
    error: error,
  };
};

export const getUserNotificationStatistics = (params = {page: 1, pageSize: 10}) => {
  return (dispatch) => {
    dispatch(getUserNotificationStatisticsStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.userNotifications.statistics}`;
    if (params.page) {
      url += `?page=${params.page}&pageSize=${params.pageSize}`;
    } else {
      url += "?page=1&pageSize=1000";
    }
                              
    if(params.email){
      url += "&email="+params.email;
    }
    if(params.phone){
      url += "&phone="+params.phone;
    }
    if(params.text){
      url += "&text="+params.text;
    }
    if(params.is_sms && params.is_sms !== -1){
      url += "&isSms="+params.is_sms;
    }
    if(params.startDate){
      url += "&startDate="+params.startDate;
    }
    if(params.endDate){
      url += "&endDate="+params.endDate;
    }
    if(params.accountId){
      url += "&accountId="+params.accountId;
    }
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getUserNotificationStatisticsSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getUserNotificationStatisticsFail(error.response.data.errors));
        }
      });
  };
};

export const addSmsLimit = (payload) => {
  return (dispatch) => {
    dispatch(addSmsLimitStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.account.addSmsLimit}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        dispatch(addSmsLimitSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(addSmsLimitFail(error.response.data.errors));
        }
      });
  };
};