// AUTH
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const VALIDATE_RESET_TOKEN_START = "VALIDATE_RESET_TOKEN_START";
export const VALIDATE_RESET_TOKEN_SUCCESS = "VALIDATE_RESET_TOKEN_SUCCESS";
export const VALIDATE_RESET_TOKEN_FAIL = "VALIDATE_RESET_TOKEN_FAIL";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const EMAIL_CONFIRMATION_START = "EMAIL_CONFIRMATION_START";
export const EMAIL_CONFIRMATION_SUCCESS = "EMAIL_CONFIRMATION_SUCCESS";
export const EMAIL_CONFIRMATION_FAIL = "EMAIL_CONFIRMATION_FAIL";
export const SINGUP_START = "SINGUP_START";
export const SINGUP_SUCCESS = "SINGUP_SUCCESS";
export const SINGUP_FAIL = "SINGUP_FAIL";
export const TOGGLE_TEMP_START = "TOGGLE_TEMP_START";
export const TOGGLE_TEMP_SUCCESS = "TOGGLE_TEMP_SUCCESS";
export const TOGGLE_TEMP_FAIL = "TOGGLE_TEMP_FAIL";
export const GET_TEMP_STATUS_START = "GET_TEMP_STATUS_START";
export const GET_TEMP_STATUS_SUCCESS = "GET_TEMP_STATUS_SUCCESS";
export const GET_TEMP_STATUS_FAIL = "GET_TEMP_STATUS_FAIL";

// MODAL
export const SET_MODAL = "SET_MODAL";

//DASHBOARD
export const GET_USER_META_START = "GET_USER_META_START";
export const GET_USER_META_SUCCESS = "GET_USER_META_SUCCESS";
export const GET_USER_META_FAIL = "GET_USER_META_FAIL";

export const GET_DEVICE_STATUS_START = "GET_DEVICE_STATUS_START";
export const GET_DEVICE_STATUS_SUCCESS = "GET_DEVICE_STATUS_SUCCESS";
export const GET_DEVICE_STATUS_FAIL = "GET_DEVICE_STATUS_FAIL";

// SOCKET
// BasicData
export const GET_DEVICE_BASIC_DATA_START = "GET_DEVICE_BASIC_DATA_START";
export const GET_DEVICE_BASIC_DATA_SUCCESS = "GET_DEVICE_BASIC_DATA_SUCCESS";
export const GET_DEVICE_BASIC_DATA_FAIL = "GET_DEVICE_BASIC_DATA_FAIL";
// GraphData
export const GET_GRAPH_DATA_START = "GET_GRAPH_DATA_START";
export const GET_GRAPH_DATA_SUCCESS = "GET_GRAPH_DATA_SUCCESS";
export const GET_GRAPH_DATA_FAIL = "GET_GRAPH_DATA_FAIL";
// RawData
export const GET_RAW_DATA_START = "GET_RAW_DATA_START";
export const GET_RAW_DATA_SUCCESS = "GET_RAW_DATA_SUCCESS";
export const GET_RAW_DATA_FAIL = "GET_RAW_DATA_FAIL";
export const GET_CSV_DOWNLOAD_START = "GET_CSV_DOWNLOAD_START";
export const GET_CSV_DOWNLOAD_SUCCESS = "GET_CSV_DOWNLOAD_SUCCESS";
export const GET_CSV_DOWNLOAD_FAIL = "GET_CSV_DOWNLOAD_FAIL";
export const GET_PDF_DOWNLOAD_START = "GET_PDF_DOWNLOAD_START";
export const GET_PDF_DOWNLOAD_SUCCESS = "GET_PDF_DOWNLOAD_SUCCESS";
export const GET_PDF_DOWNLOAD_FAIL = "GET_PDF_DOWNLOAD_FAIL";

export const GET_RAW_DOWNLOAD_START = "GET_RAW_DOWNLOAD_START";
export const GET_RAW_DOWNLOAD_SUCCESS = "GET_RAW_DOWNLOAD_SUCCESS";
export const GET_RAW_DOWNLOAD_FAIL = "GET_RAW_DOWNLOAD_FAIL";

// LOCATIONS_DEVICES
export const NO_DATE_SELECTED = "NO_DATE_SELECTED";
export const SET_EXCEEDED = "SET_EXCEEDED";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const GET_LOCATIONS_START = "GET_LOCATIONS_START";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAIL = "GET_LOCATIONS_FAIL";
export const ADD_LOCATION_START = "ADD_LOCATION_START";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAIL = "ADD_LOCATION_FAIL";
export const EDIT_LOCATION_START = "EDIT_LOCATION_START";
export const EDIT_LOCATION_SUCCESS = "EDIT_LOCATION_SUCCESS";
export const EDIT_LOCATION_FAIL = "EDIT_LOCATION_FAIL";
export const DELETE_LOCATION_START = "DELETE_LOCATION_START";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAIL = "DELETE_LOCATION_FAIL";
export const DELETE_LOCATION_DEVICE = "DELETE_LOCATION_DEVICE";
export const DELETE_LOCATION_DEVICE_SUCCESS = "DELETE_LOCATION_DEVICE_SUCCESS";
export const DELETE_LOCATION_DEVICE_FAIL = "DELETE_LOCATION_DEVICE_FAIL";

export const GET_DEVICE_LOCATION_START = "GET_DEVICE_LOCATION_START";
export const GET_DEVICE_LOCATION_SUCCESS = "GET_DEVICE_LOCATION_SUCCESS";
export const GET_DEVICE_LOCATION_FAIL = "GET_DEVICE_LOCATION_FAIL";
export const GET_DEVICE_LOCATION_NONE = "GET_DEVICE_LOCATION_NONE";

export const GET_ALL_DEVICES_START = "GET_ALL_DEVICES_START";
export const GET_ALL_DEVICES_SUCCESS = "GET_ALL_DEVICES_SUCCESS";
export const GET_ALL_DEVICES_FAIL = "GET_ALL_DEVICES_FAIL";

export const ADD_DEVICE_LOCATION_START = "ADD_DEVICE_LOCATION_START";
export const ADD_DEVICE_LOCATION_SUCCESS = "ADD_DEVICE_LOCATION_SUCCESS";
export const ADD_DEVICES_LOCATION_FAIL = "ADD_DEVICE_LOCATION_FAIL";
export const EDIT_DEVICE_LOCATION_START = "EDIT_DEVICE_LOCATION_START";
export const EDIT_DEVICE_LOCATION_SUCCESS = "EDIT_DEVICE_LOCATION_SUCCESS";
export const EDIT_DEVICE_LOCATION_FAIL = "EDIT_DEVICE_LOCATION_FAIL";
export const DELETE_DEVICE_LOCATION_START = "DELETE_DEVICE_LOCATION_START";
export const DELETE_DEVICE_LOCATION_SUCCESS = "DELETE_DEVICE_LOCATION_SUCCESS";
export const DELETE_DEVICE_LOCATION_FAIL = "DELETE_DEVICE_LOCATION_FAIL";

export const SELECT_DEVICE = "SELECT_DEVICE";

//DEVICES
export const GET_DEVICE_LIST_START = "GET_DEVICE_LIST_START";
export const GET_DEVICE_LIST_SUCCESS = "GET_DEVICE_LIST_SUCCESS";
export const GET_DEVICE_LIST_FAIL = "GET_DEVICE_LIST_FAIL";
export const DELETE_DEVICE_START = "DELETE_DEVICE_START";
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS";
export const DELETE_DEVICE_FAIL = "DELETE_DEVICE_FAIL";
export const ADD_DEVICE_START = "ADD_DEVICE_START";
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS";
export const ADD_DEVICE_FAIL = "ADD_DEVICE_FAIL";
export const EDIT_DEVICE_START = "EDIT_DEVICE_START";
export const EDIT_DEVICE_SUCCESS = "EDIT_DEVICE_SUCCESS";
export const EDIT_DEVICE_FAIL = "EDIT_DEVICE_FAIL";
export const ASSIGN_USER_START = "ASSIGN_USER_START";
export const ASSIGN_USER_SUCCESS = "ASSIGN_USER_SUCCESS";
export const ASSIGN_USER_FAIL = "ASSIGN_USER_FAIL";
export const UNASSIGN_USER_START = "UNASSIGN_USER_START";
export const UNASSIGN_USER_SUCCESS = "UNASSIGN_USER_SUCCESS";
export const UNASSIGN_USER_FAIL = "UNASSIGN_USER_FAIL";
export const GET_DEVICE_STATUS_CONECTED_START =
  "GET_DEVICE_STATUS_CONECTED_START";
export const GET_DEVICE_STATUS_CONECTED_SUCCESS =
  "GET_DEVICE_STATUS_CONECTED_SUCCESS";
export const GET_DEVICE_STATUS_CONECTED_FAIL =
  "GET_DEVICE_STATUS_CONECTED_FAIL";
export const GET_DEVICES_STATUS_START = "GET_DEVICES_STATUS_START";
export const GET_DEVICES_STATUS_SUCCESS = "GET_DEVICES_STATUS_SUCCESS";
export const GET_DEVICES_STATUS_FAIL = "GET_DEVICES_STATUS_FAIL";

export const DOWNLOAD_DEVICES_START = "DOWNLOAD_DEVICES_START";
export const DOWNLOAD_DEVICES_SUCCESS = "DOWNLOAD_DEVICES_SUCCESS";
export const DOWNLOAD_DEVICES_FAIL = "DOWNLOAD_DEVICES_FAIL";

//GATEWAYS
export const GET_GATEWAY_LIST_START = "GET_GATEWAY_LIST_START";
export const GET_GATEWAY_LIST_SUCCESS = "GET_GATEWAY_LIST_SUCCESS";
export const GET_GATEWAY_LIST_FAIL = "GET_GATEWAY_LIST_FAIL";
export const DELETE_GATEWAY_START = "DELETE_GATEWAY_START";
export const DELETE_GATEWAY_SUCCESS = "DELETE_GATEWAY_SUCCESS";
export const DELETE_GATEWAY_FAIL = "DELETE_GATEWAY_FAIL";
export const ADD_GATEWAY_START = "ADD_GATEWAY_START";
export const ADD_GATEWAY_SUCCESS = "ADD_GATEWAY_SUCCESS";
export const ADD_GATEWAY_FAIL = "ADD_GATEWAY_FAIL";
export const EDIT_GATEWAY_START = "EDIT_GATEWAY_START";
export const EDIT_GATEWAY_SUCCESS = "EDIT_GATEWAY_SUCCESS";
export const EDIT_GATEWAY_FAIL = "EDIT_GATEWAY_FAIL";
export const UPLOAD_GATEWAY_START = "UPLOAD_GATEWAY_START";
export const UPLOAD_GATEWAY_SUCCESS = "UPLOAD_GATEWAY_SUCCESS";
export const UPLOAD_GATEWAY_FAIL = "UPLOAD_GATEWAY_FAIL";
export const UPDATE_GATEWAY_START = "UPDATE_GATEWAY_START";
export const UPDATE_GATEWAY_SUCCESS = "UPDATE_GATEWAY_SUCCESS";
export const UPDATE_GATEWAY_FAIL = "UPDATE_GATEWAY_FAIL";
export const DOWNLOAD_GATEWAY_START = "DOWNLOAD_GATEWAY_START";
export const DOWNLOAD_GATEWAY_SUCCESS = "DOWNLOAD_GATEWAY_SUCCESS";
export const DOWNLOAD_GATEWAY_FAIL = "DOWNLOAD_GATEWAY_FAIL";

//USERS
export const GET_USER_IDS_START = "GET_USER_IDS_START";
export const GET_USER_IDS_SUCCESS = "GET_USER_IDS_SUCCESS";
export const GET_USER_IDS_FAIL = "GET_USER_IDS_FAIL";
export const GET_USER_LIST_START = "GET_USER_LIST_START";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL";
export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const ADD_USER_START = "ADD_USER_START";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
export const EDIT_USER_START = "EDIT_USER_START";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";

//SUBUSERS
export const GET_SUB_USER_LIST_START = "GET_SUB_USER_LIST_START";
export const GET_SUB_USER_LIST_SUCCESS = "GET_SUB_USER_LIST_SUCCESS";
export const GET_SUB_USER_LIST_FAIL = "GET_SUB_USER_LIST_FAIL";

//REPORTS
export const GET_REPORT_LIST_START = "GET_REPORT_LIST_START";
export const GET_REPORT_LIST_SUCCESS = "GET_REPORT_LIST_SUCCESS";
export const GET_REPORT_LIST_FAIL = "GET_REPORT_LIST_FAIL";
export const DELETE_REPORT_START = "DELETE_REPORT_START";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAIL = "DELETE_REPORT_FAIL";
export const ADD_REPORT_START = "ADD_REPORT_START";
export const ADD_REPORT_SUCCESS = "ADD_REPORT_SUCCESS";
export const ADD_REPORT_FAIL = "ADD_REPORT_FAIL";
export const EDIT_REPORT_START = "EDIT_REPORT_START";
export const EDIT_REPORT_SUCCESS = "EDIT_REPORT_SUCCESS";
export const EDIT_REPORT_FAIL = "EDIT_REPORT_FAIL";

export const GET_OPTIONAL_REPORT_LIST_START = "GET_OPTIONAL_REPORT_LIST_START";
export const GET_OPTIONAL_REPORT_LIST_SUCCESS =
  "GET_OPTIONAL_REPORT_LIST_SUCCESS";
export const GET_OPTIONAL_REPORT_LIST_FAIL = "GET_OPTIONAL_REPORT_LIST_FAIL";
export const DELETE_OPTIONAL_REPORT_START = "DELETE_OPTIONAL_REPORT_START";
export const DELETE_OPTIONAL_REPORT_SUCCESS = "DELETE_OPTIONAL_REPORT_SUCCESS";
export const DELETE_OPTIONAL_REPORT_FAIL = "DELETE_OPTIONAL_REPORT_FAIL";
export const ADD_OPTIONAL_REPORT_START = "ADD_OPTIONAL_REPORT_START";
export const ADD_OPTIONAL_REPORT_SUCCESS = "ADD_OPTIONAL_REPORT_SUCCESS";
export const ADD_OPTIONAL_REPORT_FAIL = "ADD_OPTIONAL_REPORT_FAIL";
export const EDIT_OPTIONAL_REPORT_START = "EDIT_OPTIONAL_REPORT_START";
export const EDIT_OPTIONAL_REPORT_SUCCESS = "EDIT_OPTIONAL_REPORT_SUCCESS";
export const EDIT_OPTIONAL_REPORT_FAIL = "EDIT_OPTIONAL_REPORT_FAIL";

//GATEWAYS
export const GET_RECENT_DOORS_LIST_START = "GET_RECENT_DOORS_LIST_START";
export const GET_RECENT_DOORS_LIST_SUCCESS = "GET_RECENT_DOORS_LIST_SUCCESS";
export const GET_RECENT_DOORS_LIST_FAIL = "GET_RECENT_DOORS_LIST_FAIL";
export const GET_RECENT_ALARM_LIST_START = "GET_RECENT_ALARM_LIST_START";
export const GET_RECENT_ALARM_LIST_SUCCESS = "GET_RECENT_ALARM_LIST_SUCCESS";
export const GET_RECENT_ALARM_LIST_FAIL = "GET_RECENT_ALARM_LIST_FAIL";
export const GET_DEVICE_ALARM_LIST_START = "GET_DEVICE_ALARM_LIST_START";
export const GET_DEVICE_ALARM_LIST_SUCCESS = "GET_DEVICE_ALARM_LIST_SUCCESS";
export const GET_DEVICE_ALARM_LIST_FAIL = "GET_DEVICE_ALARM_LIST_FAIL";
export const DELETE_DEVICE_ALARM_START = "DELETE_DEVICE_ALARM_START";
export const DELETE_DEVICE_ALARM_SUCCESS = "DELETE_DEVICE_ALARM_SUCCESS";
export const DELETE_DEVICE_ALARM_FAIL = "DELETE_DEVICE_ALARM_FAIL";
export const ADD_DEVICE_ALARM_START = "ADD_DEVICE_ALARM_START";
export const ADD_DEVICE_ALARM_SUCCESS = "ADD_DEVICE_ALARM_SUCCESS";
export const ADD_DEVICE_ALARM_FAIL = "ADD_DEVICE_ALARM_FAIL";
export const EDIT_DEVICE_ALARM_START = "EDIT_DEVICE_ALARM_START";
export const EDIT_DEVICE_ALARM_SUCCESS = "EDIT_DEVICE_ALARM_SUCCESS";
export const EDIT_DEVICE_ALARM_FAIL = "EDIT_DEVICE_ALARM_FAIL";
export const ADD_COMMENT_START = "ADD_COMMENT_START";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAIL = "ADD_COMMENT_FAIL";
export const ALARM_PDF_DOWNLOAD_START = "ALARM_PDF_DOWNLOAD_START";
export const ALARM_PDF_DOWNLOAD_SUCCESS = "ALARM_PDF_DOWNLOAD_SUCCESS";
export const ALARM_PDF_DOWNLOAD_FAIL = "ALARM_PDF_DOWNLOAD_FAIL";
export const ALARM_CSV_DOWNLOAD_START = "ALARM_CSV_DOWNLOAD_START";
export const ALARM_CSV_DOWNLOAD_SUCCESS = "ALARM_CSV_DOWNLOAD_SUCCESS";
export const ALARM_CSV_DOWNLOAD_FAIL = "ALARM_CSV_DOWNLOAD_FAIL";
export const GET_FAVOURITES_START = "GET_FAVOURITES_START";
export const GET_FAVOURITES_SUCCESS = "GET_FAVOURITES_SUCCESS";
export const GET_FAVOURITES_FAIL = "GET_FAVOURITES_FAIL";
export const ADD_FAVOURITES_START = "ADD_FAVOURITES_START";
export const ADD_FAVOURITES_SUCCESS = "ADD_FAVOURITES_SUCCESS";
export const ADD_FAVOURITES_FAIL = "ADD_FAVOURITES_FAIL";
export const DELETE_FAVOURITES_START = "DELETE_FAVOURITES_START";
export const DELETE_FAVOURITES_SUCCESS = "DELETE_FAVOURITES_SUCCESS";
export const DELETE_FAVOURITES_FAIL = "DELETE_FAVOURITES_FAIL";

//NODE DEVICES
export const GET_NODE_DEVICES_LIST_START = "GET_NODE_DEVICES_LIST_START";
export const GET_NODE_DEVICES_LIST_SUCCESS = "GET_NODE_DEVICES_LIST_SUCCESS";
export const GET_NODE_DEVICES_LIST_FAIL = "GET_NODE_DEVICES_LIST_FAIL";

//USER NOTIFICATIONS
export const GET_USER_NOTIFICATIONS_LIST_START =
  "GET_USER_NOTIFICATIONS_LIST_START";
export const GET_USER_NOTIFICATIONS_LIST_SUCCESS =
  "GET_USER_NOTIFICATIONS_LIST_SUCCESS";
export const GET_USER_NOTIFICATIONS_LIST_FAIL =
  "GET_USER_NOTIFICATIONS_LIST_FAIL";

//USER NOTIFICATIONS
export const GET_ACCOUNT_LIST_START = "GET_ACCOUNT_LIST_START";
export const GET_ACCOUNT_LIST_SUCCESS = "GET_ACCOUNT_LIST_SUCCESS";
export const GET_ACCOUNT_LIST_FAIL = "GET_ACCOUNT_LIST_FAIL";
export const EDIT_VOICE_CALL_START = "EDIT_VOICE_CALL_START";
export const EDIT_VOICE_CALL_SUCCESS = "EDIT_VOICE_CALL_SUCCESS";
export const EDIT_VOICE_CALL_FAIL = "EDIT_VOICE_CALL_FAIL";

export const DELETE_ACCOUNT_LIST_START = "DELETE_ACCOUNT_LIST_START";
export const DELETE_ACCOUNT_LIST_SUCCESS = "DELETE_ACCOUNT_LIST_SUCCESS";
export const DELETE_ACCOUNT_LIST_FAIL = "DELETE_ACCOUNT_LIST_FAIL";

export const GENERATE_ACCOUNT_API_START = "GENERATE_ACCOUNT_API_START";
export const GENERATE_ACCOUNT_API_SUCCESS = "GENERATE_ACCOUNT_API_SUCCESS";
export const GENERATE_ACCOUNT_API_FAIL = "GENERATE_ACCOUNT_API_FAIL";

export const CREATE_ACCOUNT_API_START = "CREATE_ACCOUNT_API_START";
export const CREATE_ACCOUNT_API_SUCCESS = "CREATE_ACCOUNT_API_SUCCESS";
export const CREATE_ACCOUNT_API_FAIL = "CREATE_ACCOUNT_API_FAIL";

export const GET_USER_NOTIFICATIONS_STATISTICS_START =
  "GET_USER_NOTIFICATIONS_STATISTICS_START";
export const GET_USER_NOTIFICATIONS_STATISTICS_SUCCESS =
  "GET_USER_NOTIFICATIONS_STATISTICS_SUCCESS";
export const GET_USER_NOTIFICATIONS_STATISTICS_FAIL =
  "GET_USER_NOTIFICATIONS_STATISTICS_FAIL";
export const ADD_SMS_LIMIT_START = "ADD_SMS_LIMIT_START";
export const ADD_SMS_LIMIT_SUCCESS = "ADD_SMS_LIMIT_SUCCESS";
export const ADD_SMS_LIMIT_FAIL = "ADD_SMS_LIMIT_FAIL";

//USER MESSAGE TEMPLATES
export const GET_USER_MESSAGE_TEMPLATE_START =
  "GET_USER_MESSAGE_TEMPLATE_START";
export const GET_USER_MESSAGE_TEMPLATE_SUCCESS =
  "GET_USER_MESSAGE_TEMPLATE_SUCCESS";
export const GET_USER_MESSAGE_TEMPLATE_FAIL = "GET_USER_MESSAGE_TEMPLATE_FAIL";
export const ADD_MESSAGE_TEMPLATE_START = "ADD_MESSAGE_TEMPLATE_START";
export const ADD_MESSAGE_TEMPLATE_SUCCESS = "ADD_MESSAGE_TEMPLATE_SUCCESS";
export const ADD_MESSAGE_TEMPLATE_FAIL = "ADD_MESSAGE_TEMPLATE_FAIL";
export const EDIT_MESSAGE_TEMPLATE_START = "EDIT_MESSAGE_TEMPLATE_START";
export const EDIT_MESSAGE_TEMPLATE_SUCCESS = "EDIT_MESSAGE_TEMPLATE_SUCCESS";
export const EDIT_MESSAGE_TEMPLATE_FAIL = "EDIT_MESSAGE_TEMPLATE_FAIL";
export const DELETE_MESSAGE_TEMPLATE_START = "DELETE_MESSAGE_TEMPLATE_START";
export const DELETE_MESSAGE_TEMPLATE_SUCCESS =
  "DELETE_MESSAGE_TEMPLATE_SUCCESS";
export const DELETE_MESSAGE_TEMPLATE_FAIL = "DELETE_MESSAGE_TEMPLATE_FAIL";

export const GET_USER_MESSAGE_TEMPLATE_REL_START =
  "GET_USER_MESSAGE_TEMPLATE_REL_START";
export const GET_USER_MESSAGE_TEMPLATE_REL_SUCCESS =
  "GET_USER_MESSAGE_TEMPLATE_REL_SUCCESS";
export const GET_USER_MESSAGE_TEMPLATE_REL_FAIL =
  "GET_USER_MESSAGE_TEMPLATE_REL_FAIL";
export const SET_USER_MESSAGE_TEMPLATE_REL_START =
  "SET_USER_MESSAGE_TEMPLATE_REL_START";
export const SET_USER_MESSAGE_TEMPLATE_REL_SUCCESS =
  "SET_USER_MESSAGE_TEMPLATE_REL_SUCCESS";
export const SET_USER_MESSAGE_TEMPLATE_REL_FAIL =
  "SET_USER_MESSAGE_TEMPLATE_REL_FAIL";

//GATEWAY LOGS
export const GET_GATEWAY_LOGS_LIST_START = "GET_GATEWAY_LOGS_LIST_START";
export const GET_GATEWAY_LOGS_LIST_SUCCESS = "GET_GATEWAY_LOGS_LIST_SUCCESS";
export const GET_GATEWAY_LOGS_LIST_FAIL = "GET_GATEWAY_LOGS_LIST_FAIL";

//Alarms List
export const GET_ALARMS_LIST_START = "GET_ALARMS_LIST_START";
export const GET_ALARMS_LIST_SUCCESS = "GET_ALARMS_LIST_SUCCESS";
export const GET_ALARMS_LIST_FAIL = "GET_ALARMS_LIST_FAIL";

//Alert Profile
export const SAVE_ALERT_PROFILE_START = "SAVE_ALERT_PROFILE_START";
export const SAVE_ALERT_PROFILE_SUCCESS = "SAVE_ALERT_PROFILE_SUCCESS";
export const SAVE_ALERT_PROFILE_FAIL = "SAVE_ALERT_PROFILE_FAIL";

export const GET_ALERT_PROFILE_START = "GET_ALERT_PROFILE_START";
export const GET_ALERT_PROFILE_SUCCESS = "GET_ALERT_PROFILE_SUCCESS";
export const GET_ALERT_PROFILE_FAIL = "GET_ALERT_PROFILE_FAIL";

export const DELETE_ALERT_PROFILE_START = "DELETE_ALERT_PROFILE_START";
export const DELETE_ALERT_PROFILE_SUCCESS = "DELETE_ALERT_PROFILE_SUCCESS";
export const DELETE_ALERT_PROFILE_FAIL = "DELETE_ALERT_PROFILE_FAIL";

export const EDIT_ALERT_PROFILE_START = "EDIT_ALERT_PROFILE_START";
export const EDIT_ALERT_PROFILE_SUCCESS = "EDIT_ALERT_PROFILE_SUCCESS";
export const EDIT_ALERT_PROFILE_FAIL = "EDIT_ALERT_PROFILE_FAIL";

export const RESET_ALERT_PROFILE = "RESET_ALERT_PROFILE";

//Offline Alarms
export const UPDATE_OFFLINE_ALARMS_START = "UPDATE_OFFLINE_ALARMS_START";
export const UPDATE_OFFLINE_ALARMS_SUCCESS = "UPDATE_OFFLINE_ALARMS_SUCCESS";
export const UPDATE_OFFLINE_ALARMS_FAIL = "UPDATE_OFFLINE_ALARMS_FAIL";

export const GET_OFFLINE_ALARMS_START = "GET_OFFLINE_ALARMS_START";
export const GET_OFFLINE_ALARMS_SUCCESS = "GET_OFFLINE_ALARMS_SUCCESS";
export const GET_OFFLINE_ALARMS_FAIL = "GET_OFFLINE_ALARMS_FAIL";

export const RESET_ALL_ALARMS_INFO = "RESET_ALL_ALARMS_INFO";

//Logs
export const GET_AUDIT_TRAIL_START = "GET_AUDIT_TRAIL_START";
export const GET_AUDIT_TRAIL_FAIL = "GET_AUDIT_TRAIL_FAIL";
export const GET_AUDIT_TRAIL_SUCCESS = "GET_AUDIT_TRAIL_SUCCESS";

export const PDF_EXPORT_TRAIL_START = "PDF_EXPORT_TRAIL_START";
export const PDF_EXPORT_TRAIL_FAIL = "PDF_EXPORT_TRAIL_FAIL";
export const PDF_EXPORT_TRAIL_SUCCESS = "PDF_EXPORT_TRAIL_SUCCESS";
