import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../stores/actions/index';
import { showToast } from '../../helpers/toast.js';
import { withNamespaces, Trans } from 'react-i18next';
import ContentWrapper from '../Layout/ContentWrapper';
import { Container, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import CardTool from '../Common/CardTool';
import AlarmsTable from './AlarmsTable';
// import DeviceTable from './DeviceTable';
class Alarms extends Component {
  componentDidMount() {
    const { getAlarmsList } = this.props;
    getAlarmsList(1, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, 'success');
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, 'error');
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: '', modalData: {} });
      this.props.getAlarmsList(1, 10);
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName,
      modalData,
    });
  };


  updateFirmware = (id) => {
    this.showModal('updatefirmware', { id: id, Node_Devices: "Node_Devices" }, );
  };
  uploadFirmware = () => {
    this.showModal('uploadFirmware', "Node_Devices");
  };
  changePage = (page) => {
    this.props.getAlarmsList(page);
  };

  
  render() {
    const {alarmsList, meta, loading } = this.props;
    const role = localStorage.getItem('role');
    const filteredAlarmsList = alarmsList && alarmsList.map(val => {
        return {
            ...val,
            deviceName: val.deviceInfo.name,
            name: val.alarmInfo.name
        }
    })
    
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Alarms
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          {/* <div className="d-flex p-3 justify-content-end"> */}
            {/* {role === 'super_admin' ? ( */}
              {/* <button
                className="mr-2 float-right btn-labeled btn btn-info"
                onClick={this.uploadFirmware.bind(this)}
              >
                <span className="btn-label">
                  <i className="fa fa-upload" aria-hidden="true"></i>
                </span>
                Upload FW
              </button> */}
            {/* // ) : (
            //   <button 
            //     className="mr-2 float-right btn-labeled btn btn-info"
            //     onClick={this.addGateway.bind(this)}
            //   >
            //     <span className="btn-label">
            //       <i className="fa fa-plus" aria-hidden="true"></i>
            //     </span>
            //     Add
            //   </button>
            // )}*/}
          {/* </div> */}
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>Alarms List</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <CardBody>
              {filteredAlarmsList && filteredAlarmsList.length > 0 && (
                <AlarmsTable
                  pagination={meta.pagination}
                  devices={filteredAlarmsList}
                //   editGateway={this.editGateway}
                //   deleteGateway={this.deleteGateway}
                  changePage={(page) => this.changePage(page)}
                //   updateFirmware={this.updateFirmware}
                ></AlarmsTable>
              )}
             {!!loading &&  <h3 className="text-center">Loading...</h3> }
            </CardBody> 
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.alarms.loading,
    alarmsList: state.alarms.alarmsList,
    meta: state.alarms.meta,
    error: state.alarms.error,
    success: state.alarms.success,
    reload: state.alarms.reload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAlarmsList: (data) => dispatch(actions.getAlarmsList(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces('translations')(Alarms));
