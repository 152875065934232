import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import { withNamespaces, Trans } from "react-i18next";
import ContentWrapper from "../Layout/ContentWrapper";
import { Container, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import CardTool from "../Common/CardTool";
import ReportTable from "./ReportTable";
import { ConfirmationModal } from "../Common/ConfirmationModal";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";
import OptionalReportTable from "./OptionReportTable";

export const REPORT_OPTIONAL = "optional";
export const REPORT_NORMAL = "normal";
export const REPORT_BY_KEY = "reportby";

const reportByArray = [
  {
    id: 123,
    name: "Environment Report",
    value: 0,
  },
  {
    id: 124,
    name: "Other Report",
    value: 1,
  },
];

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      reportBy: 0,
      reportById: {
        id: 123,
        name: "Environment Report",
        value: 0,
      },
    };
  }

  componentDidMount() {
    const {
      getReportList,
      getDeviceList,
      getUserList,
      getOptionalReportList,
    } = this.props;
    if (Number(localStorage.getItem(REPORT_BY_KEY))) {
      getOptionalReportList(1);
      this.setState({
        reportById: {
          id: 124,
          name: "Other Report",
          value: 1,
        },
      });
    } else {
      getReportList(1);
      this.setState({
        reportById: {
          id: 123,
          name: "Environment Report",
          value: 0,
        },
      });
    }
    getDeviceList(0);
    getUserList(0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.checkReportBy(1);
    }
    if (prevState.reportBy !== this.state.reportBy) {
      this.checkReportBy(1);
    }
  }
  checkReportBy = (page) => {
    if (Number(localStorage.getItem(REPORT_BY_KEY))) {
      this.props.getOptionalReportList(page);
    } else {
      this.props.getReportList(page);
    }
  };
  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName: modalName,
      modalData: modalData,
      size: "lg",
    });
  };

  editEnvironmentReport = (report) => {
    this.showModal("addEditEnvironmentReport", { report: report });
  };
  editOptionalReport = (report) => {
    this.showModal("addEditOptionalReport", { report: report });
  };

  addOptionalReport = () => {
    this.showModal("addEditOptionalReport");
  };
  addEnvironmentReport = () => {
    this.showModal("addEditEnvironmentReport");
  };

  deleteReport = (id) => {
    this.setState({
      showAlert: true,
      deleteId: id,
    });
  };

  changePage = (page) => {
    this.props.getReportList(page);
  };
  changeOptionalPage = (page) => {
    this.props.getOptionalReportList(page);
  };
  handleChangeReportBy = (value) => {
    localStorage.setItem(REPORT_BY_KEY, value.value);
    this.setState({ reportById: value, reportBy: value.value });
  };

  modalAction = (action) => {
    const { deleteReport } = this.props;
    const { deleteId } = this.state;
    if (action === "confirm") {
      deleteReport({ id: deleteId });
    }

    this.setState({
      showAlert: false,
    });
  };

  render() {
    const {
      reportList,
      meta,
      loading,
      optionalReportList,
      optionalReportMeta,
    } = this.props;

    const { showAlert } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Reports
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          <div className="d-flex p-3 justify-content-end">
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.addOptionalReport.bind(this)}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Optional Report
            </button>
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.addEnvironmentReport.bind(this)}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Environment Report
            </button>
          </div>
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>Reports</CardTitle>
              <div className="d-flex align-items-center">
                <div className="form-group d-flex justify-content-end align-items-center mr-4 mb-0">
                  <label className=" mr-3">Report Type</label>
                  <div style={{ width: "176px", marginRight: "0.5rem" }}>
                    <Select
                      styles={selectStyle}
                      value={this.state.reportById}
                      onChange={this.handleChangeReportBy}
                      options={reportByArray}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option}`}
                    />
                  </div>
                </div>
                <CardTool
                  refresh
                  onRefresh={(_, done) => setTimeout(done, 2000)}
                />
              </div>
            </CardHeader>
            <div
              style={{
                height: "1px",
                width: "100%",
                background: "#ccc",
              }}
            />
            <CardBody>
              {this.state.reportById.value && optionalReportList.length ? (
                <OptionalReportTable
                  pagination={optionalReportMeta.pagination}
                  reports={optionalReportList}
                  editReport={this.editOptionalReport}
                  deleteReport={this.deleteReport}
                  changePage={(page) => this.changeOptionalPage(page)}
                />
              ) : null}
              {!this.state.reportById.value && reportList.length ? (
                <ReportTable
                  pagination={meta.pagination}
                  reports={reportList}
                  editReport={this.editEnvironmentReport}
                  deleteReport={this.deleteReport}
                  changePage={(page) => this.changePage(page)}
                />
              ) : null}
              {loading ? <h3 className="text-center">Loading...</h3> : ""}
            </CardBody>
          </Card>
          <ConfirmationModal
            isOpen={showAlert}
            deleteName="Report"
            modalAction={this.modalAction}
          />
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.reports.loading,
    userList: state.users.userList,
    deviceList: state.devices.deviceList,
    reportList: state.reports.reportList,
    meta: state.reports.meta,
    error: state.reports.error,
    success: state.reports.success,
    reload: state.reports.reload,
    optionalReportList: state.reports.optionalReportList,
    optionalReportMeta: state.reports.optionalReportMeta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeviceList: (data) => dispatch(actions.getDeviceList(data)),
    getUserList: (data) => dispatch(actions.getUserList(data)),
    getReportList: (data) => dispatch(actions.getReportList(data)),
    getOptionalReportList: (data) =>
      dispatch(actions.getOptionalReportList(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
    deleteReport: (data) => dispatch(actions.deleteReport(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(Reports));
