import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";

class DeleteUser extends Component {
  submitModal = () => {
    const { deleteUser, modalData, deleteProfile } = this.props;
    const { id, component } = modalData;
    component === "alertProfile" ? deleteProfile(id) : deleteUser({ id: id });
  };

  render() {
    const { toggleModal, modalData } = this.props;
    const { component } = modalData;

    return (
      <React.Fragment>
        <ModalHeader toggle={toggleModal}>{`Delete ${
          component === "alertProfile" ? "Profile" : "User"
        }`}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            <i
              className="fas fa-question-circle text-warning display-4"
              style={{ fontWeight: 900 }}
            ></i>
            <p className="text-warning">Warning!</p>
            <p className="px-3 text-center">
              Are you sure you want to permanently remove this{" "}
              {component === "alertProfile" ? "Profile" : "User"}? <br /> Please
              note there are currently active{" "}
              {component === "alertProfile" ? "Profile" : "alarms"}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={this.submitModal}>
            Confirm
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUser: (data) => dispatch(actions.deleteUser(data)),
    deleteProfile: (data) => dispatch(actions.deleteProfile(data)),
  };
};

export default connect(null, mapDispatchToProps)(DeleteUser);
