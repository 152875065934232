import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { showToast } from "../../helpers/toast";
import history from "../../helpers/history";
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userId: userId,
  };
};
export const signUpSuccess = () => {
  return {
    type: actionTypes.SINGUP_SUCCESS,
  };
};
export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};
export const toggleTempStart = () => {
  return {
    type: actionTypes.TOGGLE_TEMP_START,
  };
};

export const toggleTempSuccess = (data) => {
  return {
    type: actionTypes.TOGGLE_TEMP_SUCCESS,
    data: data,
  };
};

export const toggleTempFail = (error) => {
  return {
    type: actionTypes.TOGGLE_TEMP_FAIL,
    error: error,
  };
};

export const getTempStatusStart = () => {
  return {
    type: actionTypes.GET_TEMP_STATUS_START,
  };
};

export const getTempStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_TEMP_STATUS_SUCCESS,
    data: data,
  };
};

export const getTempStatusFail = (error) => {
  return {
    type: actionTypes.GET_TEMP_STATUS_FAIL,
    error: error,
  };
};

export const autoLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const login = ({ email, password }) => {
  return (dispatch) => {
    dispatch(authStart());

    const authData = {
      email: email,
      password: password,
      panelId: 1,
    };

    const url = `${apiPath.baseUrl}${apiPath.auth.login}`;
    axios
      .post(url, authData)
      .then((response) => {
        localStorage.setItem("token", response.data.jwt);
        localStorage.setItem("userId", response.data.sessionId);
        dispatch(authSuccess(response.data.jwt, response.data.email));
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("email", response.data.email);
        if (response.data.role && response.data.role === "super_admin") {
          history.push("/users");
        } else {
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        dispatch(authFail(error.response.data.errors));
      });
  };
};

export const signUp = ({
  name,
  email,
  password,
  role,
  countryCode,
  phoneNumber,
  companyName,
}) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      name,
      email,
      password,
      role,
      countryCode,
      phoneNumber,
      companyName,
    };
    // const companyData = { companyName, companyEmail: email };
    const url = `${apiPath.baseUrl}${apiPath.auth.signUp}`;
    // const createCompanyUrl = `${apiPath.baseUrl}${apiPath.auth.createCompany}`;
    axios
      .post(url, authData)
      .then((response) => {
        dispatch(signUpSuccess());
        setTimeout(() => history.push("/login"), 1000);
        // localStorage.setItem('token', response.data.data.jwt);
        // localStorage.setItem('userId', response.data.data.sessionId);
        // axios
        //   .post(createCompanyUrl, companyData)
        //   .then((response1) => {
        //     dispatch(signUpSuccess());
        //     setTimeout(() => history.push("/login"), 1000);
        //   })
        //   .catch((err) => {
        //     dispatch(authFail(err.response.data.errors));
        //   });
      })
      .catch((err) => {
        dispatch(authFail(err.response.data.errors));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(authStart());
    const url = `${apiPath.baseUrl}${apiPath.auth.logout}`;
    const headers = getCommonHeaders();
    axios
      .delete(url, { headers })
      .then((response) => {
        dispatch(autoLogout());
        history.push("/login");
      })
      .catch((error) => {
        dispatch(autoLogout());
        history.push("/login");
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token || token === null || token === "undefined") {
      dispatch(autoLogout());
    } else {
      const userId = localStorage.getItem("userId");
      dispatch(authSuccess(token, userId));
    }
  };
};

export const unauthorized = () => {
  showToast("You are not authorized, please login again", "error");
  return (dispatch) => {
    dispatch(autoLogout());
  };
};

export const toggleTemp = (tempData) => {
  return (dispatch) => {
    dispatch(toggleTempStart());
    const url = `${apiPath.baseUrl}${apiPath.account.toggleTemp}`;
    const headers = getCommonHeaders();
    const data = {
      temperatureUnit: tempData,
    };
    axios
      .put(url, data, { headers })
      .then((response) => {
        dispatch(toggleTempSuccess(response.data.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(toggleTempFail(error.response.data.errors));
        }
      });
  };
};

export const getTempStatus = () => {
  return (dispatch) => {
    dispatch(getTempStatusStart());
    const url = `${apiPath.baseUrl}${apiPath.account.tempStatus}`;
    const headers = getCommonHeaders();
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getTempStatusSuccess(response.data.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getTempStatusFail(error.response.data.errors));
        }
      });
  };
};
