import React, { Component } from "react";
import { ModalHeader, ModalFooter, Button, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import AlertModal from "../../Common/AlertModal";
import Select, { components } from "react-select";
import { selectStyle } from "../../../config/selectStyle";
import { showToast } from "../../../helpers/toast";

class RecentAlarmComments extends Component {
  state = {
    comments: "",
    showAlert: false,
    favourites: false,
    selectedFavourites: {
      id: "0",
      name: "Select A Favourite",
    },
  };

  onSubmit = (e) => {
    this.setState({ showAlert: false });
    const { modalData } = this.props;
    const alarmId = modalData && modalData.comments && modalData.comments.id;
    this.state.comments && this.props.addComment(this.state.comments, alarmId);
  };

  componentWillUnmount() {
    this.props.modalData.getRecentAlarmList();
  }

  componentDidMount() {
    this.props.getFavourites();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.favMessage !== this.props.favMessage &&
      this.props.favMessage
    ) {
      showToast(this.props.favMessage, "success");
      this.props.getFavourites();
    }
    if (
      prevProps.deletedMessage !== this.props.deletedMessage &&
      this.props.deletedMessage
    ) {
      showToast(this.props.deletedMessage, "success");
      this.props.getFavourites();
    }
  }

  handleChange = (selectedFavourites, value) => {
    if (value === "ADDING") {
      this.setState({
        selectedFavourites: selectedFavourites,
        comments: selectedFavourites.name,
      });
    } else {
      this.props.deleteFavourite(selectedFavourites.id);
    }
  };

  CustomMenu = (props) => {
    const { data } = props;
    const isLightTheme =
      document.documentElement.getAttribute("data-theme") === "light";

    return (
      <div>
        <components.Option {...props}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ display: "none" }}></span>
            <span
              onClick={() => {
                this.handleChange(data, "ADDING");
              }}
            >
              {props.children}
            </span>
            <span>
              {" "}
              <i
                className="fa fa-times"
                aria-hidden="true"
                style={{
                  color: isLightTheme ? "#9ca3af" : "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.handleChange(data, "DELETE");
                }}
              ></i>
            </span>
          </div>
        </components.Option>
      </div>
    );
  };

  render() {
    const { toggleModal, favouritesList } = this.props;
    const formattedFavList =
      favouritesList && favouritesList.length
        ? favouritesList.map((val) => {
            return { id: val.id, name: val.message };
          })
        : [];

    const isLightTheme =
      document.documentElement.getAttribute("data-theme") === "light";
    const isFavourited = this.state.favourites;

    const borderColor = isLightTheme ? "#9ca3af" : "#fff";
    const backgroundColor = isFavourited
      ? isLightTheme
        ? "#d1d5db"
        : "#fff"
      : "transparent";

    const iconColor = isFavourited ? "#000" : isLightTheme ? "#9ca3af" : "#fff";

    return (
      <React.Fragment>
        <ModalHeader toggle={toggleModal}>Add Your Comments</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "inline-block",
              marginBottom: "20px",
              width: "50%",
            }}
          >
            <h6>Select Favourites: </h6>
            <Select
              styles={selectStyle}
              name="locationId"
              value={this.state.selectedFavourites}
              options={formattedFavList}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) => {
                return this.state.selectedFavourites.id === option.id
                  ? true
                  : false;
              }}
              components={{ Option: this.CustomMenu }}
            />
          </div>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            style={{
              background: "transparent",
              color:
                document.documentElement.getAttribute("data-theme") === "light"
                  ? "#000"
                  : "#fff",
            }}
            value={this.state.comments}
            onChange={(e) => this.setState({ comments: e.target.value })}
          ></textarea>
          <AlertModal
            isOpen={this.state.showAlert}
            modalAction={this.onSubmit}
            shouldCancel={true}
            cancelModalAction={() => this.setState({ showAlert: false })}
            heading="Are you Sure?"
            message={
              "Adding a comment will remove the alert from the recent alarm list and cannot be edited afterward."
            }
          />
          <div
            style={{
              position: "absolute",
              right: "15px",
              top: "62%",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!this.state.favourites) {
                this.setState({
                  favourites: true,
                });
                this.props.addFavourites(this.state.comments);
              }
            }}
          >
            <span
              className="btn-label"
              style={{
                border: `1px solid ${borderColor}`,
                borderRadius: "5px",
                background: backgroundColor,
              }}
            >
              <i
                className="fa fa-star fa-1x"
                aria-hidden="true"
                style={{ color: iconColor }}
              ></i>
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            type="submit"
            onClick={() =>
              this.state.comments &&
              this.setState({
                showAlert: true,
              })
            }
          >
            Add
          </Button>
          <Button color="secondary" type="button" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    favouritesList: state.deviceAlarms.favouritesList,
    favMessage: state.deviceAlarms.favMessage,
    deletedMessage: state.deviceAlarms.deletedMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addComment: (comment, alarmId) =>
      dispatch(actions.addComment(comment, alarmId)),
    addFavourites: (comment) => dispatch(actions.addFavourites(comment)),
    // editFavourite: (comment) => dispatch(actions.editFavourite(comment)),
    getFavourites: () => dispatch(actions.getFavourites()),
    deleteFavourite: (comment) => dispatch(actions.deleteFavourite(comment)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentAlarmComments);
