import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";

class DeleteMessageTemplate extends Component {
  submitModal = () => {
    const { deleteMessageTemplate, modalData, toggleModal } = this.props;
    const { id } = modalData;
    deleteMessageTemplate(id);
    toggleModal();
  };

  render() {
    const { toggleModal } = this.props;

    return (
      <React.Fragment>
        <ModalHeader toggle={toggleModal}>{`Delete Template`}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            <i
              className="fas fa-question-circle text-warning display-4"
              style={{ fontWeight: 900 }}
            ></i>
            <p className="text-warning">Warning!</p>
            <p className="px-3 text-center">
              Are you sure you want to permanently remove this template ?
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={this.submitModal}>
            Confirm
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteMessageTemplate: (data) => dispatch(actions.deleteMessageTemplate(data))
  };
};

export default connect(null, mapDispatchToProps)(DeleteMessageTemplate);
