import React from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import Content from "./Content";
import "./index.styles.scss";

class LocationDevices extends React.Component {
  render() {
    return (
      <Card
        style={{
          height: "270px",
        }}
        className="card-default location-devices  mb-md-0 mb-2"
      >
        <CardHeader className="d-flex align-items-center justify-content-between card-header-border">
          <CardTitle>Locations &amp; Devices</CardTitle>
        </CardHeader>
        <CardBody
          style={{
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Content {...this.props} />
        </CardBody>
      </Card>
    );
  }
}

export default LocationDevices;
