import * as actionTypes from "./actionTypes";
import axios from "axios";
import { saveAs } from "file-saver";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";

export const getRecentAlarmListStart = () => {
  return {
    type: actionTypes.GET_RECENT_ALARM_LIST_START,
  };
};

export const getRecentAlarmListSuccess = (recentAlarms) => {
  return {
    type: actionTypes.GET_RECENT_ALARM_LIST_SUCCESS,
    recentAlarms,
  };
};

export const getRecentAlarmListFail = (error) => {
  return {
    type: actionTypes.GET_RECENT_ALARM_LIST_FAIL,
    error: error,
  };
};
export const getRecentDoorsListStart = () => {
  return {
    type: actionTypes.GET_RECENT_DOORS_LIST_START,
  };
};

export const getRecentDoorsListSuccess = (recentDoors) => {
  return {
    type: actionTypes.GET_RECENT_DOORS_LIST_SUCCESS,
    recentDoors,
  };
};

export const getRecentDoorsListFail = (error) => {
  return {
    type: actionTypes.GET_RECENT_DOORS_LIST_FAIL,
    error: error,
  };
};

export const getDeviceAlarmListStart = () => {
  return {
    type: actionTypes.GET_DEVICE_ALARM_LIST_START,
  };
};

export const getDeviceAlarmListSuccess = (data) => {
  return {
    type: actionTypes.GET_DEVICE_ALARM_LIST_SUCCESS,
    data: data,
  };
};

export const getDeviceAlarmListFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_ALARM_LIST_FAIL,
    error: error,
  };
};

export const deleteDeviceAlarmStart = () => {
  return {
    type: actionTypes.DELETE_DEVICE_ALARM_START,
  };
};

export const deleteDeviceAlarmSuccess = (data) => {
  return {
    type: actionTypes.DELETE_DEVICE_ALARM_SUCCESS,
    data: data,
  };
};

export const deleteDeviceAlarmFail = (error) => {
  return {
    type: actionTypes.DELETE_DEVICE_ALARM_FAIL,
    error: error,
  };
};

export const addDeviceAlarmStart = () => {
  return {
    type: actionTypes.ADD_DEVICE_ALARM_START,
  };
};

export const addDeviceAlarmSuccess = (data) => {
  return {
    type: actionTypes.ADD_DEVICE_ALARM_SUCCESS,
    data: data,
  };
};

export const addDeviceAlarmFail = (error) => {
  return {
    type: actionTypes.ADD_DEVICE_ALARM_FAIL,
    error: error,
  };
};

export const editDeviceAlarmStart = () => {
  return {
    type: actionTypes.EDIT_DEVICE_ALARM_START,
  };
};

export const editDeviceAlarmSuccess = (data) => {
  return {
    type: actionTypes.EDIT_DEVICE_ALARM_SUCCESS,
    data: data,
  };
};

export const editDeviceAlarmFail = (error) => {
  return {
    type: actionTypes.EDIT_DEVICE_ALARM_FAIL,
    error: error,
  };
};

export const addCommentStart = () => {
  return {
    type: actionTypes.ADD_COMMENT_START,
  };
};

export const addCommentSuccess = () => {
  return {
    type: actionTypes.ADD_COMMENT_SUCCESS,
  };
};

export const addCommentFail = (error) => {
  return {
    type: actionTypes.ADD_COMMENT_FAIL,
    error: error,
  };
};

export const pdfAlarmDataDownloadStart = () => {
  return {
    type: actionTypes.ALARM_PDF_DOWNLOAD_START,
  };
};

export const pdfAlarmDataDownloadSuccess = () => {
  return {
    type: actionTypes.ALARM_PDF_DOWNLOAD_SUCCESS,
  };
};

export const pdfAlarmDataDownloadFail = (error) => {
  return {
    type: actionTypes.ALARM_PDF_DOWNLOAD_FAIL,
    error: error,
  };
};

export const csvAlarmDataDownloadStart = () => {
  return {
    type: actionTypes.ALARM_CSV_DOWNLOAD_START,
  };
};

export const csvAlarmDataDownloadSuccess = () => {
  return {
    type: actionTypes.ALARM_CSV_DOWNLOAD_SUCCESS,
  };
};

export const csvAlarmDataDownloadFail = (error) => {
  return {
    type: actionTypes.ALARM_CSV_DOWNLOAD_FAIL,
    error: error,
  };
};

export const getFavouritesStart = () => {
  return {
    type: actionTypes.GET_FAVOURITES_START,
  };
};

export const getFavouritesSuccess = (data) => {
  return {
    type: actionTypes.GET_FAVOURITES_SUCCESS,
    data: data,
  };
};

export const getFavouritesFail = (error) => {
  return {
    type: actionTypes.GET_FAVOURITES_FAIL,
    error: error,
  };
};

export const addFavouritesStart = () => {
  return {
    type: actionTypes.ADD_FAVOURITES_START,
  };
};

export const addFavouritesSuccess = (data) => {
  return {
    type: actionTypes.ADD_FAVOURITES_SUCCESS,
    data: data,
  };
};

export const addFavouritesFail = (error) => {
  return {
    type: actionTypes.ADD_FAVOURITES_FAIL,
    error: error,
  };
};

export const deleteFavouritesStart = () => {
  return {
    type: actionTypes.DELETE_FAVOURITES_START,
  };
};

export const deleteFavouritesSuccess = (data) => {
  return {
    type: actionTypes.DELETE_FAVOURITES_SUCCESS,
    data: data,
  };
};

export const deleteFavouritesFail = (error) => {
  return {
    type: actionTypes.DELETE_FAVOURITES_FAIL,
    error: error,
  };
};

export const getRecentAlarmList = (page) => {
  const payload = null;
  return (dispatch) => {
    dispatch(getRecentAlarmListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.deviceAlarm.recent}`;
    url += `?page=${page || 1}&pageSize=9`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          const { data: data1, ...pagination } = response.data;

          let recentAlarms = [];
          if (data.length) {
            const newData = data.filter((alram) => alram.type !== "door");
            if (newData) {
              recentAlarms = newData;
            }
          }

          dispatch(
            getRecentAlarmListSuccess({ data: recentAlarms, ...pagination })
          );
        } else {
          dispatch(getRecentAlarmListFail(response.data.errors));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getRecentAlarmListFail(error.response.data.errors));
        }
      });
  };
};

export const getRecentDoorsList = (page) => {
  return (dispatch) => {
    dispatch(getRecentDoorsListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.deviceAlarm.recentDoors}`;
    url += `?page=${page || 1}&pageSize=9`;
    axios
      .post(url, { authToken: localStorage.getItem("token") }, { headers })
      .then((response) => {
        if (response.status === 200) {
          dispatch(getRecentDoorsListSuccess(response.data));
        } else {
          dispatch(getRecentDoorsListFail(response.data.errors));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getRecentDoorsListFail(error.response.data.errors));
        }
      });
  };
};

export const getDeviceAlarmList = ({ id }) => {
  return (dispatch) => {
    dispatch(getDeviceAlarmListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.deviceAlarm.list}`;
    url = url.replace("{id}", id);
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getDeviceAlarmListSuccess(response.data));
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(getDeviceAlarmListFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};

export const deleteDeviceAlarm = ({ id }) => {
  return (dispatch) => {
    dispatch(deleteDeviceAlarmStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.deviceAlarm.delete}`;
    url = url.replace("{id}", id);
    axios
      .delete(url, { headers })
      .then((response) => {
        dispatch(
          deleteDeviceAlarmSuccess(
            response.data || "Device Alarm has been deleted!"
          )
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(deleteDeviceAlarmFail(error.response.data.errors));
        }
      });
  };
};

export const addDeviceAlarm = (payload) => {
  return (dispatch) => {
    dispatch(addDeviceAlarmStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.deviceAlarm.add}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        dispatch(addDeviceAlarmSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(addDeviceAlarmFail(error.response.data.errors));
        }
      });
  };
};

export const editDeviceAlarm = ({ payload, id }) => {
  return (dispatch) => {
    dispatch(editDeviceAlarmStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.deviceAlarm.edit}`;
    url = url.replace("{id}", id);
    axios
      .put(url, payload, { headers })
      .then((response) => {
        dispatch(editDeviceAlarmSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(editDeviceAlarmFail(error.response.data.errors));
        }
      });
  };
};

export const addComment = (comment, alarmId) => {
  return (dispatch) => {
    dispatch(addCommentStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.deviceAlarm.addComment}/${alarmId}`;
    const payload = { comment };
    axios
      .put(url, payload, { headers })
      .then((response) => {
        dispatch(addCommentSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(addCommentFail(error.response.data.errors));
        }
      });
  };
};

export const pdfAlarmDataDownload = ({ startTime, endTime }) => {
  return (dispatch) => {
    dispatch(pdfAlarmDataDownloadStart());
    // window.open(link, '_blank')
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${
      apiPath.deviceAlarm.pdfDownload
    }?startTime=${encodeURI(startTime)}&endTime=${encodeURI(endTime)}`;

    axios
      .get(url, { headers, responseType: "arraybuffer" })
      .then((response) => {
        const { data } = response;
        const blob = new Blob([data], { type: "application/pdf" });
        saveAs(blob, "recentAlarms.pdf");

        dispatch(pdfAlarmDataDownloadSuccess());
      })

      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(pdfAlarmDataDownloadFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};

export const csvAlarmDataDownload = ({ startTime, endTime }) => {
  return (dispatch) => {
    dispatch(csvAlarmDataDownloadStart());
    // window.open(link, '_blank')
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${
      apiPath.deviceAlarm.csvDownload
    }?startTime=${encodeURI(startTime)}&endTime=${encodeURI(endTime)}`;

    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = response;
        const blob = new Blob([data], { type: "application/csv" });

        saveAs(blob, "recentAlarms.csv");
        dispatch(csvAlarmDataDownloadSuccess());
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(csvAlarmDataDownloadFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};

export const getFavourites = () => {
  return (dispatch) => {
    dispatch(getFavouritesStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.deviceAlarm.favourites}`;

    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = response;
        dispatch(getFavouritesSuccess(data));
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(getFavouritesFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};

export const addFavourites = (comment) => {
  return (dispatch) => {
    dispatch(addFavouritesStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.deviceAlarm.favourites}`;
    const payload = { message: comment };
    axios
      .post(url, payload, { headers })
      .then((response) => {
        const { data } = response;
        dispatch(addFavouritesSuccess(data));
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(addFavouritesFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};

export const deleteFavourite = (comment) => {
  return (dispatch) => {
    dispatch(deleteFavouritesStart());
    const headers = getCommonHeaders();
    const url = `${apiPath.baseUrl}${apiPath.deviceAlarm.favourites}/${comment}`;
    // const payload = { message: comment };
    axios
      .delete(url, { headers })
      .then((response) => {
        const { data } = response;
        dispatch(deleteFavouritesSuccess(data));
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(deleteFavouritesFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};
