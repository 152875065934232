import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { fieldHasError, onSubmit } from "../../helpers/formValidators";
import Select from "react-select";
import { showToast } from "../../helpers/toast";
import { selectStyle } from "../../config/selectStyle";

class AddDeviceLocation extends Component {
  state = {
    formDeviceLocationDevice: {
      locationId: "",
    },
    selectedDevice: {
      id: "",
      name: "",
    },
    locationId: "",
  };

  componentDidMount() {
    const { modalData, getDeviceList } = this.props;
    this.setState({ locationId: modalData.id });

    getDeviceList({ page: 0, isFilter: true });
  }

  editDeviceFn = (payload, id) => {
    const { editDevice } = this.props;
    editDevice({ payload, id });
  };

  handleChangeDevice = (selectedDevice) => {
    this.setState({ selectedDevice });
  };

  isDeviceSelected = (option) => {
    return this.state.selectedDevice.id === option.id ? true : false;
  };

  onSubmit = (e) => {
    if (this.state.selectedDevice.id) {
      const state = onSubmit(e, this.state);
      this.setState(state);
      if (!state.hasError) {
        let params = {
          locationId: this.state.locationId,
          timezone: state.selectedDevice.timezone,
        };

        if (state.selectedDevice.id) {
          params.deviceId = state.selectedDevice.deviceId;
          params.deviceName = state.selectedDevice.name;
          params.gatewayId = state.selectedDevice.gatewayId || null;
        }

        this.editDeviceFn(params, state.selectedDevice.id);
      }
      e.preventDefault();
    } else {
      showToast("Please select a device", "error");
      e.preventDefault();
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    const { toggleModal, deviceList } = this.props;
    // const { mode, selectedDevice } = this.state;
    return (
      <React.Fragment>
        <form
          className="mb-3"
          name="formDeviceLocation"
          onSubmit={this.onSubmit}
        >
          <ModalHeader toggle={toggleModal}>Add Device</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="text-muted">Devices</label>
              <Select
                styles={selectStyle}
                name="locationId"
                value={this.state.selectedDevice}
                onChange={this.handleChangeDevice}
                options={deviceList}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  this.isDeviceSelected(option);
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="submit">
              Add
            </Button>
            <Button color="secondary" type="button" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deviceList: state.devices.deviceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeviceList: (data) => dispatch(actions.getDeviceList(data)),
    editDevice: (data) => dispatch(actions.editDeviceLocation(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDeviceLocation);
