import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";

export const getUserIdsStart = () => {
  return {
    type: actionTypes.GET_USER_IDS_START,
  };
};
export const getUserIdsSussess = (data) => {
  return {
    type: actionTypes.GET_USER_IDS_SUCCESS,
    data: data.data,
  };
};
export const getUserIdsFail = (error) => {
  return {
    type: actionTypes.GET_USER_IDS_FAIL,
    error: error,
  };
};
export const getUserListStart = () => {
  return {
    type: actionTypes.GET_USER_LIST_START,
  };
};

export const getUserListSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_LIST_SUCCESS,
    data: data,
  };
};

export const getUserListFail = (error) => {
  return {
    type: actionTypes.GET_USER_LIST_FAIL,
    error: error,
  };
};

export const deleteUserStart = () => {
  return {
    type: actionTypes.DELETE_USER_START,
  };
};

export const deleteUserSuccess = (data) => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    data: data,
  };
};

export const deleteUserFail = (error) => {
  return {
    type: actionTypes.DELETE_USER_FAIL,
    error: error,
  };
};

export const addUserStart = () => {
  return {
    type: actionTypes.ADD_USER_START,
  };
};

export const addUserSuccess = (data) => {
  return {
    type: actionTypes.ADD_USER_SUCCESS,
    data: data,
  };
};

export const addUserFail = (error) => {
  return {
    type: actionTypes.ADD_USER_FAIL,
    error: error,
  };
};

export const editUserStart = () => {
  return {
    type: actionTypes.EDIT_USER_START,
  };
};

export const editUserSuccess = (data) => {
  return {
    type: actionTypes.EDIT_USER_SUCCESS,
    data: data,
  };
};

export const editUserFail = (error) => {
  return {
    type: actionTypes.EDIT_USER_FAIL,
    error: error,
  };
};

export const getUserIds = () => {
  return (dispatch) => {
    dispatch(getUserIdsStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.user.userIds}`;
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getUserIdsSussess(response.data));
      })
      .catch((error) => {
        // this part is wrong ken

        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 404) {
          getUserIdsFail({ error: [{ message: "404 not found" }] });
        } else {
          dispatch(getUserIdsFail(error.response.data.errors));
        }
      });
  };
};

export const getUserList = (page = 1, userId = undefined, searchString) => {
  return (dispatch) => {
    dispatch(getUserListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.user.list}`;
    url = url.replace("{id}", userId);
    // if (page) {
    //   url += `?page=${page}&pageSize=10`;
    // } else
    if (searchString) {
      url += `?page=1&pageSize=1000?searchString=${searchString}`;
    } else {
      url += "?page=1&pageSize=1000";
    }

    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getUserListSuccess(response.data));
      })
      .catch((error) => {
        // this part is wrong ken

        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 404) {
          getUserIdsFail({ error: [{ message: "404 not found" }] });
        } else {
          dispatch(getUserListFail(error.response.data.errors));
        }
      });
  };
};

export const deleteUser = ({ id }) => {
  return (dispatch) => {
    dispatch(deleteUserStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.user.delete}`;
    url = url.replace("{id}", id);
    axios
      .delete(url, { headers })
      .then((response) => {
        dispatch(deleteUserSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 500) {
          dispatch(
            deleteUserFail(
              "Cant delete user! Some devices, alarms or reports may have been Assigned to this user"
            )
          );
        } else {
          dispatch(deleteUserFail(error.response.data.errors));
        }
      });
  };
};

export const addUser = (payload) => {
  return (dispatch) => {
    dispatch(addUserStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.user.add}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        dispatch(addUserSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(addUserFail(error.response.data.errors));
        }
      });
  };
};

export const editUser = ({ payload, id }) => {
  return (dispatch) => {
    dispatch(editUserStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.user.edit}`;
    url = url.replace("{id}", id);
    axios
      .put(url, payload, { headers })
      .then((response) => {
        dispatch(editUserSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 404) {
          getUserIdsFail({ error: [{ message: "404 not found" }] });
        } else {
          dispatch(editUserFail(error.response.data.errors));
        }
      });
  };
};
