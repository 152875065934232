import React, { Component } from "react";
import { ModalHeader, ModalFooter, Button, ModalBody, Input } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { dispatch } from "d3";
import { showToast } from "../../helpers/toast";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";

class OfflineAlarms extends Component {
  state = {
    offlineAlarmsEnable: true,
    checked: false,
    alarmFrequency: {
      id: "",
      name: "",
    },
    alarmFrequencyData: [
      {
        id: 30,
        name: "30 min",
      },
      {
        id: 60,
        name: "1 hr",
      },
      {
        id: 90,
        name: "1.5 hr",
      },
      {
        id: 120,
        name: "2 hr",
      },
    ],
  };

  onSubmit = () => {
    const payload = {
      offlineAlarmEnable: this.state.offlineAlarmsEnable ? 1 : 0,
      maxAlarms: this.state.checked ? 1 : 24,
      alarmFrequency: this.state.alarmFrequency.id || 60,
    };
    return dispatch(this.props.updateOfflineAlarm(payload));
  };

  handleAlarmFrequency = (alarmFrequency) => {
    this.setState({ alarmFrequency });
  };

  componentDidMount() {
    this.props.getOfflineAlarmDetails();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.offlineAlarmsStatus !== this.props.offlineAlarmsStatus &&
      this.props.offlineAlarmsStatus
    ) {
      showToast("Offline Alarms updated Successfully", "success");
      setTimeout(() => {
        this.props.toggleModal();
      }, 1000);
    }
    if (
      prevProps.offlineAlarmsDetails !== this.props.offlineAlarmsDetails &&
      this.props.offlineAlarmsDetails
    ) {
      const data = this.state.alarmFrequencyData.filter(
        (val) => val.id === this.props.offlineAlarmsDetails.alarmFrequency
      );
      this.setState({
        offlineAlarmsEnable: !!this.props.offlineAlarmsDetails
          .offlineAlarmEnable,
        checked:
          this.props.offlineAlarmsDetails.maxAlarms === 24 ? false : true,
        alarmFrequency: data.length ? data[0] : { id: "", name: "" },
      });
    }
  }

  componentWillUnmount() {
    this.props.resetAll();
  }

  render() {
    const { toggleModal } = this.props;

    return (
      <React.Fragment>
        <ModalHeader toggle={toggleModal}>Update Offline Alarms</ModalHeader>
        <ModalBody>
          {/* <div className="text-xLarge text-left">Offline Alarms :</div>
          <div
            className="col-sm-12 d-flex mb-3"
            style={{ alignItems: "center" }}
          >
            <div className="col-sm-5"></div>
            <div
              className="d-flex"
              style={{ alignItems: "center", width: "100%" }}
            >
              <span className="mr-2 text-large" style={{ width: "25%" }}>
                Disabled
              </span>
              <Switch
                onChange={() => {
                  this.setState({
                    offlineAlarmsEnable: !this.state.offlineAlarmsEnable,
                  });
                }}
                checked={this.state.offlineAlarmsEnable}
                height={32}
                width={68}
              />
              <span className="ml-2 text-large"> Enabled</span>
            </div>
          </div> */}
          <div className="d-flex justify-content-around">
            <div className="enableCheckbox d-flex justify-content-start algin-items-center flex-column form-group pt-2">
              <h6 className="text-muted text-center">Offline Alarms</h6>
              <p className="pt-2">
                <label className="text-muted">Disable</label>
                <Input
                  type="checkbox"
                  id="offlineAlarmsEnable"
                  name="offlineAlarmsEnable"
                  checked={this.state.offlineAlarmsEnable}
                  onChange={() => {
                    this.setState({
                      offlineAlarmsEnable: !this.state.offlineAlarmsEnable,
                    });
                  }}
                />
                <label htmlFor="offlineAlarmsEnable" className="checkbox-label">
                  <span className="ui" />
                </label>
                <label className="text-muted">Enable</label>
              </p>
            </div>
            {/* <div className="text-xLarge text-left">Alarm Duration :</div>
          <div
            className="col-sm-12 d-flex mb-3"
            style={{ alignItems: "center" }}
          >
            <div className="col-sm-5"></div>
            <div
              className="d-flex"
              style={{ alignItems: "center", width: "100%" }}
            >
              <span className="mr-2 text-large" style={{ width: "25%" }}>
                One Time
              </span>
              <Switch
                onChange={() => {
                  this.setState({
                    checked: !this.state.checked,
                  });
                }}
                checked={this.state.checked}
                height={32}
                width={68}
                disabled={!this.state.offlineAlarmsEnable}
              />
            </div>
          </div> */}
            <div className="enableCheckbox d-flex justify-content-start algin-items-center flex-column form-group pt-2">
              <h6 className="text-muted text-center">Alarm Duration</h6>
              <p className="pt-2">
                <label className="text-muted">One Time</label>
                <Input
                  type="checkbox"
                  id="checked"
                  name="checked"
                  checked={this.state.checked}
                  onChange={() => {
                    this.setState({
                      checked: !this.state.checked,
                    });
                  }}
                  disabled={!this.state.offlineAlarmsEnable}
                />
                <label htmlFor="checked" className="checkbox-label">
                  <span className="ui" />
                </label>
                {/* <label className="text-muted">Enable</label> */}
              </p>
            </div>
          </div>
          {/* <div className="text-xLarge text-left">Alarm Frequency :</div> */}
          <h6 className="text-muted text-center">Alarm Frequency</h6>
          <div
            className="mb-2 form-group"
            style={{ marginLeft: "15%", marginRight: "15%", marginTop: "4%" }}
          >
            <Select
              maxMenuHeight={170}
              styles={selectStyle}
              name="frequency"
              isDisabled={this.state.checked || !this.state.offlineAlarmsEnable}
              value={this.state.alarmFrequency}
              onChange={this.handleAlarmFrequency}
              options={this.state.alarmFrequencyData}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option}`}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="info" type="submit" onClick={this.onSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.OfflineAlarms.loading,
    offlineAlarmsStatus: state.OfflineAlarms.offlineAlarmsStatus,
    metaData: state.dashboard.dashboard,
    offlineAlarmsDetails: state.OfflineAlarms.offlineAlarmsDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOfflineAlarm: (payload) =>
      dispatch(actions.updateOfflineAlarms(payload)),
    getOfflineAlarmDetails: () => dispatch(actions.getOADetails()),
    resetAll: () => dispatch(actions.resetAlarms()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflineAlarms);
