import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  userList: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
  userIds: [],
};

const getUserIdsStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: true,
    success: undefined,
    userIds: [],
  });
};
const getUserIdsSussess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    success: true,
    userIds: action.data,
  });
};
const getUserIdsFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    success: undefined,
    userIds: [],
  });
};
const getUserListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    userList: [],
    meta: {},
    loading: true,
  });
};

const getUserListSuccess = (state, action) => {
  return updateObject(state, {
    userList: action.userList,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getUserListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    userList: [],
    meta: {},
    loading: false,
  });
};

const deleteUserStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const deleteUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: undefined,
    reload: true,
    userList: [],
    success: "User deleted successfully.",
  });
};

const deleteUserFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error[0].message || action.error,
    reload: false,
    success: undefined,
  });
};

const addUserStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const addUserSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    userList: [],
    success: "User added successfully.",
  });
};

const addUserFail = (state, action) => {
  return updateObject(state, {
    error:
      action.error[0].msg &&
      action.error[0].msg === "PhoneNumber is a required property."
        ? "Enter a valid phone number"
        : action.error[0].message
        ? action.error[0].message
        : action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const editUserStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const editUserSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    userList: [],
    success: "Changes saved successfully.",
  });
};

const editUserFail = (state, action) => {
  return updateObject(state, {
    error:
      action.error[0].msg &&
      action.error[0].msg === "PhoneNumber is a required property."
        ? "Enter a valid phone number"
        : action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_IDS_START:
      return getUserIdsStart(state, action);
    case actionTypes.GET_USER_IDS_SUCCESS:
      return getUserIdsSussess(state, action);
    case actionTypes.GET_USER_IDS_FAIL:
      return getUserIdsFail(state, action);
    case actionTypes.GET_USER_LIST_START:
      return getUserListStart(state, action);
    case actionTypes.GET_USER_LIST_SUCCESS:
      return getUserListSuccess(state, action.data);
    case actionTypes.GET_USER_LIST_FAIL:
      return getUserListFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state, action);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action.data);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.ADD_USER_START:
      return addUserStart(state, action);
    case actionTypes.ADD_USER_SUCCESS:
      return addUserSuccess(state, action.data);
    case actionTypes.ADD_USER_FAIL:
      return addUserFail(state, action);
    case actionTypes.EDIT_USER_START:
      return editUserStart(state, action);
    case actionTypes.EDIT_USER_SUCCESS:
      return editUserSuccess(state, action.data);
    case actionTypes.EDIT_USER_FAIL:
      return editUserFail(state, action);
    default:
      return state;
  }
};

export default users;
