import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  deviceList: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const getNodeDevicesListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    deviceList: [],
    meta: {},
    loading: true,
  });
};

const getNodeDevicesListSuccess = (state, action) => {
  return updateObject(state, {
    deviceList: action.deviceList,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getNodeDevicesListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    deviceList: [],
    meta: {},
    loading: false,
  });
};

// const deleteGatewayStart = (state, action) => {
//   return updateObject(state, {
//     loading: true,
//     error: undefined,
//     reload: false,
//     success: undefined,
//   });
// };

// const deleteGatewaySuccess = (state, action) => {
//   return updateObject(state, {
//     loading: false,
//     error: undefined,
//     reload: true,
//     deviceList: [],
//     success: "Gateway deleted successfully.",
//   });
// };

// const deleteGatewayFail = (state, action) => {
//   return updateObject(state, {
//     loading: false,
//     error: action.error[0].message,
//     reload: false,
//     success: undefined,
//   });
// };

// const addGatewayStart = (state, action) => {
//   return updateObject(state, {
//     loading: true,
//     error: undefined,
//     reload: false,
//     success: undefined,
//   });
// };

// const addGatewaySuccess = (state, action) => {
//   return updateObject(state, {
//     error: undefined,
//     loading: false,
//     reload: true,
//     deviceList: [],
//     success: "Gateway added successfully.",
//   });
// };

// const addGatewayFail = (state, action) => {
//   return updateObject(state, {
//     error: action.error[0].message,
//     loading: false,
//     reload: false,
//     success: undefined,
//   });
// };

// const editGatewayStart = (state, action) => {
//   return updateObject(state, {
//     loading: true,
//     error: undefined,
//     reload: false,
//     success: undefined,
//   });
// };

// const editGatewaySuccess = (state, action) => {
//   return updateObject(state, {
//     error: undefined,
//     loading: false,
//     reload: true,
//     deviceList: [],
//     success: "Changes saved successfully.",
//   });
// };

// const editGatewayFail = (state, action) => {
//   return updateObject(state, {
//     error: action.error[0].message || action.error[0].msg,
//     loading: false,
//     reload: false,
//     success: undefined,
//   });
// };

// const uploadGatewayStart = (state, action) => {
//   return updateObject(state, {
//     loading: true,
//     error: undefined,
//     reload: false,
//     success: undefined,
//   });
// };

// const uploadGatewaySuccess = (state, action) => {
//   return updateObject(state, {
//     error: undefined,
//     loading: false,
//     reload: true,
//     success: "Gateway updated successfully",
//   });
// };

// const uploadGatewayFail = (state, action) => {
//   return updateObject(state, {
//     error: action.error[0].message || action.error[0].msg,
//     loading: false,
//     reload: false,
//     success: undefined,
//   });
// };

// const updateGatewayStart = (state, action) => {
//   return updateObject(state, {
//     loading: true,
//     error: undefined,
//     reload: false,
//     success: undefined,
//   });
// };

// const updateGatewaySuccess = (state, action) => {
//   return updateObject(state, {
//     error: undefined,
//     loading: false,
//     reload: true,
//     success: "Gateway updated successfully",
//   });
// };

// const updateGatewayFail = (state, action) => {
//   return updateObject(state, {
//     error: action.error[0].message || action.error[0].msg,
//     loading: false,
//     reload: false,
//     success: undefined,
//   });
// };

const otaDevices = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NODE_DEVICES_LIST_START:
      return getNodeDevicesListStart(state, action);
    case actionTypes.GET_NODE_DEVICES_LIST_SUCCESS:
      return getNodeDevicesListSuccess(state, action.data);
    case actionTypes.GET_NODE_DEVICES_LIST_FAIL:
      return getNodeDevicesListFail(state, action);
    // case actionTypes.DELETE_GATEWAY_START:
    //   return deleteGatewayStart(state, action);
    // case actionTypes.DELETE_GATEWAY_SUCCESS:
    //   return deleteGatewaySuccess(state, action.data);
    // case actionTypes.DELETE_GATEWAY_FAIL:
    //   return deleteGatewayFail(state, action);
    // case actionTypes.ADD_GATEWAY_START:
    //   return addGatewayStart(state, action);
    // case actionTypes.ADD_GATEWAY_SUCCESS:
    //   return addGatewaySuccess(state, action.data);
    // case actionTypes.ADD_GATEWAY_FAIL:
    //   return addGatewayFail(state, action);
    // case actionTypes.EDIT_GATEWAY_START:
    //   return editGatewayStart(state, action);
    // case actionTypes.EDIT_GATEWAY_SUCCESS:
    //   return editGatewaySuccess(state, action.data);
    // case actionTypes.EDIT_GATEWAY_FAIL:
    //   return editGatewayFail(state, action);
    // case actionTypes.UPLOAD_GATEWAY_START:
    //   return uploadGatewayStart(state.action);
    // case actionTypes.UPLOAD_GATEWAY_SUCCESS:
    //   return uploadGatewaySuccess(state, action.data);
    // case actionTypes.UPLOAD_GATEWAY_FAIL:
    //   return uploadGatewayFail(state, action);
    //   case actionTypes.UPDATE_GATEWAY_START:
    //   return updateGatewayStart(state.action);
    // case actionTypes.UPDATE_GATEWAY_SUCCESS:
    //   return updateGatewaySuccess(state, action.data);
    // case actionTypes.UPDATE_GATEWAY_FAIL:
    //   return updateGatewayFail(state, action);
    default:
      return state;
  }
};

export default otaDevices;
