import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import * as actions from '../../stores/actions/index';
import { showToast } from '../../helpers/toast.js';
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from '../../helpers/formValidators';

class ResetPassword extends Component {
  state = {
    formReset: {
      password: '',
      password2: '',
    },
  };

  // UNSAFE_componentWillMount() {
  //   this.props.validateToken(this.props.resetToken);
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, 'success');
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, 'error');
    }
  }

  validateOnChange = (event) => {
    const state = validateOnChange(event, this.state);
    this.setState(state);
  };

  onSubmit = (e) => {
    const state = onSubmit(e, this.state);
    this.setState(state);

    this.setState(state);

    if (!state.hasError) {
      const params = {
        password: this.state.formReset.password,
        resetToken: this.props.resetToken,
      };
      this.props.resetPassword(params);
    }
    e.preventDefault();
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    return (
      <React.Fragment>
            <p className='text-center py-2'>PASSWORD RESET</p>
            <form className='mb-3' name='formReset' onSubmit={this.onSubmit}>
              <div className='form-group'>
                <label className='text-muted'>New Password</label>
                <div className='input-group with-focus'>
                  <Input
                    type='password'
                    id='id-password'
                    name='password'
                    className='border-right-0 border-light'
                    placeholder='Password'
                    invalid={this.hasError('formReset', 'password', 'required')}
                    onChange={this.validateOnChange}
                    data-validate='["required"]'
                    minLength='6'
                    value={this.state.formReset.password}
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text text-muted bg-transparent border-left-0'>
                      <em className='fa fa-lock'></em>
                    </span>
                  </div>
                  <span className='invalid-feedback'>Password is required</span>
                </div>
              </div>
              <div className='form-group'>
                <label className='text-muted'>Confirm Password</label>
                <div className='input-group with-focus'>
                  <Input
                    type='password'
                    name='password2'
                    className='border-right-0 border-light'
                    placeholder='Retype assword'
                    invalid={this.hasError('formReset', 'password2', 'equalto')}
                    onChange={this.validateOnChange}
                    data-validate='["equalto"]'
                    value={this.state.formReset.password2}
                    data-param='id-password'
                    minLength='6'
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text text-muted bg-transparent border-left-0'>
                      <em className='fa fa-lock'></em>
                    </span>
                  </div>
                  <span className='invalid-feedback'>Passwords must match</span>
                </div>
              </div>
              <button className='btn btn-block btn-primary mt-3' type='submit'>
                Reset Password
              </button>
            </form>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.forgotPassword.loading,
    error: state.forgotPassword.error,
    success : state.forgotPassword.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (params) => dispatch(actions.resetPassword(params)),
    validateToken: (params) => dispatch(actions.validateToken(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
