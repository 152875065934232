import React from "react";
import { Tooltip } from "reactstrap";
import { convertDateFormat } from "../../../../utils/helperFunctions";

class Alarm extends React.Component {
  render() {
    const {
      index,
      alarm,
      isToolTipOpen,
      toggleTooltip,
      editDeviceAlarm,
      deleteDeviceAlarm,
      userRole,
      isNodeLiteDevice,
    } = this.props;

    return (
      <div
        style={{ minWidth: "250px" }}
        className="locationDiv alarmDiv align-items-center d-flex flex-row"
        key={index}
        ref={index}
      >
        <div className="w-75">
          <p className="pl-1 pr-1 m-0 w-100 text-uppercase font-weight-bold">
            {alarm.name}
          </p>
        </div>
        <div
          className={`w-25 text-right d-flex mr-3 ${
            (userRole === "user" && alarm.createdBy === "super_user") ||
            alarm.profileId
              ? "alarmContent"
              : ""
          }`}
        >
          <button className="transparentBtn">
            <i
              id={"Tooltip-" + alarm.id}
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
          </button>
          {/* this.isToolTipOpen(`Tooltip-${device.id}`) */}
          <Tooltip
            className="tooltip"
            placement="bottom"
            autohide={false}
            target={"Tooltip-" + alarm.id}
            isOpen={isToolTipOpen(`Tooltip-${alarm.id}`)}
            toggle={() => toggleTooltip(`Tooltip-${alarm.id}`)}
          >
            <span>Alarm ID : {alarm.id}</span>
            <br />
            {alarm.tempEnabled && !isNodeLiteDevice ? (
              <>
                <span>
                  Temperature:
                  <br /> (Min) {alarm.minTemp} - (Max) {alarm.maxTemp}
                </span>
                <br />
              </>
            ) : null}
            {alarm.humidityEnabled && !isNodeLiteDevice ? (
              <>
                <span>
                  Humidity:
                  <br /> (Min) {alarm.minHumidity} - (Max) {alarm.maxHumidity}
                </span>
                <br />
              </>
            ) : null}
            {alarm.carbonEnabled && !isNodeLiteDevice ? (
              <>
                <span>
                  CO₂:
                  <br /> (Min) {alarm.minCarbon} - (Max) {alarm.maxCarbon}
                </span>
                <br />
              </>
            ) : null}
            {isNodeLiteDevice ? (
              <>
                <span>
                  Water:
                  <br /> {alarm.waterEnabled ? "Enabled" : "Disabled"}
                </span>
                <br />
              </>
            ) : null}
            {/* {alarm.lnlvlEnabled ? (
              <>
                <span>
                  LN2 Level:
                  <br /> (Min) {alarm.minLnlvl} - (Max) {alarm.maxLnlvl}
                </span>
                <br />
              </>
            ) : null} */}
            {/* {alarm.lntmpEnabled ? (
              <>
                <span>
                  LN2 Temperature:
                  <br /> (Min) {alarm.minLntmp} - (Max) {alarm.maxLntmp}
                </span>
                <br />
              </>
            ) : null} */}

            <>
              <span>{`Alarm: ${alarm.enable ? "Enabled" : "Disabled"} `}</span>
              <br />
            </>
            <span>
              Created At : {convertDateFormat(alarm.createdAt, "shortDateTime")}
            </span>
          </Tooltip>
          {!(userRole === "user" && alarm.createdBy === "super_user") &&
            !alarm.profileId && (
              <>
                <button
                  className="transparentBtn"
                  onClick={() => editDeviceAlarm(alarm)}
                >
                  <i className=" fas fa-pencil-alt mt-0"></i>
                </button>
                <button
                  className="transparentBtn"
                  onClick={() => deleteDeviceAlarm(alarm.id)}
                >
                  <i className=" fas fa-trash mt-0"></i>
                </button>
              </>
            )}
        </div>
      </div>
    );
  }
}

export default Alarm;
