import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";

export const getUserMetaStart = () => {
  return {
    type: actionTypes.GET_USER_META_START,
  };
};

export const getUserMetaSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_META_SUCCESS,
    data: data,
  };
};

export const getUserMetaFail = (error) => {
  return {
    type: actionTypes.GET_USER_META_FAIL,
    error: error,
  };
};

export const getDeviceStatusStart = () => {
  return {
    type: actionTypes.GET_DEVICE_STATUS_START,
  };
};

export const getDeviceStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_DEVICE_STATUS_SUCCESS,
    data: data,
  };
};

export const getDeviceStatusFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_STATUS_FAIL,
    error: error,
  };
};

export const getUserMeta = () => {
  return (dispatch) => {
    dispatch(getUserMetaStart());
    const headers = getCommonHeaders();
    const url = `${apiPath.baseUrl}${apiPath.dashboard.userMeta}`;
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getUserMetaSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 500) {
          dispatch(getUserMetaFail([error.response.data.message]));
        } else {
          dispatch(getUserMetaFail(error.response.data.errors));
        }
      });
  };
};

export const getDeviceStatusData = (payload) => {
  return (dispatch) => {
    dispatch(getDeviceStatusStart());
    const headers = getCommonHeaders();
    const url = `${apiPath.baseUrl}${apiPath.dashboard.deviceStatus}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        dispatch(getDeviceStatusSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 500) {
          dispatch(getDeviceStatusFail([error.response.data.message]));
        } else {
          dispatch(getDeviceStatusFail(error.response.data.errors));
        }
      });
  };
};
