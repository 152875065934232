import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
import { showToast } from "../../helpers/toast";
// import { dispatch } from "d3-dispatch";

export const getUserNotificationsListStart = () => {
  return {
    type: actionTypes.GET_USER_NOTIFICATIONS_LIST_START,
  };
};

export const getUserNotificationsListSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_NOTIFICATIONS_LIST_SUCCESS,
    data: data,
  };
};

export const getUserNotificationsListFail = (error) => {
  return {
    type: actionTypes.GET_USER_NOTIFICATIONS_LIST_FAIL,
    error: error,
  };
};

export const getUserNotificationsList = (params = {page: 1, pageSize: 10}) => {
  return (dispatch) => {
    dispatch(getUserNotificationsListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.userNotifications.list}`;
    if (params.page) {
      url += `?page=${params.page}&pageSize=${params.pageSize}`;
    } else {
      url += "?page=1&pageSize=1000";
    }
                              
    if(params.email){
      url += "&email="+params.email;
    }
    if(params.phone){
      url += "&phone="+params.phone;
    }
    if(params.text){
      url += "&text="+params.text;
    }
    if(params.is_sms && params.is_sms !== -1){
      url += "&isSms="+params.is_sms;
    }
    if(params.startDate){
      url += "&startDate="+params.startDate;
    }
    if(params.endDate){
      url += "&endDate="+params.endDate;
    }
    if(params.accountId){
      url += "&accountId="+params.accountId;
    }
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getUserNotificationsListSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getUserNotificationsListFail(error.response.data.errors));
        }
      });
  };
};