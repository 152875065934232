import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  deviceAlarmList: [],
  error: undefined,
  loading: false,
  reload: false,
  recentAlarms: {},
  success: undefined,
  recentDoors: {},
  recentDoorLoading: false,
  recentAlarmLoading: false,
};

const getRecentAlarmListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    recentAlarms: {},
    recentAlarmLoading: true,
  });
};

const getRecentAlarmListSuccess = (state, action) => {
  return updateObject(state, {
    recentAlarms: action.recentAlarms,
    error: undefined,
    recentAlarmLoading: false,
  });
};
const getRecentAlarmListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    recentAlarms: {},
    recentAlarmLoading: false,
  });
};
const getRecentDoorsListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    recentDoors: {},
    recentDoorLoading: true,
  });
};

const getRecentDoorsListSuccess = (state, action) => {
  return updateObject(state, {
    recentDoors: action.recentDoors,
    error: undefined,
    recentDoorLoading: false,
  });
};

const getRecentDoorsListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    recentDoors: {},
    recentDoorLoading: false,
  });
};

const getDeviceAlarmListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    deviceAlarmList: [],
    loading: true,
  });
};

const getDeviceAlarmListSuccess = (state, action) => {
  return updateObject(state, {
    deviceAlarmList: action.data,
    error: undefined,
    loading: false,
  });
};

const getDeviceAlarmListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    deviceAlarmList: [],
    loading: false,
  });
};

const deleteDeviceAlarmStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const deleteDeviceAlarmSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: undefined,
    reload: true,
    deviceAlarmList: [],
    success: "The device alarm has been deleted successfully.",
  });
};

const deleteDeviceAlarmFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    success: undefined,
    error: action.error[0].message,
    reload: false,
  });
};

const addDeviceAlarmStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    success: undefined,
    error: undefined,
    reload: false,
  });
};

const addDeviceAlarmSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    deviceAlarmList: [],
    success: "The device alarm has been added successfully.",
  });
};

const addDeviceAlarmFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const editDeviceAlarmStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
    successMessage: undefined,
  });
};

const editDeviceAlarmSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    deviceAlarmList: [],
    success: undefined,
    successMessage: "The device alarm changes has been saved successfully.",
  });
};

const editDeviceAlarmFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reload: false,
    success: undefined,
    successMessage: undefined,
  });
};

const addCommentStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const addCommentSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    comments: true,
    success: "The Comments has been added successfully.",
  });
};

const addCommentFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const pdfAlarmDataDownloadStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
  });
};

const pdfAlarmDataDownloadSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
  });
};

const pdfAlarmDataDownloadFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
  });
};

const csvAlarmDataDownloadStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
  });
};

const csvAlarmDataDownloadSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
  });
};

const csvAlarmDataDownloadFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
  });
};

const getFavouritesStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
  });
};

const getFavouritesSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    favouritesList: action.data,
  });
};

const getFavouritesFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    favouritesList: [],
  });
};

const addFavouritesStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
  });
};

const addFavouritesSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    favMessage: "Favourites has been added successfully",
  });
};

const addFavouritesFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    favMessage: null,
  });
};

const deleteFavouritesStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    deletedMessage: "",
  });
};
const deleteFavouritesSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    deletedMessage: "Favourites has been deleted successfully",
  });
};

const deleteFavouritesFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    favMessage: null,
  });
};

const deviceAlarms = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RECENT_ALARM_LIST_START:
      return getRecentAlarmListStart(state, action);
    case actionTypes.GET_RECENT_ALARM_LIST_SUCCESS:
      return getRecentAlarmListSuccess(state, action);
    case actionTypes.GET_RECENT_ALARM_LIST_FAIL:
      return getRecentAlarmListFail(state, action);
    case actionTypes.GET_RECENT_DOORS_LIST_START:
      return getRecentDoorsListStart(state, action);
    case actionTypes.GET_RECENT_DOORS_LIST_SUCCESS:
      return getRecentDoorsListSuccess(state, action);
    case actionTypes.GET_RECENT_DOORS_LIST_FAIL:
      return getRecentDoorsListFail(state, action);
    case actionTypes.GET_DEVICE_ALARM_LIST_START:
      return getDeviceAlarmListStart(state, action);
    case actionTypes.GET_DEVICE_ALARM_LIST_SUCCESS:
      return getDeviceAlarmListSuccess(state, action.data);
    case actionTypes.GET_DEVICE_ALARM_LIST_FAIL:
      return getDeviceAlarmListFail(state, action);
    case actionTypes.DELETE_DEVICE_ALARM_START:
      return deleteDeviceAlarmStart(state, action);
    case actionTypes.DELETE_DEVICE_ALARM_SUCCESS:
      return deleteDeviceAlarmSuccess(state, action.data);
    case actionTypes.DELETE_DEVICE_ALARM_FAIL:
      return deleteDeviceAlarmFail(state, action);
    case actionTypes.ADD_DEVICE_ALARM_START:
      return addDeviceAlarmStart(state, action);
    case actionTypes.ADD_DEVICE_ALARM_SUCCESS:
      return addDeviceAlarmSuccess(state, action.data);
    case actionTypes.ADD_DEVICE_ALARM_FAIL:
      return addDeviceAlarmFail(state, action);
    case actionTypes.EDIT_DEVICE_ALARM_START:
      return editDeviceAlarmStart(state, action);
    case actionTypes.EDIT_DEVICE_ALARM_SUCCESS:
      return editDeviceAlarmSuccess(state, action.data);
    case actionTypes.EDIT_DEVICE_ALARM_FAIL:
      return editDeviceAlarmFail(state, action);
    case actionTypes.ADD_COMMENT_START:
      return addCommentStart(state, action);
    case actionTypes.ADD_COMMENT_SUCCESS:
      return addCommentSuccess(state, action.data);
    case actionTypes.ADD_COMMENT_FAIL:
      return addCommentFail(state, action);
    case actionTypes.ALARM_PDF_DOWNLOAD_START:
      return pdfAlarmDataDownloadStart(state, action);
    case actionTypes.ALARM_PDF_DOWNLOAD_SUCCESS:
      return pdfAlarmDataDownloadSuccess(state, action.data);
    case actionTypes.ALARM_PDF_DOWNLOAD_FAIL:
      return pdfAlarmDataDownloadFail(state, action);
    case actionTypes.ALARM_CSV_DOWNLOAD_START:
      return csvAlarmDataDownloadStart(state, action);
    case actionTypes.ALARM_CSV_DOWNLOAD_SUCCESS:
      return csvAlarmDataDownloadSuccess(state, action.data);
    case actionTypes.ALARM_CSV_DOWNLOAD_FAIL:
      return csvAlarmDataDownloadFail(state, action);
    case actionTypes.GET_FAVOURITES_START:
      return getFavouritesStart(state, action);
    case actionTypes.GET_FAVOURITES_SUCCESS:
      return getFavouritesSuccess(state, action.data);
    case actionTypes.GET_FAVOURITES_FAIL:
      return getFavouritesFail(state, action);
    case actionTypes.ADD_FAVOURITES_START:
      return addFavouritesStart(state, action);
    case actionTypes.ADD_FAVOURITES_SUCCESS:
      return addFavouritesSuccess(state, action.data);
    case actionTypes.ADD_FAVOURITES_FAIL:
      return addFavouritesFail(state, action);
    case actionTypes.DELETE_FAVOURITES_START:
      return deleteFavouritesStart(state, action);
    case actionTypes.DELETE_FAVOURITES_SUCCESS:
      return deleteFavouritesSuccess(state, action.data);
    case actionTypes.DELETE_FAVOURITES_FAIL:
      return deleteFavouritesFail(state, action);
    default:
      return state;
  }
};

export default deviceAlarms;
