import * as actionTypes from './actionTypes';
import axios from 'axios';
import { apiPath } from '../../config/config';
import history from '../../helpers/history';

export const forgotStart = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_START,
  };
};

export const forgotSuccess = (success) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    success: success,
  };
};

export const forgotFail = (error) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_FAIL,
    error: error,
  };
};

export const validateTokenStart = () => {
  return {
    type: actionTypes.VALIDATE_RESET_TOKEN_START,
  };
};

export const validateTokenSuccess = (token, userId) => {
  return {
    type: actionTypes.VALIDATE_RESET_TOKEN_SUCCESS,
    token: token,
    userId: userId,
  };
};

export const validateTokenFail = (error) => {
  return {
    type: actionTypes.VALIDATE_RESET_TOKEN_FAIL,
    error: error,
  };
};

export const resetPasswordStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START,
  };
};

export const resetPasswordSuccess = (success) => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    success :success
  };
};

export const resetPasswordFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    error: error,
  };
};

export const forgotPassword = ({ email }) => {
  return (dispatch) => {
    dispatch(forgotStart());
    const authData = {
      email: email,
    };
    const url = `${apiPath.baseUrl}${apiPath.auth.forgotInit}`;
    axios
      .post(url, authData)
      .then((response) => {
        dispatch(forgotSuccess('An forget password email has been sent to your Email ID'));
        setTimeout(()=>history.push('/login'), 2000)
      })
      .catch((error) => {
        dispatch(forgotFail(error.response.data.errors));
      });
  };
};

export const validateToken = (resetToken) => {
  return (dispatch) => {
    dispatch(validateTokenStart());
    let url = `${apiPath.baseUrl}${apiPath.auth.validateToken}`;
    url = url.replace('{resetToken}', resetToken);
    axios
      .get(url)
      .then((response) => {
        dispatch(validateTokenSuccess({ data: 'token_valid' }));
      })
      .catch((error) => {
        dispatch(validateTokenFail(error.response.data.errors));
      });
  };
};

export const resetPassword = ({ password, resetToken }) => {
  return (dispatch) => {
    dispatch(resetPasswordStart());
    const authData = {
      password: password, 
    };
    let url = `${apiPath.baseUrl}${apiPath.auth.resetPassword}`;
    url = url.replace('{resetToken}', resetToken);
    axios
      .post(url, authData)
      .then((response) => {
        if(response.status === 200){
          dispatch(resetPasswordSuccess('Password reset successfully'));
          setTimeout(()=>history.push('/login'), 2000)
        }
      })
      .catch((error) => {
        dispatch(          
          resetPasswordFail(error.response.data.errors || error.response.data)
        );
      });
  };
};
