import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  alertProfileList: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const saveAlertProfileStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    meta: {},
    loading: true,
  });
};

const saveAlertProfileSuccess = (state, action) => {
  return updateObject(state, {
    success: "The Alert Profile has been added successfully.",
    saved: true,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const saveAlertProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    gatewayLogsList: [],
    meta: {},
    loading: false,
  });
};

const getAlertProfileStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    meta: {},
    loading: true,
  });
};

const getAlertProfileSuccess = (state, action) => {
  return updateObject(state, {
    alertProfileList: action.result,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getAlertProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    gatewayLogsList: [],
    meta: {},
    loading: false,
  });
};

const deleteProfileStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    meta: {},
    loading: true,
  });
};

const deleteProfileSuccess = (state, action) => {
  return updateObject(state, {
    success: "Alert Profile deleted successfully.",
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const deleteProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    gatewayLogsList: [],
    meta: {},
    loading: false,
  });
};

const editProfileStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    meta: {},
    loading: true,
  });
};

const editProfileSuccess = (state, action) => {
  return updateObject(state, {
    success: "Alert Profile updated successfully.",
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const editProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    gatewayLogsList: [],
    meta: {},
    loading: false,
  });
};

const resetSuccess = (state, action) => {
  return updateObject(state, {
    success: "",
    error: undefined,
    loading: false,
  });
};

const gatewayLogs = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_ALERT_PROFILE_START:
      return saveAlertProfileStart(state, action);
    case actionTypes.SAVE_ALERT_PROFILE_SUCCESS:
      return saveAlertProfileSuccess(state, action.data);
    case actionTypes.SAVE_ALERT_PROFILE_FAIL:
      return saveAlertProfileFail(state, action);

    case actionTypes.GET_ALERT_PROFILE_START:
      return getAlertProfileStart(state, action);
    case actionTypes.GET_ALERT_PROFILE_SUCCESS:
      return getAlertProfileSuccess(state, action.data);
    case actionTypes.GET_ALERT_PROFILE_FAIL:
      return getAlertProfileFail(state, action);

    case actionTypes.DELETE_ALERT_PROFILE_START:
      return deleteProfileStart(state, action);
    case actionTypes.DELETE_ALERT_PROFILE_SUCCESS:
      return deleteProfileSuccess(state, action.data);
    case actionTypes.DELETE_ALERT_PROFILE_FAIL:
      return deleteProfileFail(state, action);

    case actionTypes.EDIT_ALERT_PROFILE_START:
      return editProfileStart(state, action);
    case actionTypes.EDIT_ALERT_PROFILE_SUCCESS:
      return editProfileSuccess(state, action.data);
    case actionTypes.EDIT_ALERT_PROFILE_FAIL:
      return editProfileFail(state, action);
    case actionTypes.RESET_ALERT_PROFILE:
      return resetSuccess(state, action);

    default:
      return state;
  }
};

export default gatewayLogs;
