import React, { Component } from "react";
import { ModalHeader, ModalFooter, Button } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";

class LogoutModal extends Component {

  onSubmit = (e) => {
    this.props.logout()
    this.props.toggleModal()

  };

  render() {
    const { toggleModal } = this.props;
    return (
      <React.Fragment>
        {/* <form className="mb-3" name="formAssignUser" onSubmit={this.onSubmit}> */}
          <ModalHeader toggle={toggleModal}>Are you sure ?</ModalHeader>
          <ModalFooter>
            <Button color="info" type="submit" onClick={this.onSubmit}>
              Log out
            </Button>
            <Button color="secondary" type="button" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        {/* </form> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    getUserMetaStart: () => dispatch(actions.getUserMetaStart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutModal);
