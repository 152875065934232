import React from "react";
import "./Footer.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer-container">
      <p>
        &copy; {year} -{" "}
        <a href="https://www.lariantech.com">www.lariantech.com</a>{" "}
      </p>
    </footer>
  );
};

export default Footer;
