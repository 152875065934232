import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
import { showToast } from "../../helpers/toast";
// import { dispatch } from "d3-dispatch";

export const getNodeDevicesListStart = () => {
  return {
    type: actionTypes.GET_NODE_DEVICES_LIST_START,
  };
};

export const getNodeDevicesListSuccess = (data) => {
  return {
    type: actionTypes.GET_NODE_DEVICES_LIST_SUCCESS,
    data: data,
  };
};

export const getNodeDevicesListFail = (error) => {
  return {
    type: actionTypes.GET_NODE_DEVICES_LIST_FAIL,
    error: error,
  };
};

export const getNodeDevicesList = (
  page = 1,
  pageSize = 10,
  deviceId = undefined
) => {
  return (dispatch) => {
    dispatch(getNodeDevicesListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.otaDevices.list}`;
    url = url.replace("{id}", deviceId);
    // if (page) {
    //   url += `?page=${page}&pageSize=${pageSize}`;
    // }
    // else {
    url += "?page=1&pageSize=1000";
    // }
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getNodeDevicesListSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getNodeDevicesListFail(error.response.data.errors));
        }
      });
  };
};
