import React, { Component } from "react";
import { convertDateFormat } from "../../utils/helperFunctions";
import { MDBBtn, MDBDataTable } from "mdbreact";
import ConfirmationModal from "../Common/ConfirmationModal";
// import { Row, Col } from "reactstrap";
// import Pagination from "../Common/Pagination";
const columns = [
  {
    label: "Account ID",
    field: "id",
    sort: "asc",
    width: 70,
  },
  {
    label: "Company Name",
    field: "companyName",
    sort: "asc",
    width: 250,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 250,
  },
  {
    label: "Offline Alarm",
    field: "offlineAlarmEnable",
    sort: "asc",
    width: 150,
  },
  {
    label: "Alarm Sensor Name",
    field: "alarmSensorName",
    sort: "asc",
    width: 150,
  },

  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 250,
  },
  {
    label: "Voice Call",
    field: "editVoiceCall",
    width: 80,
  },
  {
    label: "Action",
    field: "action",
    width: 80,
  },
];
class AccountTable extends Component {
  state = {
    loadTable: false,
    accountList: [],
    pagination: {},
    showAlert: false,
    accountId: null,
  };

  componentDidMount() {
    const { accounts } = this.props;
    if (accounts && accounts.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.accounts !== this.props.accounts &&
      this.props.accounts &&
      this.props.accounts.length
    ) {
      this.showTable(true);
    }
  }

  modalAction = (action) => {
    const { deleteAccount } = this.props;
    const { accountId } = this.state;
    if (action === "confirm") {
      deleteAccount({ id: accountId });
    }

    this.setState({
      showAlert: false,
    });
  };

  showTable = (loadTable) => {
    if (loadTable) {
      const { accounts, pagination, updateVoiceCall } = this.props;
      const rows = accounts.map((gateway, index) => {
        let accountRow = { ...gateway };
        accountRow.createdAt = convertDateFormat(
          accountRow.createdAt,
          "shortDateTime"
        );
        accountRow.offlineAlarmEnable =
          accountRow.offlineAlarmEnable === 1 ? "Enabled" : "Disbaled";
        accountRow.editVoiceCall = (
          <>
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={updateVoiceCall.bind(this, accountRow)}
            >
              Voice Call
            </button>
          </>
        );
        accountRow.action = (
          <MDBBtn
            onClick={() => {
              this.setState({
                showAlert: true,
                accountId: accountRow.id,
              });
            }}
            tag="a"
            rounded
            size="sm"
            className="px-2 transparentBtn"
          >
            <i className="fas fa-trash mt-0"></i>
          </MDBBtn>
        );
        //   this.state.role === "super_admin" ? (
        //     <MDBBtn
        //       onClick={() => updateFirmware(gateway.gatewayId)}
        //       tag="a"
        //       rounded
        //       size="sm"
        //       className="px-2 transparentBtn"
        //     >
        //       <i className="fas fa-cog mt-0"></i>
        //     </MDBBtn>
        //   ) : (
        //     <>
        //       <MDBBtn
        //         onClick={deleteGateway.bind(this, accountRow.id)}
        //         tag="a"
        //         rounded
        //         size="sm"
        //         className="px-2 transparentBtn"
        //       >
        //         <i className="fas fa-trash mt-0"></i>
        //       </MDBBtn>
        //       <MDBBtn
        //         onClick={editGateway.bind(this, accountRow)}
        //         tag="a"
        //         rounded
        //         size="sm"
        //         className="px-2 transparentBtn"
        //       >
        //         <i className="fas fa-pencil-alt mt-0"></i>
        //       </MDBBtn>
        //     </>
        //   );
        return accountRow;
      });
      this.setState({
        loadTable,
        accountList: {
          columns,
          rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    // const { changePage } = this.props;
    const { loadTable, accountList } = this.state;

    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort"
              bordered
              data={accountList}
              paging={false}
              scrollX
            />
            {/* <Row className="mt-3 mb-3">
              <Col lg={6}>
                <label className="ml-3">
                  Showing {pagination.currentPage * 10 - 9} to{" "}
                  {pagination.currentPage * 10} of all results
                </label>
              </Col>
              <Col lg={6} className="text-right">
                <Pagination
                  pagination={pagination}
                  changePage={(page) => changePage(page)}
                ></Pagination>
              </Col>
            </Row> */}
          </>
        ) : null}
        <ConfirmationModal
          isOpen={this.state.showAlert}
          deleteName="Account"
          modalAction={this.modalAction}
        />
      </React.Fragment>
    );
  }
}

export default AccountTable;
