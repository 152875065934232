import React, { Component } from "react";
import {
  ModalHeader,
  ModalFooter,
  Button,
  ModalBody,
  TabPane,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  Input,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { Button as AntdBtn, Modal } from "antd";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";
import { dispatch } from "d3";
import ConfirmationModal from "../Common/ConfirmationModal";

class ToggleTempModal extends Component {
  state = {
    openConfirmationModal: false,
    tempState: this.props.temperatureUnit,
    activeTab: 1,
    offlineAlarmsEnable: true,
    checked: false,
    savedApiKey: "",
    alarmFrequency: {
      id: "",
      name: "",
    },
    alarmFrequencyData: [
      {
        id: 30,
        name: "30 min",
      },
      {
        id: 60,
        name: "1 hr",
      },
      {
        id: 90,
        name: "1.5 hr",
      },
      {
        id: 120,
        name: "2 hr",
      },
    ],
  };

  onSubmit = () => {
    const payload = {
      offlineAlarmEnable: this.state.offlineAlarmsEnable ? 1 : 0,
      maxAlarms: this.state.checked ? 1 : 24,
      alarmFrequency: this.state.alarmFrequency.id || 60,
    };
    return dispatch(this.props.updateOfflineAlarm(payload));
  };

  handleAlarmFrequency = (alarmFrequency) => {
    this.setState({ alarmFrequency });
  };

  componentDidMount() {
    this.props.getOfflineAlarmDetails();
    this.props.generateAccountAPI();

    if (this.props.account.apiKey.apiKey) {
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.offlineAlarmsStatus !== this.props.offlineAlarmsStatus &&
      this.props.offlineAlarmsStatus
    ) {
      showToast("Offline Alarms updated Successfully", "success");
      setTimeout(() => {
        this.props.toggleModal();
      }, 1000);
    }
    if (
      prevProps.offlineAlarmsDetails !== this.props.offlineAlarmsDetails &&
      this.props.offlineAlarmsDetails
    ) {
      const data = this.state.alarmFrequencyData.filter(
        (val) => val.id === this.props.offlineAlarmsDetails.alarmFrequency
      );
      this.setState({
        offlineAlarmsEnable: !!this.props.offlineAlarmsDetails
          .offlineAlarmEnable,
        checked:
          this.props.offlineAlarmsDetails.maxAlarms === 24 ? false : true,
        alarmFrequency: data.length ? data[0] : { id: "", name: "" },
      });
    }
    if (prevProps.apiKey !== this.props.apiKey && this.props.apiKey) {
      this.getAPIKey();
    }
  }

  getAPIKey() {
    const { apiKey } = this.props;
    if (apiKey) {
      this.setState({ savedApiKey: apiKey || "" });
    } else this.props.createAccountAPI();
  }

  componentWillUnmount() {
    this.props.resetAll();
  }

  render() {
    const { toggleModal, modalData, account } = this.props;
    const { loading, apiKey } = account;

    return (
      <React.Fragment>
        <Modal
          isOpen={!!apiKey.apiKey}
          toggle={this.toggleModal}
          size="md"
          centered={true}
        >
          <ModalHeader>API Key Generation Successful</ModalHeader>
          <ModalBody>Your account API key is {apiKey}</ModalBody>
        </Modal>

        <ModalHeader toggle={toggleModal}>Settings</ModalHeader>
        <ModalBody>
          <div>
            <Nav tabs className="border-0 mb-4 justify-content-center">
              <NavItem>
                <NavLink
                  className={
                    this.state.activeTab === 1
                      ? "active rounded-left"
                      : "rounded-left"
                  }
                  onClick={() =>
                    this.setState({
                      activeTab: 1,
                    })
                  }
                >
                  Change Temperature
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === 2 ? "active" : ""}
                  onClick={() =>
                    this.setState({
                      activeTab: 2,
                    })
                  }
                >
                  Update Offline Alarms
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    this.state.activeTab === 3
                      ? "active rounded-right"
                      : "rounded-right"
                  }
                  onClick={() =>
                    this.setState({
                      activeTab: 3,
                    })
                  }
                >
                  API Key
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab} className="border-0">
              <TabPane tabId={1}>
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 row mb-2 mb-lg-0 justify-content-center">
                      <div>
                        <div className="enableCheckbox d-flex justify-content-start algin-items-center flex-column form-group pt-2">
                          <h6 className="text-muted text-center">
                            Change Temperature
                          </h6>

                          <div className="d-flex justify-content-center">
                            <Button
                              className="m-1"
                              color={
                                this.state.tempState !== "Fahrenheit"
                                  ? "primary"
                                  : "secondary"
                              }
                              type="button"
                              onClick={() => {
                                this.setState({
                                  tempState: "Celsius",
                                });
                              }}
                            >
                              Celsius
                            </Button>
                            <Button
                              className="m-1"
                              color={
                                this.state.tempState === "Fahrenheit"
                                  ? "primary"
                                  : "secondary"
                              }
                              type="button"
                              onClick={() => {
                                this.setState({
                                  tempState: "Fahrenheit",
                                });
                              }}
                            >
                              Fahrenheit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={2}>
                <div className="d-flex justify-content-around">
                  <div className="enableCheckbox d-flex justify-content-start algin-items-center flex-column form-group pt-2">
                    <h6 className="text-muted text-center">Offline Alarms</h6>
                    <p className="pt-2">
                      <label className="text-muted">Disable</label>
                      <Input
                        type="checkbox"
                        id="offlineAlarmsEnable"
                        name="offlineAlarmsEnable"
                        checked={this.state.offlineAlarmsEnable}
                        onChange={() => {
                          this.setState({
                            offlineAlarmsEnable: !this.state
                              .offlineAlarmsEnable,
                          });
                        }}
                      />
                      <label
                        htmlFor="offlineAlarmsEnable"
                        className="checkbox-label"
                      >
                        <span className="ui" />
                      </label>
                      <label className="text-muted">Enable</label>
                    </p>
                  </div>
                  {/* <div className="text-xLarge text-left">Alarm Duration :</div>
          <div
            className="col-sm-12 d-flex mb-3"
            style={{ alignItems: "center" }}
          >
            <div className="col-sm-5"></div>
            <div
              className="d-flex"
              style={{ alignItems: "center", width: "100%" }}
            >
              <span className="mr-2 text-large" style={{ width: "25%" }}>
                One Time
              </span>
              <Switch
                onChange={() => {
                  this.setState({
                    checked: !this.state.checked,
                  });
                }}
                checked={this.state.checked}
                height={32}
                width={68}
                disabled={!this.state.offlineAlarmsEnable}
              />
            </div>
          </div> */}
                  <div className="enableCheckbox d-flex justify-content-start algin-items-center flex-column form-group pt-2">
                    <h6 className="text-muted text-center">Alarm Duration</h6>
                    <p className="pt-2">
                      <label className="text-muted">One Time</label>
                      <Input
                        type="checkbox"
                        id="checked"
                        name="checked"
                        checked={this.state.checked}
                        onChange={() => {
                          this.setState({
                            checked: !this.state.checked,
                          });
                        }}
                        disabled={!this.state.offlineAlarmsEnable}
                      />
                      <label htmlFor="checked" className="checkbox-label">
                        <span className="ui" />
                      </label>
                      {/* <label className="text-muted">Enable</label> */}
                    </p>
                  </div>
                </div>
                {/* <div className="text-xLarge text-left">Alarm Frequency :</div> */}
                <h6 className="text-muted text-center">Alarm Frequency</h6>
                <div
                  className="mb-2 form-group"
                  style={{
                    marginLeft: "15%",
                    marginRight: "15%",
                    marginTop: "4%",
                  }}
                >
                  <Select
                    maxMenuHeight={170}
                    styles={selectStyle}
                    name="frequency"
                    isDisabled={
                      this.state.checked || !this.state.offlineAlarmsEnable
                    }
                    value={this.state.alarmFrequency}
                    onChange={this.handleAlarmFrequency}
                    options={this.state.alarmFrequencyData}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option}`}
                  />
                </div>
              </TabPane>
              <TabPane tabId={3}>
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 row mb-2 mb-lg-0 justify-content-center">
                      <div style={{ display: "grid" }}>
                        <AntdBtn
                          className="btn btn-primary"
                          type="button"
                          onClick={() =>
                            this.setState({
                              openConfirmationModal: true,
                            })
                          }
                          loading={loading}
                          style={{ width: "21rem" }}
                          title="clicking on this button will generate a new API key"
                        >
                          Generate API Key
                        </AntdBtn>
                        {this.state.savedApiKey &&
                          this.state.savedApiKey.apiKey && (
                            <Card
                              style={{
                                border: "1px solid #79797a",
                                borderRadius: "0px",
                                backgroundColor: "#000 !important",
                                padding: "0.75rem",
                                paddingBottom: "0px",
                                marginTop: "1rem",
                                width: "21rem",
                              }}
                            >
                              <CardBody>
                                <CardTitle tag="h5">
                                  {this.state.savedApiKey.apiKey}
                                </CardTitle>
                              </CardBody>
                            </Card>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </ModalBody>

        <ModalFooter>
          {this.state.activeTab === 1 && (
            <Button
              color="primary"
              type="button"
              onClick={() => {
                modalData.toggleTemp(this.state.tempState);
                this.props.getRecentAlarmList();
                toggleModal();
              }}
            >
              Save
            </Button>
          )}
          {this.state.activeTab === 2 && (
            <Button color="info" type="submit" onClick={this.onSubmit}>
              Submit
            </Button>
          )}
        </ModalFooter>
        <ConfirmationModal
          isOpen={this.state.openConfirmationModal}
          modalAction={(action) => {
            if (action === "confirm") this.props.createAccountAPI();
            this.setState({ openConfirmationModal: false });
          }}
          modalBody="Clicking on Submit will generate a new key"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    temperatureUnit: state.auth.temperatureUnit,
    offlineAlarmsStatus: state.OfflineAlarms.offlineAlarmsStatus,
    metaData: state.dashboard.dashboard,
    offlineAlarmsDetails: state.OfflineAlarms.offlineAlarmsDetails,
    account: state.account,
    apiKey: state.account.apiKey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserMetaStart: () => dispatch(actions.getUserMetaStart()),
    getRecentAlarmList: () => dispatch(actions.getRecentAlarmList()),
    updateOfflineAlarm: (payload) =>
      dispatch(actions.updateOfflineAlarms(payload)),
    getOfflineAlarmDetails: () => dispatch(actions.getOADetails()),
    generateAccountAPI: () => dispatch(actions.generateAccountAPI()),
    createAccountAPI: () => dispatch(actions.createAccountAPI()),
    resetAll: () => dispatch(actions.resetAlarms()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleTempModal);
