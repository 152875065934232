import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";

export const getReportListStart = () => {
  return {
    type: actionTypes.GET_REPORT_LIST_START,
  };
};

export const getReportListSuccess = (data) => {
  return {
    type: actionTypes.GET_REPORT_LIST_SUCCESS,
    data: data,
  };
};

export const getReportListFail = (error) => {
  return {
    type: actionTypes.GET_REPORT_LIST_FAIL,
    error: error,
  };
};

export const deleteReportStart = () => {
  return {
    type: actionTypes.DELETE_REPORT_START,
  };
};

export const deleteReportSuccess = (data) => {
  return {
    type: actionTypes.DELETE_REPORT_SUCCESS,
    data: data,
  };
};

export const deleteReportFail = (error) => {
  return {
    type: actionTypes.DELETE_REPORT_FAIL,
    error: error,
  };
};

export const addReportStart = () => {
  return {
    type: actionTypes.ADD_REPORT_START,
  };
};

export const addReportSuccess = (data) => {
  return {
    type: actionTypes.ADD_REPORT_SUCCESS,
    data: data,
  };
};

export const addReportFail = (error) => {
  return {
    type: actionTypes.ADD_REPORT_FAIL,
    error: error,
  };
};

export const editReportStart = () => {
  return {
    type: actionTypes.EDIT_REPORT_START,
  };
};

export const editReportSuccess = (data) => {
  return {
    type: actionTypes.EDIT_REPORT_SUCCESS,
    data: data,
  };
};

export const editReportFail = (error) => {
  return {
    type: actionTypes.EDIT_REPORT_FAIL,
    error: error,
  };
};

export const getReportList = (page = 1, reportId = undefined) => {
  return (dispatch) => {
    dispatch(getReportListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.report.list}`;
    url = url.replace("{id}", reportId);
    if (page) {
      url += `?page=${page}&pageSize=10`;
    } else {
      url += "?page=1&pageSize=1000";
    }

    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getReportListSuccess(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getReportListFail(error.response.data.errors));
        }
      });
  };
};

export const deleteReport = ({ id }) => {
  return (dispatch) => {
    dispatch(deleteReportStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.report.delete}`;
    url = url.replace("{id}", id);
    axios
      .delete(url, { headers })
      .then((response) => {
        dispatch(deleteReportSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(deleteReportFail(error.response.data.errors));
        }
      });
  };
};

export const addReport = (payload) => {
  return (dispatch) => {
    dispatch(addReportStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.report.add}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        let reportby;
        if (payload.type === "optional") {
          reportby = 1;
        } else {
          reportby = 0;
        }
        dispatch(addReportSuccess(response.data, reportby));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(addReportFail(error.response.data.errors));
        }
      });
  };
};

export const editReport = ({ payload, id }) => {
  return (dispatch) => {
    dispatch(editReportStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.report.edit}`;
    url = url.replace("{id}", id);

    axios
      .put(url, payload, { headers })
      .then((response) => {
        dispatch(editReportSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(editReportFail(error.response.data.errors));
        }
      });
  };
};

export const getOptionalReportListStart = () => {
  return {
    type: actionTypes.GET_OPTIONAL_REPORT_LIST_START,
  };
};

export const getOptionalReportListSuccess = (data) => {
  return {
    type: actionTypes.GET_OPTIONAL_REPORT_LIST_SUCCESS,
    data: data,
  };
};

export const getOptionalReportListFail = (error) => {
  return {
    type: actionTypes.GET_OPTIONAL_REPORT_LIST_FAIL,
    error: error,
  };
};

export const getOptionalReportList = (page = 1, reportId = undefined) => {
  return (dispatch) => {
    dispatch(getOptionalReportListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.report.list}?type=optional`;
    url = url.replace("{id}", reportId);
    if (page) {
      url += `&page=${page}&pageSize=10`;
    } else {
      url += "&page=1&pageSize=1000";
    }

    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getOptionalReportListSuccess(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getOptionalReportListFail(error.response.data.errors));
        }
      });
  };
};
