import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  data: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const getUserMessageTemplatesStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    data: [],
    meta: {},
    loading: true,
  });
};

const getUserMessageTemplatesSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getUserMessageTemplatesFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    data: [],
    meta: {},
    loading: false,
  });
};

const addMessageTemplateStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const addMessageTemplateSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    success: "Template added successfully.",
  });
};

const addMessageTemplateFail = (state, action) => {
  return updateObject(state, {
    error:
      action.error[0].msg ? action.error[0].message: action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};


const editMessageTemplateStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const editMessageTemplateSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    success: "Template edited successfully.",
  });
};

const editMessageTemplateFail = (state, action) => {
  return updateObject(state, {
    error:
      action.error[0].msg ? action.error[0].message: action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};

const deleteMessageTemplateStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success: undefined,
  });
};

const deleteMessageTemplateSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    success: "Template deleted successfully.",
  });
};

const deleteMessageTemplateFail = (state, action) => {
  return updateObject(state, {
    error:
      action.error[0].msg ? action.error[0].message: action.error[0].msg,
    loading: false,
    reload: false,
    success: undefined,
  });
};


const userMessageTemplates = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_MESSAGE_TEMPLATE_START:
      return getUserMessageTemplatesStart(state, action);
    case actionTypes.GET_USER_MESSAGE_TEMPLATE_SUCCESS:
      return getUserMessageTemplatesSuccess(state, action.data);
    case actionTypes.GET_USER_MESSAGE_TEMPLATE_FAIL:
      return getUserMessageTemplatesFail(state, action);
    case actionTypes.ADD_MESSAGE_TEMPLATE_START:
      return addMessageTemplateStart(state, action);
    case actionTypes.ADD_MESSAGE_TEMPLATE_SUCCESS:
      return addMessageTemplateSuccess(state, action.data);
    case actionTypes.ADD_MESSAGE_TEMPLATE_FAIL:
      return addMessageTemplateFail(state, action);
    case actionTypes.EDIT_MESSAGE_TEMPLATE_START:
      return editMessageTemplateStart(state, action);
    case actionTypes.EDIT_MESSAGE_TEMPLATE_SUCCESS:
      return editMessageTemplateSuccess(state, action.data);
    case actionTypes.EDIT_MESSAGE_TEMPLATE_FAIL:
      return editMessageTemplateFail(state, action);
    case actionTypes.DELETE_MESSAGE_TEMPLATE_START:
      return deleteMessageTemplateStart(state, action);
    case actionTypes.DELETE_MESSAGE_TEMPLATE_SUCCESS:
      return deleteMessageTemplateSuccess(state, action.data);
    case actionTypes.DELETE_MESSAGE_TEMPLATE_FAIL:
      return deleteMessageTemplateFail(state, action);
    default:
      return state;
  }
};

export default userMessageTemplates;
