export const selectStyle = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "var(--dropdown-bg)",
    borderColor: "var(--dropdown-border-bg)",
    color: "var(--secondary)",
  }),
  input: (styles) => ({ ...styles, border: "none" }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid var(--dropdown-separator)",
    backgroundColor: "var(--dropdown-bg)",
    color: "var(--secondary)",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "var(--secondary)" };
  },
};
