import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  alarmsList: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const getAlarmsListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    alarmsList: [],
    meta: {},
    loading: true,
  });
};

const getAlarmsListSuccess = (state, action) => {
  return updateObject(state, {
    alarmsList: action.alarmsList,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getAlarmsListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    alarmsList: [],
    meta: {},
    loading: false,
  });
};


const Alarms = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALARMS_LIST_START:
      return getAlarmsListStart(state, action);
    case actionTypes.GET_ALARMS_LIST_SUCCESS:
      return getAlarmsListSuccess(state, action.data);
    case actionTypes.GET_ALARMS_LIST_FAIL:
      return getAlarmsListFail(state, action);
    
    default:
      return state;
  }
};

export default Alarms;
