import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import ContentWrapper from "../Layout/ContentWrapper";
import { Container, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import CardTool from "../Common/CardTool";
import AccountTable from "./accountTable.js";
class Gateways extends Component {
  componentDidMount() {
    const { getAccountList } = this.props;
    getAccountList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    console.log("===== prevProps ", prevProps);
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.props.getAccountList();
    }
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");
      this.props.getAccountList();
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName,
      modalData,
    });
  };

  editGateway = (gateway) => {
    this.showModal("addEditGateway", { gateway });
  };

  addGateway = () => {
    this.showModal("addEditGateway");
  };

  deleteGateway = (id) => {
    this.showModal("deleteGateway", { id });
  };
  updateFirmware = (id) => {
    this.showModal("updatefirmware", { id });
  };
  uploadFirmware = () => {
    this.showModal("uploadFirmware");
  };
  changePage = (page) => {
    this.props.getAccountList(page);
  };

  updateVoiceCall = (account) => {
    this.showModal("editVoiceCall", { account });
  };

  render() {
    const { account: accountList, loading } = this.props;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Accounts</div>
        </div>

        <Container fluid>
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>Accounts</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <CardBody>
              {accountList && accountList.length > 0 && (
                <AccountTable
                  accounts={accountList}
                  updateVoiceCall={this.updateVoiceCall}
                  deleteAccount={this.props.deleteAccount}
                ></AccountTable>
              )}
              {loading ? <h3 className="text-center">Loading...</h3> : ""}
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.data,
    loading: state.account.loading,
    error: state.account.error,
    voice_call_success: state.account.voice_call_success,
    voice_call_loading: state.account.voice_call_loading,
    voice_call_fail: state.account.voice_call_fail,
    success: state.account.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountList: (data) => dispatch(actions.getAccountList(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
    deleteAccount: (data) => dispatch(actions.deleteAccount(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gateways);
