import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utilities';

const initialState = {
    subUsersList: [],
    meta: {},
    error: undefined,
    success: undefined,
    loading: false,
    reload: false,
    userIds : [],
};


const getSubUserListStart = (state, action) => {
    return updateObject(state, {
        error: undefined,
        subUsersList: [],
        meta: {},
        loading: true
    });
};

const getSubUserListSuccess = (state, action) => {
    return updateObject(state, {
        subUsersList: action.userList,
        meta: action.meta,
        error: undefined,
        loading: false
    });
};

const getSubUserListFail = (state, action) => {
    return updateObject(state, {
        error: action.error[0].message,
        subUsersList: [],
        meta: {},
        loading: false
    });
};


const users = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SUB_USER_LIST_START: return getSubUserListStart(state, action);
        case actionTypes.GET_SUB_USER_LIST_SUCCESS: return getSubUserListSuccess(state, action.data);
        case actionTypes.GET_SUB_USER_LIST_FAIL: return getSubUserListFail(state, action);
        default:
            return state;
    }
};

export default users;