import React, { Component } from "react";
import { convertDateFormat } from "../../utils/helperFunctions";
import { MDBBtn, MDBDataTable } from "mdbreact";
import { Row, Col } from "reactstrap";
import Pagination from "../Common/Pagination";
const columns = [
  {
    label: "Gateway ID",
    field: "gatewayId",
    sort: "asc",
    width: 250,
  },
  {
    label: "Gateway Name",
    field: "name",
    sort: "asc",
    width: 250,
  },
  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 250,
  },
  {
    label: "Action",
    field: "action",
    width: 80,
  },
];
const superAdminColumn = [
  {
    label: "Gateway Id",
    field: "gatewayId",
    sort: "asc",
    width: 150,
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 200,
  },
  {
    label: "ESP 32 Last",
    field: "esp32LastVersion",
    sort: "asc",
    width: 100,
  },
  {
    label: "ESP 32 New",
    field: "esp32NewVersion",
    sort: "asc",
    width: 100,
  },
  {
    label: "Lora Last",
    field: "loraLastVersion",
    sort: "asc",
    width: 100,
  },
  {
    label: "Lora New",
    field: "loraNewVersion",
    sort: "asc",
    width: 100,
  },
  {
    label: "Nexation Last",
    field: "nextionLastVersion",
    sort: "asc",
    width: 100,
  },
  {
    label: "Nexation New",
    field: "nextionNewVersion",
    sort: "asc",
    width: 100,
  },
  {
    label: "LoRa OTA status",
    field: "loraMainStatus",
    sort: "asc",
    width: 100,
  },
  {
    label: "Main ESP OTA status",
    field: "mainOtaStatus",
    sort: "asc",
    width: 100,
  },
  {
    label: "Nextion OTA status",
    field: "nextionStatus",
    sort: "asc",
    width: 100,
  },
  {
    label: "Action",
    field: "action",
    width: 80,
  },
];
class GatewayTable extends Component {
  state = {
    loadTable: false,
    gatewayList: [],
    pagination: {},
    role: localStorage.getItem("role"),
  };

  componentDidMount() {
    const { gateways } = this.props;
    if (gateways && gateways.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.gateways !== this.props.gateways &&
      this.props.gateways &&
      this.props.gateways.length
    ) {
      this.showTable(true);
    }
  }

  showTable = (loadTable) => {
    if (loadTable) {
      const {
        gateways,
        editGateway,
        deleteGateway,
        pagination,
        updateFirmware,
      } = this.props;
      const rows = gateways.map((gateway, index) => {
        let gatewayRow = { ...gateway };
        delete gatewayRow["userId"];
        gatewayRow.createdAt = convertDateFormat(
          gatewayRow.createdAt,
          "shortDateTime"
        );
        gatewayRow.action =
          this.state.role === "super_admin" ? (
            <MDBBtn
              onClick={() => updateFirmware(gateway.gatewayId)}
              tag="a"
              rounded
              size="sm"
              className="px-2 transparentBtn"
            >
              <i className="fas fa-cog mt-0"></i>
            </MDBBtn>
          ) : (
            <>
              <MDBBtn
                onClick={deleteGateway.bind(this, gatewayRow.id)}
                tag="a"
                rounded
                size="sm"
                className="px-2 transparentBtn"
              >
                <i className="fas fa-trash mt-0"></i>
              </MDBBtn>
              <MDBBtn
                onClick={editGateway.bind(this, gatewayRow)}
                tag="a"
                rounded
                size="sm"
                className="px-2 transparentBtn"
              >
                <i className="fas fa-pencil-alt mt-0"></i>
              </MDBBtn>
            </>
          );
        return gatewayRow;
      });
      this.setState({
        loadTable,
        gatewayList: {
          columns:
            this.state.role === "super_admin" ? superAdminColumn : columns,
          rows: rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { changePage } = this.props;
    const { loadTable, gatewayList, pagination } = this.state;

    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort table-pagination"
              bordered
              data={gatewayList}
              paging={true}
              scrollX
            />
            {/* <Row className="mt-3 mb-3">
              <Col lg={6}>
                <label className="ml-3">
                  Showing {pagination.currentPage * 10 - 9} to{" "}
                  {pagination.currentPage * 10} of all results
                </label>
              </Col>
              <Col lg={6} className="text-right">
                <Pagination
                  pagination={pagination}
                  changePage={(page) => changePage(page)}
                ></Pagination>
              </Col>
            </Row> */}
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default GatewayTable;
