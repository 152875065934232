import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import { withNamespaces, Trans } from "react-i18next";
import ContentWrapper from "../Layout/ContentWrapper";
import { Container, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import CardTool from "../Common/CardTool";
import GatewayTable from "./GatewayTable";
class Gateways extends Component {
  componentDidMount() {
    const { getGatewayList } = this.props;
    getGatewayList(1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.props.getGatewayList(1);
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName,
      modalData,
    });
  };

  editGateway = (gateway) => {
    this.showModal("addEditGateway", { gateway });
  };

  addGateway = () => {
    this.showModal("addEditGateway");
  };

  deleteGateway = (id) => {
    this.showModal("deleteGateway", { id });
  };
  updateFirmware = (id) => {
    this.showModal("updatefirmware", { id });
  };
  uploadFirmware = () => {
    this.showModal("uploadFirmware");
  };
  changePage = (page) => {
    this.props.getGatewayList(page);
  };

  render() {
    const { gatewayList, meta, loading } = this.props;
    const role = localStorage.getItem("role");
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Gateways
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          <div className="d-flex p-3 justify-content-end">
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.props.downloadGatways}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Download Gateways List
            </button>
            {role === "super_admin" ? (
              <>
                <button
                  className="mr-2 float-right btn-labeled btn btn-info"
                  onClick={() => this.props.getGatewayList(1)}
                >
                  Refresh Table
                </button>
                <button
                  className="mr-2 float-right btn-labeled btn btn-info"
                  onClick={this.uploadFirmware.bind(this)}
                >
                  <span className="btn-label">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                  </span>
                  Upload FW
                </button>
              </>
            ) : (
              <button
                className="mr-2 float-right btn-labeled btn btn-info"
                onClick={this.addGateway.bind(this)}
              >
                <span className="btn-label">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </span>
                Add
              </button>
            )}
          </div>
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>Gateways</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <div
              style={{
                height: "1px",
                width: "100%",
                background: "#ccc",
              }}
            />
            <CardBody>
              {gatewayList && gatewayList.length > 0 && (
                <GatewayTable
                  pagination={meta.pagination}
                  gateways={gatewayList}
                  editGateway={this.editGateway}
                  deleteGateway={this.deleteGateway}
                  changePage={(page) => this.changePage(page)}
                  updateFirmware={this.updateFirmware}
                ></GatewayTable>
              )}
              {loading ? <h3 className="text-center">Loading...</h3> : ""}
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.gateways.loading,
    gatewayList: state.gateways.gatewayList,
    meta: state.gateways.meta,
    error: state.gateways.error,
    success: state.gateways.success,
    reload: state.gateways.reload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGatewayList: (data) => dispatch(actions.getGatewayList(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
    downloadGatways: () => dispatch(actions.downloadGatways()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(Gateways));
