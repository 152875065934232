import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import { withNamespaces, Trans } from "react-i18next";
import ContentWrapper from "../Layout/ContentWrapper";
import { Container, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import CardTool from "../Common/CardTool";
import DeviceTable from "./DeviceTable";
class OtaDevices extends Component {
  componentDidMount() {
    const { getNodeDevicesList } = this.props;
    getNodeDevicesList(1, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.props.getNodeDevicesList(1, 10);
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName,
      modalData,
    });
  };

  updateFirmware = (id) => {
    this.showModal("updatefirmware", { id: id, Node_Devices: "Node_Devices" });
  };
  uploadFirmware = () => {
    this.showModal("uploadFirmware", "Node_Devices");
  };
  changePage = (page) => {
    this.props.getNodeDevicesList(page);
  };
  showLinkedAccountDetails = (device) => {
    this.showModal("showAssociatedGateways", { deviceDetail: device });
  };

  render() {
    const { deviceList, meta, loading } = this.props;
    const role = localStorage.getItem("role");
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            OtaDevices
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          <div className="d-flex p-3 justify-content-end">
            {/* {role === 'super_admin' ? ( */}
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.uploadFirmware.bind(this)}
            >
              <span className="btn-label">
                <i className="fa fa-upload" aria-hidden="true"></i>
              </span>
              Upload FW
            </button>
            {/* // ) : (
            //   <button 
            //     className="mr-2 float-right btn-labeled btn btn-info"
            //     onClick={this.addGateway.bind(this)}
            //   >
            //     <span className="btn-label">
            //       <i className="fa fa-plus" aria-hidden="true"></i>
            //     </span>
            //     Add
            //   </button>
            // )}*/}
          </div>
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>OTA Devices List</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <CardBody>
              {deviceList && deviceList.length > 0 && (
                <DeviceTable
                  pagination={meta.pagination}
                  devices={deviceList}
                  //   editGateway={this.editGateway}
                  //   deleteGateway={this.deleteGateway}
                  changePage={(page) => this.changePage(page)}
                  updateFirmware={this.updateFirmware}
                  showLinkedAccountDetails={this.showLinkedAccountDetails}
                ></DeviceTable>
              )}
              {!!loading && <h3 className="text-center">Loading...</h3>}
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.otaDevices.loading,
    deviceList: state.otaDevices.deviceList,
    meta: state.otaDevices.meta,
    error: state.gateways.error,
    success: state.gateways.success,
    reload: state.gateways.reload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNodeDevicesList: (data) => dispatch(actions.getNodeDevicesList(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(OtaDevices));
