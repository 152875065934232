import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import { showToast } from "../../../helpers/toast.js";
import { Row, Col, Button, Tooltip } from "reactstrap";
import "../LocationsDevices.scss";
import { convertDateFormat } from "../../../utils/helperFunctions";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import moment from "moment";
import timezoneMoment from "moment-timezone";
import "./index.styles.scss";
import "../DeviceStatus/index.styles.scss";

let socketing;
class Content extends Component {
  constructor(props) {
    super(props);
    this.locationsRef = React.createRef();
  }
  state = {
    showConfirmationModal: false,
    deleteId: "",
    deleteType: "",
    showLocations: true,
    showDevices: false,
    locations: [],
    devices: [],
    allDevices: [],
    selectedLocation:
      JSON.parse(localStorage.getItem("selectedLocation")) || {},
    selectedDevice: JSON.parse(localStorage.getItem("selectedDevice")) || {},
    editingLocation: {},
    editingDevice: {},
    basicData: [],
    activeStatus: [],
    alarmsData: [],
    removeAlarm: [],
  };

  componentDidMount() {
    const { getLocations, getAllDevices } = this.props;
    getLocations(0);
    getAllDevices();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevProps.basicData !== this.props.basicData) {
      this.setState({
        ...this.state,
        basicData: this.props.basicData,
      });
    }
    if (prevProps.activeStatus !== this.props.activeStatus) {
      this.setState({
        ...this.state,
        activeStatus: this.props.activeStatus,
      });
    }
    if (prevProps.alarmsData !== this.props.alarmsData) {
      this.setState({
        ...this.state,
        alarmsData: this.props.alarmsData,
      });
    }
    if (prevProps.removeAlarm !== this.props.removeAlarm) {
      this.setState({
        ...this.state,
        removeAlarm: this.props.removeAlarm,
      });
    }
    if (
      prevProps.locations !== this.props.locations &&
      this.props.locations.length
    ) {
      this.renderLocations(true, this.props.locations);
    }
    if (
      prevProps.reloadLocations !== this.props.reloadLocations &&
      this.props.reloadLocations
    ) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.props.getLocations(0);
    }
    if (
      prevProps.reloadDevices !== this.props.reloadDevices &&
      this.props.reloadDevices
    ) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.getLocationDevices(this.state.selectedLocation);
    }

    if (prevProps.devices !== this.props.devices) {
      this.renderDevices(true, this.props.devices);
    }

    if (
      prevProps.deviceSaved !== this.props.deviceSaved &&
      this.props.deviceSaved
    ) {
      this.saveDevice();
    }

    if (prevProps.timeValue !== this.props.timeValue) {
      this.changeTimeValue(this.props.timeValue);
    }

    if (
      prevProps.clickFromRecentAlarms !== this.props.clickFromRecentAlarms &&
      this.props.clickFromRecentAlarms
    ) {
      this.setState({
        selectedDevice: JSON.parse(localStorage.getItem("selectedDevice")),
        selectedLocation: JSON.parse(localStorage.getItem("selectedLocation")),
      });
      this.props.getLocationDevices({
        page: 0,
        locationId: JSON.parse(localStorage.getItem("selectedLocation")).id,
      });
      this.props.getDeviceAlarmList({
        id: JSON.parse(localStorage.getItem("selectedDevice")).id,
      });
    }
  }

  editLocation = (index) => {
    this.setState({
      locations: this.state.locations.map((location, i) =>
        index === i
          ? { ...location, editing: true }
          : { ...location, editing: false }
      ),
      editingLocation: this.state.locations[index],
    });
  };

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName: modalName,
      modalData: modalData,
    });
  };

  confirmDelete = (deleteId, deleteType) => {
    this.setState({
      showConfirmationModal: true,
      deleteId,
      deleteType,
    });
  };

  renderLocations = (showLocations, locations = []) => {
    if (showLocations) {
      const rows = locations.map((location, index) => {
        let locationRow = { ...location };
        locationRow.editing = false;
        return locationRow;
      });
      this.setState({ locations: rows, showLocations });
      if (!this.props.clickFromRecentAlarms) {
        this.getLocationDevices(rows[0]);
      }
    } else {
      this.setState({ showLocations });
    }
  };

  handleLocationChange = (index) => {
    this.props.updateRecentAlarmsEvent(false);
    this.setState({
      locations: this.state.locations.map((location, i) =>
        index === i ? { ...location, name: this.locationInput.value } : location
      ),
    });
  };

  onLocationSubmit = (event, index) => {
    event.preventDefault();
    const { editLocation, addLocation } = this.props;
    const { locations } = this.state;
    if (!locations[index].name.trim()) {
      showToast("Location is Required", "error");
    } else if (index >= 0 && locations[index].name) {
      if (locations[index].name.length > 40) {
        showToast(
          "Location name shouldn't be longer than 40 characters",
          "error"
        );
        return false;
      }
      const payload = {
        name: locations[index].name,
      };
      const id = locations[index].id;
      if (id) {
        editLocation({ payload, id });
      } else {
        addLocation(payload);
      }
    }
  };

  addLocation = () => {
    const { locations } = this.state;
    const editingAlready = locations.some(
      (location) => location["editing"] === true
    );
    if (!editingAlready) {
      let locationsArr = [...locations];
      locationsArr.push({ name: "", editing: true });
      this.setState({ locations: locationsArr }, () => {
        this.scrollLocationsToBottom();
      });
    }
  };

  scrollLocationsToBottom = () => {
    this.locationsRef.current.scrollTop = this.locationsRef.current.scrollHeight;
  };

  getLocationDevices = (location) => {
    const { getLocationDevices } = this.props;

    const id = location.id;

    this.setState({ showDevices: true, selectedLocation: location }, () => {
      localStorage.setItem("selectedLocation", JSON.stringify(location));
    });

    getLocationDevices({ page: 0, locationId: id });
  };

  assignUser = (device) => {
    this.showModal("assignUser", { device: device });
  };

  addDevice = () => {
    this.showModal("addDeviceLocation", { id: this.state.selectedLocation.id });
  };

  renderDevices = (showDevices, devices = []) => {
    const {
      getGraphData,
      // rawDataInterval,
      getRawData,
      getDeviceAlarmList,
      disconnectStream,
      timeValue,
      startDate,
      updateRawDataInterval,
      endDate,
    } = this.props;

    if (showDevices) {
      if (devices.length) {
        const rows = devices.map((location, index) => {
          let deviceRow = { ...location };
          deviceRow.editing = false;
          return deviceRow;
        });
        if (!this.props.clickFromRecentAlarms) {
          this.props.getDeviceData({ deviceId: rows[0].deviceId });
          this.setState(
            { devices: rows, showDevices: true, selectedDevice: rows[0] },
            () => {
              localStorage.setItem("selectedDevice", JSON.stringify(rows[0]));
              this.connectStreamForDevice(rows[0]);
              updateRawDataInterval(0.5);
              if (timeValue === 6 && !!startDate && !!endDate) {
                getGraphData({
                  page: 0,
                  deviceId: rows[0].deviceId,
                  timeValue,
                  startDate: moment(startDate).format("YYYY-MM-DD"),
                  endDate: moment(endDate).format("YYYY-MM-DD"),
                  timezone: timezoneMoment.tz.guess(),
                });
                getRawData({
                  timeDiff: 1,
                  page: 1,
                  deviceId: rows[0].deviceId,
                  timeValue,
                  startDate: moment(startDate).format("YYYY-MM-DD"),
                  endDate: moment(endDate).format("YYYY-MM-DD"),
                  timezone: timezoneMoment.tz.guess(),
                });
              } else {
                getGraphData({
                  page: 0,
                  deviceId: rows[0].deviceId,
                  timeValue,
                });
                getRawData({
                  timeDiff: 1,
                  page: 1,
                  deviceId: rows[0].deviceId,
                  timeValue,
                });
              }
              getDeviceAlarmList({ id: rows[0].id });
            }
          );
        } else {
          this.setState({ devices: rows, showDevices: true });
          this.props.getDeviceData({
            deviceId: this.state.selectedDevice.deviceId,
          });
          this.props.updateRecentAlarmsEvent(false);
        }
      } else {
        this.setState({
          devices: [],
          showDevices: true,
          selectDevice: {},
        });
        disconnectStream();
      }
    } else {
      this.setState({ showDevices });
    }
  };

  changeTimeValue = (timeValue) => {
    const {
      createUpdateStream,
      getGraphData,
      getRawData,
      rawDataInterval,
      getDeviceAlarmList,
      startDate,
      endDate,
    } = this.props;
    createUpdateStream(this.state.selectedDevice, timeValue);
    if (timeValue === 6 && !!startDate && !!endDate) {
      getGraphData({
        page: 0,
        deviceId: this.state.selectedDevice.deviceId,
        timeValue,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        timezone: timezoneMoment.tz.guess(),
      });
      getRawData({
        timeDiff: rawDataInterval,
        page: 1,
        deviceId: this.state.selectedDevice.deviceId,
        timeValue,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        timezone: timezoneMoment.tz.guess(),
      });
    } else {
      getGraphData({
        page: 0,
        deviceId: this.state.selectedDevice.deviceId,
        timeValue,
      });
      getRawData({
        timeDiff: rawDataInterval,
        page: 1,
        deviceId: this.state.selectedDevice.deviceId,
        timeValue,
      });
    }
    getDeviceAlarmList({ id: this.state.selectedDevice.id });
  };

  selectDevice = (device) => {
    const {
      getGraphData,
      rawDataInterval,
      getRawData,
      getDeviceAlarmList,
      timeValue,
      startDate,
      endDate,
    } = this.props;

    this.setState({ selectedDevice: device }, () => {
      localStorage.setItem("selectedDevice", JSON.stringify(device));
      this.connectStreamForDevice(device);
      if (timeValue === 6 && !!startDate && !!endDate) {
        getGraphData({
          page: 0,
          deviceId: device.deviceId,
          timeValue,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          timezone: timezoneMoment.tz.guess(),
        });
        getRawData({
          page: 1,
          timeDiff: rawDataInterval,
          deviceId: device.deviceId,
          timeValue,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          timezone: timezoneMoment.tz.guess(),
        });
      } else {
        getGraphData({ page: 0, deviceId: device.deviceId, timeValue });
        getRawData({
          timeDiff: rawDataInterval,
          page: 1,
          deviceId: device.deviceId,
          timeValue,
        });
      }
      getDeviceAlarmList({ id: device.id });
    });
  };

  connectStreamForDevice = (device) => {
    const { createUpdateStream, timeValue } = this.props;
    createUpdateStream(device, timeValue);
  };

  saveDevice = () => {
    const { devices } = this.state;
    const rows = devices.map((location, index) => {
      let deviceRow = { ...location };
      deviceRow.editing = false;
      return deviceRow;
    });
    this.setState({ devices: rows, showDevices: true });
  };

  editDevice = (index) => {
    this.setState({
      devices: this.state.devices.map((device, i) =>
        index === i
          ? { ...device, editing: true }
          : { ...device, editing: false }
      ),
      editingDevice: this.state.devices[index],
    });
  };

  handleDeviceChange = (index) => {
    this.props.updateRecentAlarmsEvent(false);
    this.setState({
      devices: this.state.devices.map((device, i) =>
        index === i ? { ...device, name: this.deviceInput.value } : device
      ),
    });
  };

  onDeviceSubmit = (event, index) => {
    const { editLocationDevice } = this.props;
    const { devices } = this.state;
    if (index >= 0 && devices[index].name) {
      const payload = {
        deviceName: devices[index].name,
        deviceId: devices[index].deviceId,
        gatewayId: devices[index].gateway,
        locationId: devices[index].locationId,
        userId: devices[index].userId,
      };
      const id = devices[index].id;
      editLocationDevice({ payload, id });
    }
    event.preventDefault();
  };

  cancelAddEditLocation = (index) => {
    const { locations, editingLocation } = this.state;
    let location = locations[index];
    if (location.id) {
      //editing
      this.setState({
        locations: this.state.locations.map((location, i) =>
          index === i
            ? { ...location, editing: false, name: editingLocation.name }
            : location
        ),
      });
    } else {
      //adding
      locations.pop();
      this.setState({ locations });
    }
  };

  cancelAddEditDevice = (index) => {
    const { devices, editingDevice } = this.state;
    let device = devices[index];
    if (device.id) {
      //editing
      this.setState({
        devices: this.state.devices.map((device, i) =>
          index === i
            ? { ...device, editing: false, name: editingDevice.name }
            : device
        ),
      });
    } else {
      //adding
      devices.pop();
      this.setState({ devices });
    }
  };

  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }
  };

  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  handleDeleteAction = (actionType) => {
    const { deleteId, deleteType } = this.state;
    const { deleteLocation, removeDeviceFromLocationByID } = this.props;
    if (actionType === "confirm") {
      if (deleteType === "Location") {
        deleteLocation({ id: deleteId });
      } else if (deleteType === "Device") {
        removeDeviceFromLocationByID(deleteId);
      }
    }
    const component = this;
    setTimeout(() => {
      component.setState({
        showConfirmationModal: false,
        deleteId: "",
        deleteName: "",
      });
    }, 200);
  };
  deleteDeviceMessage = () => {
    const { deleteType } = this.state;

    return (
      <>
        <div className="d-flex flex-column align-items-center">
          <i
            className="fas fa-question-circle text-warning display-4"
            style={{ fontWeight: 900 }}
          ></i>
          <p className="text-warning">Warning!</p>
          <p className="px-3 text-center">
            Are you sure you want to permanently remove this {deleteType}?<br />{" "}
            {deleteType === "Device"
              ? "Please note there are currently active alarms"
              : null}
          </p>
        </div>
      </>
    );
  };

  showRH = (filteredBasicData) => {
    if (filteredBasicData.length > 0) {
      if (
        filteredBasicData[0].Node_Type &&
        filteredBasicData[0].Node_Type === 1
      ) {
        if (
          filteredBasicData[0].thermo &&
          filteredBasicData[0].thermo !== -255
        ) {
          return false;
        }
      } else {
        if (filteredBasicData[0].m && filteredBasicData[0].m === 2) {
          return false;
        }
      }
    }
    return true;
  };
  render() {
    const {
      locations,
      selectedLocation,
      selectedDevice,
      showLocations,
      showDevices,
      devices,
      basicData,
      activeStatus,
      alarmsData,
    } = this.state;

    const { role, allDevices, convertToFeherenheit } = this.props;
    const shouldConvertToFeherenheit = this.props.temperatureUnit;

    const selectedLocationColor =
      !!allDevices &&
      allDevices.length > 0 &&
      !!alarmsData &&
      alarmsData.length > 0 &&
      allDevices.filter((eachDevice) =>
        alarmsData.includes(eachDevice.deviceId)
      );

    return (
      <>
        <Row>
          <Col sm={6}>
            <div className="mob-border-bottom">
              <h5 style={{ marginTop: 15, paddingLeft: 15 }}>Locations</h5>
              <div
                style={{ paddingLeft: 15 }}
                className="locations "
                ref={this.locationsRef}
              >
                {showLocations ? (
                  locations.map((location, index) => (
                    <div
                      className={`locationDiv align-items-center d-flex flex-row location-device-info ${
                        !!selectedLocationColor &&
                        selectedLocation &&
                        selectedLocationColor.some(
                          (eachLocation) =>
                            eachLocation.location.id === location.id
                        )
                          ? selectedLocation.id === location.id
                            ? "alarmsLocation borderedAlarmsLocation"
                            : "alarmsLocation"
                          : selectedLocation &&
                            selectedLocation.id === location.id
                          ? "activeLocation"
                          : ""
                      }`}
                      key={index}
                      ref={index}
                    >
                      {location.editing ? (
                        <form
                          className="w-100 align-items-center d-flex"
                          name="formLocation"
                          onSubmit={(event) =>
                            this.onLocationSubmit(event, index)
                          }
                        >
                          <div className="w-100 pr-1">
                            <input
                              type="text"
                              className="inlineInput form-control"
                              value={location.name}
                              maxLength="50"
                              minLength="3"
                              ref={(input) => {
                                this.locationInput = input;
                              }}
                              onChange={() => this.handleLocationChange(index)}
                            />
                          </div>
                          <div className="text-right">
                            <button type="submit" className="transparentBtn">
                              <i className="fas fa-save mt-0"></i>
                            </button>
                            <button
                              type="button"
                              onClick={() => this.cancelAddEditLocation(index)}
                              className="transparentBtn"
                            >
                              <i className="fas fa-times mt-0"></i>
                            </button>
                          </div>
                        </form>
                      ) : (
                        <>
                          <div className="w-75">
                            <p
                              onClick={() => {
                                this.props.updateRecentAlarmsEvent(false);
                                this.getLocationDevices(location);
                              }}
                              className="pl-1 pr-1 m-0 w-100 text-uppercase font-weight-bold"
                            >
                              {location.name}
                            </p>
                          </div>
                          <div className="w-25 d-flex justify-content-end mr-2">
                            {role === "super_user" && (
                              <>
                                <button
                                  className="transparentBtn"
                                  onClick={() =>
                                    this.confirmDelete(location.id, "Location")
                                  }
                                >
                                  <i className="fas fa-trash mt-0"></i>
                                </button>
                                <button
                                  className="transparentBtn"
                                  onClick={() => this.editLocation(index)}
                                >
                                  <i className=" fas fa-pencil-alt mt-0"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No Locations yet</p>
                )}
              </div>
              {role === "super_user" && (
                <Button
                  color="info"
                  className="btn-labeledz"
                  style={{ marginLeft: 15, marginBottom: 15 }}
                  onClick={() => {
                    this.addLocation();
                  }}
                >
                  <span className="btn-label">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </span>
                  Add Location
                </Button>
              )}
            </div>
          </Col>
          <Col
            sm={6}
            style={window.innerWidth > 580 ? { paddingLeft: "0px" } : {}}
          >
            {showDevices ? (
              <>
                <h5 style={{ marginTop: 15 }} className="pl-3 pl-sm-0">
                  Devices for {selectedLocation.name}
                </h5>
                <div className="locations pl-3 pl-sm-0">
                  {devices.length ? (
                    devices.map((device, index) => {
                      const filteredDataStatus =
                        basicData &&
                        basicData.length > 0 &&
                        basicData.filter(
                          (eachDevice) => eachDevice.id === device.deviceId
                        );
                      const deviceStatusValue =
                        device &&
                        device.latestData &&
                        device.latestData.nodeData;
                      const filteredBasicData =
                        !!filteredDataStatus && filteredDataStatus.length > 0
                          ? filteredDataStatus
                          : !!deviceStatusValue && [deviceStatusValue];

                      const filteredActiveStatus =
                        activeStatus &&
                        activeStatus.filter(
                          (eachDevice) =>
                            eachDevice.device_id === device.deviceId
                        );

                      return (
                        <div
                          className={`locationDiv align-items-center d-flex location-device-info flex-row pr-0 ${
                            alarmsData.length > 0 &&
                            selectedDevice &&
                            alarmsData.some(
                              (eachAlarm) => eachAlarm === device.deviceId
                            )
                              ? selectedDevice.id === device.id
                                ? "alarmsLocation borderedAlarmsLocation"
                                : "alarmsLocation"
                              : selectedDevice &&
                                selectedDevice.id === device.id
                              ? "activeLocation"
                              : ""
                          }`}
                          key={index}
                        >
                          {device.editing ? (
                            <form
                              className="w-100 align-items-center d-flex"
                              name="formDevice"
                              onSubmit={(event) =>
                                this.onDeviceSubmit(event, index)
                              }
                            >
                              <div className="w-100 pr-1">
                                <input
                                  type="text"
                                  className="inlineInput form-control"
                                  value={device.name}
                                  maxLength="50"
                                  minLength="3"
                                  ref={(input) => {
                                    this.deviceInput = input;
                                  }}
                                  onChange={() =>
                                    this.handleDeviceChange(index)
                                  }
                                />
                              </div>
                              <div className="text-right">
                                <button
                                  type="submit"
                                  className="transparentBtn"
                                >
                                  <i className="fas fa-save mt-0"></i>
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.cancelAddEditDevice(index)
                                  }
                                  className="transparentBtn"
                                >
                                  <i className="fas fa-times mt-0"></i>
                                </button>
                              </div>
                            </form>
                          ) : (
                            <>
                              <div className="w-70 d-flex flex-row">
                                <p
                                  onClick={async () => {
                                    await this.props.updateRecentAlarmsEvent(
                                      false
                                    );
                                    await this.props.updateDeviceName(
                                      device.name
                                    );
                                    await this.props.updateDeviceId(
                                      device.deviceId
                                    );
                                    await this.selectDevice(device);
                                  }}
                                  className="pl-1 pr-1 m-0 w-100 text-uppercase font-weight-bold"
                                >
                                  {device.name}
                                  {((filteredActiveStatus &&
                                    filteredActiveStatus.length > 0 &&
                                    filteredActiveStatus[0].status) ||
                                    device.status) === "active" ? (
                                    <div className="green-circle space" />
                                  ) : (
                                    <div className="red-circle space" />
                                  )}

                                  <div
                                    className="d-flex center"
                                    style={{
                                      fontSize: "0.7rem",
                                      marginLeft: "-5px",
                                    }}
                                  >
                                    {filteredBasicData.length > 0 &&
                                    !!filteredBasicData[0].Node_Type &&
                                    filteredBasicData[0].Node_Type === 2 ? (
                                      <>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 68 68"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M30.7015 60.2954C25.788 59.2582 21.2584 55.7095 18.9478 51.0868C15.9048 44.9989 16.4104 38.453 20.5838 29.9071C22.2187 26.5594 23.6761 24.1062 27.537 18.2025C31.029 12.8629 32.5493 10.3681 33.4666 8.47086C33.843 7.69225 34.2183 7.03087 34.3002 7.00108C34.3826 6.97155 34.7384 7.55041 35.0922 8.28794C36.049 10.2845 37.4954 12.6779 40.8027 17.738C44.7157 23.7245 46.6223 26.9227 48.1812 30.1138C52.255 38.4539 52.7171 45.0758 49.6509 51.1798C47.7837 54.8972 44.3068 58.0472 40.3625 59.5947C37.661 60.6549 33.7487 60.9388 30.7015 60.2954ZM29.9425 56.1133C27.81 54.4492 26.0275 51.5429 24.7472 47.6446C23.2086 42.959 23.0717 38.3002 24.2788 31.7109C24.5089 30.455 24.6339 29.4935 24.5577 29.5744C24.4808 29.6551 24.1675 30.223 23.8618 30.8361C20.9797 36.6155 20.0278 40.4683 20.4849 44.5051C21.0852 49.8086 24.4426 54.3609 29.1954 56.3159C30.6621 56.9192 30.8945 56.8564 29.9427 56.1133L29.9425 56.1133Z"
                                            fill="#fff "
                                          />
                                        </svg>{" "}
                                        <span className="no-space">
                                          {filteredBasicData[0].water_s &&
                                          filteredBasicData[0].water_s !== -255
                                            ? "1"
                                            : "0"}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 68 68"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M31.8427 53.781C29.9625 53.1101 28.7148 51.3123 28.7148 49.2746C28.7148 47.3474 29.5152 45.9445 31.1651 44.9806L32.1514 44.4042L32.2426 29.0825C32.2947 20.6556 32.3507 13.743 32.3748 13.7212C32.3944 13.7015 32.6581 13.5564 32.9538 13.4034C33.5646 13.0875 34.0357 13.1813 34.5371 13.7192C34.8757 14.0833 34.8842 14.4221 34.8842 29.2948V44.4984L35.6331 44.8319C37.9673 45.871 39.0651 49.0657 37.884 51.3812C36.7999 53.506 34.0901 54.5825 31.8436 53.7807L31.8427 53.781Z"
                                            fill="white"
                                          />
                                          <path
                                            d="M30.7304 60.0143C26.7735 59.057 23.2958 55.5772 22.2682 51.5465C21.9106 50.144 21.9106 47.3601 22.2682 45.9577C22.8445 43.6957 24.3333 41.3311 26.0406 39.9649L26.7322 39.4112V26.0552C26.7322 13.034 26.7517 12.6721 27.106 11.6178C27.7939 9.57597 29.1226 8.17953 31.1208 7.39857C32.5063 6.85738 34.5391 6.86846 35.9944 7.42486C37.4485 7.98167 39.2053 9.72977 39.7998 11.2108L40.2244 12.2683L40.2765 25.7737L40.3286 39.2791L41.7105 40.6816C45.2619 44.2859 46.0686 49.3566 43.8214 53.95C43.2301 55.1592 42.796 55.7377 41.5711 56.9501C39.8935 58.6105 38.2689 59.547 36.2333 60.0268C34.8456 60.3537 32.1081 60.3472 30.7272 60.0134L30.7304 60.0143ZM36.1435 57.122C37.5508 56.6877 39.2001 55.6097 40.1395 54.5114C43.3721 50.7315 42.7878 44.9024 38.8733 41.877C38.2637 41.4061 37.7262 40.9894 37.6776 40.9505C37.632 40.9118 37.5493 34.5156 37.5018 26.7371L37.4132 12.5941L36.8798 11.7316C36.1003 10.471 34.937 9.79974 33.5496 9.81008C31.789 9.82327 30.1771 10.9768 29.7438 12.5339C29.6396 12.9097 29.5719 18.5113 29.5719 26.8673L29.5709 40.5838L29.0779 41.0553C28.8064 41.3145 28.2205 41.8203 27.7758 42.1795C24.6803 44.6801 23.8757 49.6206 25.9795 53.2104C27.9931 56.6463 32.3085 58.3071 36.1445 57.1223L36.1435 57.122Z"
                                            fill="white"
                                          />
                                        </svg>
                                        <span className="no-space">
                                          {filteredBasicData.length > 0
                                            ? !!filteredBasicData[0].Node_Type
                                              ? filteredBasicData[0]
                                                  .Node_Type === 1 &&
                                                (filteredBasicData[0].sht_t ||
                                                  filteredBasicData[0].thermo)
                                                ? filteredBasicData[0]
                                                    .thermo !== -255
                                                  ? shouldConvertToFeherenheit ===
                                                    "Celsius"
                                                    ? `${Number(
                                                        filteredBasicData[0]
                                                          .thermo
                                                      ).toFixed(2)} °C`
                                                    : `${convertToFeherenheit(
                                                        Number(
                                                          filteredBasicData[0]
                                                            .thermo
                                                        )
                                                      ).toFixed(2)} °F`
                                                  : filteredBasicData[0]
                                                      .sht_t === -255
                                                  ? "N/A"
                                                  : shouldConvertToFeherenheit ===
                                                    "Celsius"
                                                  ? `${Number(
                                                      filteredBasicData[0].sht_t
                                                    ).toFixed(2)} °C`
                                                  : `${convertToFeherenheit(
                                                      Number(
                                                        filteredBasicData[0]
                                                          .sht_t
                                                      )
                                                    ).toFixed(2)} °F`
                                                : "N/A"
                                              : filteredBasicData[0].t
                                              ? filteredBasicData[0].t === -255
                                                ? "N/A"
                                                : shouldConvertToFeherenheit ===
                                                  "Celsius"
                                                ? `${Number(
                                                    filteredBasicData[0].t
                                                  ).toFixed(2)} °C`
                                                : `${convertToFeherenheit(
                                                    Number(
                                                      filteredBasicData[0].t
                                                    )
                                                  ).toFixed(2)} °F`
                                              : "N/A"
                                            : "N/A"}
                                        </span>
                                        {this.showRH(filteredBasicData) ? (
                                          <>
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 68 68"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M30.0411 60.3997C18.9387 58.0249 11.8068 46.7445 14.6118 35.9945C15.8456 31.2662 18.0725 27.5293 24.8864 18.7539C28.9015 13.5831 30.9354 10.7886 32.095 8.8498C32.4983 8.17559 32.9855 7.4819 33.1777 7.30812C33.5915 6.93363 34.73 6.89052 35.1435 7.23383C35.3042 7.36767 36.0604 8.43451 36.8243 9.60645C38.44 12.0848 40.0956 14.33 44.2176 19.6322C49.9113 26.9562 51.9733 30.317 53.3896 34.5814C54.2216 37.086 54.4915 39.0708 54.3614 41.7272C53.9092 50.9636 47.0942 58.7444 37.9347 60.4819C35.8943 60.8691 32.0486 60.829 30.0411 60.399V60.3997ZM41.463 53.7005C43.3032 52.8375 44.3419 51.1535 44.3405 49.0361C44.3401 47.9947 44.2446 47.5591 43.8451 46.7949C41.6818 42.6451 35.6743 43.2652 34.3637 47.7736C33.8712 49.4679 34.2633 51.071 35.504 52.4452C37.0736 54.1827 39.3936 54.6714 41.463 53.7005ZM35.4669 41.7597L44.4975 32.7291L44.3836 32.0194C44.2363 31.0923 43.5404 30.479 42.6365 30.479C41.9886 30.479 41.5676 30.87 33.0518 39.3792C26.9465 45.4795 24.1096 48.4378 24.034 48.7828C23.909 49.3521 24.3226 50.2989 24.8238 50.5909C25.0024 50.6932 25.4369 50.7805 25.7911 50.7851C26.3973 50.7851 26.9678 50.2584 35.466 41.7601L35.4669 41.7597ZM31.2683 36.7904C32.8338 35.9919 33.8397 34.7188 34.1919 33.0912C34.5749 31.3209 33.5982 29.0068 32.0232 27.9528C29.6337 26.3539 26.5252 26.9654 24.9047 29.3529C22.5574 32.8115 25.2347 37.5122 29.4003 37.2464C30.0355 37.2062 30.8376 37.0101 31.2683 36.7904V36.7904Z"
                                                fill="white"
                                              />
                                              <path
                                                d="M28.1482 33.4359C27.2198 32.7055 27.3626 31.2816 28.419 30.7351C30.0663 29.8832 31.5545 31.9484 30.2402 33.2625C29.5972 33.9055 28.8265 33.9693 28.1482 33.4365V33.4359Z"
                                                fill="white"
                                              />
                                              <path
                                                d="M38.6126 50.5289C37.5284 50.0565 37.3585 48.5767 38.3035 47.8335C38.9817 47.3001 39.7524 47.3639 40.3954 48.0075C41.2502 48.8624 41.0263 50.064 39.9267 50.5235C39.1746 50.8378 39.319 50.8371 38.6126 50.5235V50.5289Z"
                                                fill="white"
                                              />
                                            </svg>
                                            <span className="no-space">
                                              {filteredBasicData.length > 0
                                                ? !!filteredBasicData[0]
                                                    .Node_Type
                                                  ? filteredBasicData[0]
                                                      .Node_Type === 1 &&
                                                    filteredBasicData[0].sht_rh
                                                    ? filteredBasicData[0]
                                                        .sht_rh === -255
                                                      ? "N/A"
                                                      : `${Number(
                                                          filteredBasicData[0]
                                                            .sht_rh
                                                        ).toFixed(2)} %`
                                                    : "N/A"
                                                  : filteredBasicData[0].h
                                                  ? filteredBasicData[0].h ===
                                                    -255
                                                    ? "N/A"
                                                    : `${Number(
                                                        filteredBasicData[0].h
                                                      ).toFixed(2)} %`
                                                  : "N/A"
                                                : "N/A"}
                                            </span>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </p>
                              </div>
                              <div className="w-30 d-flex flex-row justify-content-end">
                                <button
                                  className="transparentBtn"
                                  style={{ opacity: 1 }}
                                >
                                  <i
                                    id={
                                      "Tooltip-" +
                                      device.equipmentStatus +
                                      device.id
                                    }
                                    class="fa fa-times-circle"
                                    style={
                                      !device.equipmentStatus
                                        ? { color: "#ff0000", opacity: "1" }
                                        : {}
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <Tooltip
                                  className="tooltip"
                                  placement="bottom"
                                  autohide={false}
                                  target={
                                    "Tooltip-" +
                                    device.equipmentStatus +
                                    device.id
                                  }
                                  isOpen={this.isToolTipOpen(
                                    `Tooltip-${device.equipmentStatus}${device.id}`
                                  )}
                                  toggle={() =>
                                    this.toggle(
                                      `Tooltip-${device.equipmentStatus}${device.id}`
                                    )
                                  }
                                >
                                  <span>
                                    {device.equipmentStatus
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </Tooltip>
                                <button className="transparentBtn">
                                  <i
                                    id={"Tooltip-" + device.id}
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                {/* this.isToolTipOpen(`Tooltip-${device.id}`) */}
                                <Tooltip
                                  className="tooltip"
                                  placement="bottom"
                                  autohide={false}
                                  target={"Tooltip-" + device.id}
                                  isOpen={this.isToolTipOpen(
                                    `Tooltip-${device.id}`
                                  )}
                                  toggle={() =>
                                    this.toggle(`Tooltip-${device.id}`)
                                  }
                                >
                                  <span>Device ID : {device.deviceId}</span>
                                  <br />
                                  <span>
                                    Created at :{" "}
                                    {convertDateFormat(
                                      device.createdAt,
                                      "shortDateTime"
                                    )}
                                  </span>
                                </Tooltip>
                                {role === "super_user" && (
                                  <>
                                    <button
                                      className="transparentBtn"
                                      onClick={() =>
                                        this.confirmDelete(device.id, "Device")
                                      }
                                    >
                                      <i className=" fas fa-trash mt-0"></i>
                                    </button>
                                    <button
                                      className="transparentBtn"
                                      onClick={() => this.assignUser(device)}
                                    >
                                      <i className=" fas fa-user-check mt-0"></i>
                                    </button>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p>No devices yet</p>
                  )}
                </div>
              </>
            ) : (
              <>
                <h5 style={{ marginTop: 15 }} className="pl-3 pl-sm-0">
                  Devices
                </h5>
                <div className="locations pl-3 pl-sm-0">
                  <p>No devices assigned yet</p>
                </div>
              </>
            )}
            {role === "super_user" && (
              <Button
                color="info"
                className="btn-labeled add-button"
                style={
                  window.innerWidth > 580
                    ? { marginBottom: 15 }
                    : { marginBottom: 15, marginLeft: 15 }
                }
                onClick={() => {
                  this.addDevice();
                }}
              >
                <span className="btn-label">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </span>
                Add Device
              </Button>
            )}
          </Col>
          <ConfirmationModal
            isOpen={this.state.showConfirmationModal}
            modalAction={this.handleDeleteAction}
            deleteName={this.state.deleteName}
            modalBody={this.deleteDeviceMessage()}
          />
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    startDate: state.locationsDevices.startDate,
    endDate: state.locationsDevices.endDate,
    error: state.locationsDevices.error,
    loadingLocations: state.locationsDevices.loading,
    locations: state.locationsDevices.locations,
    reloadLocations: state.locationsDevices.reload,
    loadingDevices: state.locationsDevices.loadingDevices,
    devices: state.locationsDevices.devices,
    deviceSaved: state.locationsDevices.deviceSaved,
    reloadDevices: state.locationsDevices.reloadDevices,
    allDevices: state.locationsDevices.allDevices,
    deviceStatusData: state.dashboard.deviceStatus,
    temperatureUnit: state.auth.temperatureUnit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: (data) => dispatch(actions.getLocations(data)),
    addLocation: (data) => dispatch(actions.addLocation(data)),
    editLocation: (data) => dispatch(actions.editLocation(data)),
    deleteLocation: (data) => dispatch(actions.deleteLocation(data)),
    getLocationDevices: (data) => dispatch(actions.getLocationDevices(data)),
    getAllDevices: () => dispatch(actions.getAllDevices()),
    editLocationDevice: (data) => dispatch(actions.editDeviceLocation(data)),
    deleteLocationDevice: (data) => dispatch(actions.deleteDevice(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
    getGraphData: (data) => dispatch(actions.getGraphData(data)),
    getRawData: (data) => dispatch(actions.getRawData(data)),
    getDeviceAlarmList: (data) => dispatch(actions.getDeviceAlarmList(data)),
    removeDeviceFromLocationByID: (data) =>
      dispatch(actions.removeDeviceFromLocationByID(data)),
    // addUserDevice: (data) => dispatch(actions.addUserDevice(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
