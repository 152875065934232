import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
// import { dispatch } from "d3-dispatch";

export const getAccountListStart = () => {
  return {
    type: actionTypes.GET_ACCOUNT_LIST_START,
  };
};

export const getAccountListSuccess = (data) => {
  return {
    type: actionTypes.GET_ACCOUNT_LIST_SUCCESS,
    data: data,
  };
};

export const getAccountListFail = (error) => {
  return {
    type: actionTypes.GET_ACCOUNT_LIST_FAIL,
    error: error,
  };
};

export const deleteAccountStart = () => {
  return {
    type: actionTypes.DELETE_ACCOUNT_LIST_START,
  };
};

export const deleteAccountSuccess = (data) => {
  return {
    type: actionTypes.DELETE_ACCOUNT_LIST_SUCCESS,
    data: data,
  };
};

export const deleteAccountFail = (error) => {
  return {
    type: actionTypes.DELETE_ACCOUNT_LIST_FAIL,
    error: error,
  };
};

export const generateAccountAPIStart = () => {
  return {
    type: actionTypes.GENERATE_ACCOUNT_API_START,
  };
};

export const generateAccountAPISuccess = (data) => {
  return {
    type: actionTypes.GENERATE_ACCOUNT_API_SUCCESS,
    data: data,
  };
};

export const generateAccountAPIFail = (error) => {
  return {
    type: actionTypes.GENERATE_ACCOUNT_API_FAIL,
    error: error,
  };
};

export const createAccountAPIStart = () => {
  return {
    type: actionTypes.CREATE_ACCOUNT_API_START,
  };
};

export const createAccountAPISuccess = (data) => {
  return {
    type: actionTypes.CREATE_ACCOUNT_API_SUCCESS,
    data: data,
  };
};

export const createAccountAPIFail = (error) => {
  return {
    type: actionTypes.CREATE_ACCOUNT_API_FAIL,
    error: error,
  };
};

export const getAccountList = () => {
  return (dispatch) => {
    dispatch(getAccountListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.account.list}`;
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getAccountListSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getAccountListFail(error.response.data.errors));
        }
      });
  };
};

export const deleteAccount = ({ id }) => {
  return (dispatch) => {
    dispatch(deleteAccountStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.account.delete}`;
    url = url.replace("{id}", id);
    axios
      .delete(url, { headers })
      .then((response) => {
        dispatch(deleteAccountSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(deleteAccountFail(error.response.data.errors));
        }
      });
  };
};

export const generateAccountAPI = () => {
  return (dispatch) => {
    dispatch(getAccountListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.account.generateAPIKey}`;
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(generateAccountAPISuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(generateAccountAPIFail(error.response.data.errors));
        }
      });
  };
};

export const createAccountAPI = () => {
  return (dispatch) => {
    dispatch(createAccountAPIStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.account.createAPIKey}`;
    axios
      .post(url, {}, { headers })
      .then((response) => {
        dispatch(createAccountAPISuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(createAccountAPIFail(error.response.data.errors));
        }
      });
  };
};

export const editVoiceCallStart = () => {
  return {
    type: actionTypes.EDIT_VOICE_CALL_START,
  };
};

export const editVoiceCallSuccess = (data) => {
  return {
    type: actionTypes.EDIT_VOICE_CALL_SUCCESS,
    data: data,
  };
};

export const editVoiceCallFail = (error) => {
  return {
    type: actionTypes.EDIT_VOICE_CALL_FAIL,
    error: error,
  };
};

export const editVoiceCall = ({ payload, id }) => {
  return (dispatch) => {
    dispatch(editVoiceCallStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.account.voiceCall}`;
    url = url.replace("{id}", id);
    axios
      .patch(url, payload, { headers })
      .then((response) => {
        dispatch(editVoiceCallSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(editVoiceCallFail(error.response.data.errors));
        }
      });
  };
};
