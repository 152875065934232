import React, { Component } from 'react';
import { convertDateFormat } from '../../utils/helperFunctions';
import { MDBBtn, MDBDataTable } from 'mdbreact';
import { Row, Col } from 'reactstrap';
import Pagination from '../Common/Pagination';

const superAdminColumn = [
    {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 250,
      },
      {
        label: 'Account ID',
        field: 'accountId',
        sort: 'asc',
        width: 250,
      },
      {
        label: 'Count',
        field: 'count',
        sort: 'asc',
        width: 250,
      }
];
class UserNotificationStatisticsTable extends Component {
  state = {
    loadTable: false,
    deviceList: [],
    pagination: {},
    role: localStorage.getItem('role'),
  };

  componentDidMount() {
    const { data } = this.props;
    if (data && data.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data &&
      this.props.data &&
      this.props.data.length
    ) {
      this.showTable(true);
    }
  }

  showTable = (loadTable) => {
    if (loadTable) {
      const {
        data,
        // editGateway,
        // deleteGateway,
        pagination,
        updateFirmware,
      } = this.props;
      const rows = data.map((item, index) => {
        
        let gatewayRow = { ...item };
        delete gatewayRow['userId'];
        gatewayRow.createdAt = convertDateFormat(
          gatewayRow.createdAt,
          'shortDateTime'
        );
        gatewayRow.action =
          // this.state.role === 'super_admin' ? (
            <MDBBtn
              tag="a"
              rounded
              size="sm"
              className="px-2 transparentBtn"
            >
              <i className="fas fa-cog mt-0"></i>
            </MDBBtn>
        
        return gatewayRow;
      });
      this.setState({
        loadTable,
        deviceList: {
          columns:
            superAdminColumn,
          rows: rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { changePage } = this.props;
    const { loadTable, deviceList, pagination } = this.state;
    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort"
              bordered
              data={deviceList}
              paging={false}
              scrollX
            />
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default UserNotificationStatisticsTable;
