import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  deviceConectedStatus: {
    gatewayCount: 0,
    deviceCount: 0,
    totalOfflineDevices: 0,
    totalOfflineGateway: 0,
  },
  error: undefined,
  loading: false,
};

const getDeviceConectedStatusStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
  });
};

const getDeviceConectedStatusSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    deviceConectedStatus: action.data,
    loading: false,
  });
};

const getDeviceConectedStatusFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    deviceConectedStatus: {
      gatewayCount: 0,
      deviceCount: 0,
      totalOfflineDevices: 0,
      totalOfflineGateway: 0,
    },
    loading: false,
  });
};

const getDeviceStatusStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
  });
};

const getDeviceStatusSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    deviceStatusResult: action,
    loading: false,
  });
};

const getDeviceStatusFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    deviceStatusResult: {
      devices: null,
      gateways: null,
    },
    loading: false,
  });
};

const deviceConectedStatus = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DEVICE_STATUS_CONECTED_START:
      return getDeviceConectedStatusStart(state, action);
    case actionTypes.GET_DEVICE_STATUS_CONECTED_SUCCESS:
      return getDeviceConectedStatusSuccess(state, action.data);
    case actionTypes.GET_DEVICE_STATUS_CONECTED_FAIL:
      return getDeviceConectedStatusFail(state, action);

    case actionTypes.GET_DEVICES_STATUS_START:
      return getDeviceStatusStart(state, action);
    case actionTypes.GET_DEVICES_STATUS_SUCCESS:
      return getDeviceStatusSuccess(state, action.data);
    case actionTypes.GET_DEVICES_STATUS_FAIL:
      return getDeviceStatusFail(state, action);
    default:
      return state;
  }
};
export default deviceConectedStatus;
