import React, { Component } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
  InputGroup,
} from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators";
import LoopCircleLoading from "react-loadingg/lib/LoopCircleLoading";
import "./index.scss";
class UploadFirmware extends Component {
  state = {
    formGateway: {
      loraFw: undefined,
      espFw: undefined,
      nextion: undefined,
    },
    id: "",
  };

  uploadGateway = (payload) => {
    const { uploadGatewayFirmware } = this.props;
    uploadGatewayFirmware(payload);
  };
  componentDidMount() { }

  handleChange = (event) => {
    console.info({
      [event.target.name]: event.target.files[0],
    });
    this.setState({
      formGateway: {
        ...this.state.formGateway,
        [event.target.name]: event.target.files[0],
      },
    });
  };

  onSubmit = (e) => {
    this.uploadGateway([
      this.state.formGateway.loraFw,
      this.state.formGateway.espFw,
      this.state.formGateway.nextion,
    ]);
    e.preventDefault();
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    const { toggleModal, loading, modalData } = this.props;
    const { formGateway } = this.state;
    const { loraFw, espFw, nextion } = formGateway;
    console.log(this.props, "props")

    return (
      <React.Fragment>
        {!!loading ? (
          <div style={{ position: "fixed", top: "50%", left: "50%" }}>
            <div className="loadingWrapper">
              <div className="d-flex justify-content-center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <LoopCircleLoading />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <form className="mb-3" name="formGateway" onSubmit={this.onSubmit}>
            <ModalHeader toggle={toggleModal}>
              {modalData === "Node_Devices" ? 'Node Device Firmware upload' : 'Gateway Firmware Upload'}
            </ModalHeader>
            <ModalBody>
              <div className="form-group">
                <label className="text-muted">Select LoRa firmware</label>
                <div className="input-group with-focus">
                  <label htmlFor="loraFwId" className="form-control fileUpload">
                    {loraFw ? (
                      `${loraFw.name}`
                    ) : (
                      <span className="text-muted text-bold">{modalData === "Node_Devices" ? 'LoRa ESP fw' : 'LoRa fw'}</span>
                    )}
                  </label>
                  <input
                    id="loraFwId"
                    type="file"
                    name="loraFw"
                    placeholder="LoRa Firmware"
                    className="border-light border-right-0  d-none"
                    onChange={this.handleChange}
                    accept=".bin, .tft"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text text-muted bg-transparent border-left-0">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.51 4.00031V4C4.51 3.17287 5.1794 2.5 6 2.5H13.7929L19.5 8.20711V20C19.5 20.8239 18.8239 21.5 18 21.5H5.99C5.16945 21.5 4.50008 20.8272 4.5 20.0002C4.5 20.0001 4.5 20.0001 4.5 20L4.51 4.00031ZM18 20.5H18.5V20V9V8.5H18H13.5V4V3.5H13H6H5.5V4V20V20.5H6H18Z"
                          stroke="currentColor"
                        />
                        <path
                          d="M15 16.5V18H9V16.5H8V18C8 18.55 8.45 19 9 19H15C15.55 19 16 18.55 16 18V16.5H15ZM9.5 13.5L10.205 14.205L11.5 12.915V17H12.5V12.915L13.795 14.205L14.5 13.5L12 11L9.5 13.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              { modalData !== "Node_Devices" &&
                (
                  <div className="form-group">
                    <label className="text-muted">Select ESP firmware</label>
                    <div className="input-group with-focus">
                      <label htmlFor="espFwId" className="form-control fileUpload">
                        {espFw ? (
                          `${espFw.name}`
                        ) : (
                          <span className="text-muted text-bold">ESP fw</span>
                        )}
                      </label>
                      <input
                        id="espFwId"
                        type="file"
                        name="espFw"
                        placeholder="ESP Firmware"
                        className="border-light border-right-0  d-none"
                        onChange={this.handleChange}
                        accept=".bin, .tft"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text text-muted bg-transparent border-left-0">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.51 4.00031V4C4.51 3.17287 5.1794 2.5 6 2.5H13.7929L19.5 8.20711V20C19.5 20.8239 18.8239 21.5 18 21.5H5.99C5.16945 21.5 4.50008 20.8272 4.5 20.0002C4.5 20.0001 4.5 20.0001 4.5 20L4.51 4.00031ZM18 20.5H18.5V20V9V8.5H18H13.5V4V3.5H13H6H5.5V4V20V20.5H6H18Z"
                              stroke="currentColor"
                            />
                            <path
                              d="M15 16.5V18H9V16.5H8V18C8 18.55 8.45 19 9 19H15C15.55 19 16 18.55 16 18V16.5H15ZM9.5 13.5L10.205 14.205L11.5 12.915V17H12.5V12.915L13.795 14.205L14.5 13.5L12 11L9.5 13.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>)
              }
              <div className="form-group">
                <label className="text-muted">Select Nextion firmware</label>
                <div className="input-group with-focus">
                  <label
                    htmlFor="nextionId"
                    className="form-control fileUpload"
                  >
                    {nextion ? (
                      `${nextion.name}`
                    ) : (
                      <span className="text-muted text-bold">Nextion fw</span>
                    )}
                  </label>
                  <input
                    id="nextionId"
                    type="file"
                    name="nextion"
                    placeholder="Nextion Firmware"
                    className="border-light border-right-0  d-none"
                    onChange={this.handleChange}
                    accept=".bin, .tft"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text text-muted bg-transparent border-left-0">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.51 4.00031V4C4.51 3.17287 5.1794 2.5 6 2.5H13.7929L19.5 8.20711V20C19.5 20.8239 18.8239 21.5 18 21.5H5.99C5.16945 21.5 4.50008 20.8272 4.5 20.0002C4.5 20.0001 4.5 20.0001 4.5 20L4.51 4.00031ZM18 20.5H18.5V20V9V8.5H18H13.5V4V3.5H13H6H5.5V4V20V20.5H6H18Z"
                          stroke="currentColor"
                        />
                        <path
                          d="M15 16.5V18H9V16.5H8V18C8 18.55 8.45 19 9 19H15C15.55 19 16 18.55 16 18V16.5H15ZM9.5 13.5L10.205 14.205L11.5 12.915V17H12.5V12.915L13.795 14.205L14.5 13.5L12 11L9.5 13.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="info" type="submit">
                Upload
              </Button>
              <Button color="secondary" type="button" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addGateway: (data) => dispatch(actions.addGateway(data)),
    editGateway: (data) => dispatch(actions.editGateway(data)),
    uploadGatewayFirmware: (data) =>
      dispatch(actions.uploadGatewayFirmware(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.gateways.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFirmware);
