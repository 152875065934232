import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import { showToast } from "../../helpers/toast.js";
import * as actions from "../../stores/actions/index";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";

class AddSmsLimit extends Component {
  countyCodeList = ["+44", "+91", "+1"];
  state = {
    form: {
      smsLimit: "",
      accountId: "",
    },
    id: "",
    accountList: [],
    dropdownOpen: false
  };

  toggleDropDown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  addSmsLimitFn = (payload) => {
    const { addSmsLimit } = this.props;
    addSmsLimit(payload);
  };

  componentDidMount() {
    const { modalData } = this.props;
    if (modalData && modalData.accountList) {
      const { accountList } = modalData;

      this.setState({
        form: {
          accountId: "",
          smsLimit: ""
        },
        accountList,
      });
    }
  }
  handleAccountIdChange = (account) => {
    if (account) {
      if(account.smsLimit){
        this.setState({
          form: {
            ...this.state.form,
            smsLimit: account.smsLimit,
            accountId: account.id
          }
        })
      }else{
        this.setState({
          form: {
            ...this.state.form,
            smsLimit: '',
            accountId: account.id
          }
        })
      }
    }
    
  };

  onSubmit = (e) => {
    e.preventDefault();
    const state = onSubmit(e, this.state);
    this.setState(state);
    if (!state.hasError) {
      let accountId = this.state.form.accountId;
      if (!accountId) {
        showToast("Please Choose Account", "error");
        return;
      }
      const params = {
        accountId: this.state.form.accountId,
        smsLimit: this.state.form.smsLimit,
      };
      this.addSmsLimitFn(params);
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    const { toggleModal, account } = this.props;

    return (
      <React.Fragment>
        <form className="mb-3" name="formAccount" onSubmit={this.onSubmit}>
          <ModalHeader toggle={toggleModal}>Add Sms Limit</ModalHeader>
          <ModalBody>
          <div className="form-group">
              <label className="text-muted">Select Account</label>
              <Select
                styles={selectStyle}
                name="accountId"
                value={this.state.form.id}
                onChange={this.handleAccountIdChange}
                options={this.state.accountList}
                getOptionLabel={(option) => `${option.companyName}`}
                getOptionValue={(option) => `${option}`}
              />
            </div>
            <div className="form-group">
              <label className="text-muted">Limit</label>
              <div className="input-group with-focus">
                <Input
                  type="number"
                  name="limit"
                  placeholder="Limit"
                  defaultValue={this.state.form.smsLimit}
                  onChange={(data)=>{
                    this.setState(
                      {
                        form: {
                          ...this.state.form,
                          smsLimit: data.target.value
                        }
                      })
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="submit">
              Add
            </Button>
            <Button color="secondary" type="button" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.users.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSmsLimit: (data) => dispatch(actions.addSmsLimit(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSmsLimit);
