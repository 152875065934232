import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import { selectStyle } from "../../../../config/selectStyle";
class TypeNav extends React.Component {
  state = {
    allHours: [
      {
        id: "1h",
        name: "1 Hour",
        value: "hour",
      },
      {
        id: "3h",
        name: "3 Hour",
        value: "hour",
      },
      {
        id: "6h",
        name: "6 Hour",
        value: "hour",
      },
      {
        id: "12h",
        name: "12 Hour",
        value: "hour",
      },
      {
        id: "24h",
        name: "24 Hour",
        value: "hour",
      },
      {
        id: "7d",
        name: "1 Week",
        value: "week",
      },
      {
        id: "1y",
        name: "1 Year",
        value: "year",
      },
      {
        id: "custom",
        name: "Custom",
        value: "custom",
      },
      // { id: "custom", name: "Custom" },
    ],
  };

  render() {
    const {
      activeTab,
      toggle,
      startDate,
      endDate,
      timeValue,
      selectedHours,
      handleChange,
    } = this.props;

    const startDateLabel = moment(startDate).format("DD-MM-YYYY");
    const endDateLabel = moment(endDate).format("DD-MM-YYYY");

    return (
      <div
        className="d-flex justify-content-between align-items-center mt-2 mr-2"
        style={{ marginLeft: "0.7rem" }}
      >
        <Nav tabs style={{ border: "none" }}>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
              style={{ borderRadius: "4px 0px 0px 4px", color: "var(--body-bg)", backgroundColor: "var(--dropdown-bg)" }}
            >
              Graph
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              Raw
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                toggle("3");
              }}
              style={{ borderRadius: "0px 4px 4px 0px" }}
            >
              Alarms
            </NavLink>
          </NavItem>
        </Nav>
        {/* {!!this.props.isSensorConnected &&
          !!this.props.isSensorConnected["ma"] && (
            <Button
              color="info"
              onClick={() => {
                this.props.toggleGraph(this.props.showSensor);
              }}
              className="mb-2"
              style={{ float: "right", cursor: "pointer" }}
            >
              {this.props.showSensor
                ? "Hide Analog Sensor Graph"
                : "Show Analog Sensor Graph"}
            </Button>
          )} */}
        {timeValue === 6 &&
          selectedHours.id === "custom" &&
          startDate &&
          endDate && (
            <p>
              {startDateLabel} ~ {endDateLabel}
            </p>
          )}

        {activeTab !== "3" && (
          <div style={{ float: "right", width: "15vw" }}>
            <Select
              styles={selectStyle}
              name="locationId"
              value={selectedHours}
              onChange={handleChange}
              options={this.state.allHours}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) => {
                return selectedHours.id === option.id ? true : false;
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    startDate: state.locationsDevices.startDate,
    endDate: state.locationsDevices.endDate,
  };
};

export default connect(mapStateToProps)(TypeNav);
