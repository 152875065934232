import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
import * as actionTypes from "./actionTypes";

export const saveAlertProfileStart = () => {
  return {
    type: actionTypes.SAVE_ALERT_PROFILE_START,
  };
};

export const saveAlertProfileSuccess = (data) => {
  return {
    type: actionTypes.SAVE_ALERT_PROFILE_SUCCESS,
    data: data,
  };
};

export const saveAlertProfileFail = (error) => {
  return {
    type: actionTypes.SAVE_ALERT_PROFILE_FAIL,
    error: error,
  };
};

export const getAlertProfileStart = () => {
  return {
    type: actionTypes.GET_ALERT_PROFILE_START,
  };
};

export const getAlertProfileSuccess = (data) => {
  return {
    type: actionTypes.GET_ALERT_PROFILE_SUCCESS,
    data: data,
  };
};

export const getAlertProfileFail = (error) => {
  return {
    type: actionTypes.GET_ALERT_PROFILE_FAIL,
    error: error,
  };
};

export const deleteProfileStart = () => {
  return {
    type: actionTypes.DELETE_ALERT_PROFILE_START,
  };
};

export const deleteProfileSuccess = (data) => {
  return {
    type: actionTypes.DELETE_ALERT_PROFILE_SUCCESS,
    data: data,
  };
};

export const deleteProfileFail = (error) => {
  return {
    type: actionTypes.DELETE_ALERT_PROFILE_FAIL,
    error: error,
  };
};

export const editProfileStart = () => {
  return {
    type: actionTypes.EDIT_ALERT_PROFILE_START,
  };
};

export const editProfileSuccess = (data) => {
  return {
    type: actionTypes.EDIT_ALERT_PROFILE_SUCCESS,
    data: data,
  };
};

export const editProfileFail = (error) => {
  return {
    type: actionTypes.EDIT_ALERT_PROFILE_FAIL,
    error: error,
  };
};

export const resetSuccess = () => {
  return {
    type: actionTypes.RESET_ALERT_PROFILE,
  };
};

export const saveAlertProfile = (payload) => {
  return (dispatch) => {
    dispatch(saveAlertProfileStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.alertProfile.save}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        dispatch(saveAlertProfileSuccess(response.status));
        setTimeout(() => {
          dispatch(resetSuccess());
        }, 1000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(saveAlertProfileFail(error.response.data.errors));
        }
      });
  };
};

export const getAlertProfileList = () => {
  return (dispatch) => {
    dispatch(getAlertProfileStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.alertProfile.get}`;
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getAlertProfileSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //   dispatch(unauthorized());
        } else {
          dispatch(getAlertProfileFail(error.response.data.errors));
        }
      });
  };
};

export const deleteProfile = (id) => {
  return (dispatch) => {
    dispatch(deleteProfileStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.alertProfile.delete}`;
    const payload = {
      profileId: id,
    };
    axios
      .post(url, payload, { headers })
      .then((response) => {
        dispatch(deleteProfileSuccess(response.data));
        setTimeout(() => {
          dispatch(resetSuccess());
        }, 1000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //   dispatch(unauthorized());
        } else if (error.response.status === 500) {
          dispatch(
            deleteProfileFail(
              "Cant delete user! Some devices, alarms or reports may have been Assigned to this user"
            )
          );
        } else {
          dispatch(deleteProfileFail(error.response.data.errors));
        }
      });
  };
};

export const editAlertProfile = ({ payload }) => {
  return (dispatch) => {
    dispatch(editProfileStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.alertProfile.edit}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        dispatch(editProfileSuccess(response.status));
        setTimeout(() => {
          dispatch(resetSuccess());
        }, 1000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // dispatch(unauthorized());
        } else {
          dispatch(editProfileFail(error.response.data.errors));
        }
      });
  };
};
