import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";

import { unauthorized } from "./auth";
import { showToast } from "../../helpers/toast";
import moment from "moment";
import timezoneMoment from "moment-timezone";
import { data } from "jquery";

const setExceededActionCreator = (exceeded) => {
  return {
    type: actionTypes.SET_EXCEEDED,
    exceeded,
  };
};
const setNoDateSelectedCreator = (nodate) => {
  return {
    type: actionTypes.NO_DATE_SELECTED,
    nodate,
  };
};
const setStartDateActionCreator = (date) => {
  return {
    type: actionTypes.SET_START_DATE,
    date,
  };
};

const setEndDateActionCreator = (date) => {
  return {
    type: actionTypes.SET_END_DATE,
    date,
  };
};

const getLocationsStart = () => {
  return {
    type: actionTypes.GET_LOCATIONS_START,
  };
};

const getLocationsSuccess = (data) => {
  return {
    type: actionTypes.GET_LOCATIONS_SUCCESS,
    data: data,
  };
};

const getLocationsFail = (error) => {
  return {
    type: actionTypes.GET_LOCATIONS_FAIL,
    error: error,
  };
};

const getDeviceListStart = () => {
  return {
    type: actionTypes.GET_DEVICE_LOCATION_START,
  };
};

const getDeviceListSuccess = (data) => {
  return {
    type: actionTypes.GET_DEVICE_LOCATION_SUCCESS,
    data: data,
  };
};

const getDeviceListFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_LOCATION_FAIL,
    error: error,
  };
};

const getAllDevicesStart = () => {
  return {
    type: actionTypes.GET_ALL_DEVICES_START,
  };
};

const getAllDevicesSuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_DEVICES_SUCCESS,
    data: data,
  };
};

const getAllDevicesFail = (error) => {
  return {
    type: actionTypes.GET_ALL_DEVICES_FAIL,
    error: error,
  };
};

const removeDeviceFromLocation = () => {
  return {
    type: actionTypes.DELETE_LOCATION_DEVICE,
  };
};

const removeDeviceFromLocationSuccess = (data) => {
  return {
    type: actionTypes.DELETE_LOCATION_DEVICE_SUCCESS,
    data: data,
  };
};

const removeDeviceFromLocationFail = (error) => {
  return {
    type: actionTypes.DELETE_LOCATION_DEVICE_FAIL,
    error: error,
  };
};

const getGraphDataStart = () => {
  return {
    type: actionTypes.GET_GRAPH_DATA_START,
  };
};

const getGraphDataSuccess = (data) => {
  return {
    type: actionTypes.GET_GRAPH_DATA_SUCCESS,
    data: data,
  };
};

const getGraphDataFail = (error) => {
  return {
    type: actionTypes.GET_GRAPH_DATA_FAIL,
    error: error,
  };
};

const getRawDataStart = () => {
  return {
    type: actionTypes.GET_RAW_DATA_START,
  };
};

const getRawDataSuccess = (data) => {
  return {
    type: actionTypes.GET_RAW_DATA_SUCCESS,
    data: data,
  };
};

const getRawDataSuccess2 = (data, isFalse) => {
  return {
    type: actionTypes.GET_RAW_DATA_SUCCESS,
    data: data,
    graph: isFalse,
  };
};

const getRawDataFail = (error) => {
  return {
    type: actionTypes.GET_RAW_DATA_FAIL,
    error: error,
  };
};

const editLocationStart = () => {
  return {
    type: actionTypes.EDIT_LOCATION_START,
  };
};

const editLocationSuccess = (data) => {
  return {
    type: actionTypes.EDIT_LOCATION_SUCCESS,
    data: data,
  };
};

const editLocationFail = (error) => {
  return {
    type: actionTypes.EDIT_LOCATION_FAIL,
    error: error,
  };
};

const addLocationStart = () => {
  return {
    type: actionTypes.ADD_LOCATION_START,
  };
};

const addLocationSuccess = (data) => {
  return {
    type: actionTypes.ADD_LOCATION_SUCCESS,
    data: data,
  };
};

const addLocationFail = (error) => {
  return {
    type: actionTypes.ADD_LOCATION_FAIL,
    error: error,
  };
};

const deleteLocationStart = () => {
  return {
    type: actionTypes.DELETE_LOCATION_START,
  };
};

const deleteLocationSuccess = (data) => {
  return {
    type: actionTypes.DELETE_LOCATION_SUCCESS,
    data: data,
  };
};

const deleteLocationFail = (error) => {
  return {
    type: actionTypes.DELETE_LOCATION_FAIL,
    error: error,
  };
};

const editDeviceStart = () => {
  return {
    type: actionTypes.EDIT_DEVICE_LOCATION_START,
  };
};

const editDeviceSuccess = (data) => {
  return {
    type: actionTypes.EDIT_DEVICE_LOCATION_SUCCESS,
    data: data,
  };
};

const editDeviceFail = (error) => {
  return {
    type: actionTypes.EDIT_DEVICE_LOCATION_FAIL,
    error: error,
  };
};

const assignUserStart = () => {
  return {
    type: actionTypes.ASSIGN_USER_START,
  };
};

const assignUserSuccess = (data) => {
  return {
    type: actionTypes.ASSIGN_USER_SUCCESS,
    data: data,
  };
};

const assignUserFail = (error) => {
  return {
    type: actionTypes.ASSIGN_USER_FAIL,
    error: error,
  };
};
export const setNoDateSelected = ({ nodate }) => {
  return (dispatch) => {
    dispatch(setNoDateSelectedCreator(nodate));
  };
};
export const setExceeded = ({ exceeded }) => {
  return (dispatch) => {
    dispatch(setExceededActionCreator(exceeded));
  };
};

export const setStartDate = ({ date }) => {
  return (dispatch) => {
    dispatch(setStartDateActionCreator(date));
  };
};

export const setEndDate = ({ date }) => {
  return (dispatch) => {
    dispatch(setEndDateActionCreator(date));
  };
};

export const editDeviceLocation = ({ payload, id }) => {
  return (dispatch) => {
    dispatch(editDeviceStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.edit}`;
    url = url.replace("{id}", id);
    axios
      .put(url, payload, { headers })
      .then((response) => {
        showToast("Device updated successfully", "success");
        dispatch(editDeviceSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(editDeviceFail(error.response.data.errors));
        }
      });
  };
};

export const getLocations = (page = 1, id = undefined) => {
  return (dispatch) => {
    dispatch(getLocationsStart());

    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.locations.list}`;
    url = url.replace("{id}", id);
    if (page) {
      url += `?page=${page}&pageSize=10`;
    } else {
      url += "?page=1&pageSize=1000";
    }
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getLocationsSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getLocationsFail(error.response.data.errors));
        }
      });
  };
};

export const addLocation = (payload) => {
  return (dispatch) => {
    dispatch(addLocationStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.locations.add}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        showToast("Location added successfully", "success");
        dispatch(addLocationSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(addLocationFail(error.response.data.errors));
        }
      });
  };
};

export const editLocation = ({ payload, id }) => {
  return (dispatch) => {
    dispatch(editLocationStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.locations.edit}`;
    url = url.replace("{id}", id);
    axios
      .put(url, payload, { headers })
      .then((response) => {
        showToast("Location updated successfully", "success");
        dispatch(editLocationSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(editLocationFail(error.response.data.errors));
        }
      });
  };
};

export const deleteLocation = ({ id }) => {
  return (dispatch) => {
    dispatch(deleteLocationStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.locations.delete}`;
    url = url.replace("{id}", id);
    axios
      .delete(url, { headers })
      .then((response) => {
        showToast("Location deleted successfully", "success");
        dispatch(deleteLocationSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(deleteLocationFail(error.response.data.errors));
        }
      });
  };
};

export const getLocationDevices = ({ page, locationId, deviceId }) => {
  return (dispatch) => {
    dispatch(getDeviceListStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.list}`;
    url = url.replace("{id}", deviceId);
    if (page) {
      url += `?page=${page}&pageSize=10`;
    } else {
      url += "?page=1&pageSize=1000";
    }
    if (locationId) {
      url += `&locationId=${locationId}`;
    }

    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getDeviceListSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getDeviceListFail(error.response.data.errors));
        }
      });
  };
};

export const getAllDevices = () => {
  return (dispatch) => {
    dispatch(getAllDevicesStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.list}`;
    url = url.replace("{id}", "undefined");
    url += "?page=1&pageSize=1000";

    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getAllDevicesSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getAllDevicesFail(error.response.data.errors));
        }
      });
  };
};

export const removeDeviceFromLocationByID = (deviceId) => {
  return (dispatch) => {
    dispatch(removeDeviceFromLocation());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.unassignLocation}`;

    url = url.replace("{id}", deviceId);

    axios
      .post(url, {}, { headers })
      .then((response) => {
        showToast("Device removed from Location successfully", "success");
        dispatch(removeDeviceFromLocationSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(removeDeviceFromLocationFail(error.response.data.errors));
        }
      });
  };
};

export const assignUser = (payload) => {
  return (dispatch) => {
    dispatch(assignUserStart());
    const headers = getCommonHeaders();
    const url = `${apiPath.baseUrl}${apiPath.device.assignUser}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        showToast("Device assigned to user successfully", "success");
        dispatch(assignUserSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(assignUserFail(error.response.data.errors));
        }
      });
  };
};

export const unassignUser = (payload) => {
  return (dispatch) => {
    dispatch(assignUserStart());
    const headers = getCommonHeaders();
    const url = `${apiPath.baseUrl}${apiPath.device.unassignUser}`;
    axios
      .post(url, payload, { headers })
      .then((response) => {
        showToast("Device unassigned from the user successfully", "success");
        dispatch(assignUserSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(assignUserFail(error.response.data.errors));
        }
      });
  };
};

export const getGraphData = ({
  page,
  deviceId,
  timeValue,
  startDate,
  endDate,
  timezone,
}) => {
  return (dispatch) => {
    dispatch(getGraphDataStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.csvdata}`;
    url += "?page=1";
    if (startDate && endDate) {
      const days = moment(endDate).diff(moment(startDate), "days");
      url += `?&pageSize=${!days ? 1440 : days * 1440}`;
    } else {
      url += "&pageSize=10000";
    }

    url += `&time=${timeValue}&deviceId=${deviceId}`;

    if (startDate) {
      url += `&startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;
    }
    let timezone = timezoneMoment.tz.guess();

    url += `&timezone=${timezone}&graph=1`;
    // if(timezone) {
    //   url += `&timezone=${timezone}`
    // }
    axios
      .get(url, { headers })
      .then((response) => {
        if (startDate) {
          var a = moment(startDate);
          var b = moment(endDate);

          // b.diff(a, "days") > 90
          //   ? dispatch(getRawDataSuccess2(response.data, true))
          //   :
          dispatch(getGraphDataSuccess(response.data));
        } else {
          dispatch(getGraphDataSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getGraphDataFail(error.response.data.errors));
        }
      });
  };
};

export const getRawData = ({
  page,
  timeDiff,
  deviceId,
  timeValue,
  startDate,
  endDate,
}) => {
  return (dispatch) => {
    dispatch(getRawDataStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.csvdata}`;

    url += `?page=${page}&pageSize=9`;

    if (timeValue !== undefined) {
      url += `&time=${timeValue}`;
    }
    if (timeDiff) {
      url += `&timeDiff=${timeDiff}`;
    }
    url += `&deviceId=${deviceId}`;

    if (startDate) {
      url += `&startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;
    }
    let timezone = timezoneMoment.tz.guess();

    url += `&timezone=${timezone}`;

    axios
      .get(url, { headers })
      .then((response) => {
        if (startDate) {
          var a = moment(startDate);
          var b = moment(endDate);

          b.diff(a, "days") > 90
            ? dispatch(getRawDataSuccess2(response.data, false))
            : dispatch(getRawDataSuccess(response.data));
        } else {
          dispatch(getRawDataSuccess(response.data));
        }
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(getRawDataFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};

const getRawDataCsvDownloadStart = () => {
  return {
    type: actionTypes.GET_CSV_DOWNLOAD_START,
  };
};

const getRawDataCsvDownloadSuccess = () => {
  return {
    type: actionTypes.GET_CSV_DOWNLOAD_SUCCESS,
  };
};

const getRawDataCsvDownloadFail = (error) => {
  return {
    type: actionTypes.GET_CSV_DOWNLOAD_FAIL,
    error: error,
  };
};
export const getRawDataCsvDownload = ({
  timeDiff,
  page,
  deviceId,
  timeValue,
  startDate,
  endDate,
  timezone,
}) => {
  return (dispatch) => {
    dispatch(getRawDataCsvDownloadStart());
    // window.open(link, '_blank')
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.device.downloadCsv}`;
    if (timeValue !== undefined) {
      url += `?time=${timeValue}`;
    }

    url += `&deviceId=${deviceId}`;

    if (!!timeDiff) {
      url += `&timeDiff=${timeDiff}`;
    }
    if (startDate) {
      url += `&startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;
    }
    let timezone = timezoneMoment.tz.guess();

    url += `&timezone=${timezone}`;
    axios
      .get(url, { headers })
      .then((response) => {
        let url = response.data.data.url;
        dispatch(getRawDataCsvDownloadSuccess());
        window.location.href = url;
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(getRawDataCsvDownloadFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};

const getRawDataPdfDownloadStart = () => {
  return {
    type: actionTypes.GET_PDF_DOWNLOAD_START,
  };
};
const getRawDataPdfDownloadSuccess = () => {
  return {
    type: actionTypes.GET_PDF_DOWNLOAD_SUCCESS,
  };
};
const getRawDataPdfDownloadFail = () => {
  return {
    type: actionTypes.GET_PDF_DOWNLOAD_FAIL,
  };
};

const getRawDataDownloadStart = () => {
  return {
    type: actionTypes.GET_RAW_DOWNLOAD_START,
  };
};
const getRawDataDownloadSuccess = () => {
  return {
    type: actionTypes.GET_RAW_DOWNLOAD_SUCCESS,
  };
};
const getRawDataDownloadFail = () => {
  return {
    type: actionTypes.GET_RAW_DOWNLOAD_FAIL,
  };
};

export const getRawDataPdfDownload = ({
  timeDiff,
  page,
  deviceId,
  timeValue,
  startDate,
  endDate,
  timezone,
}) => {
  return (dispatch) => {
    dispatch(getRawDataPdfDownloadStart());
    const headers = getCommonHeaders();

    let url = `${apiPath.baseUrl}${apiPath.device.downloadPdf}`;
    if (timeValue !== undefined) {
      url += `?time=${timeValue}`;
    }

    url += `&deviceId=${deviceId}`;
    if (!!timeDiff) {
      url += `&timeDiff=${timeDiff}`;
    }
    if (startDate) {
      url += `&startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;
    }
    let timezone = timezoneMoment.tz.guess();

    url += `&timezone=${timezone}`;

    axios
      .get(url, { headers })
      .then((response) => {
        let url = response.data.data.url;
        window.open(url, "_self");

        dispatch(getRawDataPdfDownloadSuccess());
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(getRawDataPdfDownloadFail(error.response.data.errors));
          }
        } catch (err) {}
      });
  };
};

export const getDownloadRawData = (payload) => {
  return (dispatch) => {
    dispatch(getRawDataDownloadStart());
    const headers = getCommonHeaders();
    const timezone = timezoneMoment.tz.guess();
    const { type, deviceId, from, to } = payload;
    let url =
      type === "csv"
        ? `${apiPath.device.downloadCsvData}?deviceId=${deviceId}&from=${from}&to=${to}&timezone=${timezone}`
        : `${apiPath.device.downloadPdfData}?deviceId=${deviceId}&from=${from}&to=${to}&timezone=${timezone}`;

    axios
      .get(url, { headers })
      .then((response) => {
        const responseURL = response.data.url;
        window.open(responseURL, "_self");

        dispatch(getRawDataDownloadSuccess());
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(getRawDataDownloadFail(error.response.data.errors));
          }
        } catch (err) {
          console.log(err);
        }
      });
  };
};
