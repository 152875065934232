import React from 'react';
import {
    Modal,
    ModalBody, 
    ModalHeader, 
    ModalFooter, 
    
    Button, 
} from 'reactstrap';

export function ConfirmationModal({
  isOpen,
  modalAction,
  modalBody,
  deleteName,
}) {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        Delete {`${deleteName || ''}`}
      </ModalHeader>
      <ModalBody>
        {
          modalBody ? modalBody : (
            <p>Are you sure you want to delete {deleteName ? `this ${deleteName}` : ''} ?</p>
          )
        }
      </ModalBody>
      <ModalFooter>
          <Button 
            color="info" 
            onClick={() => modalAction('confirm')}
          >Confirm
          </Button>
          <Button 
            color="secondary" 
            onClick={() => modalAction('cancel')}
          >Cancel
          </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmationModal;
