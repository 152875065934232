import React, { Component } from 'react';
import { convertDateFormat } from '../../utils/helperFunctions';
import { MDBBtn, MDBDataTable } from 'mdbreact';
import { Row, Col } from 'reactstrap';
import Pagination from '../Common/Pagination';
import users from '../../stores/reducers/users';

const superAdminColumn = [
    {
        label: 'Account',
        field: 'account',
        width: 100,
      },
      {
        label: 'Action',
        field: 'action',
        sort: "asc",
        width: 100,
      },
      {
        label: 'Event',
        field: 'event',
        sort: "asc",
        width: 100,
      },
      {
        label: 'Message',
        field: 'information',
        sort: "asc",
        width: 100,
      },
      {
        label: "Result",
        field: "result",
        sort: "asc",
        width: 100,
      },
      {
        label: "Time",
        field: "time",
        sort: 'desc',
        sort: "asc",
        width: 100,
      },
      {
        label: "User",
        field: "user",
        sort: "asc",
        width: 100,
      },
];
class AuditTrailTable extends Component {
  state = {
    loadTable: false,
    logs: [],
    pagination: {},
    role: localStorage.getItem('role'),
  };

  componentDidMount() {
    const { data } = this.props;
    if (data && data.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data &&
      this.props.data &&
      this.props.data.length
    ) {
      this.showTable(true);
    }
  }

  showTable = (loadTable) => {
    if (loadTable) {
      const {
        data,
        pagination,
        updateFirmware,
      } = this.props;
      const rows = data.map((item, index) => {
        
        let gatewayRow = { ...item };
        
        gatewayRow.message1 = gatewayRow.message;
        return gatewayRow;
      });
      console.log("==== data ", data)
      this.setState({
        loadTable,
        logs: {
          columns:
            superAdminColumn,
          rows: data,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { loadTable, logs } = this.state;
    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort"
              bordered
              data={logs}
              paging={false}
              scrollX
              displayEntries={true}
              searching={false}
            />
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default AuditTrailTable;
