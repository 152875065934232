import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  data: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const getUserNotificationsListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    data: [],
    meta: {},
    loading: true,
  });
};

const getUserNotificationsListSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getUserNotificationsListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    data: [],
    meta: {},
    loading: false,
  });
};

const userNotifications = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_NOTIFICATIONS_LIST_START:
      return getUserNotificationsListStart(state, action);
    case actionTypes.GET_USER_NOTIFICATIONS_LIST_SUCCESS:
      return getUserNotificationsListSuccess(state, action.data);
    case actionTypes.GET_USER_NOTIFICATIONS_LIST_FAIL:
      return getUserNotificationsListFail(state, action);
    default:
      return state;
  }
};

export default userNotifications;
