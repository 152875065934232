import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../stores/actions/index';
import { showToast } from '../../helpers/toast.js';
import { withNamespaces, Trans } from 'react-i18next';
import ContentWrapper from '../Layout/ContentWrapper.js';
import { Container, Card, CardHeader, CardTitle, CardBody, Input, Button } from 'reactstrap';
import { selectStyle } from "../../config/selectStyle.js";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import CardTool from '../Common/CardTool.js';
import UserMessageTemplatesTable from './UserMessageTemplatesTable.js';
class UserMessageTemplates extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      text: "",
      is_sms: -1,
      startDate: null,
      endDate: null,
      accountList: [],
      accountId: null
    };
  }

  componentDidMount() {
    const { getUserMessageTemplates } = this.props;
    getUserMessageTemplates({
      page: 1,
      pageSize: 10
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, 'success');
      this.changePage(1);
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, 'error');
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: '', modalData: {} });
      this.props.getUserMessageTemplates( {
        page: 1,
        pageSize: 10
      });
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName: modalName,
      modalData: modalData,
      accountList: this.state.accountList,
    });
  };

  addMessageTemplate = () => {
    this.showModal("addMessageTemplate", { });
  };

  editMessageTemplate = (template) => {
    this.showModal("addMessageTemplate", { template: template });
  };

  deleteMessageTemplate = (id) => {
    this.showModal("deleteMessageTemplate", { id: id });
  };

  changePage = (page) => {
    this.props.getUserMessageTemplates( {
      page: page,
      pageSize: 10
    });
  };

  
  render() {
    const {data, meta, loading, account } = this.props;

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Message Templates
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
        <div className="d-flex p-3 justify-content-end">
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.addMessageTemplate.bind(this)}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Add
            </button>
          </div>
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>Message Templates</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <CardBody>
              {data && data.length > 0 && (
                <UserMessageTemplatesTable
                  data={data}
                  editMessageTemplate={this.editMessageTemplate}
                  deleteMessageTemplate={this.deleteMessageTemplate}
                  changePage={(page) => this.changePage(page)}
                ></UserMessageTemplatesTable>
              )}
             {!!loading &&  <h3 className="text-center">Loading...</h3> }
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.userMessageTemplates.loading,
    data: state.userMessageTemplates.data,
    meta: state.userMessageTemplates.meta,
    success: state.userMessageTemplates.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserMessageTemplates: (data) => dispatch(actions.getUserMessageTemplates(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces('translations')(UserMessageTemplates));
