import React, { Component } from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import AddEditDevice from "../Devices/AddEditDevice";
import AddEditGateway from "../Gateways/AddEditGateway";
import DeleteGateway from "../Gateways/DeleteGateway";
import DeleteMap from "../Map/DeleteMap";
import DeleteMapDevice from "../Map/DeleteMapDevice";
import DeleteMapLocation from "../Map/DeleteMapLocation";
import ConfirmDeviceRemovedMap from "../Map/ConfirmDeviceRemovedMap";
import UpdateGatewayFirmware from "../Gateways/UpdateFrimware";
import DeleteDevice from "../Devices/DeleteDevice";
import AddEditUser from "../Users/AddEditUser";
import AddSmsLimit from "../UserNotificationsStatistics/AddSmsLimit";
import AddMessageTemplate from "../UserMessageTemplates/addMessageTemplate";
import DeleteMessageTemplate from "../UserMessageTemplates/deleteMessageTemplate";
import EditVoiceCall from "../Accounts/editVoiceCall";
import AddUserMessageTemplate from "../Users/AddUserMessageTemplate";
import AddMap from "../Map/AddMap";
import DeleteUser from "../Users/DeleteUser";
import AddEditEnvironmentReport from "../Reports/AddEditEnvironmentReport";
import DeleteLocation from "../Dashboard/DeleteLocation";
import AddDeviceLocation from "../Dashboard/AddDeviceLocation";
import AssignUser from "../Dashboard/AssignUser";
import AddEditDeviceAlarm from "../Dashboard/AddEditDeviceAlarm";
import DeleteDeviceAlarm from "../Dashboard/DeleteDeviceAlarm";
import ChooseDate from "../Dashboard/ChooseDate";
import AddEditOptionalReport from "../Reports/AddEditOptionalReport";
import UploadFirmware from "../Gateways/UploadFirmware";
import LogoutModal from "../Layout/LogoutModal";
import AlertProfiles from "../Dashboard/AlertProfiles";
import OfflineAlarms from "../Devices/OfflineAlarms";
import RecentAlarmComments from "../Dashboard/RecentAlarms/recentAlarmComments";
import ToggleTempModal from "../Layout/ToggleTempModal";
import AssociatedGatewayAndDevices from "../Devices/associatedGatewayAndDevices";

class GenericModal extends Component {
  state = {
    showModal: this.props.showModal,
    size: "md",
  };

  componentDidUpdate(prevProps) {
    if (prevProps.modalName !== this.props.modalName && this.props.modalName) {
      if (
        this.props.modalName === "addEditDeviceAlarm" ||
        this.props.modalName === "addEditEnvironmentReport" ||
        this.props.modalName === "addEditOptionalReport" ||
        this.props.modalName === "alertProfiles" ||
        this.props.modalName === "showAssociatedGateways"
      ) {
        this.updateState(true, "lg");
      } else {
        this.updateState(true);
      }
    }
    if (prevProps.showModal !== this.props.showModal && !this.props.showModal) {
      this.updateState(false);
    }
  }

  updateState(showModal, size = "md") {
    this.setState({ showModal, size });
  }

  toggleModal = () => {
    const { setModal } = this.props;
    this.setState({
      showModal: !this.state.showModal,
    });

    setModal({ showModal: false, modalName: "", modalData: {} });
  };

  render() {
    const { showModal, size } = this.state;
    const { modalName, modalData, loading } = this.props;
    return (
      <React.Fragment>
        <Modal
          isOpen={showModal}
          toggle={this.toggleModal}
          size={size}
          backdrop={false}
          centered={loading}
        >
          {
            {
              addEditDevice: (
                <AddEditDevice
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              deleteDevice: (
                <DeleteDevice
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              addEditGateway: (
                <AddEditGateway
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              deleteGateway: (
                <DeleteGateway
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              deleteMap: (
                <DeleteMap
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              deleteMapDevice: (
                <DeleteMapDevice
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              deleteMapLocation: (
                <DeleteMapLocation
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              confirmDeviceRemovedMap: (
                <ConfirmDeviceRemovedMap
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              updatefirmware: (
                <UpdateGatewayFirmware
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              uploadFirmware: (
                <UploadFirmware
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              addEditUser: (
                <AddEditUser
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              deleteUser: (
                <DeleteUser
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              addUserMessageTemplate: (
                <AddUserMessageTemplate
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              addSmsLimit: (
                <AddSmsLimit
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              addMessageTemplate: (
                <AddMessageTemplate
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              deleteMessageTemplate: (
                <DeleteMessageTemplate
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              editVoiceCall: (
                <EditVoiceCall
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              addMap: (
                <AddMap toggleModal={this.toggleModal} modalData={modalData} />
              ),
              addEditEnvironmentReport: (
                <AddEditEnvironmentReport
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              addEditOptionalReport: (
                <AddEditOptionalReport
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              deleteLocation: (
                <DeleteLocation
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              addDeviceLocation: (
                <AddDeviceLocation
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              assignUser: (
                <AssignUser
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              addEditDeviceAlarm: (
                <AddEditDeviceAlarm
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              deleteDeviceAlarm: (
                <DeleteDeviceAlarm
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              chooseDate: (
                <ChooseDate
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              logoutModal: (
                <LogoutModal
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              alertProfiles: (
                <AlertProfiles
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              offlineAlarms: (
                <OfflineAlarms
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              recentAlarmComments: (
                <RecentAlarmComments
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              toggleTempModal: (
                <ToggleTempModal
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
              showAssociatedGateways: (
                <AssociatedGatewayAndDevices
                  toggleModal={this.toggleModal}
                  modalData={modalData}
                />
              ),
            }[modalName]
          }
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showModal: state.modal.showModal,
    modalName: state.modal.modalName,
    modalData: state.modal.modalData,
    onCancel: state.modal.onCancel,
    loading: state.gateways.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModal: (data) => dispatch(actions.setModal(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericModal);
