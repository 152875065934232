import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
import { showToast } from "../../helpers/toast";
// import { dispatch } from "d3-dispatch";

export const getGatewayLogsListStart = () => {
    return {
        type: actionTypes.GET_GATEWAY_LOGS_LIST_START,
    };
};

export const getGatewayLogsListSuccess = (data) => {
    return {
        type: actionTypes.GET_GATEWAY_LOGS_LIST_SUCCESS,
        data: data,
    };
};

export const getGatewayLogsListFail = (error) => {
    return {
        type: actionTypes.GET_GATEWAY_LOGS_LIST_FAIL,
        error: error,
    };
};


export const getGatewayLogsList = (page = 1, gatewayId) => {
    const pageSize = 10;
    return (dispatch) => {
        dispatch(getGatewayLogsListStart());
        const headers = getCommonHeaders();
        let url = `${apiPath.baseUrl}${apiPath.gatewayLogs.list}`;
        // url = url.replace("{id}", gatewayId);
        if (page) {
            if (gatewayId) {
                url += `?page=${page}&pageSize=${pageSize}&gatewayId=${gatewayId}`;
            }
            else {
                url += `?page=${page}&pageSize=${pageSize}`;
            }
        } else {
            url += "?page=1&pageSize=1000";
        }
        axios
            .get(url, { headers })
            .then((response) => {
                dispatch(getGatewayLogsListSuccess(response.data));
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    dispatch(unauthorized());
                } else {
                    dispatch(getGatewayLogsListFail(error.response.data.errors));
                }
            });
    };
};
