import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import * as actions from '../../stores/actions/index';
import { showToast } from '../../helpers/toast.js';
import { fieldHasError, validateOnChange, onSubmit, } from '../../helpers/formValidators';

class ForgotPassword extends Component {
  state = {
    formForgot: {
      email: '',
    },
  };

  componentDidUpdate(prevProps) {
    // if (prevProps.token !== this.props.token && this.props.token) {
    //   showToast('Mail Sent Successfully', 'success');
    // }
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, 'success');
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, 'error');
    }
  }

  validateOnChange = (event) => {
    const state = validateOnChange(event, this.state);
    this.setState(state);
  };

  onSubmit = (event) => {
    const state = onSubmit(event, this.state)
    this.setState(state);
    if (!state.hasError) {
      const params = {
        email: this.state.formForgot.email,
      };
      this.props.forgotPassword(params);
    }
    event.preventDefault();
  };
  
  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    return (
      <React.Fragment>
        <p className='text-center py-2'>FORGOT PASSWORD</p>
        <form className='mb-3' name='formForgot' onSubmit={this.onSubmit}>
          <p className='text-center'>
            Fill with your mail to receive instructions on how to reset your
            password.
          </p>
          <div className='form-group'>
            <label className='text-muted' htmlFor='forgotPasswordEmail'>
              Email address
            </label>
            <div className='input-group with-focus'>
              <Input
                type='email'
                name='email'
                className='border-right-0 border-light'
                placeholder='Enter email'
                invalid={
                  this.hasError('formForgot', 'email', 'required') ||
                  this.hasError('formForgot', 'email', 'email')
                }
                onChange={this.validateOnChange}
                data-validate='["required", "email"]'
                value={this.state.formForgot.email}
              />
              <div className='input-group-append'>
                <span className='input-group-text text-muted bg-transparent border-left-0'>
                  <em className='fa fa-envelope'></em>
                </span>
              </div>
              {this.hasError('formForgot', 'email', 'required') && (
                <span className='invalid-feedback'>Email is required</span>
              )}
              {this.hasError('formForgot', 'email', 'email') && (
                <span className='invalid-feedback'>Email must be valid</span>
              )}
            </div>
          </div>
          <button className='btn btn-danger btn-block' type='submit'>
            Send Link
          </button>
        </form>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.forgotPassword.loading,
    error: state.forgotPassword.error,
    success : state.forgotPassword.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (params) => dispatch(actions.forgotPassword(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
