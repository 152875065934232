import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  gatewayLogsList: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const getGatewayLogsListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    gatewayLogsList: [],
    meta: {},
    loading: true,
  });
};

const getGatewayLogsListSuccess = (state, action) => {
  return updateObject(state, {
    gatewayLogsList: action.gatewayLogs,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getGatewayLogsListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    gatewayLogsList: [],
    meta: {},
    loading: false,
  });
};


const gatewayLogs = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GATEWAY_LOGS_LIST_START:
      return getGatewayLogsListStart(state, action);
    case actionTypes.GET_GATEWAY_LOGS_LIST_SUCCESS:
      return getGatewayLogsListSuccess(state, action.data);
    case actionTypes.GET_GATEWAY_LOGS_LIST_FAIL:
      return getGatewayLogsListFail(state, action);
    
    default:
      return state;
  }
};

export default gatewayLogs;
