import React, { Component } from 'react';
import { ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../stores/actions/index';

class ConfirmDeviceRemovedMap extends Component {

    render () {
        const { toggleModal, modalData } = this.props;
        return (
            <React.Fragment>
                <ModalHeader toggle={toggleModal}>Removed device</ModalHeader>
                <ModalBody>
                    <p>{`Device ${(modalData.title) ? modalData.title : ''} has been moved or deleted from its location. To receive status updates further, the device needs to be relocated.`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Confirm</Button>
                </ModalFooter> 
            </React.Fragment>
        );
    }
}

export default ConfirmDeviceRemovedMap;
