import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";

export const updateOfflineAlarmsStart = () => {
  return {
    type: actionTypes.UPDATE_OFFLINE_ALARMS_START,
  };
};

export const updateOfflineAlarmsSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_OFFLINE_ALARMS_SUCCESS,
    data: data,
  };
};

export const updateOfflineAlarmsFail = (error) => {
  return {
    type: actionTypes.UPDATE_OFFLINE_ALARMS_FAIL,
    error: error,
  };
};

export const getOfflineAlarmsStart = () => {
  return {
    type: actionTypes.GET_OFFLINE_ALARMS_START,
  };
};

export const getOfflineAlarmsSuccess = (data) => {
  return {
    type: actionTypes.GET_OFFLINE_ALARMS_SUCCESS,
    data: data,
  };
};

export const getOfflineAlarmsFail = (error) => {
  return {
    type: actionTypes.GET_OFFLINE_ALARMS_FAIL,
    error: error,
  };
};

export const resetAllInfo = () => {
  return {
    type: actionTypes.RESET_ALL_ALARMS_INFO,
  };
};

export const updateOfflineAlarms = (payload) => {
  return (dispatch) => {
    dispatch(updateOfflineAlarmsStart());
    const headers = getCommonHeaders();
    const url = `${apiPath.baseUrl}${apiPath.offlineAlarms.offlineAlarmsUrl}`;
    axios
      .put(url, payload, { headers })
      .then((response) => {
        dispatch(updateOfflineAlarmsSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 500) {
          dispatch(updateOfflineAlarmsFail([error.response.data.message]));
        } else {
          dispatch(updateOfflineAlarmsFail(error.response.data.errors));
        }
      });
  };
};

export const getOADetails = () => {
  return (dispatch) => {
    dispatch(getOfflineAlarmsStart());
    const headers = getCommonHeaders();
    const url = `${apiPath.baseUrl}${apiPath.offlineAlarms.offlineAlarmsUrl}`;
    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getOfflineAlarmsSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else if (error.response.status === 500) {
          dispatch(getOfflineAlarmsFail([error.response.data.message]));
        } else {
          dispatch(getOfflineAlarmsFail(error.response.data.errors));
        }
      });
  };
};

export const resetAlarms = () => {
  return (dispatch) => {
    dispatch(resetAllInfo());
  };
};
