import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { fieldHasError } from "../../helpers/formValidators";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";
import { showToast } from "../../helpers/toast";
import "./reports.scss";
import TimezoneSelect from "react-timezone-select";
import moment from "moment-timezone";
import { REPORT_OPTIONAL } from "./Reports";
const freqButtons = [
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 2 },
  { label: "Monthly", value: 3 },
];

// const reportTime = [
//   { label: '1 Day', value: 0 },
//   { label: '1 Week', value: 1 },
//   { label: '1 Month', value: 2 },
//   { label: '3 Months', value: 3 },
//   { label: '6 Months', value: 4 },
// ];

// const groupData = [
//   { label: 'DataStream', value: 0 },
//   { label: 'Device', value: 1 },
//   { label: 'Report', value: 2 },
// ];
const docFormat = [
  { label: "CSV", value: 0 },
  { label: "PDF", value: 1 },
];
const sortBy = [
  { label: "Oldest", value: 0 },
  { label: "Latest", value: 1 },
];
class AddEditOptionalReport extends Component {
  state = {
    mode: "Add",
    formReport: {
      reportName: "",
      doorOpeningEnabled: true,
      ln2LavelEnabled: true,
      ln2TempEnabled: true,
      waterLevelEnabled: true,
      selectedFreq: 1,
      selectedReportTime: 0,

      docFormat: 0,
      sortBy: 1,
      notifyAdmin: true,
      alarmEnabled: true,
      offset: 0,
      timezone: "",
      selectedUsers: [],
      selectedDevices: [],
    },
    userId: "",
    deviceId: "",
    dropDownUsers: [],
    dropDownDevices: [],
    reportId: "",
  };

  componentDidMount() {
    const { users, deviceList, modalData } = this.props;
    if (Object.keys(modalData).length) {
      // edit case
      const { report } = modalData;
      const { formReport } = this.state;
      let postedUsers = [];
      report.reportsUsers.forEach((user) => postedUsers.push(user.users));

      let newUserArray = [];
      postedUsers.forEach((el) =>
        users.forEach((user) => {
          if (user.id !== el.id) {
            newUserArray.push(user);
          }
        })
      );

      let postedDevices = [];
      report.reportsDevices.forEach((device) =>
        postedDevices.push(device.devices)
      );

      let newDeviceArray = [];
      postedDevices.forEach((el) =>
        deviceList.forEach((device) => {
          if (device.id !== el.id) {
            newDeviceArray.push(device);
          }
        })
      );

      this.setState({
        formReport: {
          ...formReport,
          reportName: report.name,
          doorOpeningEnabled: report.door === "Enabled" ? true : false,
          ln2LavelEnabled: report.lnlvl === "Enabled" ? true : false,
          ln2TempEnabled: report.lntmp === "Enabled" ? true : false,

          water: report.waterLevelEnabled === "Enabled" ? true : false,
          selectedFreq: report.frequency,
          selectedReportTime: report.duration,
          notifyAdmin: report.adminEnable === "Enabled" ? true : false,
          alarmEnabled: report.enable === "Enabled" ? true : false,
          selectedUsers: postedUsers,
          selectedDevices: postedDevices,
          offset: report.offset,
          docFormat: report.format,
          sortBy: report.sortBy,
          timezone: {
            value: report.timezone,
          },
        },
        dropDownUsers: newUserArray,
        dropDownDevices: newDeviceArray,
        mode: "Edit",
        reportId: report.id,
      });
    } else {
      this.setState({
        dropDownUsers: users,
        dropDownDevices: deviceList,
      });
    }
  }

  addReportFn = (payload) => {
    const { addReport } = this.props;
    addReport(payload);
  };

  editReportFn = (payload, id) => {
    const { editReport } = this.props;
    editReport({ payload, id });
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const { formReport } = this.state;
    formReport[name] = checked;
    this.setState({
      formReport,
    });
  };

  handleButtonChange = (key, value) => {
    const { formReport } = this.state;
    formReport[key] = value;
    this.setState({
      formReport,
    });
  };

  handleChangeDevice = (selectedDevice) => {
    const { deviceList } = this.props;
    if (!!selectedDevice && selectedDevice.id) {
      const isExisting = this.state.formReport.selectedDevices.find((el) => {
        return el.id === selectedDevice.id;
      });
      if (!isExisting) {
        this.setState(
          {
            deviceId: selectedDevice,
            formReport: {
              ...this.state.formReport,
              selectedDevices: [
                ...this.state.formReport.selectedDevices,
                selectedDevice,
              ],
            },
          },
          () => {
            const array = deviceList.filter((el) => {
              return !this.state.formReport.selectedDevices.includes(el);
            });

            this.setState({
              dropDownDevices: array,
            });
          }
        );
      } else {
        showToast("Device already exists", "error");
      }
    }
  };

  handleChangeUser = (selectedUser) => {
    if (!!selectedUser && selectedUser.email) {
      const isExisting = this.state.formReport.selectedUsers.find((el) => {
        return el.email === selectedUser.email;
      });

      if (!isExisting) {
        this.setState(
          {
            userId: selectedUser,
            formReport: {
              ...this.state.formReport,
              selectedUsers: [
                ...this.state.formReport.selectedUsers,
                selectedUser,
              ],
            },
          },
          () => {
            const array = this.props.users.filter((el) => {
              return !this.state.formReport.selectedUsers.includes(el);
            });

            this.setState({
              dropDownUsers: array,
            });
          }
        );
      } else {
        showToast("User already exists", "error");
      }
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { dashboard } = this.props;
    const { userInfo } = dashboard;
    let userRole = "";
    if (userInfo) {
      const { role } = userInfo;
      userRole = role;
    }
    const { formReport, reportId, mode } = this.state;
    if (formReport.reportName.length === 0) {
      showToast("Report Name is required", "error");
      return false;
    }
    if (formReport.timezone.length === 0) {
      showToast("Timezone is required", "error");
      return false;
    }
    if (userRole === "super_user") {
      if (formReport.selectedUsers.length === 0 && !formReport.notifyAdmin) {
        showToast("Please enter atleast one user", "error");
        return false;
      }
    }
    if (formReport.selectedDevices.length === 0) {
      showToast("Please enter atleast one device", "error");
      return false;
    }
    // this.setState(state);
    const users = [];
    formReport.selectedUsers.forEach((user) => {
      users.push(user.id);
    });

    const devices = [];
    formReport.selectedDevices.forEach((device) => {
      devices.push(device.id);
    });

    const params = {
      name: formReport.reportName,
      door: formReport.doorOpeningEnabled === true ? 1 : 0,
      lnlvl: formReport.ln2LavelEnabled === true ? 1 : 0,
      lntmp: formReport.ln2TempEnabled === true ? 1 : 0,

      water: formReport.waterLevelEnabled === true ? true : false,
      adminEnable: formReport.notifyAdmin === true ? 1 : 0,
      enable: formReport.alarmEnabled === true ? 1 : 0,
      frequency: formReport.selectedFreq,
      offset: formReport.offset,
      timezone: formReport.timezone.value,
      format: formReport.docFormat,
      sortBy: formReport.sortBy,
      type: REPORT_OPTIONAL,
      duration: "",
      devices,
      users,
    };
    if (params.frequency === 1) {
      params.duration = 0;
    } else if (params.frequency === 2) {
      params.duration = 1;
    } else {
      params.duration = 2;
    }
    if (mode === "Add") {
      this.addReportFn(params);
    } else {
      this.editReportFn(params, reportId);
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  removeUser = (user) => {
    const { formReport } = this.state;
    let { selectedUsers } = formReport;
    selectedUsers = selectedUsers.filter((item) => item.email !== user.email);
    const array = this.props.users.filter((el) => {
      return !selectedUsers.includes(el);
    });

    this.setState({
      formReport: {
        ...this.state.formReport,
        selectedUsers: [...selectedUsers],
      },
      dropDownUsers: array,
    });
  };

  removeDevice = (device) => {
    const { formReport } = this.state;
    let { selectedDevices } = formReport;
    selectedDevices = selectedDevices.filter((item) => item.id !== device.id);
    const array = this.props.deviceList.filter((el) => {
      return !selectedDevices.includes(el);
    });
    this.setState({
      formReport: {
        ...this.state.formReport,
        selectedDevices: [...selectedDevices],
      },
      dropDownDevices: array,
    });
  };
  handleTimeZoneChange = (e) => {
    let value = e.value;
    let zoneTime = moment().tz(value);
    let offset = zoneTime.utcOffset();
    this.setState({
      formReport: {
        ...this.state.formReport,
        timezone: {
          value: value,
        },
        offset: offset,
      },
    });
  };
  render() {
    const { toggleModal, dashboard } = this.props;
    const { userInfo } = dashboard;
    let userRole = "";
    if (userInfo) {
      const { role } = userInfo;
      userRole = role;
    }
    const {
      mode,
      formReport,
      userId,
      deviceId,
      dropDownUsers,
      dropDownDevices,
    } = this.state;

    return (
      <React.Fragment>
        <form className="mb-3" name="formDevice" onSubmit={this.onSubmit}>
          <ModalHeader toggle={toggleModal}>{mode} Optional Report</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="text-muted">Report Name</label>
              <div className="input-group with-focus">
                <Input
                  type="text"
                  name="reportName"
                  placeholder="Enter report name"
                  invalid={this.hasError(
                    "formReport",
                    "reportName",
                    "required"
                  )}
                  onChange={(e) =>
                    this.handleButtonChange("reportName", e.target.value, e)
                  }
                  data-validate='["required"]'
                  value={
                    !!this.state.formReport.reportName.trim()
                      ? this.state.formReport.reportName
                      : ""
                  }
                  minLength="2"
                />
                {this.hasError("formReport", "reportName", "required") && (
                  <span className="invalid-feedback">
                    Report name is required
                  </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="text-muted">Select DataStream</label>
              <div className="container-fluid">
                <div className="tempSlider row mt-2 justify-content-center">
                  <div className="col-lg-4 col-sm-6 d-flex justify-content-center align-item-center">
                    <div className="enableCheckbox">
                      <p>
                        <Input
                          type="checkbox"
                          id="doorOpeningEnabled"
                          name="doorOpeningEnabled"
                          checked={formReport.doorOpeningEnabled}
                          onChange={this.handleCheckboxChange}
                        />
                        <label htmlFor="doorOpeningEnabled">
                          <span className="ui"></span>
                          <span className="checkbox-span">Door Opening</span>
                        </label>
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-sm-6 d-flex justify-content-center align-item-center">
                    <div className="enableCheckbox">
                      <p>
                        <Input
                          type="checkbox"
                          id="ln2LavelEnabled"
                          name="ln2LavelEnabled"
                          checked={formReport.ln2LavelEnabled}
                          onChange={this.handleCheckboxChange}
                        />
                        <label htmlFor="ln2LavelEnabled">
                          <span className="ui"></span>
                          <span className="checkbox-span">LN2 Level</span>
                        </label>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 d-flex justify-content-center align-item-center">
                    <div className="enableCheckbox">
                      <p>
                        <Input
                          type="checkbox"
                          id="ln2TempEnabled"
                          name="ln2TempEnabled"
                          checked={formReport.ln2TempEnabled}
                          onChange={this.handleCheckboxChange}
                        />
                        <label htmlFor="ln2TempEnabled">
                          <span className="ui"></span>
                          <span className="checkbox-span">LN2 Temperature</span>
                        </label>
                      </p>
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-sm-6 d-flex justify-content-center align-item-center">
                    <div className="enableCheckbox">
                      <p>
                        <Input
                          type="checkbox"
                          id="waterLevelEnabled"
                          name="waterLevelEnabled"
                          checked={formReport.waterLevelEnabled}
                          onChange={this.handleCheckboxChange}
                        />
                        <label htmlFor="waterLevelEnabled">
                          <span className="ui"></span>
                          <span className="checkbox-span">Water Level</span>
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {formReport.selectedUsers.length ? (
              <div>
                <label className="text-muted mt-2">Added Users</label>
                <div className="locations alarmUsers">
                  {formReport.selectedUsers.map((user, index) => (
                    <div
                      className="locationDiv align-items-center d-flex flex-row overflow-auto"
                      key={index}
                      ref={index}
                    >
                      <div className="w-75 d-flex flex-column">
                        <p className="pl-1 pr-1 m-0 w-100 text-uppercase font-weight-bold">
                          {user.name || ""}
                        </p>
                        <p className="pl-1 pr-1 m-0 w-100 font-weight-bold">
                          {user.email || ""}
                        </p>
                        <p className="pl-1 pr-1 m-0 w-100 font-weight-bold">
                          {user.countryCode}-{user.phoneNumber || ""}
                        </p>
                      </div>
                      <div className="w-25 text-right">
                        <button
                          type="button"
                          className="transparentBtn"
                          id="removeButton"
                          onClick={() => this.removeUser(user)}
                        >
                          <i className=" fas fa-trash mt-0"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {userRole === "super_user" && (
              <div className="form-group">
                <label className="text-muted">Select Users</label>
                <Select
                  styles={selectStyle}
                  name="userId"
                  value={userId}
                  onChange={this.handleChangeUser}
                  options={dropDownUsers}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option}`}
                />
              </div>
            )}
            {formReport.selectedDevices.length ? (
              <div>
                <label className="text-muted mt-2">Added Devices</label>
                <div className="locations alarmUsers">
                  {formReport.selectedDevices.map((device, index) => (
                    <div
                      className="locationDiv align-items-center d-flex flex-row overflow-auto"
                      key={index}
                      ref={index}
                    >
                      <div className="w-75 d-flex flex-column">
                        <p className="pl-1 pr-1 m-0 w-100 text-uppercase font-weight-bold">
                          {device.name || ""}
                        </p>
                      </div>
                      <div className="w-25 text-right">
                        <button
                          className="transparentBtn"
                          id="removeDevice"
                          type="button"
                          onClick={() => this.removeDevice(device)}
                        >
                          <i className=" fas fa-trash mt-0"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label className="text-muted">Select Devices</label>
              <Select
                styles={selectStyle}
                name="deviceId"
                id="deviceId"
                value={deviceId}
                onChange={this.handleChangeDevice}
                options={dropDownDevices}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option}`}
              />
            </div>
            <div className="form-group">
              <label className="text-muted">Select Timezone</label>
              <TimezoneSelect
                styles={selectStyle}
                name="timezone"
                id="timeZone"
                data-validate='["required"]'
                value={formReport.timezone}
                onChange={this.handleTimeZoneChange}
              />
            </div>
            <div className="form-group">
              <label className="text-muted">Report Frequency</label>
              <div className="frequency mt-2">
                {freqButtons.map((each, index) => {
                  return (
                    <Button
                      key={index}
                      color="info"
                      className={`mb-2 ${
                        formReport.selectedFreq === each.value ? "active" : ""
                      }`}
                      onClick={() =>
                        this.handleButtonChange("selectedFreq", each.value)
                      }
                    >
                      {each.label}
                    </Button>
                  );
                })}
              </div>
            </div>

            <div className="form-group">
              <label className="text-muted">Document Format</label>
              <div className="frequency mt-2">
                {docFormat.map((each, index) => {
                  return (
                    <Button
                      key={index}
                      color="info"
                      className={`mb-2 ${
                        formReport.docFormat === each.value ? "active" : ""
                      }`}
                      onClick={() =>
                        this.handleButtonChange("docFormat", each.value)
                      }
                    >
                      {each.label}
                    </Button>
                  );
                })}
              </div>
            </div>
            <div className="form-group">
              <label className="text-muted">Data sort by</label>
              <div className="frequency mt-2">
                {sortBy.map((each, index) => {
                  return (
                    <Button
                      key={index}
                      color="info"
                      className={`mb-2 ${
                        formReport.sortBy === each.value ? "active" : ""
                      }`}
                      onClick={() =>
                        this.handleButtonChange("sortBy", each.value)
                      }
                    >
                      {each.label}
                    </Button>
                  );
                })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8 row mb-2 mb-lg-0">
                  <div className="col-sm-6">
                    <div className="form-group enableCheckbox m-0">
                      <p>
                        <Input
                          type="checkbox"
                          id="notifyAdmin"
                          name="notifyAdmin"
                          checked={formReport.notifyAdmin}
                          onChange={this.handleCheckboxChange}
                        />
                        <label htmlFor="notifyAdmin">
                          <span className="ui"></span>
                          <span className="checkbox-span">Notify Admin</span>
                        </label>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group enableCheckbox m-0">
                      <p>
                        <Input
                          type="checkbox"
                          id="alarmEnabled"
                          name="alarmEnabled"
                          checked={formReport.alarmEnabled}
                          onChange={this.handleCheckboxChange}
                        />
                        <label htmlFor="alarmEnabled">
                          <span className="ui"></span>
                          <span className="checkbox-span">Report Enabled</span>
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-end">
                  <Button color="info" type="submit">
                    {mode === "Add" ? "Add" : "Save"}
                  </Button>
                  <Button
                    className="ml-2"
                    color="secondary"
                    type="button"
                    onClick={toggleModal}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users.userList,
    deviceList: state.devices.deviceList,
    locations: state.locationsDevices.locations,
    dashboard: state.dashboard.dashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addReport: (data) => dispatch(actions.addReport(data)),
    editReport: (data) => dispatch(actions.editReport(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditOptionalReport);
