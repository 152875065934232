import React, { Component } from 'react';
import {Router} from 'react-router-dom';
import { connect } from 'react-redux';
// App Routes
import Routes from './Routes';

// Vendor dependencies
import './Vendor';
// Application Styles
import './assets/styles/bootstrap.scss';
import './assets/styles/app.scss';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as actions from './stores/actions/index';
import history from './helpers/history';

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  componentDidUpdate() {
    this.props.onTryAutoSignup();
  }

  render() {
    return (
      <Router history={history}>
        <ToastContainer />
        <Routes isAuthenticated={this.props.isAuthenticated} />
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
