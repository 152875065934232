import React, { Component } from 'react';
import { ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../stores/actions/index';

class DeleteMapLocation extends Component {

    submitModal = () => {
        const { modalData } = this.props;
        if(modalData.deleteMapLocation){
            modalData.deleteMapLocation()
        }
    }

    render () {
        const { toggleModal } = this.props;
        return (
            <React.Fragment>
                <ModalHeader toggle={toggleModal}>Delete Location</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to delete this location ?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.submitModal}>Confirm</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter> 
            </React.Fragment>
        );
    }
}

export default DeleteMapLocation;
