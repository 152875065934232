import moment from 'moment';

export function disableBackgroundScroll() {
    document.body.classList.add('overflow-hidden');
}

export function enableBackgroundScroll() {
    document.body.classList.remove('overflow-hidden');
}

export function convertDateFormat(date, formatName) {
    try {
        /*
        output format:Feb 3, 2020 2:07 PM
        */
        if (formatName === 'shortDateTime') {
            return moment(date).format('lll');
        }
        /*
        output format:3rd Feb 2020
        */
        if (formatName === 'abbDate') {
            return moment(date).format('Do MMM YYYY');
        }
        /*
        output format:Feb 20, 2020
        */
        if (formatName === 'shortDate') {
            return moment(date).format('ll');
        }
        return date;
    } catch (e) {
        return date;
    }
}

export function converFileIntoBinary(file) {
    return new Promise(resolve => {
        const fr = new FileReader();
        fr.onload = () => {
            resolve(fr.result);
        };
        fr.readAsArrayBuffer(file);
    });
}

export function convertHTMLToData(htmlData) {
    return htmlData.replace(/(<([^>]+)>)/ig, '');
}

export const getNumericChars = str => {
    if (str) {
        return str.replace(/\D/g, '');
    }
    return str;
};

export const fileDownload = (blobData, name) => {
    const blob = new Blob([blobData]);
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(fileUrl);
};

export const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) => {
    return [first.toLocaleUpperCase(locale), ...rest].join('');
}
