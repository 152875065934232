import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  data: [],
  meta: {},
  error: undefined,
  success: undefined,
  loading: false,
  reload: false,
};

const getUserMessageTemplatesRelStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    data: [],
    meta: {},
    loading: true,
  });
};

const getUserMessageTemplatesRelSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getUserMessageTemplatesRelFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    data: [],
    meta: {},
    loading: false,
  });
};

const setUserMessageTemplateRelStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    data: [],
    meta: {},
    loading: true,
  });
};

const setUserMessageTemplateRelSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    meta: action.meta,
    error: undefined,
    loading: false,
    success: "Template added successfully.",
  });
};

const setUserMessageTemplateRelFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    data: [],
    meta: {},
    loading: false,
  });
};


const userMessageTemplates = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_MESSAGE_TEMPLATE_REL_START:
      return getUserMessageTemplatesRelStart(state, action);
    case actionTypes.GET_USER_MESSAGE_TEMPLATE_REL_SUCCESS:
      return getUserMessageTemplatesRelSuccess(state, action.data);
    case actionTypes.GET_USER_MESSAGE_TEMPLATE_REL_FAIL:
      return getUserMessageTemplatesRelFail(state, action);
    case actionTypes.SET_USER_MESSAGE_TEMPLATE_REL_START:
      return setUserMessageTemplateRelStart(state, action);
    case actionTypes.SET_USER_MESSAGE_TEMPLATE_REL_SUCCESS:
      return setUserMessageTemplateRelSuccess(state, action.data);
    case actionTypes.SET_USER_MESSAGE_TEMPLATE_REL_FAIL:
      return setUserMessageTemplateRelFail(state, action);
    default:
      return state;
  }
};

export default userMessageTemplates;
