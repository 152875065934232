import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utilities";

const initialState = {
  exceeded: false,
  nodate: false,
  startDate: "",
  endDate: "",
  locations: [],
  error: false,
  loading: false,
  reload: false,
  reloadDevices: false,
  deviceSaved: false,
  loadingDevices: false,
  deviceDataList: [],
  pagedDeviceDataList: [],
  pagedDeviceMeta: {},
  basicData: { datatime: "", temperature: "", humidity: "", carbon: "" },
};

const setExceeded = (state, action) => {
  return updateObject(state, {
    exceeded: action.exceeded,
  });
};
const setNoDateSelected = (state, action) => {
  return updateObject(state, {
    nodate: action.nodate,
  });
};
const setStartDate = (state, action) => {
  return updateObject(state, {
    startDate: action.date,
  });
};

const setEndDate = (state, action) => {
  return updateObject(state, {
    endDate: action.date,
  });
};

const getLocationsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    reload: false,
  });
};

const getLocationsSuccess = (state, action) => {
  return updateObject(state, {
    locations: action.locationList,
    loading: false,
  });
};

const getLocationsFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    locations: [],
    loading: false,
  });
};

const editLocationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    reload: false,
  });
};

const editLocationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    reload: true,
  });
};

const editLocationFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reload: false,
  });
};
const addLocationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    reload: false,
  });
};

const addLocationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    reload: true,
  });
};

const addLocationFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reload: false,
  });
};
const deleteLocationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    reload: false,
  });
};

const deleteLocationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    reload: true,
  });
};

const deleteLocationFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reload: false,
  });
};

const editDeviceStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    deviceSaved: false,
    reloadDevices: false,
  });
};

const editDeviceSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    // reload: true,
    deviceSaved: true,
    reloadDevices: true,
    // deviceList: []
  });
};

const editDeviceFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    deviceSaved: false,
    reloadDevices: false,
  });
};

const getDeviceListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loadingDevices: true,
  });
};

const getDeviceListSuccess = (state, action) => {
  return updateObject(state, {
    devices: action.deviceList,
    error: undefined,
    loadingDevices: false,
  });
};

const getDeviceListNone = (state, action) => {
  return updateObject(state, {
    devices: [],
    loadingDevices: false,
  });
};

const getDeviceListFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    devices: [],
    loadingDevices: false,
  });
};

const getAllDevicesStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    allDevices: true,
  });
};

const getAllDevicesSuccess = (state, action) => {
  return updateObject(state, {
    allDevices: action.deviceList,
    error: undefined,
  });
};

const getAllDevicesFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    allDevices: [],
  });
};

const removeDeviceFromLocation = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loadingDevices: true,
    reloadDevices: false,
  });
};

const removeDeviceFromLocationSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loadingDevices: false,
    reloadDevices: true,
  });
};

const removeDeviceFromLocationFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loadingDevices: false,
  });
};

const assignUserStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reloadDevices: false,
  });
};

const assignUserSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reloadDevices: true,
    // deviceList: []
  });
};

const assignUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reloadDevices: false,
  });
};

const unassignUserStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reloadDevices: false,
  });
};

const unassignUserSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reloadDevices: true,
    // deviceList: []
  });
};

const unassignUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reloadDevices: false,
  });
};

const getGraphDataStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    graphLoading: true,
  });
};

const getGraphDataSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    deviceDataList: action.data.deviceDataList,
    loading: false,
    graphLoading: false,
  });
};

const getGraphDataFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
  });
};

const getRawDataStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
  });
};

const getRawDataSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    pagedDeviceDataList: action.data.deviceDataList,
    pagedDeviceMeta: action.data.meta,
    showGraph: action.graph,
  });
};

const getRawDataFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
  });
};

const getRawDataCsvDownloadStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
  });
};
const getRawDataCsvDownloadSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: undefined,
  });
};
const getRawDataCsvDownloadFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
  });
};
const getRawDataPdfDownloadStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
  });
};
const getRawDataPdfDownloadSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: undefined,
  });
};
const getRawDataPdfDownloadFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
  });
};

const getRawDataDownloadStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
  });
};
const getRawDataDownloadSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: undefined,
  });
};
const getRawDataDownloadFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
  });
};

const locationsDevices = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NO_DATE_SELECTED:
      return setNoDateSelected(state, action);
    case actionTypes.SET_EXCEEDED:
      return setExceeded(state, action);
    case actionTypes.SET_START_DATE:
      return setStartDate(state, action);
    case actionTypes.SET_END_DATE:
      return setEndDate(state, action);
    case actionTypes.GET_LOCATIONS_START:
      return getLocationsStart(state, action);
    case actionTypes.GET_LOCATIONS_SUCCESS:
      return getLocationsSuccess(state, action.data);
    case actionTypes.GET_LOCATIONS_FAIL:
      return getLocationsFail(state, action);
    case actionTypes.EDIT_LOCATION_START:
      return editLocationStart(state, action);
    case actionTypes.EDIT_LOCATION_SUCCESS:
      return editLocationSuccess(state, action.data);
    case actionTypes.EDIT_LOCATION_FAIL:
      return editLocationFail(state, action);
    case actionTypes.ADD_LOCATION_START:
      return addLocationStart(state, action);
    case actionTypes.ADD_LOCATION_SUCCESS:
      return addLocationSuccess(state, action.data);
    case actionTypes.ADD_LOCATION_FAIL:
      return addLocationFail(state, action);
    case actionTypes.DELETE_LOCATION_START:
      return deleteLocationStart(state, action);
    case actionTypes.DELETE_LOCATION_SUCCESS:
      return deleteLocationSuccess(state, action.data);
    case actionTypes.DELETE_LOCATION_FAIL:
      return deleteLocationFail(state, action);
    case actionTypes.GET_DEVICE_LOCATION_START:
      return getDeviceListStart(state, action);
    case actionTypes.GET_DEVICE_LOCATION_SUCCESS:
      return getDeviceListSuccess(state, action.data);
    case actionTypes.GET_DEVICE_LOCATION_FAIL:
      return getDeviceListFail(state, action);
    case actionTypes.GET_DEVICE_LOCATION_NONE:
      return getDeviceListNone(state, action);

    case actionTypes.GET_ALL_DEVICES_START:
      return getAllDevicesStart(state, action);
    case actionTypes.GET_ALL_DEVICES_SUCCESS:
      return getAllDevicesSuccess(state, action.data);
    case actionTypes.GET_ALL_DEVICES_FAIL:
      return getAllDevicesFail(state, action);

    case actionTypes.DELETE_LOCATION_DEVICE:
      return removeDeviceFromLocation(state, action);
    case actionTypes.DELETE_LOCATION_DEVICE_SUCCESS:
      return removeDeviceFromLocationSuccess(state, action.data);
    case actionTypes.DELETE_LOCATION_DEVICE_FAIL:
      return removeDeviceFromLocationFail(state, action);

    case actionTypes.EDIT_DEVICE_LOCATION_START:
      return editDeviceStart(state, action);
    case actionTypes.EDIT_DEVICE_LOCATION_SUCCESS:
      return editDeviceSuccess(state, action.data);
    case actionTypes.EDIT_DEVICE_LOCATION_FAIL:
      return editDeviceFail(state, action);
    case actionTypes.ASSIGN_USER_START:
      return assignUserStart(state, action);
    case actionTypes.ASSIGN_USER_SUCCESS:
      return assignUserSuccess(state, action);
    case actionTypes.ASSIGN_USER_FAIL:
      return assignUserFail(state, action);
    case actionTypes.UNASSIGN_USER_START:
      return unassignUserStart(state, action);
    case actionTypes.UNASSIGN_USER_SUCCESS:
      return unassignUserSuccess(state, action);
    case actionTypes.UNASSIGN_USER_FAIL:
      return unassignUserFail(state, action);
    case actionTypes.GET_GRAPH_DATA_START:
      return getGraphDataStart(state, action);
    case actionTypes.GET_GRAPH_DATA_SUCCESS:
      return getGraphDataSuccess(state, action);
    case actionTypes.GET_GRAPH_DATA_FAIL:
      return getGraphDataFail(state, action);
    case actionTypes.GET_RAW_DATA_START:
      return getRawDataStart(state, action);
    case actionTypes.GET_RAW_DATA_SUCCESS:
      return getRawDataSuccess(state, action);
    case actionTypes.GET_RAW_DATA_FAIL:
      return getRawDataFail(state, action);
    case actionTypes.GET_CSV_DOWNLOAD_START:
      return getRawDataCsvDownloadStart(state, action);
    case actionTypes.GET_CSV_DOWNLOAD_SUCCESS:
      return getRawDataCsvDownloadSuccess(state, action);
    case actionTypes.GET_CSV_DOWNLOAD_FAIL:
      return getRawDataCsvDownloadFail(state, action);

    case actionTypes.GET_PDF_DOWNLOAD_START:
      return getRawDataPdfDownloadStart(state, action);
    case actionTypes.GET_PDF_DOWNLOAD_SUCCESS:
      return getRawDataPdfDownloadSuccess(state, action);
    case actionTypes.GET_PDF_DOWNLOAD_FAIL:
      return getRawDataPdfDownloadFail(state, action);
    case actionTypes.GET_RAW_DOWNLOAD_START:
      return getRawDataDownloadStart(state, action);
    case actionTypes.GET_RAW_DOWNLOAD_SUCCESS:
      return getRawDataDownloadSuccess(state, action);
    case actionTypes.GET_RAW_DOWNLOAD_FAIL:
      return getRawDataDownloadFail(state, action);
    default:
      return state;
  }
};

export default locationsDevices;
