import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utilities';

const initialState = {
  reportList: [],
  optionalReportList:[],
  optionalReportMeta:{},
  meta: {},
  error: undefined,
  loading: false,
  reload: false,
  success : undefined,
  reportBy :undefined
};

const getReportListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    reportList: [],
    meta: {},
    loading: true,
  });
};

const getReportListSuccess = (state, action) => {
  return updateObject(state, {
    reportList: action.reportList,
    meta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getReportListFail = (state, action) => {
  return updateObject(state, {
    error: 'Something went wrong',
    reportList: [],
    meta: {},
    loading: false,
  });
};


const deleteReportStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success : undefined,
  });
};

const deleteReportSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: undefined,
    reload: true,
    reportList: [],
    success : 'Report deleted successfully.',
  });
};

const deleteReportFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error[0].message,
    reload: false,
    success : undefined,
  });
};

const addReportStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success : undefined,
  });
};

const addReportSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    success : 'Report added successfully.',
    reportBy : action.data
    // reportList: [],
  });
};

const addReportFail = (state, action) => {
  return updateObject(state, {
    error: action.error ? action.error[0].message : 'Something went wrong',
    loading: false,
    reload: false,
    success : undefined,
  });
};

const editReportStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined,
    reload: false,
    success : undefined,
  });
};

const editReportSuccess = (state, action) => {
  return updateObject(state, {
    error: undefined,
    loading: false,
    reload: true,
    reportList: [],
    success : 'Changes saved successfully.',
  });
};

const editReportFail = (state, action) => {
  return updateObject(state, {
    error: action.error[0].message,
    loading: false,
    reload: false,
    success : undefined,
  });
};


const getOptionalReportListStart = (state, action) => {
  return updateObject(state, {
    error: undefined,
    optionalReportList:[],
    optionalReportMeta:{},
    loading: true,
  });
};

const getOptionalReportListSuccess = (state, action) => {
  return updateObject(state, {
    optionalReportList: action.reportList,
    optionalReportMeta: action.meta,
    error: undefined,
    loading: false,
  });
};

const getOptionalReportListFail = (state, action) => {
  return updateObject(state, {
    error: 'Something went wrong',
    optionalReportList: [],
    optionalReportMeta: {},
    loading: false,
  });
};







const reports = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REPORT_LIST_START:
      return getReportListStart(state, action);
    case actionTypes.GET_REPORT_LIST_SUCCESS:
      return getReportListSuccess(state, action.data);
    case actionTypes.GET_REPORT_LIST_FAIL:
      return getReportListFail(state, action);
    case actionTypes.DELETE_REPORT_START:
      return deleteReportStart(state, action);
    case actionTypes.DELETE_REPORT_SUCCESS:
      return deleteReportSuccess(state, action.data);
    case actionTypes.DELETE_REPORT_FAIL:
      return deleteReportFail(state, action);
    case actionTypes.ADD_REPORT_START:
      return addReportStart(state, action);
    case actionTypes.ADD_REPORT_SUCCESS:
      return addReportSuccess(state, action.data);
    case actionTypes.ADD_REPORT_FAIL:
      return addReportFail(state, action);
    case actionTypes.EDIT_REPORT_START:
      return editReportStart(state, action);
    case actionTypes.EDIT_REPORT_SUCCESS:
      return editReportSuccess(state, action.data);
    case actionTypes.EDIT_REPORT_FAIL:
      return editReportFail(state, action);
    case actionTypes.GET_OPTIONAL_REPORT_LIST_START:
      return getOptionalReportListStart(state, action);
    case actionTypes.GET_OPTIONAL_REPORT_LIST_SUCCESS:
      return getOptionalReportListSuccess(state, action.data);
    case actionTypes.GET_OPTIONAL_REPORT_LIST_FAIL:
      return getOptionalReportListFail(state, action);
    default:
      return state;
  }
};

export default reports;
