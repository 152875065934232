import React, { memo } from "react";
// import { Row, Col } from "reactstrap";
// import Now from "../../Common/Now";
// import CountUp from "react-countup";

import "./index.styles.scss";
// import { apiPath } from "../../../config/config";

class Status extends React.Component {
  state = {
    totalCount: {
      deviceCount: 0,
      gatewayCount: 0,
    },
    onlineCount: {
      deviceOnline: 0,
      gatewayOnline: 0,
    },
    offlineCount: {
      totalOfflineDevices: 0,
      totalOfflineGateway: 0,
    },
  };
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    const { deviceConectedStatus, devicesStatusData } = this.props;
    if (prevProps.deviceConectedStatus !== this.props.deviceConectedStatus) {
      const {
        deviceCount,
        gatewayCount,
        totalOfflineDevices,
        totalOfflineGateway,
      } = deviceConectedStatus;
      this.setState({
        totalCount: {
          deviceCount: Number(deviceCount),
          gatewayCount: Number(gatewayCount),
        },
        offlineCount: {
          totalOfflineDevices: Number(totalOfflineDevices),
          totalOfflineGateway: Number(totalOfflineGateway),
        },

        onlineCount: {
          deviceOnline: Number(deviceCount) - Number(totalOfflineDevices),
          gatewayOnline: Number(gatewayCount) - Number(totalOfflineGateway),
        },
      });
    }
    if (Object.keys(this.props.devicesStatusData).length !== 0) {
      if (prevProps.devicesStatusData !== this.props.devicesStatusData) {
        const { totalOfflineDevices, totalOfflineGateways } = devicesStatusData;

        this.setState({
          onlineCount: {
            deviceOnline:
              this.state.totalCount.deviceCount - Number(totalOfflineDevices),
            gatewayOnline:
              this.state.totalCount.gatewayCount - Number(totalOfflineGateways),
          },
        });
      }
    }
    if (prevProps.deviceConectedStatus !== this.props.deviceConectedStatus) {
      this.setState({
        totalCount: {
          deviceCount: Number(this.props.deviceConectedStatus.deviceCount),
          gatewayCount: Number(this.props.deviceConectedStatus.gatewayCount),
        },
      });
    }
  }
  render() {
    const { isExpanded } = this.props;
    // const token = localStorage.getItem("token");
    // console.log(this.props, "props");
    const svgClass = {
      width: "72%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    };

    const RenderSVG = ({ color, value, status }) => {
      return (
        <div style={{ width: "115px" }}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 159 118"
            // style="enable-background:new 0 0 159 118;"
            // xml:space="preserve"
          >
            <path
              class="st0"
              d="M39.2,86.5c-0.9-3.5-1.4-7.2-1.4-10.9c0-23.3,18.9-42.3,42.3-42.3s42.3,18.9,42.3,42.3c0,3.7-0.5,7.4-1.4,10.8
              l22.9,7.1c1.6-5.7,2.5-11.7,2.5-17.9c0-36.6-29.7-66.2-66.2-66.2S13.8,38.9,13.8,75.5c0,6.6,1,13.1,2.8,19.1L39.2,86.5z"
              fill={color}
            />
          </svg>
          <div style={{ textAlign: "center", marginTop: "-45px" }}>
            <h2
              style={{
                color: status === "Inactive" ? "rgb(224, 47, 68)" : color,
              }}
            >
              {value || 0}
            </h2>
            <p>{status}</p>
          </div>
        </div>
      );
    };

    return (
      <div
        className={`d-flex flex-column statusContainer ${
          isExpanded ? "expanded" : ""
        }`}
        style={{
          height: isExpanded ? "310px" : "335px", // Adjust these values as needed
          transition: "height 0.3s ease-in-out",
        }}

        // style={
        //   window.innerWidth > 1200
        //     ? {  }
        //     : {
        //         border: "1px solid #fff",
        //         borderRadius: "0.25rem",
        //         marginBottom: "5px",
        //       }
        // }
      >
        <div className="w-100 d-flex justify-content-between align-items-center p-2">
          <div
            className="text-center"
            style={
              window.innerWidth < 1200 && window.innerWidth > 480
                ? { width: "15%" }
                : { width: "23%" }
            }
          >
            <img
              alt="gateway"
              src="img/Gateway.png"
              className={isExpanded ? " w-60 h-80" : " w-75 h-100"}
            />
          </div>
          {/* <hr
            style={{
              border: "0.5px solid #ffffff70",
              width: "100%",
              marginBottom: "0px",
            }}
          /> */}
          <div style={svgClass} className="mt-3 ">
            {/* <iframe
              src={`${apiPath.baseGraphanaUrl}grafana-iframe-data/d-solo/ca5494ea-69d0-4c6c-b4cc-8a7b58274f04/livedashboard?orgId=1&refresh=5s&var-from=now%28%29-1h&var-to=now%28%29&var-auth_token=${token}&tab=query&from=1h&to=now&panelId=7&var-type=prod`}
              width="100%"
              height="150"
              frameborder="0"
            ></iframe> */}

            <RenderSVG
              color="#72bf69"
              value={
                this.props.deviceStatusResult &&
                this.props.deviceStatusResult.gateways.activeGateways
              }
              status="Active"
            />
            <RenderSVG
              color="#23252a"
              value={
                this.props.deviceStatusResult &&
                this.props.deviceStatusResult.gateways.inActiveGateways
              }
              status="Inactive"
            />
          </div>
        </div>

        <div
          style={{
            borderTop: "1px solid #ccc",
          }}
          className="w-100 d-flex justify-content-between align-items-center p-2"
        >
          <div
            className="text-center"
            style={
              window.innerWidth < 1200 && window.innerWidth > 480
                ? { width: "15%" }
                : { width: "23%" }
            }
          >
            <img
              src="img/Node.png"
              className="h-100"
              style={{ width: isExpanded ? "60%" : "70%" }}
              alt="node"
            />
          </div>

          <div style={svgClass}>
            <RenderSVG
              color="#72bf69"
              value={
                this.props.deviceStatusResult &&
                this.props.deviceStatusResult.devices.activeDevices
              }
              status="Active"
            />
            <RenderSVG
              color="#23252a"
              value={
                this.props.deviceStatusResult &&
                this.props.deviceStatusResult.devices.inActiveDevices
              }
              status="Inactive"
            />
            {/* <iframe
              src={`${apiPath.baseGraphanaUrl}grafana-iframe-data/d-solo/ca5494ea-69d0-4c6c-b4cc-8a7b58274f04/livedashboard?orgId=1&refresh=5s&var-from=now%28%29-1h&var-to=now%28%29&var-auth_token=${token}&tab=query&from=1h&to=now&panelId=4&var-type=prod`}
              width="100%"
              height="150"
              frameborder="0"
            ></iframe> */}
            {/*  <Col xl={6} md={6}>
          <div className="card align-items-center align-items-stretch border-0 status-card">
            <div className="col-4 d-flex align-items-center bg-info justify-content-center rounded-left ">
              <div className="text-center">
                <Now format="MMMM" className="text-sm" />
                <br />
                <Now format="D" className="h2 mt0" />
              </div>
            </div>
            <div className="col-8 py-3 bgc-gray rounded-right d-flex flex-column justify-content-center">
              <Now format="dddd" className="text-uppercase" />
              <div className="d-flex align-items-center">
                <Now format="h:mm" className="h2 mt0 mr-sm mr-1" />
                <Now format="a" className="text-muted text-sm" />
              </div>
            </div>
          </div>
        </Col>
        <Col xl={6} md={6}>
          <div className="card align-items-center align-items-stretch border-0 status-card">
            <div className="col-4 d-flex align-items-center bg-info justify-content-center rounded-left">
              <em className="icon-screen-smartphone fa-3x"></em>
            </div>
            <div className="col-8 py-3 bgc-gray rounded-right d-flex flex-column justify-content-center">
              <div className="card-info-heading my-0">LarianTech</div>
              <div className="text-sm">service@lariantech.com</div>
              <div className="text-sm">+41715757724</div>
            </div>
          </div>
        </Col>
        <Col xl={6} md={6}>
          <div className="card align-items-center align-items-stretch border-0 status-card">
            <div className="col-4 d-flex align-items-center bg-info justify-content-center rounded-left">
              {/* <em className='icon-drawer fa-3x'></em>
              <div className="h2 mt-0">
                <CountUp
                  duration={3}
                  start={0}
                  end={deviceConectedStatus.gatewayCount}
                />
              </div>
            </div>
            <div className="col-8 py-3 bgc-gray rounded-right d-flex flex-column justify-content-center">
              <div className="text-uppercase">Gateways Connected</div>
            </div>
          </div>
        </Col>
        <Col xl={6} md={6}>
          <div className="card align-items-center align-items-stretch border-0 status-card">
            <div className="col-4 d-flex align-items-center bg-info justify-content-center rounded-left">
              <div className="h2 mt-0">
                <CountUp
                  duration={3}
                  start={0}
                  end={Number(this.state.onlineCount.gatewayOnline)}
                />
              </div>
            </div>
            <div className="col-8 py-3 rounded-right d-flex bgc-gray flex-column justify-content-center">
              <div className="text-uppercase">Gateways Online</div>
            </div>
          </div>
        </Col>
        <Col xl={6} md={6}>
          <div className="card align-items-center align-items-stretch border-0 status-card">
            <div className="col-4 d-flex align-items-center bg-info justify-content-center rounded-left">
              <div className="h2 mt-0">
                <CountUp
                  duration={3}
                  start={0}
                  end={deviceConectedStatus.deviceCount}
                />
              </div>
            </div>
            <div className="col-8 py-3 bgc-gray rounded-right d-flex flex-column justify-content-center">
              <div className="text-uppercase">Nodes Connected</div>
            </div>
          </div>
        </Col>
        <Col xl={6} md={6}>
          <div className="card align-items-center align-items-stretch border-0 status-card">
            <div className="col-4 d-flex align-items-center bg-info justify-content-center rounded-left">
              <div className="h2 mt-0">
                <CountUp
                  duration={3}
                  start={0}
                  end={Number(this.state.onlineCount.deviceOnline)}
                />
              </div>
            </div>
            <div className="col-8 py-3 rounded-right bgc-gray d-flex flex-column justify-content-center">
              <div className="text-uppercase">Nodes Online</div>
            </div>
          </div>
    </Col> */}
          </div>
        </div>
      </div>
    );
  }
}

export default memo(Status);
