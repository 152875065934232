// GLOBAL CONSTANTS
// -----------------------------------

export const APP_COLORS = {
  primary: "#5d9cec",
  success: "#27c24c",
  info: "#1b76b8",
  warning: "#ff902b",
  danger: "#f05050",
  inverse: "#131e26",
  green: "#37bc9b",
  pink: "#f532e5",
  purple: "#7266ba",
  dark: "#3a3f51",
  "gray-darker": "#232735",
  "gray-dark": "#3a3f51",
  gray: "#dde6e9",
  "gray-light": "#e4eaec",
  "gray-lighter": "#edf1f2",
  "red-dark": "#723837",
  red: "#ef5350",
  "blue-dark": "#2B536B",
  "blue-light": "#3498DB",
  yellow: "#fad732",
  "yellow-dark": "#73631A",
};

export const APP_MEDIAQUERY = {
  desktopLG: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 480,
};
