import React, { Component } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators";
import PhoneInput from "react-phone-input-2";

class Register extends Component {
  state = {
    formRegister: {
      username: "",
      email: "",
      password: "",
      password2: "",
      phoneNumber: "",
      countryCode: "",
      role: "super_user",
      seeMore: false,
      consent: false,
      companyName: "",
    },
  };

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast("Signed up successfully", "success");
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
  }

  validateOnChange = (event) => {
    const state = validateOnChange(event, this.state);
    this.setState(state);
  };

  onSubmit = (e) => {
    const state = onSubmit(e, this.state);
    this.setState(state);
    this.setState(state);

    const formattedCountryCode = this.state.formRegister.countryCode.replace(
      "+",
      ""
    );
    const validatePhone =
      String(this.state.formRegister.phoneNumber).startsWith(
        formattedCountryCode
      ) && String(this.state.formRegister.phoneNumber);
    const phoneNumber = validatePhone.substring(formattedCountryCode.length);

    if (!state.hasError) {
      const params = {
        name: this.state.formRegister.username,
        email: this.state.formRegister.email,
        password: this.state.formRegister.password,
        role: this.state.formRegister.role,
        countryCode: this.state.formRegister.countryCode,
        phoneNumber: Number(phoneNumber),
        companyName: this.state.formRegister.companyName,
      };

      this.props.signUp(params);
    }
    e.preventDefault();
  };
  handlePhoneChange = (value, country, e, formattedValue) => {
    this.setState({
      formRegister: {
        ...this.state.formRegister,
        phoneNumber: value,
        countryCode: "+" + country.dialCode.toString(),
      },
    });
  };
  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  ExpendButton = (text) => {
    return (
      <span
        style={{
          cursor: "pointer",
          marginLeft: "5px",
          color: "#1890ff",
        }}
        onClick={() =>
          this.setState({
            seeMore: !this.state.seeMore,
          })
        }
      >
        {`...${text}`}
      </span>
    );
  };

  render() {
    return (
      <React.Fragment>
        <p className="text-center py-2">SIGNUP TO GET INSTANT ACCESS.</p>
        <form className="mb-3" name="formRegister" onSubmit={this.onSubmit}>
          <div className="form-group">
            <label className="text-muted">Name</label>
            <div className="input-group with-focus">
              <Input
                type="text"
                name="username"
                className="border-light border-right-0"
                placeholder="Enter name"
                invalid={this.hasError("formRegister", "username", "required")}
                onChange={this.validateOnChange}
                data-validate='["required"]'
                value={
                  !!this.state.formRegister.username.trim()
                    ? this.state.formRegister.username
                    : ""
                }
              />
              <div className="input-group-append">
                <span className="input-group-text text-muted bg-transparent border-left-0">
                  <em className="fa fa-user"></em>
                </span>
              </div>
              {this.hasError("formRegister", "username", "required") && (
                <span className="invalid-feedback">Name is required</span>
              )}
              {/* {this.hasError('formRegister', 'name', 'alphanum') && <span className="invalid-feedback">Name must be valid</span>} */}
            </div>
          </div>
          <div className="form-group">
            <label className="text-muted">Email address</label>
            <div className="input-group with-focus">
              <Input
                type="email"
                name="email"
                className="border-right-0 border-light"
                placeholder="Enter email"
                invalid={
                  this.hasError("formRegister", "email", "required") ||
                  this.hasError("formRegister", "email", "email")
                }
                onChange={this.validateOnChange}
                data-validate='["required", "email"]'
                value={this.state.formRegister.email}
              />
              <div className="input-group-append">
                <span className="input-group-text text-muted bg-transparent border-left-0">
                  <em className="fa fa-envelope"></em>
                </span>
              </div>
              {this.hasError("formRegister", "email", "required") && (
                <span className="invalid-feedback">Email is required</span>
              )}
              {this.hasError("formRegister", "email", "email") && (
                <span className="invalid-feedback">Email must be valid</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="text-muted">Phone Number</label>
            <div className="input-group with-focus flex-nowrap">
              <PhoneInput
                containerclassName="rounded-left rounded-right-0 border-light border-right-0 "
                inputclassName="border-right-0"
                country="gb"
                countryCodeEditable={false}
                value={this.state.formRegister.phoneNumber}
                onChange={this.handlePhoneChange}
              />
              <div className="input-group-append">
                <span className="input-group-text text-muted bg-transparent border-left-0">
                  <em className="fas fa-phone"></em>
                </span>
              </div>
              {this.hasError("formRegister", "email", "required") && (
                <span className="invalid-feedback">Email is required</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="text-muted">Company</label>
            <div className="input-group with-focus">
              <Input
                type="text"
                name="companyName"
                className="border-light border-right-0"
                placeholder="Enter Company"
                invalid={this.hasError(
                  "formRegister",
                  "companyName",
                  "required"
                )}
                onChange={this.validateOnChange}
                data-validate='["required"]'
                value={
                  !!this.state.formRegister.companyName.trim()
                    ? this.state.formRegister.companyName
                    : ""
                }
              />
              <div className="input-group-append">
                <span className="input-group-text text-muted bg-transparent border-left-0">
                  <em className="fa fa-user"></em>
                </span>
              </div>
              {this.hasError("formRegister", "companyName", "required") && (
                <span className="invalid-feedback">company is required</span>
              )}
              {/* {this.hasError('formRegister', 'name', 'alphanum') && <span className="invalid-feedback">Name must be valid</span>} */}
            </div>
          </div>
          <div>
            By enrolling in and using{" "}
            {!this.state.seeMore && this.ExpendButton("read more")}{" "}
            {this.state.seeMore &&
              `the Text Alerts Service, you agree to 
              receive text messages containing Information about the current
              status of the monitored equipment (Temperature, Humidity, Co2, Water
              level, Door openings, device offline as well as a link for a 24h
              graph) from us at your telephone number. You agree to provide us
              with a telephone number for receiving the alert messages and to
              notify us if this telephone number changes. Message frequency can be
              chosen while setting up the alert parameters.`}
            {this.state.seeMore && this.ExpendButton("...read less")}
          </div>
          <div className="form-group">
            <label className="text-muted">Password</label>
            <div className="input-group with-focus">
              <Input
                type="password"
                id="id-password"
                name="password"
                className="border-right-0 border-light"
                placeholder="Password"
                invalid={this.hasError("formRegister", "password", "required")}
                onChange={this.validateOnChange}
                data-validate='["required"]'
                minLength="6"
                value={this.state.formRegister.password}
              />
              <div className="input-group-append">
                <span className="input-group-text text-muted bg-transparent border-left-0">
                  <em className="fa fa-lock"></em>
                </span>
              </div>
              <span className="invalid-feedback">Password is required</span>
            </div>
          </div>
          <div className="form-group">
            <label className="text-muted">Confirm Password</label>
            <div className="input-group with-focus">
              <Input
                type="password"
                name="password2"
                className="border-right-0 border-light"
                placeholder="Retype Password"
                invalid={this.hasError("formRegister", "password2", "equalto")}
                onChange={this.validateOnChange}
                data-validate='["equalto"]'
                value={this.state.formRegister.password2}
                data-param="id-password"
                minLength="6"
              />
              <div className="input-group-append">
                <span className="input-group-text text-muted bg-transparent border-left-0">
                  <em className="fa fa-lock"></em>
                </span>
              </div>
              <span className="invalid-feedback">Passwords must match</span>
            </div>
          </div>
          <div className="ml-4">
            <Input
              type="checkbox"
              id="tempEnabled"
              name="tempEnabled"
              checked={this.state.consent}
              onChange={() =>
                this.setState({
                  consent: !this.state.consent,
                })
              }
            />
            I Agree to the <a href="/termsAndConditions">Terms & Conditions</a>{" "}
            and <a href="/privacyPolicy">Privacy policy</a>.
          </div>
          <button
            className="btn btn-block btn-primary mt-3"
            type="submit"
            disabled={!this.state.consent}
          >
            Sign Up
          </button>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    success: state.auth.success,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (params) => dispatch(actions.signUp(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
