import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Container,
  Input,
} from "reactstrap";
import ContentWrapper from "../Layout/ContentWrapper";
import Slider from "rc-slider";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";
import PhoneInput from "react-phone-input-2";
import { Switch } from "antd";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const LightProfile = () => {
  const [startDate, setStartDate] = useState(new Date());
  const tempMarks = {
    "1": <strong>1</strong>,
    65536: <strong>65536</strong>,
  };
  return (
    <ContentWrapper>
      <Container fluid>
        {/* <div className="d-flex p-3 justify-content-end"> */}
        {/* <button
            className="mr-2 float-right btn-labeled btn btn-info"
            // onClick={() => {
            //   this.showModal("alertProfiles");
            // }}
          >
            {/* <span className="btn-label">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span> 
            Light Profile
          </button> */}
        <Card className="card-default">
          <CardHeader className="d-flex align-items-center justify-content-between">
            <CardTitle>Light Profile</CardTitle>
            {/* <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              /> */}
          </CardHeader>
        </Card>
        {/* </div> */}
        <div style={{ margin: "40px 100px" }}>
          <div className="sliders alarm__sliders form-group mb-5">
            <div className="d-flex justify-content-center">
              <div
                className="enableCheckbox d-flex justify-content-start algin-items-center"
                style={{ width: "15%" }}
              >
                <p className="mt-3 mb-3">
                  <div className="mr-4 mb-3 ml-4">Monday</div>
                  <Input
                    type="checkbox"
                    id="tempEnabled"
                    name="tempEnabled"
                    checked={true}
                    // onChange={this.validateOnChange}
                  />
                  <label htmlFor="tempEnabled" className="checkbox-label">
                    <span className="ui" />
                  </label>
                </p>
              </div>
              <div className="tempSlider mb-3" style={{ width: "85%" }}>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sliders alarm__sliders form-group mb-5">
            <div className="d-flex justify-content-center">
              <div
                className="enableCheckbox d-flex justify-content-start algin-items-center"
                style={{ width: "15%" }}
              >
                <p className="mt-3 mb-3">
                  <div className="mr-4 mb-3 ml-4">Tuesday</div>
                  <Input
                    type="checkbox"
                    id="tempEnabled"
                    name="tempEnabled"
                    checked={true}
                    // onChange={this.validateOnChange}
                  />
                  <label htmlFor="tempEnabled" className="checkbox-label">
                    <span className="ui" />
                  </label>
                </p>
              </div>
              <div className="tempSlider mb-3" style={{ width: "85%" }}>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sliders alarm__sliders form-group mb-5">
            <div className="d-flex justify-content-center">
              <div
                className="enableCheckbox d-flex justify-content-start algin-items-center"
                style={{ width: "15%" }}
              >
                <p className="mt-3 mb-3">
                  <div className="mr-4 mb-3 ml-4">Wednesday</div>
                  <Input
                    type="checkbox"
                    id="tempEnabled"
                    name="tempEnabled"
                    checked={true}
                    // onChange={this.validateOnChange}
                  />
                  <label htmlFor="tempEnabled" className="checkbox-label">
                    <span className="ui" />
                  </label>
                </p>
              </div>
              <div className="tempSlider mb-3" style={{ width: "85%" }}>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sliders alarm__sliders form-group mb-5">
            <div className="d-flex justify-content-center">
              <div
                className="enableCheckbox d-flex justify-content-start algin-items-center"
                style={{ width: "15%" }}
              >
                <p className="mt-3 mb-3">
                  <div className="mr-4 mb-3 ml-4">Thursday</div>
                  <Input
                    type="checkbox"
                    id="tempEnabled"
                    name="tempEnabled"
                    checked={true}
                    // onChange={this.validateOnChange}
                  />
                  <label htmlFor="tempEnabled" className="checkbox-label">
                    <span className="ui" />
                  </label>
                </p>
              </div>
              <div className="tempSlider mb-3" style={{ width: "85%" }}>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sliders alarm__sliders form-group mb-5">
            <div className="d-flex justify-content-center">
              <div
                className="enableCheckbox d-flex justify-content-start algin-items-center"
                style={{ width: "15%" }}
              >
                <p className="mt-3 mb-3">
                  <div className="mr-4 mb-3 ml-4">Friday</div>
                  <Input
                    type="checkbox"
                    id="tempEnabled"
                    name="tempEnabled"
                    checked={true}
                    // onChange={this.validateOnChange}
                  />
                  <label htmlFor="tempEnabled" className="checkbox-label">
                    <span className="ui" />
                  </label>
                </p>
              </div>
              <div className="tempSlider mb-3" style={{ width: "85%" }}>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sliders alarm__sliders form-group mb-5">
            <div className="d-flex justify-content-center">
              <div
                className="enableCheckbox d-flex justify-content-start algin-items-center"
                style={{ width: "15%" }}
              >
                <p className="mt-3 mb-3">
                  <div className="mr-4 mb-3 ml-4">Saturday</div>
                  <Input
                    type="checkbox"
                    id="tempEnabled"
                    name="tempEnabled"
                    checked={true}
                    // onChange={this.validateOnChange}
                  />
                  <label htmlFor="tempEnabled" className="checkbox-label">
                    <span className="ui" />
                  </label>
                </p>
              </div>
              <div className="tempSlider mb-3" style={{ width: "85%" }}>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sliders alarm__sliders form-group mb-5">
            <div className="d-flex justify-content-center">
              <div
                className="enableCheckbox d-flex justify-content-start algin-items-center"
                style={{ width: "15%" }}
              >
                <p className="mt-3 mb-3">
                  <div className="mr-4 mb-3 ml-4">Sunday</div>
                  <Input
                    type="checkbox"
                    id="tempEnabled"
                    name="tempEnabled"
                    checked={true}
                    // onChange={this.validateOnChange}
                  />
                  <label htmlFor="tempEnabled" className="checkbox-label">
                    <span className="ui" />
                  </label>
                </p>
              </div>
              <div className="tempSlider mb-3" style={{ width: "85%" }}>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row w-100 no-gutters"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-lg-10 col-10">
                    <div className="col-lg-12 col-12 d-flex mb-3">
                      <div className="d-flex">
                        <div className="mr-2">
                          <div>From</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <div className="mr-2">
                          <div>To</div>
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center col-lg-8 col-8">
                        <div
                          className=" mr-2 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='startIndex'>Min</label> */}
                          <input
                            className="slider-input ml-1 mr-1"
                            type="number"
                            name="startIndex"
                            size="2"
                            value={1}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="startIndex"
                            className="mr-1"
                            style={{ marginBottom: "0px" }}
                          >
                            min LUX
                          </label>
                        </div>
                        <div
                          className={`w-100`}
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="w-100 slider-pos mx-auto">
                            <Range
                              allowCross={false}
                              marks={{}}
                              step={0.1}
                              tipProps={{ placement: "bottom", visible: false }}
                              tipFormatter={(value) => `${value} C`}
                              //   onChange={this.tempUpdated.bind(this)}
                              min={1}
                              max={65536}
                              value={[1, 65536]}
                              //   disabled={!this.state.formDeviceAlarm.tempEnabled}
                              // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                            />{" "}
                          </div>
                        </div>
                        <div
                          className=" ml-1 d-flex"
                          style={{
                            flexDirection: "column-reverse",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <label htmlFor='endIndex'>Max</label> */}
                          <input
                            className="slider-input ml-3 mr-1"
                            type="number"
                            name="endIndex"
                            size="2"
                            value={65536}
                            style={{ borderRadius: "5px" }}
                            // onFocus={(e) => e.target.select()}
                            // onChange={(e) => this.handleInputChange(e, "tempValue")}
                          />
                          <label
                            htmlFor="endIndex"
                            style={{ marginBottom: "0px", marginLeft: "15px" }}
                          >
                            max LUX
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-10 ml-3">
            <div className="mt-2 form-group">
              <div>
                <label className="text-muted">Select existing user</label>
                <Select
                  maxMenuHeight={170}
                  styles={selectStyle}
                  name="locationId"
                  value=""
                  // onChange={this.handleChangeUser}
                  // options={dropDownUsers}
                  // getOptionLabel={(option) => `${option.name}`}
                  // getOptionValue={(option) => `${option}`}
                  // isOptionSelected={(option) => {
                  //   this.isUserSelected(option);
                  // }}
                />
              </div>
            </div>

            <div className="mt-2 form-group">
              <div>
                <label className="text-muted">Select existing devices</label>
                <Select
                  maxMenuHeight={170}
                  styles={selectStyle}
                  name="locationId"
                  value=""
                  // onChange={this.handleChangeDevice}
                  // options={dropDownDevices}
                  // getOptionLabel={(option) => `${option.name}`}
                  // getOptionValue={(option) => `${option}`}
                  // isOptionSelected={(option) => {
                  //   this.isDeviceSelected(option);
                  // }}
                />
              </div>
            </div>
            <label className="text-muted">Add new user</label>
            <div className="mt-3 alarm_add_user">
              <div className="form-group">
                <label className="text-muted">Email</label>
                <div className="input-group with-focus">
                  <Input
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={(event) => this.validateUserChange(event)}
                    value={""}
                    minLength="2"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="text-muted">Phone Number</label>
                <PhoneInput
                  className="w-100"
                  country="gb"
                  countryCodeEditable={false}
                  value=""
                  // onChange={this.handlePhoneChange}
                />
              </div>
              <Button
                color="info"
                className="btn-labeled mt-28 p-0"
                type="button"
                //   onClick={this.addUser.bind(this)}
              >
                <span className="btn-label left-0">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </span>
              </Button>
              <Button
                color="info"
                className="btn-labeled hidden-on-pc "
                type="button"
                //   onClick={this.addUser.bind(this)}
              >
                <span className="btn-label">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </span>
                Add
              </Button>
            </div>
            <div className="mt-3 alarm_add_user alarm_delay">
              <div className="mt-2 form-group">
                <label className="text-muted">Select Alarm Delay</label>
                <Select
                  maxMenuHeight={170}
                  styles={selectStyle}
                  name="delay"
                  value=""
                  // onChange={this.handleAlarmDelay}
                  // options={this.state.alarmDelayData}
                  // getOptionLabel={(option) => `${option.name}`}
                  // getOptionValue={(option) => `${option}`}
                />
              </div>
              <div className="mt-2 form-group">
                <label className="text-muted">Select Alarm Frequency</label>
                <Select
                  maxMenuHeight={170}
                  styles={selectStyle}
                  name="frequency"
                  value=""
                  // onChange={this.handleAlarmFrequency}
                  // options={this.state.alarmFrequencyData}
                  // getOptionLabel={(option) => `${option.name}`}
                  // getOptionValue={(option) => `${option}`}
                />
              </div>
              <div style={{ margin: "auto" }}>
                <div className="text-muted mb-1">One Time</div>
                <Switch
                  // onChange={this.handleSwitch}
                  checked={true}
                />
              </div>
            </div>
            <div className="alarm_add_user mt-3">
              <div className="col-sm-9 d-flex justify-content-center">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="emailEnable"
                      name="emailEnable"
                      checked={true}
                      //   onChange={this.validateOnChange}
                    />
                    <label htmlFor="emailEnable">
                      <span className="ui"></span>
                      <span className="checkbox-span">E-mail Alert</span>
                    </label>
                  </p>
                </div>
              </div>
              <div className="col-sm-9 d-flex justify-content-center">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="textEnable"
                      name="textEnable"
                      checked={true}
                      //   onChange={this.validateOnChange}
                    />
                    <label htmlFor="textEnable">
                      <span className="ui"></span>
                      <span className="checkbox-span">Text Alert</span>
                    </label>
                  </p>
                </div>
              </div>
              {/* <div className="col-sm-9 d-flex justify-content-center">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="sentToAllEnable"
                      name="sentToAllEnable"
                      checked={this.state.formDeviceAlarm.sentToAllEnable}
                      onChange={this.validateOnChange}
                    />
                    <label htmlFor="sentToAllEnable">
                      <span className="ui"></span>
                      <span className="checkbox-span">Alert to All</span>
                    </label>
                  </p>
                </div>
              </div> */}
              <div className="col-sm-9 d-flex justify-content-center">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="adminEnable"
                      name="adminEnable"
                      checked={true}
                      //   onChange={this.validateOnChange}
                    />
                    <label htmlFor="adminEnable">
                      <span className="ui"></span>
                      <span className="checkbox-span">Notify Admin</span>
                    </label>
                  </p>
                </div>
              </div>
              <div className="col-sm-9 d-flex justify-content-center mt-3">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="enable"
                      name="enable"
                      checked={true}
                      //   onChange={this.validateOnChange}
                    />
                    <label htmlFor="enable">
                      <span className="ui"></span>
                      <span className="checkbox-span">Alarm Enabled</span>
                    </label>
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-3 mb-3" />
            <div className="container-fluid px-0">
              <div className="row">
                <div className="row col-lg-8 no-gutters"></div>
                <div className="d-flex justify-content-end col-lg-4">
                  <Button color="info" type="submit">
                    Submit
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    className="ml-2"
                    // onClick={toggleModal}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </ContentWrapper>
  );
};

export default LightProfile;
