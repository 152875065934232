import $ from "jquery";
export const roundUptoDigits = (number, digits) => {
  if (number) {
    const factor = 10 ** digits;
    return Math.round(Number(number) * factor) / factor;
  }
  return number;
};

export const getQueryParam = (query, param) => {
  const result = {};
  query.split("&").forEach((param) => {
    const item = param.split(/=(.+)/);
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return param ? result[param] : result;
};

export const getCommonHeaders = () => {
  const session =
    localStorage.getItem("token") || "8e8b0816-4c73-4f08-8f7d-022dcd186a91";
  return {
    Authorization: "bearer " + decodeURIComponent(session),
  };
};

export const getUserId = () => {
  const userId = localStorage.getItem("userId");
  return userId;
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const dtOptions = () => {
  return {
    paging: true, // Table pagination
    ordering: true, // Column ordering
    info: true, // Bottom left status text
    responsive: true,
    // Text translation options
    // Note the required keywords between underscores (e.g _MENU_)
    oLanguage: {
      sSearch: '<em className="fa fa-search"></em>',
      // sLengthMenu: '_MENU_ records per page',
      info: "Showing page _PAGE_ of _PAGES_",
      zeroRecords: "Nothing found - sorry",
      infoEmpty: "No records available",
      infoFiltered: "(filtered from _MAX_ total records)",
      oPaginate: {
        sNext: '<em className="fa fa-caret-right"></em>',
        sPrevious: '<em className="fa fa-caret-left"></em>',
      },
    },
  };
};

export const dtInstanceFn = (dtInstance) => {
  const inputSearchClass = "datatable_input_col_search";
  const columnInputs = $("tfoot ." + inputSearchClass);
  // On input keyup trigger filtering
  columnInputs.keyup(function() {
    dtInstance.fnFilter(this.value, columnInputs.index(this));
  });
};
