import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

export function AlertModal({
  isOpen,
  heading,
  message,
  modalAction,
  shouldCancel,
  cancelModalAction,
}) {
  return (
    <Modal isOpen={isOpen} backdrop>
      <ModalHeader>{heading || ""}</ModalHeader>
      <ModalBody>
        <p>{message || ""}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="info" onClick={modalAction}>
          OK
        </Button>
        {shouldCancel && (
          <Button color="info" onClick={cancelModalAction}>
            Cancel
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default AlertModal;
