import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../stores/actions/index';
import { showToast } from '../../helpers/toast.js';
import { withNamespaces, Trans } from 'react-i18next';
import ContentWrapper from '../Layout/ContentWrapper.js';
import { Container, Card, CardHeader, CardTitle, CardBody, Input, Button } from 'reactstrap';
import { selectStyle } from "../../config/selectStyle";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import CardTool from '../Common/CardTool.js';
import UserNotificationsTable from './UserNotificationsTable.js';
class userNotifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      text: "",
      is_sms: -1,
      startDate: null,
      endDate: null,
      accountList: [],
      accountId: null
    };
  }

  componentDidMount() {
    const { getUserNotificationsList, getAccountList } = this.props;
    getUserNotificationsList({
      page: 1,
      pageSize: 10,
      email: this.state.email, 
      phone: this.state.phone, 
      text: this.state.text, 
      is_sms: this.state.is_sms, 
      startDate: this.state.startDate, 
      endDate: this.state.endDate,
      accountId: this.state.accountId
    });
    getAccountList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, 'success');
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, 'error');
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: '', modalData: {} });
      this.props.getUserNotificationsList( {
        page: 1,
        pageSize: 10,
        email: this.state.email, 
        phone: this.state.phone, 
        text: this.state.text, 
        is_sms: this.state.is_sms, 
        startDate: this.state.startDate, 
        endDate: this.state.endDate,
        accountId: this.state.accountId
      });
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName,
      modalData,
    });
  };


  changePage = (page) => {
                
    this.props.getUserNotificationsList( {
      page: page,
      pageSize: 10,
      email: (this.state.email.trim() === "") ? '' : this.state.email, 
      phone: (this.state.phone.trim() === "") ? '' : this.state.phone, 
      text: (this.state.text.trim() === "") ? '' : this.state.text, 
      is_sms: this.state.is_sms, 
      startDate: this.state.startDate, 
      endDate: this.state.endDate,
      accountId: this.state.accountId
    });
  };

  
  render() {
    const {data, meta, loading, account } = this.props;

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            User Notifications
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>User Notifications List</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <CardBody>
            <div className="form-group m-2">
                  <div className="row">
                    <div className="col-3 mt-2">
                      <Input type="text"
                          name="email"
                          placeholder="Email"
                          onChange={(data)=>{
                            this.setState({ email: data.target.value })
                          }}
                          value={this.state.email} />
                    </div>
                    <div className="col-3 mt-2">
                      <Input type="text"
                          name="sms"
                          placeholder="Phone"
                          onChange={(data)=>{
                            this.setState({ phone: data.target.value })
                          }}
                          value={this.state.phone} />
                    </div>  
                    <div className="col-3 mt-2">
                      <Input type="text"
                          name="text"
                          placeholder="Content"
                          onChange={(data)=>{
                            this.setState({ text: data.target.value })
                          }}
                          value={this.state.text} />
                    </div>  
                    <div className="col-3 mt-2">
                    <select style={{
                        backgroundColor: "#272934", 
                        color: "#fff",
                        borderBottom: "1px solid black",
                        width: "100%",
                        padding: "9px",
                        opacity: "0.7"}}
                        onChange={(data) => this.setState({ is_sms: data.target.value })}
                        >
                        <option value="0" selected={!this.state.is_sms ? "selected" : "" }>Both</option>
                        <option value="1" selected={this.state.is_sms === 1 ? "selected" : "" }>Email</option>
                        <option value="2" selected={!this.state.is_sms === 2 ? "selected" : "" }>SMS</option>
                      </select>
                    </div>  
                    <div className="col-3 mt-2">
                      <select style={{
                        backgroundColor: "#272934", 
                        color: "#fff",
                        borderBottom: "1px solid black",
                        width: "100%",
                        padding: "9px",
                        opacity: "0.7"}}
                        onChange={(data) => this.setState({ accountId: data.target.value })}
                        >
                        <option value="0" selected={!this.state.accountId ? "selected" : "" }>Select...</option>
                        {account.map((el) => (
                          <option value={el.id} selected={(el.id === this.state.accountId) ? "selected" : "" }>{el.companyName}</option>
                        )
                        )}
                      </select>
                    </div>  
                    <div className="col-3 mt-2">
                      Start Date: <ReactDatePicker
                            selected={this.state.startDate}
                            onChange={(date) => this.setState({ startDate: date })}
                            //showTimeSelect
                            //showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Date"
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                          />
                    </div>  
                    <div className="col-3 mt-2">
                      End Date: <ReactDatePicker
                            selected={this.state.endDate}
                            onChange={(date) => this.setState({ endDate: date })}
                            //showTimeSelect
                            //showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Date"
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                          />
                    </div> 
                    <div className="col-3 mt-2">
                      <Button
                            onClick={() => {
                              this.changePage(1);
                            }}
                            color="info"
                            type="button"
                          >Search</Button>
                           <span className="ml-2">
                            <Button
                              onClick={() => {
                                this.setState({ 
                                  email: "",
                                  phone: "",
                                  text: "",
                                  is_sms: -1,
                                  startDate: null,
                                  endDate: null,
                                  accountList: [],
                                  accountId: null
                                });
                              }}
                              color="info"
                              type="button"
                            >Reset</Button>
                           </span>
                      
                          <span className="ml-2">
                            {(meta.count) ? `Data Count: ${meta.count}` : ''}
                          </span>
                          
                    </div> 
                  </div>
              </div>
              {data && data.length > 0 && (
                <UserNotificationsTable
                  pagination={meta.pagination}
                  data={data}
                //   editGateway={this.editGateway}
                //   deleteGateway={this.deleteGateway}
                  changePage={(page) => this.changePage(page)}
                ></UserNotificationsTable>
              )}
              {!loading && data && data.length === 0 && (
                <h3 className="text-center">No data found</h3>
              )}
             {!!loading &&  <h3 className="text-center">Loading...</h3> }
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.userNotifications.loading,
    data: state.userNotifications.data,
    meta: state.userNotifications.meta,
    account: state.account.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserNotificationsList: (data) => dispatch(actions.getUserNotificationsList(data)),
    getAccountList: (data) => dispatch(actions.getAccountList(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces('translations')(userNotifications));
