import FormValidator from "./FormValidator";

export const fieldHasError = (state, formName, inputName, method) => {
    return state[formName] &&
        state[formName].errors &&
        state[formName].errors[inputName] &&
        state[formName].errors[inputName][method]
}

export const validateOnChange = (event, state) => {

    const input = event.target;
    const form = input.form;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    const result = FormValidator.validate(input);
    return {
        ...state,
        [form.name]: {
            ...state[form.name],
            [input.name]: value,
            errors: {
                ...state[form.name].errors,
                [input.name]: result
            }
        },
        formError: (!!state && state.formGateway && state.formGateway.gatewayName.trim() && !!state.formGateway.gatewayId.trim() ) ? "" : !!value && (value === true ? value : !!value.trim()) ? "" : input.name
    };
}

export const onSubmit = (event, state) => {
    const form = event.target;
    const inputs = [...form.elements].filter(i => {
        return ['INPUT'].includes(i.nodeName)
    })

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    return {
        ...state,
        [form.name]: {
            ...state[form.name],
            errors
        },
        hasError
    };
}