import React from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { convertDateFormat } from "../../../utils/helperFunctions";
import { showToast } from "../../../helpers/toast.js";
import * as actions from "../../../stores/actions/index.js";
import Select from "react-select";
import moment from "moment";

// styles
import "./index.styles.scss";
import LoopCircleLoading from "react-loadingg/lib/LoopCircleLoading";
import { connect } from "react-redux";
import { selectStyle } from "../../../config/selectStyle.js";
import { Row, Col } from "reactstrap";

class RecentAlarms extends React.Component {
  state = {
    page: 1,
    allHours: [
      {
        id: 1,
        name: "1 Month",
        value: "month",
      },
      {
        id: 2,
        name: "2 Month",
        value: "month",
      },
      {
        id: 3,
        name: "3 Month",
        value: "month",
      },
      {
        id: 6,
        name: "6 Month",
        value: "month",
      },
      {
        id: 12,
        name: "12 Month",
        value: "month",
      },

      // { id: "custom", name: "Custom" },
    ],
    selectedHours: {
      id: 1,
      name: "1 Month",
      value: "month",
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevState.page !== this.state.page && this.state.page >= 1) {
      this.props.getRecentAlarmList(this.state.page);
    }
  }

  handleChange = (selectedHours) => {
    this.setState({ selectedHours });
  };

  updateDevice = (deviceName) => {
    const selectedDevice =
      this.props.allDevices &&
      this.props.allDevices.length &&
      this.props.allDevices.filter((device) => device.name === deviceName);
    if (selectedDevice && selectedDevice.length) {
      const selectedLocation =
        this.props.allLocations &&
        this.props.allLocations.filter(
          (location) => location.id === selectedDevice[0].locationId
        );
      if (selectedLocation && selectedLocation.length) {
        localStorage.setItem(
          "selectedDevice",
          JSON.stringify(selectedDevice[0])
        );
        localStorage.setItem(
          "selectedLocation",
          JSON.stringify(selectedLocation[0])
        );
        this.props.updateDeviceId(selectedDevice[0].deviceId);
        this.props.updateDeviceName(deviceName);
        this.props.updateRecentAlarmsEvent(true);
      }
    }
  };

  render() {
    const {
      recentAlarms,
      recentAlarmLoading,
      showRecentAlarmComment,
      isExpanded,
    } = this.props;

    const { data, ...paging } = recentAlarms;

    const ra_columns = [
      {
        label: "Comments",
        field: "action",
        sort: "asc",
        width: 90,
      },
      {
        label: "Alarm Name",
        field: "alarmName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 150,
      },
      {
        label: "Device",
        field: "deviceName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Parameter",
        field: "type",
        sort: "asc",
        width: 120,
      },
      {
        label: "Value",
        field: "value",
        sort: "asc",
        width: 80,
      },
      {
        label: "Set Range",
        field: "range",
        sort: "asc",
        width: 135,
      },
      {
        label: "Time",
        field: "createdAt",
        sort: "asc",
        width: 150,
      },
    ];

    const ra_rows = data
      ? data.map((alarm) => {
          let alarmRow = { ...alarm };

          alarmRow.action = (
            <div onClick={() => showRecentAlarmComment(alarmRow)}>
              <svg
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0867962,18 L6,21.8042476 L6,18 L4,18 C2.8954305,18 2,17.1045695 2,16 L2,4 C2,2.8954305 2.8954305,2 4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,16 C22,17.1045695 21.1045695,18 20,18 L12.0867962,18 Z M8,18.1957524 L11.5132038,16 L20,16 L20,4 L4,4 L4,16 L8,16 L8,18.1957524 Z M11,9 L11,6 L13,6 L13,9 L16,9 L16,11 L13,11 L13,14 L11,14 L11,11 L8,11 L8,9 L11,9 Z"
                  fill-rule="evenodd"
                  fill=" #fff"
                />
              </svg>
            </div>
          );
          alarmRow.createdAt = convertDateFormat(
            alarmRow.createdAt,
            "shortDateTime"
          );
          alarmRow.alarmName = (
            <a onClick={() => this.updateDevice(alarm.deviceName)}>
              {alarm.alarmName}
            </a>
          );
          alarmRow.deviceName = alarm.deviceName;

          switch (alarm.type.toLowerCase()) {
            case "temperature":
              alarmRow.range = `${alarm.min}/${alarm.max}`;
              alarmRow.value = `${parseFloat(alarm.value).toFixed(2)}`;
              break;
            case "humidity":
              alarmRow.range = `${alarm.min}/${alarm.max} ${String.fromCharCode(
                37
              )}`;
              alarmRow.value = `${parseFloat(alarm.value).toFixed(
                2
              )} ${String.fromCharCode(37)}`;
              break;
            case "co2":
              alarmRow.range = `${alarm.min}/${alarm.max} ${String.fromCharCode(
                37
              )}`;
              alarmRow.value = `${parseFloat(alarm.value).toFixed(
                2
              )} ${String.fromCharCode(37)}`;
              break;
            case "carbon":
              alarmRow.range = `${alarm.min}/${alarm.max} ${String.fromCharCode(
                37
              )}`;
              alarmRow.value = `${parseFloat(alarm.value).toFixed(
                2
              )} ${String.fromCharCode(37)}`;
              break;
            case "LN2 Temperature":
              alarmRow.range = `${alarm.min}/${alarm.max} ${String.fromCharCode(
                37
              )}`;
              alarmRow.value = `${parseFloat(alarm.value).toFixed(
                2
              )} ${String.fromCharCode(176)}C`;
              break;
            case "LN2 Level":
              alarmRow.range = `${alarm.min}/${alarm.max} ${String.fromCharCode(
                37
              )}`;
              alarmRow.value = `${parseFloat(alarm.value).toFixed(
                2
              )} ${String.fromCharCode(37)}`;
              break;
            case "Water":
              alarmRow.range = "N/A";
              alarmRow.value = Number(alarm.value) === 0 ? "Low" : "N/A";
              break;
            case "pressure":
              alarmRow.range = `${alarm.min}/${alarm.max} hPa`;
              // alarmRow.value = Number(alarm.value) === 0 ? "Low" : "N/A";
              break;
            default:
              break;
          }
          return alarmRow;
        })
      : [];

    const recentAlarmsRawList = {
      columns: ra_columns,
      rows: ra_rows,
    };
    const loadingWrapper = (
      <div className="">
        <div className="loadingWrapper">
          <div className="d-flex justify-content-center">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div className="d-flex justify-content-center">
                <LoopCircleLoading />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <Card
        className={`recent-alarms ${isExpanded ? "expanded" : ""}`}
        style={{
          width: "100%",
          marginBottom: "0.35rem !important",
          border: "1px solid var(--dropdown-border-bg)",
          borderRadius: "0.25rem",
          height: isExpanded ? "461.8px" : "270px", // Double the height when expanded
          transition: "height 0.3s ease-in-out",
        }}
      >
        <CardHeader
          className="recent-alarms__header commonSpacing"
          style={{ position: "relative" }}
        >
          <CardTitle>Recent Alarms</CardTitle>
          <div
            style={{
              display: "flex",
              width: "40%",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ width: "60%", marginRight: "10px" }}>
              <Select
                styles={selectStyle}
                name="locationId"
                value={this.state.selectedHours}
                onChange={this.handleChange}
                options={this.state.allHours}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  return this.state.selectedHours.id === option.id
                    ? true
                    : false;
                }}
              />
            </div>
            <button
              onClick={() => {
                this.props.pdfAlarmDataDownload({
                  startTime: moment()
                    .subtract(
                      this.state.selectedHours.id,
                      this.state.selectedHours.value
                    )
                    .toISOString(),
                  endTime: moment().toISOString(),
                });
              }}
              type="button"
              className="btn btn-info pull-right"
              style={{
                padding: "0.155rem 1rem",
              }}
            >
              <i className="fa fa-download" aria-hidden="true"></i> PDF
            </button>
            {/* <button
              onClick={() => {
                this.props.csvAlarmDataDownload({
                  startTime: moment()
                    .add(
                      this.state.selectedHours.id,
                      this.state.selectedHours.value
                    )
                    .toISOString(),
                  endTime: moment().toISOString(),
                });
              }}
              type="button"
              className="btn btn-info pull-right"
              style={{
                padding: "0.155rem 1rem",
              }}
            >
              <i className="fa fa-download" aria-hidden="true"></i> CSV
            </button> */}
          </div>
        </CardHeader>
        <div
          style={{
            height: "1px",
            width: "100%",
            background: "#ccc",
          }}
        />
        <CardBody className="recent-alarms__body" style={{ padding: "0px" }}>
          {recentAlarmLoading ? (
            loadingWrapper
          ) : (
            <>
              <MDBDataTable
                className="recentAlarmTable border-0"
                data={recentAlarmsRawList}
                paging={false}
                searching={false}
                scrollY
                scrollX
                maxHeight="193px"
                bordered
              />
              <Row
                className="mt-3 mb-3"
                style={{
                  position: "absolute",
                  maxHeight: isExpanded ? "10%" : "20%",
                  bottom: "-20px",
                  right: "3%",
                  backgroundColor: "#0000",
                  borderRadius: "5px",
                  width: "98%",
                }}
              >
                <Col
                  lg={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <label style={{ marginBottom: 0 }}>
                    Showing {(paging.page || 1) * 10 - 9} to{" "}
                    {(paging.page || 1) * 10} of all results
                  </label>
                </Col>
                <Col
                  lg={6}
                  className="text-right d-flex"
                  style={{
                    width: "100%",
                    alignItems: "center",
                    cursor: "pointer",
                    justifyContent: "flex-end",
                  }}
                >
                  <i
                    className="fas fa-angle-double-left mr-4 fa-1x"
                    onClick={() => {
                      if (this.state.page > 1) {
                        this.setState({ page: this.state.page - 1 });
                      }
                    }}
                  ></i>
                  <i
                    className="fas fa-angle-double-right fa-1x"
                    onClick={() => {
                      if (this.state.page !== paging.total) {
                        this.setState({ page: this.state.page + 1 });
                      }
                    }}
                  ></i>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    success: state.deviceAlarms.success,
    error: state.deviceAlarms.error,
    loading: state.deviceAlarms.loading,
    allDevices: state.locationsDevices.allDevices,
    allLocations: state.locationsDevices.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pdfAlarmDataDownload: (payload) =>
      dispatch(actions.pdfAlarmDataDownload(payload)),
    csvAlarmDataDownload: (payload) =>
      dispatch(actions.csvAlarmDataDownload(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentAlarms);
