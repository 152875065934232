import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import { withNamespaces, Trans } from "react-i18next";
import ContentWrapper from "../Layout/ContentWrapper";
import { Container, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import CardTool from "../Common/CardTool";
import SubUsersTable from "./SubUsersTable";
import { withRouter } from "react-router-dom";

class SubUsers extends Component {
  state = {
    userIds: [],
  };
  componentDidMount() {
    const paramId = this.props.location.search;
    const formattedParamId = paramId.replace("?", "");
    const { getSubUsersList, getUserIds } = this.props;
    getSubUsersList(formattedParamId);
    // getUserIds();
  }

  componentDidUpdate(prevProps) {
    const paramId = this.props.location.search;
    const formattedParamId = paramId.replace("?", "");
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.props.getSubUsersList(formattedParamId);
    }
    if (prevProps.userIds !== this.props.userIds) {
      this.setState({
        userIds: this.props.userIds,
      });
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName: modalName,
      modalData: modalData,
      userIds: this.state.userIds,
    });
  };

  //   editUser = (user) => {
  //     this.showModal("addEditUser", { user: user, userIds: this.state.userIds });
  //   };

  //   addUser = () => {
  //     this.showModal("addEditUser", { userIds: this.state.userIds });
  //   };

  //   deleteUser = (id) => {
  //     this.showModal("deleteUser", { id: id });
  //   };

  changePage = (page) => {
    this.props.getSubUsersList(page);
  };

  render() {
    const { subUsersList, meta, loading } = this.props;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Subusers
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          {/* <div className="d-flex p-3 justify-content-end">
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.addUser.bind(this)}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Add
            </button>
          </div> */}
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>Subusers</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <CardBody>
              {subUsersList && subUsersList.length > 0 ? (
                <SubUsersTable
                  pagination={meta.pagination}
                  users={subUsersList}
                  //   editUser={this.editUser}
                  //   deleteUser={this.deleteUser}
                  changePage={(page) => this.changePage(page)}
                  isSubUsersPage
                ></SubUsersTable>
              ) : (
                <h3 className="text-center">No Sub Users Found</h3>
              )}
              {loading ? <h3 className="text-center">Loading...</h3> : ""}
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.subUsers.loading,
    subUsersList: state.subUsers.subUsersList,
    meta: state.subUsers.meta,
    error: state.subUsers.error,
    success: state.subUsers.success,
    reload: state.subUsers.reload,
    userIds: state.users.userIds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubUsersList: (data) => dispatch(actions.getSubUsersList(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
    getUserIds: (data) => dispatch(actions.getUserIds(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(withRouter(SubUsers)));
