import React from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import Content from "./Content";
import "./index.styles.scss";

class LocationDevices extends React.Component {
  render() {
    return (
      <Card className="card-default location-devices locationDiv-height mb-md-0 mb-2">
        <CardHeader className="d-flex align-items-center justify-content-between card-header-border">
          <CardTitle>Locations &amp; Devices</CardTitle>
        </CardHeader>
        <div
          style={{
            height: "1px",
            width: "100%",
            background: "#ccc",
          }}
        />
        <CardBody>
          <Content {...this.props} />
        </CardBody>
      </Card>
    );
  }
}

export default LocationDevices;
