import React, { Component } from 'react';
class Loader extends Component {

    render() {
        return (
            <div className="loader-overlay d-flex align-items-center justify-content-center">
                <div className="ball-triangle-path">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

}

export default Loader;
