import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "../../components/Common/wrapper.js";
import * as actions from "../../stores/actions";
import HeaderHorizontal from "../../components/Layout/HeaderHorizontal";
import Footer from "../../components/Layout/Footer";
import Loader from "../../components/Layout/Loader";
import GenericModal from "../../components/Common/Modal";
import "./index.style.scss";

class Base extends Component {
  state = {
    isLoading: false,
  };
  componentDidMount() {
    this.props.getUserMeta();
  }
  UNSAFE_componentWillMount() {
    $("body").addClass("layout-h");
  }

  UNSAFE_componentWillUnmount() {
    $("body").removeClass("layout-h");
  }

  render() {
    const { isLoading, dashboard } = this.props;
    const { userInfo } = dashboard;
    let userRole = "";
    let userEmail = "";
    if (userInfo) {
      const { role, email } = userInfo;
      userRole = role;
      userEmail = email;
    }
    return (
      <div className="wrapper">
        <HeaderHorizontal
          userRole={userRole}
          userEmail={userEmail}
          updateTempState={this.props.updateTempState}
          tempState={this.props.tempState}
        />
        <section className="section-container">{this.props.children}</section>
        <Footer />
        <GenericModal />
        {isLoading && <Loader />}
      </div>
    );
  }
}

// export default Base;
const mapStateToProps = (state) => {
  return {
    loading: state.devices.loading,
    dashboard: state.dashboard.dashboard,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserMeta: () => dispatch(actions.getUserMeta()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Base);
