import { combineReducers } from "redux";
// reducers
import auth from "./auth";
import forgotPassword from "./forgotPassword";
import dashboard from "./dashboard";
import locationsDevices from "./locationsDevices";
import devices from "./devices";
import gateways from "./gateways";
import users from "./users";
import reports from "./reports";
import modal from "./modal";
import deviceAlarms from "./deviceAlarms";
import deviceConectedStatus from "./status";
import otaDevices from "./otaDevices";
import userMessageTemplates from "./userMessageTemplates";
import userMessageTemplatesRel from "./userMessageTemplatesRel";
import auditTrail from "./auditTrail";
import userNotifications from "./userNotifications";
import userNotificationStatistics from "./userNotificationStatistics";
import account from "./account";
import gatewayLogs from "./gatewayLogs";
import subUsers from "./subUsers";
import alarms from "./alarms";
import alertProfiles from "./alertProfile";
import OfflineAlarms from "./offlineAlarms";

const reducers = {
  auth,
  forgotPassword,
  modal,
  dashboard,
  locationsDevices,
  devices,
  gateways,
  users,
  reports,
  deviceAlarms,
  deviceConectedStatus,
  otaDevices,
  userNotifications,
  userNotificationStatistics,
  userMessageTemplates,
  userMessageTemplatesRel,
  auditTrail,
  account,
  gatewayLogs,
  subUsers,
  alarms,
  alertProfiles,
  OfflineAlarms,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
