import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import { withNamespaces, Trans } from "react-i18next";
import ContentWrapper from "../Layout/ContentWrapper";
import { Container, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import CardTool from "../Common/CardTool";
import DeviceTable from "./DeviceTable";
class Devices extends Component {
  state = {
    page: 0,
  };
  componentDidMount() {
    const { getDeviceList, getGateways, getLocations } = this.props;
    getDeviceList(this.state.page);
    getGateways(0);
    getLocations(0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");
      this.props.getDeviceList(this.state.page);
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.props.getDeviceList(this.state.page);
    }
    if (prevProps.deviceList !== this.props.deviceList) {
      this.forceUpdate();
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName: modalName,
      modalData: modalData,
    });
  };

  editDevice = (device) => {
    this.showModal("addEditDevice", { device: device });
  };

  addDevice = () => {
    this.showModal("addEditDevice");
  };

  openOfflineAlarms = () => {
    this.showModal("offlineAlarms");
  };

  deleteDevice = (id) => {
    this.showModal("deleteDevice", { id: id });
  };

  changePage = (page) => {
    // this.setState({ page });
    this.props.getDeviceList(page);
  };

  render() {
    const { deviceList, meta, loading } = this.props;

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Devices
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          <div className="d-flex p-3 justify-content-end">
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.props.downloadDevices}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Download Devices List
            </button>
            {/* <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.openOfflineAlarms.bind(this)}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Update Offline Alarms
            </button> */}
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.addDevice.bind(this)}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Add
            </button>
          </div>
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>Devices</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <div
              style={{
                height: "1px",
                width: "100%",
                background: "#ccc",
              }}
            />
            <CardBody>
              {deviceList && deviceList.length > 0 && (
                <DeviceTable
                  pagination={meta.pagination}
                  devices={deviceList}
                  editDevice={this.editDevice}
                  deleteDevice={this.deleteDevice}
                  changePage={(page) => this.changePage(page)}
                ></DeviceTable>
              )}
              {loading ? <h3 className="text-center">Loading...</h3> : ""}
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.devices.loading,
    deviceList: state.devices.deviceList,
    meta: state.devices.meta,
    error: state.devices.error,
    success: state.devices.success,
    reload: state.devices.reload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeviceList: (data) => dispatch(actions.getDeviceList(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
    getGateways: (data) => dispatch(actions.getGatewayList(data)),
    getLocations: (data) => dispatch(actions.getLocations(data)),
    downloadDevices: () => dispatch(actions.downloadDevices()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(Devices));
