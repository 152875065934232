import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { fieldHasError, onSubmit } from "../../helpers/formValidators";
import Select from "react-select";
import { showToast } from "../../helpers/toast";
import { selectStyle } from "../../config/selectStyle";

class AssignUser extends Component {
  state = {
    formAssignUser: {
      userId: "",
    },
    selectedUser: {
      id: "",
      name: "",
    },
    deviceId: "",
    users: [],
  };

  componentDidMount() {
    const { modalData, getUserList } = this.props;
    let users = modalData.device.deviceUsers;
    users = users.filter((user) => user.name !== null);
    this.setState({
      deviceId: modalData.device.id,
      users: users,
    });
    getUserList(0);
  }

  assignUserFn = (payload) => {
    const { assignUser } = this.props;
    assignUser(payload);
  };

  handleChangeUser = (selectedUser) => {
    this.setState({ selectedUser });
  };

  isUserSelected = (option) => {
    return this.state.selectedUser.id === option.id ? true : false;
  };

  onSubmit = (e) => {
    if (this.state.selectedUser.id) {
      const state = onSubmit(e, this.state);
      this.setState(state);

      if (!state.hasError) {
        let params = {
          userId: state.selectedUser.id,
          deviceId: state.deviceId,
        };
        this.assignUserFn(params);
      }
      e.preventDefault();
    } else {
      showToast("Please select a user", "error");
      e.preventDefault();
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  unassignUser = (user) => {
    const { unassignUser } = this.props;
    let payload = {
      userId: user.id,
      deviceId: this.state.deviceId,
    };
    unassignUser(payload);
  };

  comparer(otherArray) {
    return function(current) {
      return (
        otherArray.filter(function(other) {
          return other.id === current.id;
        }).length === 0
      );
    };
  }

  render() {
    const { toggleModal, userList } = this.props;
    const { users } = this.state;

    var uniqueResultOne = userList.filter(function(obj) {
      return !users.some(function(obj2) {
        return obj.id === obj2.id;
      });
    });

    return (
      <React.Fragment>
        <form className="mb-3" name="formAssignUser" onSubmit={this.onSubmit}>
          <ModalHeader toggle={toggleModal}>Assign Sub-User</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-wrap">
              {users.map((user, index) => (
                <button
                  type="button"
                  key={user.id}
                  className="btn mr-2 btn-infoactive mb-2"
                  onClick={() => {
                    this.unassignUser(user);
                  }}
                >
                  {user.name}{" "}
                  <i className="ml-2 fa fa-times" aria-hidden="true"></i>
                </button>
              ))}
            </div>
            <div className="mt-2 form-group">
              <label className="text-muted">Users</label>
              <Select
                styles={selectStyle}
                name="locationId"
                value={this.state.selectedUser}
                onChange={this.handleChangeUser}
                options={uniqueResultOne}
                getOptionLabel={(option) =>
                  option.name ? `${option.name} (${option.email})` : ""
                }
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  this.isUserSelected(option);
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="submit">
              Assign
            </Button>
            <Button color="secondary" type="button" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userList: state.users.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserList: (data) => dispatch(actions.getUserList(data)),
    assignUser: (data) => dispatch(actions.assignUser(data)),
    unassignUser: (data) => dispatch(actions.unassignUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignUser);
