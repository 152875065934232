import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";

export const getAlarmsListStart = () => {
    return {
      type: actionTypes.GET_ALARMS_LIST_START,
    };
  };
  
  export const getAlarmsListSuccess = (data) => {
    return {
      type: actionTypes.GET_ALARMS_LIST_SUCCESS,
      data: data,
    };
  };
  
  export const getAlarmsListFail = (error) => {
    return {
      type: actionTypes.GET_ALARMS_LIST_FAIL,
      error: error,
    };
  };
  
  
  export const getAlarmsList = (page = 1, pageSize = 10, deviceId = undefined) => {
    return (dispatch) => {
      dispatch(getAlarmsListStart());
      const headers = getCommonHeaders();
      let url = `${apiPath.baseUrl}${apiPath.alarms.list}`;
      url = url.replace("{id}", deviceId);
      if (page) {
        url += `?page=${page}&pageSize=${pageSize}`;
      } else {
        url += "?page=1&pageSize=1000";
      }
      axios
        .get(url, { headers })
        .then((response) => {
          dispatch(getAlarmsListSuccess(response.data));
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(unauthorized());
          } else {
            dispatch(getAlarmsListFail(error.response.data.errors));
          }
        });
    };
  };