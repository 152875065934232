import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import { withNamespaces, Trans } from "react-i18next";
import ContentWrapper from "../Layout/ContentWrapper";
import { Container, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import CardTool from "../Common/CardTool";
import UsersTable from "./UsersTable";
class Users extends Component {
  state = {
    userIds: [],
  };
  componentDidMount() {
    const { getUserList, getUserIds } = this.props;
    getUserList();
    getUserIds();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");
    }
    if (
      prevProps.successTemplate !== this.props.successTemplate &&
      this.props.successTemplate
    ) {
      showToast(this.props.successTemplate, "success");
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.props.getUserList();
    }
    if (prevProps.userIds !== this.props.userIds) {
      this.setState({
        userIds: this.props.userIds,
      });
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName: modalName,
      modalData: modalData,
      userIds: this.state.userIds,
    });
  };

  editUser = (user) => {
    this.showModal("addEditUser", { user: user, userIds: this.state.userIds });
  };

  addUser = () => {
    this.showModal("addEditUser", { userIds: this.state.userIds });
  };

  deleteUser = (id) => {
    this.showModal("deleteUser", { id: id });
  };

  linkMessageTemplate = (user) => {
    this.showModal("addUserMessageTemplate", { user: user });
  };

  changePage = (page) => {
    this.props.getUserList(page);
  };

  render() {
    const { userList, meta, loading } = this.props;
    const role = localStorage.getItem("role");

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Users
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          <div className="d-flex p-3 justify-content-end">
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={this.addUser.bind(this)}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Add
            </button>
          </div>
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>Users</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <div
              style={{
                height: "1px",
                width: "100%",
                background: "#ccc",
              }}
            />
            <CardBody>
              {userList && userList.length > 0 && (
                <UsersTable
                  pagination={meta.pagination}
                  users={userList}
                  editUser={this.editUser}
                  deleteUser={this.deleteUser}
                  linkMessageTemplate={this.linkMessageTemplate}
                  changePage={(page) => this.changePage(page)}
                ></UsersTable>
              )}
              {loading ? <h3 className="text-center">Loading...</h3> : ""}
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.users.loading,
    userList: state.users.userList,
    meta: state.users.meta,
    error: state.users.error,
    success: state.users.success,
    reload: state.users.reload,
    userIds: state.users.userIds,
    successTemplate: state.userMessageTemplatesRel.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserList: (data) => dispatch(actions.getUserList(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
    getUserIds: (data) => dispatch(actions.getUserIds(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(Users));
