import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { isArray } from "lodash";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";
import { showToast } from "../../helpers/toast";
import { Input } from "reactstrap";
import socketIOClient from "socket.io-client";
import "./Map.scss";

const translatePos = {
  x: 0,
  y: 0,
};
var scale = 1.0;
const minRadius = 1;
const maxRadius = 5;

const extractDeviceData = (message) => {
  let fieldsToCheck = ["t", "h", "c"];

  fieldsToCheck.forEach((field) => {
    if (parseInt(message[field], 10) === -255) {
      message[field] = -255;
    }
  });

  let temperature = message.t
    ? message.t.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    : message.thermo
    ? message.thermo.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    : null;

  const shtT = message.sht_t
    ? message.sht_t.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    : null;

  const shtRh = message.sht_rh
    ? message.sht_rh.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    : null;

  const carbon = message.c
    ? message.c.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    : -255;
  let humidity = message.h
    ? message.h.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    : null;

  if (shtRh && parseInt(shtRh, 10) != -255) {
    humidity = shtRh;
  }

  if (message.thermo && message.thermo !== -255) {
    humidity = -255;
  }
  if (message.thermo && message.thermo === -255) {
    temperature = shtT;
  }

  temperature =
    message.Node_Type && message.Node_Type === 1
      ? message.thermo && message.thermo !== -255
        ? message.thermo
        : message.sht_t
      : temperature;

  humidity =
    message.Node_Type && message.Node_Type === 1 ? message.sht_rh : humidity;

  return [temperature, carbon, humidity];
};

function Map(props) {
  const canvasRef = useRef(null);
  const [canvasSettings, setCanvasSettings] = useState({
    width: window.innerWidth - 10,
    height: window.innerHeight - 200,
    offsetX: 0,
    offsetY: 0,
    scale: 0,
  });
  const [context, setContext] = useState(null);
  const [startAddPoly, setStartAddPoly] = useState({
    started: false,
    locationId: 0,
    title: "",
  });
  const [polyCoords, setPolyCoords] = useState([]);
  const [polyCoord, setPolyCoord] = useState([]);
  const [pointCoords, setPointCoords] = useState([]);
  const [image, setImage] = useState(null);
  const [selectedMap, setSelectedMap] = useState({});
  const [removedLocations, setRemovedLocations] = useState([]);

  const [locationsDisplay, setLocationsDisplay] = useState("none");
  const [devicePref, setDevicePref] = useState({
    showSettings: "none",
    showDevices: "none",
    locationId: 0,
    locationIndex: -1,
    x: 0,
    y: 0,
  });
  const [deviceRemovePref, setDeviceRemovePref] = useState({
    display: "none",
    index: null,
    x: 0,
    y: 0,
    deviceX: 0,
    deviceY: 0,
    isDeviceRemoved: false,
  });

  const [showDeviceInfo, setShowDeviceInfo] = useState({
    show: false,
    name: "",
    id: null,
    top: 0,
    left: 0,
    isDeviceRemoved: false,
  });

  const [mapList, setMapList] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");

  const [radius, setRadius] = useState(5);
  const [locationDeviceStatusLoaded, setLocationDeviceStatusLoaded] = useState(
    false
  );
  const [locationDeviceStatuses, setLocationDeviceStatuses] = useState({});
  const [deviceLatestData, setDeviceLatestData] = useState({});

  const [socketNotificationEvent, setSocketNotificationEvent] = useState(null);
  const [socketLiveDataEvent, setSocketLiveDataEvent] = useState(null);

  const [themeColor, setThemeColor] = useState(
    document.documentElement.getAttribute("data-theme") === "light"
      ? "#e5e7eb"
      : "#313752"
  );

  const [audio] = useState(new Audio("audio/alarm.wav"));
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [redraw, setRedraw] = useState(false);

  const [deviceSearch, setDeviceSearch] = useState("");
  const [moveDevice, setMoveDevice] = useState({
    index: -1,
    mouseX: 0,
    mouseY: 0,
    deviceX: 0,
    deviceY: 0,
  });

  const role = localStorage.getItem("role");

  var scaleMultiplier = 0.8;
  var startDragOffset = {
    x: 0,
    y: 0,
  };
  var mouseDown = false;

  const draw = async (scale, translatePos) => {
    const canvas = canvasRef.current;
    var Context = context;
    if (!context) {
      Context = canvas.getContext("2d");
    }
    // clear canvas
    Context.clearRect(0, 0, canvas.width, canvas.height);

    Context.save();
    //Context.translate(translatePos.x, translatePos.y);
    //Context.scale(scale, scale);

    Context.drawImage(
      image,
      translatePos.x,
      translatePos.y,
      image.width * scale,
      image.height * scale
    );

    Context.restore();

    if (!context) {
      setContext(Context);
    }
    drawPolygons();
    drawPoints();
  };

  const isMouseOverDevice = (x, y) => {
    if (!pointCoords.length) return [false, {}];
    let i = 0;
    for (const el of pointCoords) {
      const distance = Math.sqrt(
        (x -
          ((el.x * scale) / el.scale +
            (translatePos.x - (el.imageX * scale) / el.scale))) **
          2 +
          (y -
            ((el.y * scale) / el.scale +
              (translatePos.y - (el.imageY * scale) / el.scale))) **
            2
      );
      if (distance <= (2 * radius * scale) / el.scale) {
        return [
          true,
          {
            name: el.name,
            x:
              (el.x * scale) / el.scale +
              (translatePos.x - (el.imageX * scale) / el.scale),
            y:
              (el.y * scale) / el.scale +
              (translatePos.y - (el.imageY * scale) / el.scale),
            id: el.id,
            rawX: el.x,
            rawY: el.y,
            index: i,
            isDeviceRemoved: locationDeviceStatuses[el.locationId][el.id]
              ? false
              : true,
          },
        ];
      }
      i++;
    }
    return [false, {}];
  };

  const handleMouseDown = (evt) => {
    if (startAddPoly.started) return;
    mouseDown = true;
    startDragOffset.x = evt.clientX - translatePos.x;
    startDragOffset.y = evt.clientY - translatePos.y;
    if (moveDevice.index !== -1) {
      const poly = polyCoords.find(
        (el) => el[0].id === pointCoords[moveDevice.index].locationId
      );
      const xCoord = evt.clientX - canvasSettings.offsetX;
      const yCoord = evt.clientY - canvasSettings.offsetY;
      if (poly && isInsidePolygon(poly, xCoord, yCoord)) {
        setMoveDevice({
          index: -1,
          mouseX: 0,
          mouseY: 0,
          deviceX: 0,
          deviceY: 0,
        });
      }
    }
  };

  const removeMouseDown = (evt) => {
    mouseDown = false;
  };

  const handleMouseMove = (evt) => {
    if (startAddPoly.started) return;

    if (mouseDown) {
      translatePos.x = evt.clientX - startDragOffset.x;
      translatePos.y = evt.clientY - startDragOffset.y;
      draw(scale, translatePos);
    } else {
      if (moveDevice.index !== -1) {
        const xCoord = evt.clientX - canvasSettings.offsetX;
        const yCoord = evt.clientY - canvasSettings.offsetY;
        setPointCoords((prev) =>
          prev.map((o, i) =>
            i === moveDevice.index
              ? {
                  ...o,
                  //x: (xCoord * scale / o.scale + (translatePos.x - o.imageX * scale / o.scale))  ,
                  //y: (yCoord * scale / o.scale + (translatePos.y - o.imageY * scale / o.scale))}
                  x:
                    (xCoord * o.scale) / scale -
                    ((translatePos.x * o.scale) / scale - o.imageX), //coordinates.imageX * scale / coordinates.scale
                  y:
                    (yCoord * o.scale) / scale -
                    ((translatePos.y * o.scale) / scale - o.imageY),
                } //coordinates.imageX * scale / coordinates.scale
              : o
          )
        );
      } else {
        const [status, data] = isMouseOverDevice(
          evt.clientX - canvasSettings.offsetX,
          evt.clientY - canvasSettings.offsetY
        );
        if (status) {
          setShowDeviceInfo({
            show: true,
            name: data.name,
            id: data.id,
            top: data.y - radius - 80,
            left: data.x,
            isDeviceRemoved: data.isDeviceRemoved,
          });
        } else {
          if (showDeviceInfo.show) {
            setShowDeviceInfo({
              show: false,
              name: "",
              id: null,
              top: 0,
              left: 0,
              isDeviceRemoved: false,
            });
          }
        }
      }
    }
  };

  const upScale = () => {
    if (startAddPoly.started) return;

    scale /= scaleMultiplier;
    draw(scale, translatePos);
  };

  const downScale = () => {
    if (startAddPoly.started) return;

    scale *= scaleMultiplier;
    draw(scale, translatePos);
  };

  const drawPolygon = (coordinates) => {
    let color = "green";
    let isDeviceRemoved = false;
    if (
      locationDeviceStatuses &&
      locationDeviceStatuses[coordinates[0].id] &&
      pointCoords.length > 0
    ) {
      const devices = pointCoords.filter(
        (el) => el.locationId === coordinates[0].id
      );
      devices.map((el) => {
        if (
          locationDeviceStatuses[coordinates[0].id][el.id] &&
          (locationDeviceStatuses[coordinates[0].id][el.id].offline ||
            locationDeviceStatuses[coordinates[0].id][el.id].alarm)
        ) {
          color = "red";
        } else if (!locationDeviceStatuses[coordinates[0].id][el.id]) {
          isDeviceRemoved = true;
        }
      });
    } else if (!locationDeviceStatuses[coordinates[0].id]) {
      //check if we have it in location
      const devices = pointCoords.filter(
        (el) => el.locationId === coordinates[0].id
      );
      if (devices.length > 0) {
        isDeviceRemoved = true;
      }
    }

    context.beginPath();
    context.strokeStyle = isDeviceRemoved ? "#35bcbf" : color;
    context.lineWidth = 3;
    context.moveTo(
      (coordinates[0].x * scale) / coordinates[0].scale +
        (translatePos.x -
          (coordinates[0].imageX * scale) / coordinates[0].scale),
      (coordinates[0].y * scale) / coordinates[0].scale +
        (translatePos.y -
          (coordinates[0].imageY * scale) / coordinates[0].scale)
    );
    for (let index = 1; index < coordinates.length; index++) {
      context.lineTo(
        (coordinates[index].x * scale) / coordinates[index].scale +
          (translatePos.x -
            (coordinates[index].imageX * scale) / coordinates[index].scale),
        (coordinates[index].y * scale) / coordinates[index].scale +
          (translatePos.y -
            (coordinates[index].imageY * scale) / coordinates[index].scale)
      );
    }
    context.closePath();
    context.stroke();

    if (coordinates[0].title) {
      context.beginPath();
      context.textBaseline = "top";
      context.fillStyle = "#166299";
      var width = context.measureText(coordinates[0].title).width + 10;
      context.roundRect(
        (coordinates[0].x * scale) / coordinates[0].scale +
          (translatePos.x -
            (coordinates[0].imageX * scale) / coordinates[0].scale),
        (coordinates[0].y * scale) / coordinates[0].scale +
          (translatePos.y -
            (coordinates[0].imageY * scale) / coordinates[0].scale),
        width,
        parseInt("20px Arial", 10),
        [5, 5, 5, 5]
      );
      context.fill();
      context.fillStyle = "#fff";

      context.font = "12px Arial";
      context.fillText(
        coordinates[0].title,
        (coordinates[0].x * scale) / coordinates[0].scale +
          (translatePos.x -
            (coordinates[0].imageX * scale) / coordinates[0].scale) +
          5,
        (coordinates[0].y * scale) / coordinates[0].scale +
          (translatePos.y -
            (coordinates[0].imageY * scale) / coordinates[0].scale) +
          4
      );
    }
  };

  const drawPolygons = () => {
    if (!polyCoords.length) return;

    for (const coordinate of polyCoords) {
      drawPolygon(coordinate);
    }
  };

  const drawPoint = (coordinates) => {
    let deviceStatus = "online";
    if (!locationDeviceStatuses[coordinates.locationId]) {
      locationDeviceStatuses[coordinates.locationId] = {};
    }
    if (
      locationDeviceStatuses &&
      locationDeviceStatuses[coordinates.locationId] &&
      locationDeviceStatuses[coordinates.locationId][coordinates.id]
    ) {
      if (
        locationDeviceStatuses[coordinates.locationId][coordinates.id].offline
      ) {
        deviceStatus = "offline";
      } else if (
        locationDeviceStatuses[coordinates.locationId][coordinates.id].alarm
      ) {
        deviceStatus = "alarm";
      }
    } else if (
      locationDeviceStatuses[coordinates.locationId] &&
      !locationDeviceStatuses[coordinates.locationId][coordinates.id]
    ) {
      deviceStatus = "removedFromLocation";
      if (!removedLocations.includes(coordinates.name)) {
        setRemovedLocations((oldArray) => [...oldArray, coordinates.name]);
      }
    }

    //device.x = (mouseCoordX - (translatePos.x - coordinates.imageX * scale / coordinates.scale )) * coordinates.scale /scale

    if (deviceStatus === "offline") {
      const degreesToRadians = (degrees) => {
        return (degrees * Math.PI) / 180;
      };
      const begin = 45;
      const interval = 90;
      const arcSize = degreesToRadians(interval);
      for (var startingAngle = begin; startingAngle < 360; ) {
        context.beginPath();

        context.moveTo(
          (coordinates.x * scale) / coordinates.scale +
            (translatePos.x - (coordinates.imageX * scale) / coordinates.scale),
          (coordinates.y * scale) / coordinates.scale +
            (translatePos.y - (coordinates.imageY * scale) / coordinates.scale)
        );
        context.arc(
          (coordinates.x * scale) / coordinates.scale +
            (translatePos.x - (coordinates.imageX * scale) / coordinates.scale),
          (coordinates.y * scale) / coordinates.scale +
            (translatePos.y - (coordinates.imageY * scale) / coordinates.scale),
          2 * radius * scale,
          degreesToRadians(startingAngle),
          startingAngle + arcSize,
          false
        );

        context.closePath();
        context.strokeStyle = "red";
        context.stroke();
        startingAngle = startingAngle + interval;
      }
    } else {
      let strokeStyle = "green";
      let fillStyle = "green";
      if (deviceStatus === "removedFromLocation") {
        strokeStyle = "#35bcbf";
        fillStyle = "white";
      } else if (deviceStatus === "alarm") {
        strokeStyle = "red";
        fillStyle = "red";
      }
      context.beginPath();
      context.arc(
        (coordinates.x * scale) / coordinates.scale +
          (translatePos.x - (coordinates.imageX * scale) / coordinates.scale),
        (coordinates.y * scale) / coordinates.scale +
          (translatePos.y - (coordinates.imageY * scale) / coordinates.scale),
        2 * radius * scale,
        0,
        2 * Math.PI,
        false
      );
      context.strokeStyle = strokeStyle;
      context.fillStyle = fillStyle;
      context.fill();
      context.stroke();
    }
  };

  const drawPoints = () => {
    if (!pointCoords.length) return;

    for (const coordinate of pointCoords) {
      drawPoint(coordinate);
    }
  };

  function isInsidePolygon(polygon, x, y) {
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let xi =
        (polygon[i].x * scale) / polygon[i].scale +
        (translatePos.x - (polygon[i].imageX * scale) / polygon[i].scale);
      let yi =
        (polygon[i].y * scale) / polygon[i].scale +
        (translatePos.y - (polygon[i].imageY * scale) / polygon[i].scale);
      let xj =
        (polygon[j].x * scale) / polygon[j].scale +
        (translatePos.x - (polygon[j].imageX * scale) / polygon[i].scale);
      let yj =
        (polygon[j].y * scale) / polygon[j].scale +
        (translatePos.y - (polygon[j].imageY * scale) / polygon[i].scale);

      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }

  const handleMouseClick = (e) => {
    if (e.type === "click" && startAddPoly.started) {
      const mouseX = parseInt(e.clientX - canvasSettings.offsetX);
      const mouseY = parseInt(e.clientY - canvasSettings.offsetY);

      const newPoly = [
        ...polyCoord,
        {
          id: startAddPoly.locationId,
          title: startAddPoly.title,
          x: mouseX,
          y: mouseY,
          imageWidth: image.width,
          imageHeight: image.height,
          imageX: translatePos.x,
          imageY: translatePos.y,
          scale: scale,
        },
      ];

      setPolyCoord(newPoly);
      drawPolygon(newPoly);
    }

    if (devicePref.locationId) {
      setDevicePref({
        showSettings: "none",
        showDevices: "none",
        locationId: 0,
        locationIndex: -1,
        x: 0,
        y: 0,
      });
    }
    if (deviceRemovePref.index !== null) {
      setDeviceRemovePref({
        display: "none",
        index: null,
        x: 0,
        y: 0,
        deviceX: 0,
        deviceY: 0,
        isDeviceRemoved: false,
      });
    }
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    if (role !== "super_user") return;
    const mouseX = parseInt(e.clientX - canvasSettings.offsetX);
    const mouseY = parseInt(e.clientY - canvasSettings.offsetY);

    const [status, data] = isMouseOverDevice(mouseX, mouseY);
    if (status) {
      setDeviceRemovePref({
        display: "block",
        index: data.index,
        x: data.x,
        y: data.y + 90,
        deviceX: data.rawX,
        deviceY: data.rawY,
        isDeviceRemoved: data.isDeviceRemoved,
      });
    } else {
      let i = 0;
      for (let coord of polyCoords) {
        if (isInsidePolygon(coord, mouseX, mouseY)) {
          setDevicePref({
            showSettings: "block",
            showDevices: "none",
            locationId: coord[0].id,
            locationIndex: i,
            x: mouseX,
            y: mouseY,
          });
        }
        i++;
      }
    }
  };

  const addMap = () => {
    props.setModal({
      showModal: true,
      modalName: "addMap",
      modalData: { setUploadStatus: setUploadStatus },
    });
  };

  const getMapList = async () => {
    setMapList([]);
    const headers = getCommonHeaders();
    const response = await fetch(`${apiPath.baseUrl}${apiPath.map.list}`, {
      method: "GET",
      headers: headers,
    });
    const data = await response.json();
    if (data) {
      setMapList(data);
      if (data.length > 0 && data[0] && !image) {
        setCanvasImage(data[0]);
      }
    }
  };
  const saveData = async () => {
    if (!selectedMap || !selectedMap.id) {
      showToast("Select map !!!", "error");
      return false;
    }

    let formData = new FormData();
    formData.append("id", selectedMap.id);
    formData.append(
      "image_settings",
      JSON.stringify({
        x: translatePos.x,
        y: translatePos.y,
      })
    );
    formData.append("location_settings", JSON.stringify(polyCoords));
    formData.append("device_settings", JSON.stringify(pointCoords));
    formData.append("radius", radius);
    formData.append("scale", scale);

    const headers = getCommonHeaders();
    const response = await fetch(`${apiPath.baseUrl}${apiPath.map.add}`, {
      method: "POST",
      headers: headers,
      body: formData,
    });

    if (response && response.ok) {
      getMapList();
      showToast("Map uploaded succesfully", "success");
    } else {
      showToast("Something went wrong", "error");
    }
  };

  const deleteMap = async (id) => {
    if (!id) {
      showToast("Select map !!!", "error");
      return false;
    }

    let url = `${apiPath.baseUrl}${apiPath.map.delete}`;
    url = url.replace("{id}", id);

    const headers = getCommonHeaders();
    const response = await fetch(url, {
      method: "DELETE",
      headers: headers,
    });

    if (response && response.ok) {
      getMapList();
      showToast("Map deleted succesfully", "success");
      setSelectedMap({});
      props.setModal({
        showModal: false,
        modalName: "",
        modalData: {},
      });
      resetData();
      context.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      setContext(null);
    } else {
      showToast("Something went wrong", "error");
    }
  };

  const resetData = async () => {
    translatePos.x = 0;
    translatePos.y = 0;
    scale = 1.0;
    setRadius(5);
    setPolyCoords([]);
    setPointCoords([]);

    draw(scale, translatePos);
  };

  const handleWheel = (event) => {
    if (
      !image ||
      moveDevice.index > -1 ||
      scale + event.deltaY * 0.0005 * -1 <= 0
    )
      return;
    event.preventDefault();
    const { deltaY } = event;
    scale = scale + deltaY * 0.0005 * -1;
    draw(scale, translatePos);
  };

  const isLocationUsed = (location) => {
    for (const map of mapList) {
      if (map.id === selectedMap.id) {
        const d = polyCoords.find((e) => e[0].id === location.id);
        if (d) {
          return true;
        }
      } else {
        if (map.locationSettings) {
          const d = JSON.parse(map.locationSettings).find(
            (e) => e[0] && e[0].id === location.id
          );
          if (d) {
            return true;
          }
        }
      }
    }
    const d = polyCoords.find((e) => e[0] && e[0].id === location.id);
    if (d) {
      return true;
    }
    return false;
  };

  const isDeviceUsed = (device) => {
    for (const map of mapList) {
      if (map.id === selectedMap.id) {
        const d = pointCoords.find((e) => e.id === device.id);
        if (d) {
          return true;
        }
      } else {
        if (map.deviceSettings) {
          const d = JSON.parse(map.deviceSettings).find(
            (e) => e.id === device.id
          );
          if (d) {
            return true;
          }
        }
      }
    }
    const d = pointCoords.find((e) => e && e.id === device.id);
    if (d) {
      return true;
    }
    return false;
  };

  const setCanvasImage = async (e) => {
    const newImage = new Image();
    newImage.src = e.location;
    await newImage.decode();
    setImage(newImage);
    setSelectedMap(e);
    setRemovedLocations([]);
  };

  useEffect(() => {
    if (removedLocations.length > 0) {
      for (const title of removedLocations) {
        props.setModal({
          showModal: true,
          modalName: "confirmDeviceRemovedMap",
          modalData: { title: title },
        });

        //showToast(`Device ${title} has been moved or deleted from its location. To receive status updates further, the device needs to be relocated.`, "error");
      }
    }
  }, [removedLocations]);

  useEffect(() => {
    const { getLocations, getAllDevices } = props;
    getLocations();
    getAllDevices();
    const canvas = canvasRef.current;
    var BB = canvas.getBoundingClientRect();
    setCanvasSettings({
      ...canvasSettings,
      offsetX: BB.left,
      offsetY: BB.top,
    });

    getMapList();

    const email = localStorage.getItem("email");
    let con = null;
    if (!email) {
      showToast("Please login again, otherwise socket wont work !!!", "error");
    } else {
      const endpoint = `${apiPath.baseUrl}?email=${email.replace(
        "@",
        "AT_SYMBOL"
      )}&timeValue=0`;
      con = socketIOClient(endpoint);
      con.on("mapNotification", (data) => {
        if (data) {
          setSocketNotificationEvent(data);
        }
      });
      con.on("mapLiveData", (data) => {
        if (data) {
          setSocketLiveDataEvent(data);
        }
      });
    }

    audio.addEventListener("ended", () => setPlaying(false));
    audio.muted = false;
    return () => {
      if (con) {
        con.disconnect();
      }
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  useEffect(() => {
    if (uploadStatus === "Saved") {
      setUploadStatus("");
      getMapList();
      props.setModal({ showModal: false, modalName: "", modalData: {} });
    }
  }, [uploadStatus]);

  useEffect(() => {
    if (context && polyCoords) {
      draw(scale, translatePos);
    }
  }, [context, polyCoords, pointCoords, radius]);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.muted = muted;
  }, [muted]);

  useEffect(() => {
    if (image && selectedMap.id && locationDeviceStatusLoaded) {
      if (selectedMap.imageSettings) {
        translatePos.x = JSON.parse(selectedMap.imageSettings).x;
        translatePos.y = JSON.parse(selectedMap.imageSettings).y;
      } else {
        translatePos.x = 0;
        translatePos.y = 0;
      }

      if (selectedMap.scale) {
        scale = selectedMap.scale;
      } else {
        scale = 1.0;
      }

      if (selectedMap.radius) {
        setRadius(selectedMap.radius > 5 ? 5 : selectedMap.radius);
      } else {
        setRadius(5);
      }

      if (selectedMap.locationSettings) {
        setPolyCoords(JSON.parse(selectedMap.locationSettings));
      } else {
        setPolyCoords([]);
      }

      if (selectedMap.deviceSettings) {
        setPointCoords(JSON.parse(selectedMap.deviceSettings));
      } else {
        setPointCoords([]);
      }

      draw(scale, translatePos);
    }
  }, [selectedMap, image, locationDeviceStatusLoaded]);

  useEffect(() => {
    if (props.allDevices && isArray(props.allDevices)) {
      let obj = {};
      let latestDataObj = {};
      props.allDevices.map((el) => {
        if (!obj[el.locationId]) {
          obj[el.locationId] = {};
        }
        if (!obj[el.locationId][el.id]) {
          obj[el.locationId][el.id] = {};
        }
        obj[el.locationId][el.id].offline =
          el.status === "active" ? false : true;
        obj[el.locationId][el.id].alarm = false;
        obj[el.locationId][el.id].alarmData = {};
        if (el.lastAlarms && Object.keys(el.lastAlarms).length) {
          obj[el.locationId][el.id].alarmData = el.lastAlarms;
          const errAlarms = Object.values(el.lastAlarms).filter(
            (val) => val === "error"
          );
          if (errAlarms.length > 0) obj[el.locationId][el.id].alarm = true;
        }

        const [temperature, carbon, humidity] =
          el.latestData && el.latestData.nodeData
            ? extractDeviceData(el.latestData.nodeData)
            : [null, null, null];
        latestDataObj[el.id] = {
          temperature,
          carbon,
          humidity,
        };
      });
      setLocationDeviceStatuses(obj);
      setLocationDeviceStatusLoaded(true);
      setDeviceLatestData(latestDataObj);
    }
  }, [props.allDevices]);

  useEffect(() => {
    if (socketNotificationEvent) {
      const data = JSON.parse(socketNotificationEvent);

      let needsToRedraw = false;
      let needsToUpdateLocationObject = false;

      if (
        ["success", "error"].includes(data.status) &&
        Object.keys(locationDeviceStatuses).length > 0
      ) {
        const newObj = {};
        for (const [key, devices] of Object.entries(locationDeviceStatuses)) {
          if (!newObj[key]) newObj[key] = {};

          for (const [deviceId, value] of Object.entries(devices)) {
            newObj[key][deviceId] = value;
            if (data.id === deviceId) {
              if (
                data.status === "success" &&
                data.type &&
                data.type === "alarm" &&
                value.alarm
              ) {
                showToast(data.msg, data.status, "bottom-right", false);

                var changedTheStatus = true;
                if (
                  value.alarmData &&
                  Object.values(value.alarmData).length > 0
                ) {
                  for (const [alarmDataType, alarmDataValue] of Object.entries(
                    value.alarmData
                  )) {
                    if (
                      alarmDataType !== data.alarmType &&
                      alarmDataValue === "error"
                    )
                      changedTheStatus = false;
                  }
                }
                newObj[key][deviceId].alarmData[data.alarmType] = "good";
                needsToUpdateLocationObject = true;

                if (changedTheStatus) {
                  newObj[key][deviceId].alarm = false;
                }
              }
              if (
                data.status === "success" &&
                data.type &&
                data.type === "offline" &&
                value.offline
              ) {
                showToast(data.msg, data.status, "bottom-right", false);
                needsToUpdateLocationObject = true;
                newObj[key][deviceId].offline = false;
              }
              if (data.status === "error") {
                showToast(data.msg, data.status, "bottom-right", false);
                setPlaying(true);
                if (!value.offline && data.type === "offline") {
                  needsToUpdateLocationObject = true;
                  newObj[key][deviceId].offline = true;
                }
                if (!value.alarm && data.type === "alarm") {
                  newObj[key][deviceId].alarmData[data.alarmType] = "error";
                  needsToUpdateLocationObject = true;
                  newObj[key][deviceId].alarm = true;
                }
              }
              if (data.status === "info") {
                showToast(data.msg, data.status, "bottom-right", false);
              }
            }
          }
        }
        if (needsToUpdateLocationObject) setLocationDeviceStatuses(newObj);
      }

      if (pointCoords.length && needsToUpdateLocationObject) {
        for (const eachDevice of pointCoords) {
          if (eachDevice.id === data.id) {
            needsToRedraw = true;
          }
        }
      }

      if (needsToRedraw && needsToUpdateLocationObject) setRedraw(true);
    }
  }, [socketNotificationEvent]);

  useEffect(() => {
    if (socketLiveDataEvent) {
      const data = JSON.parse(socketLiveDataEvent);

      if (data["deviceDBId"]) {
        const [temperature, carbon, humidity] = extractDeviceData(data);
        deviceLatestData[data["deviceDBId"]] = {
          temperature,
          carbon,
          humidity,
        };
        setDeviceLatestData({ ...deviceLatestData });
      }
    }
  }, [socketLiveDataEvent]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.addEventListener("wheel", handleWheel);
    return () => {
      canvas.removeEventListener("wheel", handleWheel);
    };
  }, [handleWheel]);

  useEffect(() => {
    if (redraw) {
      draw(scale, translatePos);
      setRedraw(false);
    }
  }, [redraw]);

  useEffect(() => {
    const handleThemeChange = () => {
      setThemeColor(
        document.documentElement.getAttribute("data-theme") === "light"
          ? "#e5e7eb"
          : "#313752"
      );
    };

    // Initial theme setting
    handleThemeChange();

    // Update theme color when the theme changes
    window.addEventListener("storage", handleThemeChange);

    return () => {
      window.removeEventListener("storage", handleThemeChange);
    };
  }, []);

  return (
    <div style={{ marginTop: "15px" }}>
      <div style={{ width: "100%", height: "70px", display: "flex" }}>
        <div className="form-group" style={{ width: "40%", display: "flex" }}>
          <div style={{ margin: "5px", width: "100px" }}>Choose Map:</div>
          <div style={{ marginLeft: "15px", width: "300px" }}>
            <Select
              styles={selectStyle}
              name="equipmentType"
              onChange={setCanvasImage}
              value={
                !selectedMap && mapList.length > 0
                  ? mapList[0]
                  : selectedMap.id
                  ? selectedMap
                  : { id: 0, title: "" }
              }
              options={mapList}
              getOptionLabel={(option) => `${option.title}`}
              getOptionValue={(option) => `${option}`}
            />
          </div>
          {role === "super_user" ? (
            <div style={{ marginLeft: "15px" }}>
              <button
                className="mr-2 float-right btn-labeled btn btn-danger"
                onClick={() => {
                  if (selectedMap && selectedMap.id) {
                    props.setModal({
                      showModal: true,
                      modalName: "deleteMap",
                      modalData: { id: selectedMap.id, deleteMap: deleteMap },
                    });
                  }
                }}
              >
                <span className="btn-label">
                  <i className="fa fa-minus" aria-hidden="true"></i>
                </span>
                Delete selected map
              </button>
            </div>
          ) : (
            <></>
          )}

          {muted ? (
            <div
              className="icon-volume-off"
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={() => setMuted(false)}
            ></div>
          ) : (
            <div
              className="icon-volume-2"
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={() => setMuted(true)}
            ></div>
          )}
        </div>
        {role === "super_user" ? (
          <div
            className="form-group"
            style={{ width: "50%", display: "flex", marginLeft: "20px" }}
          >
            <div style={{ margin: "5px", display: "flex" }}>
              Device radius:{" "}
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  backgroundColor: "green",
                  marginTop: "3px",
                  marginLeft: "5px",
                }}
              ></div>
            </div>
            <div style={{ marginLeft: "15px", position: "relative" }}>
              <div
                style={{
                  width: "30px",
                  position: "absolute",
                  top: "3px",
                  backgroundColor: themeColor,
                  height: "30px",
                  left: "3px",
                  textAlign: "center",
                  paddingTop: "3px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const r = radius - 1;
                  if (r >= minRadius) {
                    setRadius(r);
                  }
                }}
              >
                -
              </div>
              <Input
                type="number"
                style={{ textAlign: "center" }}
                onChange={(e) => {
                  if (e.target.value < minRadius) {
                    setRadius(minRadius);
                  } else if (e.target.value > maxRadius) {
                    setRadius(maxRadius);
                  } else {
                    setRadius(e.target.value);
                  }
                }}
                value={radius}
                onFocus={(event) => event.target.select()}
              />
              <div
                style={{
                  width: "30px",
                  position: "absolute",
                  top: "3px",
                  backgroundColor: themeColor,
                  height: "30px",
                  right: "3px",
                  textAlign: "center",
                  paddingTop: "3px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const r = radius + 1;
                  if (r <= maxRadius) {
                    setRadius(r);
                  }
                }}
              >
                +
              </div>
            </div>
            <div style={{ marginLeft: "15px", display: "flex" }}>
              {startAddPoly.started ? (
                <>
                  <div>
                    <button
                      className="mr-2 float-right btn-labeled btn btn-danger"
                      onClick={() => {
                        setPolyCoord([]);
                        setStartAddPoly({
                          started: false,
                          locationId: 0,
                          title: "",
                        });
                        setRedraw(true);
                      }}
                    >
                      <span className="btn-label">
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </span>
                      Remove location
                    </button>
                  </div>
                  <div>
                    <button
                      className="mr-2 float-right btn-labeled btn btn-info"
                      onClick={() => {
                        setPolyCoords((oldArray) => {
                          const newArr = [...oldArray, polyCoord];
                          setPolyCoord([]);
                          return newArr;
                        });
                        setStartAddPoly({
                          started: false,
                          locationId: 0,
                          title: "",
                        });
                      }}
                    >
                      <span className="btn-label">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </span>
                      Done
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ position: "relative" }}>
                    <button
                      className="mr-2 float-right btn-labeled btn btn-info"
                      onClick={() => {
                        if (locationsDisplay === "block") {
                          setLocationsDisplay("none");
                        } else {
                          setLocationsDisplay("block");
                        }
                      }}
                    >
                      <span className="btn-label">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </span>
                      Add Location
                    </button>

                    <div
                      style={{
                        position: "absolute",
                        display: locationsDisplay,
                        top: 40,
                        left: 0,
                        backgroundColor: "#166299",
                        color: "#fff",
                        width: "150px",
                        zIndex: "9",
                        borderRadius: "5px",
                      }}
                    >
                      {props.locations && isArray(props.locations) ? (
                        props.locations
                          .filter((e) => !isLocationUsed(e))
                          .map((el, index) => (
                            <div
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setStartAddPoly({
                                  started: true,
                                  locationId: el.id,
                                  title: el.name,
                                });
                                setLocationsDisplay("none");
                              }}
                            >
                              <div
                                style={{
                                  width: "80%",
                                  textAlign: "center",
                                  padding: "5px",
                                  borderTop: index != 0 ? "1px solid #fff" : "",
                                  margin: "auto",
                                }}
                              >
                                {el.name}
                              </div>
                            </div>
                          ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div style={{ position: "relative" }}>
                    <button
                      className="mr-2 float-right btn-labeled btn btn-info"
                      onClick={saveData}
                    >
                      <span className="btn-label">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </span>
                      Save
                    </button>
                  </div>
                  <div style={{ position: "relative" }}>
                    <button
                      className="mr-2 float-right btn-labeled btn btn-danger"
                      onClick={resetData}
                    >
                      <span className="btn-label">
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </span>
                      Reset
                    </button>
                  </div>
                </>
              )}
              <div
                style={{
                  position: "absolute",
                  display: devicePref.showSettings,
                  top: devicePref.y,
                  left: devicePref.x,
                  backgroundColor: "#000",
                  overflow: "auto",
                  maxHeight: "200px",
                  zIndex: "9",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#166299",
                    color: "#fff",
                    borderRadius: "5px",
                    cursor: "pointer",
                    padding: "4px",
                  }}
                  onClick={() => {
                    setDevicePref((prev) => ({
                      ...prev,
                      showDevices: "block",
                    }));
                  }}
                >
                  Add Device
                </div>
                <div
                  style={{
                    backgroundColor: "red",
                    color: "#000",
                    cursor: "pointer",
                    padding: "4px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    props.setModal({
                      showModal: true,
                      modalName: "deleteMapLocation",
                      modalData: {
                        deleteMapLocation: () => {
                          let filteredArray = pointCoords.filter(
                            (object) =>
                              object.locationId !== devicePref.locationId
                          );
                          setPointCoords(filteredArray);
                          setPolyCoords([
                            ...polyCoords.slice(0, devicePref.locationIndex),
                            ...polyCoords.slice(devicePref.locationIndex + 1),
                          ]);
                          setDevicePref({
                            showSettings: "none",
                            showDevices: "none",
                            locationId: 0,
                            locationIndex: -1,
                            x: 0,
                            y: 0,
                          });
                          props.setModal({
                            showModal: false,
                            modalName: "",
                            modalData: {},
                          });
                        },
                      },
                    });
                  }}
                >
                  Remove Location
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  display: devicePref.showDevices,
                  top: devicePref.y,
                  left: devicePref.x,
                  backgroundColor: "#272934",
                  color: "#fff",
                  borderRadius: "5px",
                  overflow: "auto",
                  maxHeight: "200px",
                  zIndex: "9",
                }}
              >
                {props.allDevices && isArray(props.allDevices) ? (
                  <>
                    <Input
                      type="text"
                      onChange={(e) => {
                        setDeviceSearch(e.target.value);
                      }}
                      placeholder="Search..."
                      value={deviceSearch}
                    />
                    {props.allDevices.map(
                      (el, index) =>
                        devicePref.locationId === el.locationId &&
                        (deviceSearch.length < 2 ||
                          el.name
                            .toLowerCase()
                            .includes(deviceSearch.toLowerCase())) &&
                        !isDeviceUsed(el) && (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setPointCoords((oldArray) => {
                                const newArr = [
                                  ...oldArray,
                                  {
                                    id: el.id,
                                    deviceId: el.deviceId,
                                    name: el.name,
                                    offline: el.status,
                                    locationId: devicePref.locationId,
                                    x: devicePref.x,
                                    y: devicePref.y,
                                    imageX: translatePos.x,
                                    imageY: translatePos.y,
                                    scale: scale,
                                  },
                                ];

                                return newArr;
                              });
                              setDevicePref({
                                showSettings: "none",
                                showDevices: "none",
                                locationId: 0,
                                locationIndex: -1,
                                x: 0,
                                y: 0,
                              });
                            }}
                          >
                            <div
                              style={{
                                width: "80%",
                                textAlign: "center",
                                padding: "10px",
                                borderBottom:
                                  index != 0 ? "1px solid #fff" : "",
                                margin: "auto",
                              }}
                            >
                              {el.name}
                            </div>
                          </div>
                        )
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  display: deviceRemovePref.display,
                  top: deviceRemovePref.y,
                  left: deviceRemovePref.x,
                  border: "1px solid #000",
                  borderRadius: "10%",
                  zIndex: "9",
                }}
              >
                {!deviceRemovePref.isDeviceRemoved && (
                  <div
                    style={{
                      backgroundColor: "#166299",
                      color: "#fff",
                      borderRadius: "5px",
                      cursor: "pointer",
                      padding: "4px",
                    }}
                    onClick={(evt) => {
                      const xCoord = evt.clientX - canvasSettings.offsetX;
                      const yCoord = evt.clientY - canvasSettings.offsetY;
                      setMoveDevice({
                        index: deviceRemovePref.index,
                        mouseX: xCoord,
                        mouseY: yCoord,
                        deviceX: deviceRemovePref.deviceX,
                        deviceY: deviceRemovePref.deviceY,
                      });
                      setDeviceRemovePref({
                        display: "none",
                        index: null,
                        x: 0,
                        y: 0,
                        deviceX: 0,
                        deviceY: 0,
                        isDeviceRemoved: false,
                      });
                      setShowDeviceInfo({
                        show: false,
                        name: "",
                        id: null,
                        top: 0,
                        left: 0,
                        isDeviceRemoved: false,
                      });
                    }}
                  >
                    Move
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: "red",
                    color: "#000",
                    cursor: "pointer",
                    padding: "4px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    props.setModal({
                      showModal: true,
                      modalName: "deleteMapDevice",
                      modalData: {
                        deleteMapDevice: () => {
                          setPointCoords([
                            ...pointCoords.slice(0, deviceRemovePref.index),
                            ...pointCoords.slice(deviceRemovePref.index + 1),
                          ]);
                          setDevicePref({
                            showSettings: "none",
                            showDevices: "none",
                            locationId: 0,
                            locationIndex: -1,
                            x: 0,
                            y: 0,
                          });
                          setDeviceRemovePref({
                            display: "none",
                            index: null,
                            x: 0,
                            y: 0,
                            deviceX: 0,
                            deviceY: 0,
                            isDeviceRemoved: false,
                          });
                          props.setModal({
                            showModal: false,
                            modalName: "",
                            modalData: {},
                          });
                        },
                      },
                    });
                  }}
                >
                  Remove
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          style={{
            marginLeft: "auto",
            order: 2,
          }}
        >
          {role === "super_user" ? (
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={addMap}
            >
              <span className="btn-label">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
              Add Map
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseUp={removeMouseDown}
          onMouseOver={removeMouseDown}
          onMouseLeave={removeMouseDown}
          onMouseMove={handleMouseMove}
          onClick={handleMouseClick}
          onContextMenu={handleContextMenu}
          width={canvasSettings.width}
          height={canvasSettings.height}
        />
        <div
          id="buttonWrapper"
          style={{ position: "absolute", top: "0", right: "0" }}
        >
          <input
            type="button"
            id="plus"
            value="+"
            style={{
              width: "40px",
              height: "40px",
              cursor: "pointer",
              backgroundColor: "#D3D3D3 !important",
              color: "#000 !important",
              fontSize: "20px",
              borderRadius: "20%",
            }}
            onClick={upScale}
          />
          <input
            type="button"
            id="minus"
            value="-"
            style={{
              width: "40px",
              height: "40px",
              cursor: "pointer",
              backgroundColor: "#D3D3D3 !important",
              color: "#000 !important",
              fontSize: "20px",
              borderRadius: "20%",
            }}
            onClick={downScale}
          />
        </div>
        {showDeviceInfo.show ? (
          <div
            style={{
              position: "absolute",
              top: showDeviceInfo.top - 10,
              left: showDeviceInfo.left,
              backgroundColor: "#D3D3D3",
              color: "#000",
              padding: "3px",
              zIndex: "9",
              borderRadius: "10px",
            }}
          >
            {showDeviceInfo.name}
            {showDeviceInfo.isDeviceRemoved ? (
              <>
                <div style={{ color: "red" }}>
                  <div>Device has been removed from this </div>
                  <div>location and needs to be relocated </div>
                  <div>to receive further status updates.</div>
                </div>
              </>
            ) : (
              <>
                {console.log("showDeviceInfo = ", showDeviceInfo)}
                {console.log("deviceLatestData = ", deviceLatestData)}
                {showDeviceInfo.id && deviceLatestData[showDeviceInfo.id] ? (
                  <>
                    <div>
                      Temp:{" "}
                      {deviceLatestData[showDeviceInfo.id].temperature &&
                      deviceLatestData[showDeviceInfo.id].temperature != -255
                        ? props &&
                          props.temperatureUnit &&
                          props.temperatureUnit === "Celsius"
                          ? deviceLatestData[showDeviceInfo.id].temperature +
                            " °C"
                          : (
                              (deviceLatestData[showDeviceInfo.id].temperature *
                                9) /
                                5 +
                              32
                            ).toFixed(2) + " °F"
                        : "NA"}
                    </div>
                    <div>
                      Hum:{" "}
                      {deviceLatestData[showDeviceInfo.id].humidity &&
                      deviceLatestData[showDeviceInfo.id].humidity != -255
                        ? deviceLatestData[showDeviceInfo.id].humidity + " %"
                        : "NA"}
                    </div>
                    <div>
                      Co2:{" "}
                      {deviceLatestData[showDeviceInfo.id].carbon &&
                      deviceLatestData[showDeviceInfo.id].carbon != -255
                        ? deviceLatestData[showDeviceInfo.id].carbon + " %"
                        : "NA"}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locations: state.locationsDevices.locations,
    allDevices: state.locationsDevices.allDevices,
    temperatureUnit: state.auth.temperatureUnit,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllDevices: () => dispatch(actions.getAllDevices()),
    getLocations: (data) => dispatch(actions.getLocations(data)),
    setModal: (data) => dispatch(actions.setModal(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Map);
